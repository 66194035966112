import React from 'react';

const CreditCard = ({ className, ...props }: SVGProps) => (
  <svg
    {...props}
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99787 14.4H3.20107V12.8H7.99787V14.4Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.6 0H2.4C1.07452 0 0 1.07452 0 2.4V15.2C0 16.5255 1.07452 17.6 2.4 17.6H21.6C22.9255 17.6 24 16.5255 24 15.2V2.4C24 1.07452 22.9255 0 21.6 0ZM1.6 8V6.4H22.4V8H1.6ZM1.6 4.8H22.4V2.4C22.4 1.95817 22.0418 1.6 21.6 1.6H2.4C1.95817 1.6 1.6 1.95817 1.6 2.4V4.8ZM1.6 9.6H22.4V15.2C22.4 15.6418 22.0418 16 21.6 16H2.4C1.95817 16 1.6 15.6418 1.6 15.2V9.6Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
  </svg>
);

export default React.memo(CreditCard);
