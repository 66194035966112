const {goToNextStep} = require("../../../utils/nextStepAction");
const uuidv4 = require("uuid/v4");
const {PAGE_ACTIONS} = require("../../../../constants/app");

const useActions = (
  {
    flow,
    history,
    stepActions,
    captcha,
    requestActions,
    captchaConfig = {},
    captchaToken = {},
    validations,
    value
  }) => {

  const requestId = uuidv4();

  const nextStepAction = () => {

    if(validations.invalid || value === '') {
      return null;
    }

    const data = {
      id: PAGE_ACTIONS.NEXT_STEP,
      values: {
        'payment-amount': value
      },
    };

    return goToNextStep(
      {
        data,
        flow,
        history,
        stepActions,
        captcha,
        requestActions,
        requestId,
        captchaConfig,
        captchaToken,
      });
  };

  return {
    nextStepAction
  }
}

module.exports = useActions;
