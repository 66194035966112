const React = require('react');

const PropTypes = require('prop-types');

const {bindActionCreators} = require('redux');
const StickyPayButton = require('@cow/core-components/components/StickyPayButton');
const PaymentSummaryMobile = require('@cow/core-components/components/PaymentSummaryMobile');
const Info = require('@andes/icons/Info16');

const translate = require('../../../../translation');
const { COMMONS, APP, ANDES_STYLES_THEME, COLORS, LINKS, SELECTORS: { AUTO_HEIGHT } } = require('../../../../../constants');
const {getCompanyTheme} = require('../../../review/templates/utils/configurations');
const PaymentMethodInfo = require('../../components/PaymentMethodInfo');
const {
  handleSummaryItemProps,
  PAYMENT_SUMMARY_ALIGNMENT,
  hasBrand,
  isCollapsible,
  getMobileTotal,
} = require('../../../../utils/summary');
const {buildSubscriptionFreeTrialMessage} = require('../../../review/templates/utils/subscription');
const {propsPaymentMethodInfo, splitPaymentsProps, propsPCJForMobile} = require('../../utils/formatProps');
const useActions = require('../../hooks/useActions');
const {PAGE_ACTIONS} = require('../../../../../constants/app');
const SNACKBAR_ACTIONS = require('../../../../spa/actions/snackbar');
const STEP_ACTIONS = require('../../../../spa/actions/step');
const ANIMATION_ACTIONS = require('../../../../spa/actions/animations');
const CAPTCHA_TOKEN_ACTIONS = require('../../../../spa/actions/captchaToken');
const REQUEST_ACTIONS = require('../../../../spa/actions/request');
const LOADING_ACTIONS = require('../../../../spa/actions/loading');
const {useNavbar, useControlSummary} = require('../../../../hooks/useNavbar');
const {Tooltip} = require('@andes/tooltip');
const TyCCredits = require('../../../../containers/TyCCredits');
const DynamicContent = require('../../../../components/DynamicContent');

const Layout = require('../../../../components/LayoutContainer');
const LegalsFooter = require('../../../../components/LegalsFooter');
const BackLink = require("@cow/core-components/components/BackLink");
const {sanitizeUrl} = require("../../../../utils/UrlUtils");
const ShippingCard = require("../../../../components/ShippingCard");
const connectToReduxAndInjectI18n = require('../../../../utils/connectToRedux');
const ButtonBackURLs = require("../../../../components/ButtonBackURLs");
const Footer = require("@cow/core-components/components/Footer");
const {hasCaptcha} = require("../../../../utils/terms-and-conditions");


const {TERMS_AND_CONDITIONS} = LINKS;

const {
  COLORS: {PRIMARY},
  SIZES: {S},
  WEIGHT: {SEMI_BOLD}
} = ANDES_STYLES_THEME;

const {SUMMARY_ITEMS_TYPES} = APP;
const { THEME, CHECKOUT_CASE: {SUBSCRIPTION}, TOOLTIP_TYPE: {DARK}, ANDES_PLACEMENT: {BOTTOM}} = COMMONS;

const CombinedPaymentReviewMobileTemplate = (props) => {

  const translations = translate(props.i18n);

  const templateCase = props.templateCase || 'default';

  const tooltipContainer = React.createRef();

  const [showDiscountModal, setShowDiscountModal] = React.useState(false);

  const {handleOpenNavbar, isOpenedSummary, handleCollapsedSummary} = useControlSummary();


  const {
    flow,
    siteId,
    blockedByCaptcha,
    requestLoading,
    loadingPayButton,
    loadingStopAnimation,
    history,
    stepActions,
    captcha,
    requestActions,
    loadingActions,
    setEmailErrorMessage,
    email,
    emailErrorMessage,
    brand,
    configs,
    rebrandingMP,
    emailComponent,
    deviceType,
    analytics,
    currentStep,
    trackingPath,
    urls,
    captchaToken,
    captchaConfig,
    step_model: {
      pcj,
      challenge_instructions,
      terms_and_conditions: termsAndConditions,
      main_action,
      transaction_type,
      payment_methods,
      subscription,
      incentives,
      step_title,
      payer,
      summary: summaryRaw,
      split_payment,
      navigation,
      shipping,
    },
  } = props;

  const summary = {
    ...summaryRaw,
    total: {
      ...summaryRaw?.total,
      nameWeight: SEMI_BOLD,
      priceWeight: SEMI_BOLD,
    }
  };

  const {changePaymentMethod, pay, changeShippingAddress, changeShippingOption} = useActions({
    flow,
    history,
    stepActions,
    captcha,
    requestActions,
    challengesInstructions: challenge_instructions,
    payer,
    loadingActions,
    setEmailErrorMessage,
    email,
    emailErrorMessage,
    translations,
    captchaToken,
    captchaConfig,
  });

  const [isCouponVisible, setIsCouponVisible] = React.useState(false);

  const {showNavbar} = useNavbar(flow, payer.is_guest, configs?.isWebview);

  const theme = getCompanyTheme(props.configs?.internalConfigurations);

  const SummaryTheme = React.useMemo(
    () => (transaction_type === SUBSCRIPTION || subscription !== null ? THEME.DARK : THEME.LIGHT),
    [transaction_type, subscription]
  );

  const progressDone = loadingStopAnimation && requestLoading;

  const hasDiscountCodeApplied = summary.items.find((item) => item.type === SUMMARY_ITEMS_TYPES.COUPON_DISCOUNT);

  const isPaymentSummaryCollapsible = isCollapsible(summary?.items?.length, transaction_type);

  const [firstPaymentMethod, secondPaymentMethod] = propsPaymentMethodInfo({
    siteId,
    translations,
    rebrandingMP,
    paymentMethods: payment_methods,
    isSplitCard: split_payment
  });

  const splitPayments = splitPaymentsProps(payment_methods, translations.YOU_ARE_PAYING_WITH);

  const items = summary?.items?.map((item) =>
    handleSummaryItemProps({
      item,
      summary,
      translations,
      deviceType: props.deviceType,
      tooltipContainer,
      setShowDiscountModal,
      iconAlignment: isPaymentSummaryCollapsible
        ? PAYMENT_SUMMARY_ALIGNMENT.LEFT
        : PAYMENT_SUMMARY_ALIGNMENT.RIGHT,
      isSubscription: transaction_type === SUBSCRIPTION,
      type: SUMMARY_ITEMS_TYPES.PCJ,
      nameColor: ANDES_STYLES_THEME.COLORS.SECONDARY,
    }),
  );

  if(items.length) {
    items[0] = {
      ...items[0],
      nameColor: ANDES_STYLES_THEME.COLORS.SECONDARY,
      priceColor: ANDES_STYLES_THEME.COLORS.PRIMARY,
    };
  }

  if (pcj?.is_applicable) {
    const icon = (
      <Tooltip
        className="pcj-tooltip"
        type={DARK}
        content={translations.THERE_IS_A_PRICE_DIFFERENCE_DEPENDING_ON_THE_NUMBER_OF_INSTALLMENTS_YOU_CHOOSE}
        side={BOTTOM}
        closable
      >
        <Info/>
      </Tooltip>
    );
    const {acrescimo, juros, price} = pcj;
    items.push(propsPCJForMobile(price?.name, price?.price?.text_value));
    items.push(propsPCJForMobile(acrescimo?.name, acrescimo?.price?.text_value, icon));
    if (juros) {
      items.push(propsPCJForMobile(
        juros?.name,
        juros?.price?.text_value,
        null,
        PRIMARY,
        S,
        PRIMARY,
        S,
      ));
    }
  }

  const tyCGeneralLink = TERMS_AND_CONDITIONS[siteId.toUpperCase()];

  const sidebarContent = (
    <PaymentSummaryMobile
      isUserLogged={!payer?.is_guest}
      title={summary?.title}
      isCollapsible
      items={items}
      total={getMobileTotal(summary, transaction_type, subscription, null, splitPayments)}
      brand={hasBrand(brand)}
      autoHeightClassname={transaction_type === SUBSCRIPTION ? AUTO_HEIGHT : ''}
      aspirational={buildSubscriptionFreeTrialMessage(subscription)}
      splitPayment={splitPayments}
      withNavbar={showNavbar}
      open={isOpenedSummary}
      onCollapsed={handleCollapsedSummary}
    />
  );

  const mainContent = (
    <div className="content">

      <PaymentMethodInfo
        {...firstPaymentMethod}
        actionName={PAGE_ACTIONS.CHANGE_PAYMENT_OPTION_FIRST}
        changePaymentMethod={changePaymentMethod}
      />

      <PaymentMethodInfo
        {...secondPaymentMethod}
        actionName={PAGE_ACTIONS.CHANGE_PAYMENT_OPTION_SECOND}
        changePaymentMethod={changePaymentMethod}
      />

      <ShippingCard
        shipping={shipping}
        translations={translations}
        changeShippingOption={changeShippingOption}
        changeShippingAddress={changeShippingAddress}
      />

      <DynamicContent
        defaultContent={emailComponent}
      />

      {/* Back link */}
      {navigation?.back_urls?.failure?.length > 0 && (
        <ButtonBackURLs label={translations.RETURN_TO_SITE} url={navigation.back_urls.failure} />
      )}

      <div className="tyc">
        {
          termsAndConditions?.credits?.has_detail &&
          <TyCCredits text={termsAndConditions?.credits?.text} contractUrl={termsAndConditions?.credits?.detail}
                      tycUrl={tyCGeneralLink}/>
        }
      </div>

      <div className="footer-terms">
        <Footer
          requiredMessage={translations.PROCESSED_BY_MERCADO_PAGO}
          requiredScreenReaderMessage={translations.SCREEN_READER_FOOTER_MESSAGE}
          deviceType={props.deviceType}
          optionalInformation={hasCaptcha(termsAndConditions?.has_captcha, translations)}
        />
      </div>
    </div>
  );

  const footerContent = (
    <>
      <StickyPayButton
        label={main_action}
        onPayClick={pay}
        withBackgroundOnHandleScroll
        securityPaymentInformationLabel={incentives?.is_secure_payment ? translations.SECURE_PAYMENT : null}
        payButtonHelperText={subscription?.instructions}
        loadingButton={loadingPayButton && !progressDone}
        disablePayButton={blockedByCaptcha || (isCouponVisible && !hasDiscountCodeApplied)}
      />
    </>
  );

  const stepTitle = {
    text: shipping ? translations.CHECK_YOUR_PURCHASE : translations.CHECK_YOUR_PAYMENT
  }


  return (
    <Layout
      brand={brand}
      deviceType={deviceType}
      footerContent={footerContent}
      history={history}
      mainContent={mainContent}
      payer={payer}
      sidebarContent={sidebarContent}
      siteId={siteId}
      stepTitle={stepTitle}
      templateCase={templateCase}
      theme={theme}
      translations={translations}
      currentStep={currentStep}
      trackingPath={trackingPath}
      analytics={analytics}
      urls={urls}
      handleOpenNavbar={handleOpenNavbar}
      navigation={navigation}
    />
  );
};

const mapStateToProps = (state) => ({
  flow: state.page.flow,
  configs:
  state.configurations,
  firstRender:
  state.page.firstRender,
  basePath:
  state.configurations.basePath,
  captchaConfig:
  state.configurations.captcha,
  browserName:
  state.configurations.browserName,
  blockedByCaptcha: state.captchaToken.blockedByCaptcha,
  loadingPayButton: state.request.loadingPayButton,
  requestLoading: state.request.loading,
  loadingStopAnimation: state.request.loadingStopAnimation,
});

const mapDispatchToProps = (dispatch) => ({
  snackbarActions: bindActionCreators(SNACKBAR_ACTIONS, dispatch),
  stepActions: bindActionCreators(STEP_ACTIONS, dispatch),
  animationActions: bindActionCreators(ANIMATION_ACTIONS, dispatch),
  captchaToken: bindActionCreators(CAPTCHA_TOKEN_ACTIONS, dispatch),
  requestActions: bindActionCreators(REQUEST_ACTIONS, dispatch),
  loadingActions: bindActionCreators(LOADING_ACTIONS, dispatch),
});

CombinedPaymentReviewMobileTemplate.propTypes = {
  flow: PropTypes.object,
  siteId: PropTypes.string,
  blockedByCaptcha: PropTypes.bool,
  requestLoading: PropTypes.bool,
  deviceType: PropTypes.string,
  loadingPayButton: PropTypes.bool,
  loadingStopAnimation: PropTypes.bool,
  stepActions: PropTypes.array,
  history: PropTypes.object,
  requestActions: PropTypes.array,
  loadingActions: PropTypes.array,
  setEmailErrorMessage: PropTypes.func,
  emailErrorMessage: PropTypes.string,
  email: PropTypes.string,
  step_model: PropTypes.object,
  rebrandingMP: PropTypes.bool,
  captcha: PropTypes.bool,
  snackbarActions: PropTypes.array,
  stepTitle: PropTypes.string,
  i18n: PropTypes.object,
  brand: PropTypes.object,
  configs: PropTypes.array.isRequired,
  emailComponent: PropTypes.node,
  analytics: PropTypes.object,
  currentStep: PropTypes.string,
  trackingPath: PropTypes.string,
  urls: PropTypes.array,
  captchaToken: PropTypes.object,
  captchaConfig: PropTypes.object,
};

module.exports = connectToReduxAndInjectI18n(
  CombinedPaymentReviewMobileTemplate,
  mapStateToProps,
  mapDispatchToProps
);
