import React from 'react';

const Bank = ({ ...props }: SVGProps) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.19353 6.2124L11.9162 0L22.6412 6.21235V9.98955L20.1733 9.9923V18.2305H21.537L24 24H0L2.2781 18.2305H3.67201V10.0107L1.19353 10.0134V6.2124ZM3.402 19.8741L2.42186 22.3564H21.5055L20.4458 19.8741H3.402ZM18.5235 18.2305V9.99413L12.7412 10.0006V18.2305H18.5235ZM5.32183 10.0088L11.0914 10.0024V18.2305H5.32183V10.0088ZM2.84335 7.15788V8.36798L20.9914 8.34778V7.15794L11.9163 1.90129L2.84335 7.15788Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
  </svg>
);

export default React.memo(Bank);
