const { sendDatadogMetric } = require('./api');
const { REVIEW_EXPRESS, REVIEW_SAVED_CARD, REVIEW_CREDITS, ACTION_REVIEW_METRICS } = require('../../constants/metrics');
const {
  COMMONS: {
    CHECKOUT_CASE: { EXPRESS, SAVED_CARD, CREDITS },
  },
} = require('../../constants');

const reviewMetricsURL = '/metrics/review';

const sendReviewMetric = ({ url, ...tags }) => {
  sendDatadogMetric(url, { tags });
};

const metricsActions = (actions, interface_express) => ({
  [ACTION_REVIEW_METRICS.LOADED]: ({ device }) => {
    sendReviewMetric({
      url:reviewMetricsURL,
      device,
      interface_express,
      action: actions.LOADED,
    });
  },
  [ACTION_REVIEW_METRICS.CHANGED]: ({ device }) => {
    sendReviewMetric({
      url:reviewMetricsURL,
      device,
      interface_express,
      action: actions.CHANGED,
    });
  },
  [ACTION_REVIEW_METRICS.PAY]: ({ device }) => {
    sendReviewMetric({
      url:reviewMetricsURL,
      device,
      interface_express,
      action: actions.PAY,
    });
  },
})


const metricsStrategy = {
  credits: metricsActions(REVIEW_CREDITS.ACTION, CREDITS),
  default: metricsActions(REVIEW_EXPRESS.ACTION, EXPRESS),
  saved_card: metricsActions(REVIEW_SAVED_CARD.ACTION, SAVED_CARD),
  subscription_saved_card: metricsActions(REVIEW_SAVED_CARD.ACTION, SAVED_CARD),
};

module.exports = metricsStrategy