/* eslint-disable import/order */
/* eslint-disable react/jsx-no-bind */

// React y bibliotecas de terceros
const React = require('react');
const PropTypes = require('prop-types');

// Redux
const connectToReduxAndInjectI18n = require('../../../../../utils/connectToRedux');
const { bindActionCreators } = require('redux');
const EXPRESS_ACTIONS = require('../../../../../spa/actions/express');
const { EXPRESS_VALIDATE_CARD } = require('../../../../../spa/actions/types');

// i18n
const { injectI18n } = require('nordic/i18n');
const translate = require('../../../../../translation');

// Cow components
const CardList = require('@cow/core-components/components/CardListV2');

// Custom Components
const ReviewBase = require('..');
const BottomContent = require('../../components/CardBottomContent');

// Custom hooks
const { mobile: { CONTEXT: useExpressContext } } = require('../../containers/ReviewProvider');
const { usePaymentCard } = require('../../hooks/usePaymentCard');

// Constants
const {
    COMMONS: {
        DEVICE_TYPE: { MOBILE },
    },
} = require('../../../../../../constants');

// Utils
const escValidateCard = require('../../utils/security-code/escValidateCard');
const { shouldRenderSecurityCode, shouldRenderInstallments } = require('../../utils/buildPayment');


const ExpressMobileView = (props) => {
    const {
        i18n,
        rebrandingMP,
        requests,
        express,
        expressActions
    } = props;

    const expressProps = useExpressContext();
    const translation = translate(i18n);

    const {
        step_model: {
            discount,
            payment_methods: paymentMethods
        },
        handleChangePaymentMethod
    } = expressProps

    const { paymentCard } = usePaymentCard({
        paymentMethods,
        rebrandingMP,
        handleChangePaymentMethod,
        modifyText: translation.MODIFY
    });

    const renderBottomContentList = React.useMemo(() => {
        const escCookie = escValidateCard(paymentMethods[0]?.option_id, requests, express, paymentMethods[0]?.esc_available);
        if (escCookie) {
            expressActions[EXPRESS_VALIDATE_CARD](paymentMethods[0]?.option_id, escCookie);
        }

        const renderSecurityCode = shouldRenderSecurityCode(paymentMethods[0]) && !escCookie;
        const renderInstallments = shouldRenderInstallments(paymentMethods[0]);

        if ((!renderSecurityCode && !renderInstallments) ||
            (typeof document === 'undefined')) {
            return null;
        }

        return (
            <BottomContent
                paymentMethod={paymentMethods[0]}
                discount={discount}
                renderSecurityCode={renderSecurityCode}
                renderInstallments={renderInstallments}
            />
        );
    }, [paymentMethods, discount]);

    const renderCustomCardList = React.useMemo(() => <CardList
        deviceType={MOBILE}
        items={paymentCard}
        withFooterBorder={false}
        bottomContentList={renderBottomContentList}
    />, [paymentCard, renderBottomContentList]);

    return <ReviewBase
        {...expressProps}
        customCardList={renderCustomCardList}
    />
}

ExpressMobileView.propTypes = {
    i18n: PropTypes.object.isRequired,
    rebrandingMP: PropTypes.bool,
    expressActions: PropTypes.shape({
        [EXPRESS_VALIDATE_CARD]: PropTypes.func.isRequired,
    }).isRequired,
    requests: PropTypes.object.isRequired,
    express: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    expressActions: bindActionCreators(EXPRESS_ACTIONS, dispatch),
});

const mapStateToProps = (state) => ({
    express: state.express,
    requests: state.request,
    rebrandingMP: state.configurations.rebrandingMP,
});


module.exports = connectToReduxAndInjectI18n(ExpressMobileView, mapStateToProps, mapDispatchToProps);
