const React = require('react');

const {Button} = require('@andes/button');

const {bindActionCreators} = require('redux');
const PropTypes = require('prop-types');

const translate = require('../../../../translation');
const {ANDES_STYLES_THEME} = require('../../../../../constants');
const {getCompanyTheme} = require('../../../review/templates/utils/configurations');

const Layout = require('../../../../components/LayoutContainer');
const connectToReduxAndInjectI18n = require("../../../../utils/connectToRedux");
const useActions = require("../../hooks/useActions");
const SNACKBAR_ACTIONS = require("../../../../spa/actions/snackbar");
const STEP_ACTIONS = require("../../../../spa/actions/step");


const SwitchCombinations = require('../../components/SwitchCombinations');
const OptionListCombination = require('../../components/OptionListCombination');
const {Snackbar} = require("@andes/snackbar");
const PaymentSummaryMobile = require("@cow/core-components/components/PaymentSummaryMobile");
const StickyContainer = require("../../../../components/Groups/StickyContainer");
const {defaultProps} = require("../../../../containers/Optimus/helper");
const Footer = require("@cow/core-components/components/Footer");

const {useState, useMemo} = React;

const PaymentOptionCombinationsMobile = (props) => {

  const translations = translate(props.i18n);

  const [validations, setValidations] = useState({});

  const {
    flow,
    siteId,
    deviceType,
    stepActions,
    history,
    requestActions,
    rebrandingMP,
    analytics,
    currentStep,
    trackingPath,
    urls,
    captchaToken,
    captchaConfig,
    currency,
    step_model: {
      brand,
      payment_options: paymentOptions,
      step_title,
      payer,
      captcha,
      summary,
      navigation,
      stepper,
      totalAmountPay
    },
  } = props;

  const theme = getCompanyTheme(props.configs?.internalConfigurations);

  const [selected, setSelected] = useState(paymentOptions?.length === 1 ? {
    id: paymentOptions[0].option_id,
    method: paymentOptions[0].id,
    type: paymentOptions[0].type,
  } : null);

  const {submit, snackbarMessage, showSnackbar, unableCombinations} = useActions({
    flow,
    history,
    stepActions,
    captcha,
    requestActions,
    captchaConfig,
    captchaToken,
    validations,
    selected,
    stepper,
    translations
  });

  const templateCase = props.templateCase || 'default';

  const summaryProps = {
    isUserLogged: true,
    title: summary?.title,
    total: {
      name: summary.items[0]?.name,
      price: summary.total?.price.text_value,
    },
    isCollapsible: false,
    withNavbar: true,
  };

  const mainContent = (
    <>
      <SwitchCombinations
        translations={translations}
        unableCombination={() => unableCombinations()}
        stepper={stepper}
      />
      <OptionListCombination
        paymentOptions={paymentOptions}
        translations={translations}
        stepper={stepper}
        selected={selected}
        handleSelect={setSelected}
        deviceType={deviceType}
        totalAmountPay={totalAmountPay}
        currency={currency}
        rebrandingMP={rebrandingMP}
      />
      <Snackbar show={showSnackbar} message={snackbarMessage}/>
      <StickyContainer
        {...defaultProps()}
        step={currentStep}
        paymentOptionsSelected={selected ? [selected] : []}
        isControlled
      >
        <div className="actions-payment-option-combination">
          <Button type="button" onClick={() => submit()}>{translations.CONTINUE}</Button>
          {
            stepper === 2 && <Button
              hierarchy="quiet"
              type="button"
              onClick={(event) => {
                event.preventDefault();
                window.history.back();
              }}
            >
              {translations.TO_GO_BACK}
            </Button>
          }
        </div>
      </StickyContainer>
    </>
  );

  const sidebarContent = (
    <PaymentSummaryMobile {...summaryProps}  />
  );

  const footerContent = useMemo(() => (
    <>
      {
        stepper === 1 &&
        <Footer
          requiredMessage={translations.PROCESSED_BY_MERCADO_PAGO}
          requiredScreenReaderMessage={translations.SCREEN_READER_FOOTER_MESSAGE}
          deviceType={props.deviceType}
        />
      }
    </>
  ), [selected]);

  const stepTitle = {
    text: step_title,
    size: ANDES_STYLES_THEME.SIZES.S,
  }

  return (
    <Layout
      brand={brand}
      deviceType={deviceType}
      history={history}
      mainContent={mainContent}
      footerContent={footerContent}
      payer={payer}
      sidebarContent={sidebarContent}
      siteId={siteId}
      stepTitle={stepTitle}
      templateCase={templateCase}
      theme={theme}
      translations={translations}
      currentStep={currentStep}
      trackingPath={trackingPath}
      analytics={analytics}
      urls={urls}
      navigation={navigation}
    />
  );

};

const mapStateToProps = (state) => ({
  flow: state.page.flow,
  configs: state.configurations,
  firstRender: state.page.firstRender,
  basePath: state.configurations.basePath,
  currency: state.configurations.currency,
  captchaConfig:
  state.configurations.captcha,
  browserName:
  state.configurations.browserName,
  blockedByCaptcha: state.captchaToken.blockedByCaptcha,
  loadingPayButton: state.request.loadingPayButton,
  requestLoading: state.request.loading,
  loadingStopAnimation: state.request.loadingStopAnimation,
});

const mapDispatchToProps = (dispatch) => ({
  snackbarActions: bindActionCreators(SNACKBAR_ACTIONS, dispatch),
  stepActions: bindActionCreators(STEP_ACTIONS, dispatch),
});

PaymentOptionCombinationsMobile.propTypes = {
  flow: PropTypes.object,
  siteId: PropTypes.string,
  blockedByCaptcha: PropTypes.bool,
  requestLoading: PropTypes.bool,
  deviceType: PropTypes.string,
  loadingPayButton: PropTypes.bool,
  loadingStopAnimation: PropTypes.bool,
  stepActions: PropTypes.object,
  history: PropTypes.object,
  requestActions: PropTypes.object,
  loadingActions: PropTypes.object,
  setEmailErrorMessage: PropTypes.func,
  emailErrorMessage: PropTypes.string,
  email: PropTypes.string,
  step_model: PropTypes.object,
  rebrandingMP: PropTypes.bool,
  analytics: PropTypes.element,
  currentStep: PropTypes.string,
  trackingPath: PropTypes.string,
  urls: PropTypes.array,
  captchaToken: PropTypes.object,
  captchaConfig: PropTypes.object,
};


module.exports = connectToReduxAndInjectI18n(
  PaymentOptionCombinationsMobile,
  mapStateToProps,
  mapDispatchToProps
);
