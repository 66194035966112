const apiService = require('../service/api');
const {
  SUBMIT_FORM_ID,
  PLATFORM,
  URLS: { BASE_URL_MERCADO_LIBRE },
  NATIVE_APP_NAME_MAP,
  LOGIN_TYPE,
} = require('../../constants/app');
const internalConfigurationsEnabled = require('./internalConfigurations');
const { createInternalWebviewDeeplink, buildSniffingWebviewParams } = require('./deeplinks');

class LoginPopUp {
  /**
   * Generate the login URL (MercadoLibre)
   * @param configurations
   * @param callbackUrl - url string without being encoded
   * @returns {string}
   */
  static generateLoginURL(configurations = {}, callbackUrl) {
    const platform = configurations.platform || {};
    const siteId = (platform.siteId || '').toLowerCase();
    const { enableMlTheme } = internalConfigurationsEnabled(configurations.internalConfigurations);
    const platformId = enableMlTheme ? PLATFORM.ML : (platform.id || '').toLowerCase();
    const encodedCallback = encodeURIComponent(callbackUrl);
    const loginType = configurations.loginType || LOGIN_TYPE.STANDARD;
    const flowId = configurations.flowId;
    const callbackMP = LoginPopUp.buildLoginURL(siteId, 'mp', encodedCallback, loginType);
    const encodedCallbackMP = encodeURIComponent(callbackMP);

    const callback = enableMlTheme ? encodedCallbackMP : encodedCallback;
    return LoginPopUp.buildLoginURL(siteId, platformId, callback, loginType, flowId);
  }

  static buildLoginURL(siteId, platformId, encodedCallback, loginType, flowId) {
    return `${BASE_URL_MERCADO_LIBRE}/jms/${siteId}/lgz/login?platform_id=${platformId}&go=${encodedCallback}&loginType=${loginType}&journey_id=${flowId}`;
  }

  static async isUserLogged() {
    return apiService.isUserLogged().then((response) => !!response.logged);
  }

  /**
   * ChallengeConfig function that builds login challenge configuration
   * @param challengeRawData - component sent by Flows, this may be used to get some extra data.
   * @param configuration - Frontend's environment configurations.
   * @param callbackUrl - desired url to use a return url from challenge, without being encoded
   */
  static async loginConfig(challengeRawData, configurations, callbackUrl, components = [], hidden_components) {
    const { flowId } = challengeRawData;
    const loginType = hidden_components?.find((component) => component.id === 'login_type')?.value;
    // TODO validate nativeAppName
    let challengeUrl = LoginPopUp.generateLoginURL({...configurations, loginType, flowId}, callbackUrl);

    if (configurations.isWebview) {
      const params = buildSniffingWebviewParams({ authRequired: true });
      const checkoutURL = new URL(window.location.href);
      challengeUrl = createInternalWebviewDeeplink(
        checkoutURL.href,
        params,
        configurations.nativeApp.name ?? NATIVE_APP_NAME_MAP.MercadoLibre,
      );
    }

    return Promise.resolve({
      challengeUrl,
      pollingFunction: LoginPopUp.isUserLogged,
      submitFormId: SUBMIT_FORM_ID.LOGIN_FORM,
      popupSetup: {
        width: 500,
        height: 600,
      },
    });
  }

  /**
   * ChallengeConfig function that builds login challenge remedy configuration
   * @param challengeRawData - component sent by Flows, this may be used to get some extra data.
   * @param configurations - Frontend's environment configurations.
   * @param redirectUrl - desired url to use a return url from challenge
   * @param components - step components
   * @param hiddenComponents - step hidden_components
   */
  static async loginChallengeRemedyConfig(challengeRawData, configurations, redirectUrl, components, hiddenComponents) {
    const challengeURL = hiddenComponents.find(({ id }) => id === 'redirect_to');

    return Promise.resolve({
      challengeUrl: challengeURL?.value,
      submitFormId: SUBMIT_FORM_ID.LOGIN_REMEDY_FORM,
      popupSetup: {
        width: 500,
        height: 600,
        completeOnPostmessage: true,
      },
    });
  }
}

/**
 * Expose generateLoginURL method so it can be use outside without initializing the LoginPupUp class
 */
module.exports.generateWaitingPage = LoginPopUp.generateWaitingPage;
module.exports.generateLoginURL = LoginPopUp.generateLoginURL;
module.exports.isUserLogged = LoginPopUp.isUserLogged;
module.exports.loginConfig = LoginPopUp.loginConfig;
module.exports.loginChallengeRemedyConfig = LoginPopUp.loginChallengeRemedyConfig;
module.exports.buildLoginURL = LoginPopUp.buildLoginURL;
