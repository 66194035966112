const { COOKIES } = require('../../constants/app');
const { trackInfo } = require('../service/api');

/**
 * Method that insert a new param or update an existing one in the param url
 * @param {String} key
 * @param {String} value
 * @param {Number} expDays
 */
const setCookie = (key, value, expDays = null) => {
  const path = key?.includes(COOKIES.ESC) ? '/' : 'Path=/';
  const secure = 'Secure';
  const samesite = 'SameSite=Strict';
  let expires = '';

  if (expDays !== null && expDays > 0) {
    const date = new Date();
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
    expires = `expires=${date.toUTCString()}; `;
  }

  const cookie = `${key}=${value}; ${expires}; ${secure}; ${samesite}; ${path}`;

  document.cookie = cookie;
};

/**
 * Method that insert a new param or update an existing one for a cookie, but making a JSON
 * @param {String} key
 * @param {String} value
 * @param {Number} expDays
 */
const setCookieWithJSON = (key, value, expDays = null) => {
  setCookie(key, encodeURIComponent(JSON.stringify(value)), expDays);
};
const getCookie = (key) => {
  const expectedCookieNameRegExp = /([.$?*|{}()[\]\\/+^])/g;

  if (typeof document !== 'undefined' && typeof document.cookie !== 'undefined') {
    const matches = new RegExp(`(?:^|; )${key.replace(expectedCookieNameRegExp, '\\$1')}=([^;]*)`).exec(document.cookie);

    return matches ? decodeURIComponent(matches[1]) : null;
  } 
    return null; 
};

const getCookieWithJSON = (key) => JSON.parse(decodeURIComponent(getCookie(key)));

const getESCCookie = (key) => {
  const escCookie = getCookie(key);
  if (!escCookie) {
    return false;
  }
  try {
    const parsedCookie = getCookieWithJSON(key);
    if (parsedCookie.esc) {
      return { esc: parsedCookie.esc, internalDeviceId: parsedCookie.internalDeviceId };
    }
  } catch (error) {
    trackInfo({
      message: `Error parsing cookie: ${escCookie}`,
    });
    return escCookie;
  }
};

const deleteCookiesByKey = (key) => setCookie(key, '', -1);

module.exports = {
  getESCCookie,
  setCookie,
  setCookieWithJSON,
  getCookie,
  getCookieWithJSON,
  deleteCookiesByKey,
};
