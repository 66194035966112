const React = require('react');
const PropTypes = require('prop-types');

const SecurityCode = require('../SecurityCode');
const InstallmentsSelector = require('../InstallmentsSelector');

const CardBottomContent = ({ paymentMethod = {}, discount, renderSecurityCode, renderInstallments }) => {
  const {
    option_id: optionId,
    security_code: securityCode,
    esc_available: escAvailable,
    id: paymentMethodId,
    installments,
  } = paymentMethod;

  const securityCodeId = `${paymentMethodId}_${optionId}`;

  const divBottomContentRef = React.useRef(null);

  React.useLayoutEffect(() => {
    const divBottomContent = divBottomContentRef.current;
    if (divBottomContent.children.length > 0) {
      divBottomContent.classList.add('has-content');
    }
  }, []);

  return (
    <div ref={divBottomContentRef} className="review-express__collapsed-content">
      {renderSecurityCode && (
        <SecurityCode
          optionId={optionId}
          securityCode={securityCode}
          escAvailable={escAvailable} 
          id={securityCodeId}
        />
      )}

      {renderInstallments && (
        <InstallmentsSelector
          discount={discount}
          paymentMethod={optionId}
          items={installments}
        />
      )}
    </div>
  );
};

CardBottomContent.propTypes = {
  paymentMethod: PropTypes.shape({
    option_id: PropTypes.string,
    security_code: PropTypes.string,
    esc_available: PropTypes.bool,
    id: PropTypes.string,
    installments: PropTypes.array,
  }),
  discount: PropTypes.object,
  renderSecurityCode: PropTypes.bool,
  renderInstallments: PropTypes.bool,
};

CardBottomContent.defaultProps = {
  paymentMethod: {},
  discount: null,
  renderSecurityCode: false,
  renderInstallments: false,
};

module.exports = CardBottomContent;