const React = require('react');

const { SecurityCodeMode } = require('internal-checkout-bricks/lib/secureFields/types/enums');
const { SmartTokenizationInputCVVBrick } = require('internal-checkout-bricks/lib/smartTokenization/client');

const translate = require('../../translation');

const { useSmartTokenizationContext } = require('../SmartTokenizationProvider');
const connectToReduxAndInjectI18n = require('../../utils/connectToRedux');
const { default: classNames } = require('classnames');
const {
  APP: {
    SECURE_FIELDS: {
      ERROR_CAUSES: { INVALID_VALUE },
    },
  },
} = require('../../../constants');

const SmartTokenizationInput = ({ id, paymentMethod, i18n, securityCode, express, optionId }) => {
  const { checkTokenizationMethods, publicKey, validatedCards } = useSmartTokenizationContext();
  const card = validatedCards?.find((card) => card.cardId === paymentMethod.option_id);
  const [tokenizationError, setTokenizationError] = React.useState(null);

  const formControlClass = classNames({
    'andes-form-control--error': tokenizationError,
  });

  const translations = translate(i18n);
  const toolTipCVV =
    securityCode?.length === 4
      ? translations.CVV_HELPER(securityCode.length, translations.CVV_HELPER_FRONT)
      : translations.CVV_HELPER(securityCode.length, translations.CVV_HELPER_BACK);

  const handleError = (error) => {
    const errorMessage =
      Array.isArray(error) && error.find((err) => err.cause === INVALID_VALUE)
        ? translations.CVV_IS_REQUIRED
        : translations.CVV_INCOMPLETE;

    setTokenizationError(errorMessage);
  };

  React.useEffect(() => {
    const fetchTokenizationType = async () => {
      await checkTokenizationMethods({
        paymentMethodId: paymentMethod?.id,
        cardId: paymentMethod?.option_id,
        securityCodeLenght: paymentMethod?.security_code?.length,
        amount: paymentMethod?.total_amount,
      });
    };

    if (card) return;

    fetchTokenizationType();
  }, []);

  if (card && !card?.shouldRenderInputCVV) {
    return null;
  }

  return (
    <div
      className={`smart-tokenization__wrapper andes-form-control andes-form-control--textfield andes-form-control--default ${formControlClass}`}
    >
      <SmartTokenizationInputCVVBrick
        id={id}
        publicKey={publicKey}
        length={securityCode?.length}
        cardId={express?.[optionId]?.cardTokenId}
        mode={SecurityCodeMode.MANDATORY}
        tooltipMessage={toolTipCVV}
        onFilled={() => setTokenizationError(null)}
        onError={(error) => handleError(error.details)}
      />

      {!!tokenizationError && (
        <div className="andes-form-control__bottom">
          {tokenizationError && (
            <span className="andes-form-control__error-icon">
              <ErrorIcon />
            </span>
          )}
          <span className="andes-form-control__message">{tokenizationError}</span>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  express: state.express,
  requests: state.request,
});

module.exports = connectToReduxAndInjectI18n(SmartTokenizationInput, mapStateToProps, null);
