const React = require('react');
const PropTypes = require('prop-types');
const { Typography } = require('@andes/typography');

const { injectI18n } = require('nordic/i18n');
const translate = require('../../translation');

const Stepper = ({ id, current, total, i18n }) => {
  const translations = translate(i18n);
  return (
    <div id={id} className="stepper">
      <Typography color="secondary" size="s">
        {translations.STEP_OF(current, total)}
      </Typography>
    </div>
  );
};

Stepper.defaultProps = {
  i18n: {
    gettext: (t) => t,
  },
};

Stepper.propTypes = {
  id: PropTypes.string,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  current: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
};

module.exports = injectI18n(Stepper);
