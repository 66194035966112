
const {
  EXTERNAL_URLS,
} = require('../../../constants/commons');


const hasCaptcha = (_hasCaptcha, translations) => {
  if (_hasCaptcha) {
    const footerOptionaInformation = {
      message: translations.PROTECTED_BY_RECAPTCHA,
      links: [
        {
          href: EXTERNAL_URLS.CAPTCHA_PRIVACY,
          text: translations.PRIVACY,
        }, {
          href: EXTERNAL_URLS.CAPTCHA_TERMS,
          text: translations.CONDITIONS,
        },
      ],
    };
    return footerOptionaInformation;
  }
  return null;
}

module.exports = {
  hasCaptcha,
};