const React = require('react');
const PropTypes = require('prop-types');

const Card = require('@andes/card');
const { CardContent } = require('@andes/card');

const { getErrorProps, defaultSecurityDisables } = require('../../containers/Optimus/helper');
const PaymentMethodInfo = require('../PaymentMethodInfo');
const InputSplitAmount = require('../InputSplitAmount');
const {
  COMMONS: { PAYMENT_METHOD_TYPE },
} = require('../../../constants');

const InputAmountCard = (props) => {
  const paymentMethodTypeCards = [
    PAYMENT_METHOD_TYPE.CREDIT_CARD,
    PAYMENT_METHOD_TYPE.DEBIT_CARD,
    PAYMENT_METHOD_TYPE.PREPAID_CARD,
  ];
  return (
    <Card paddingSize={24} shadow="flat" className="input-amount-card">
      <CardContent className="input-amount-card__content">
        <PaymentMethodInfo
          firstText={props.label}
          secondText={
            paymentMethodTypeCards.includes(props.paymentMethod?.type) ? props.paymentMethod?.card_text : props.text
          }
          paymentMethod={props.paymentMethod}
        />
        <InputSplitAmount
          {...getErrorProps(props)}
          {...defaultSecurityDisables(props.publicKey)}
          id={props.id}
          name={props.name}
          label={props.labelInputSplitAmount}
          text=""
          disabled={props.disabled}
          isCombination={props.isCombination}
          paymentMethod={props.paymentMethod}
          textInstallment={props.textInstallment}
          changedValue={props.changedValue}
          data={props.data}
        />
      </CardContent>
    </Card>
  );
};

InputAmountCard.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
  payment_method: PropTypes.object,
  publicKey: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  text_installment: PropTypes.object,
  change_value: PropTypes.bool,
  data: PropTypes.object,
  labelInputSplitAmount: PropTypes.string,
  paymentMethod: PropTypes.object,
  isCombination: PropTypes.bool,
  textInstallment: PropTypes.object,
  changedValue: PropTypes.bool,
};

InputAmountCard.defaultProps = {
  label: '',
  text: '',
  payment_method: {
    id: '',
    type: '',
    issuer_name: '',
    min_allowed_amount: '0',
    max_allowed_amount: '0',
  },
  publicKey: '',
  id: '',
  name: '',
  disabled: false,
  show: false,
  text_installment: {
    id: '',
    type: '',
    text: '',
  },
  change_value: false,
  data: {
    minAmount: '0',
    amount: '',
    total: '1',
    min_allowed_amount: 0,
    max_allowed_amount: 0,
  },
  labelInputSplitAmount: '',
};

module.exports = InputAmountCard;
