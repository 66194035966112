const React = require('react');

const {Button} = require('@andes/button');

const {bindActionCreators} = require('redux');
const PropTypes = require('prop-types');

const translate = require('../../../../translation');
const { ANDES_STYLES_THEME} = require('../../../../../constants');
const {getCompanyTheme} = require('../../../review/templates/utils/configurations');

const LegalsFooter = require('../../../../components/LegalsFooter');
const Layout = require('../../../../components/LayoutContainer');
const connectToReduxAndInjectI18n = require("../../../../utils/connectToRedux");
const FormAmount = require('../../components/AmountForm')
const useActions = require("../../hooks/useActions");
const SNACKBAR_ACTIONS = require("../../../../spa/actions/snackbar");
const STEP_ACTIONS = require("../../../../spa/actions/step");
const Brand = require("@cow/core-components/components/Brand");
const SummaryDesktop = require("../../components/Summary");

const {useState} = React;

const CombinedPaymentAmountDesktopTemplate = (props) => {

  const translations = translate(props.i18n);

  const [value, setValue] = useState(0);

  const [validations, setValidations] = useState({});

  const {
    flow,
    siteId,
    blockedByCaptcha,
    deviceType,
    loadingPayButton,
    stepActions,
    history,
    requestActions,
    rebrandingMP,
    analytics,
    currentStep,
    trackingPath,
    urls,
    captchaToken,
    captchaConfig,
    currency,
    step_model: {
      brand,
      pcj,
      terms_and_conditions: termsAndConditions,
      main_action: mainAction,
      transaction_type,
      payment_methods,
      subscription,
      step_title,
      payer,
      captcha,
      summary,
      incentives,
      navigation,
    },
  } = props;

  const theme = getCompanyTheme(props.configs?.internalConfigurations);

  const {nextStepAction} = useActions({
    flow,
    history,
    stepActions,
    captcha,
    requestActions,
    captchaConfig,
    captchaToken,
    validations,
    value
  });

  const templateCase = props.templateCase || 'default';

  const paymentMethod = payment_methods[0];

  const mainContent = (
    <>
      <FormAmount
        paymentMethod={paymentMethod}
        translations={translations}
        nextStepAction={nextStepAction}
        currency={currency}
        siteId={siteId}
        rebrandingMP={rebrandingMP}
        setValue={setValue}
        value={value}
        setValidations={setValidations}
      />
      <div className="actions-split-amount">
        <Button
          hierarchy="quiet"
          type="button"
          onClick={(event) => {
            event.preventDefault();
            window.history.back();
          }}
        >
          {translations.TO_GO_BACK}
        </Button>
        <Button type="button" onClick={() => nextStepAction(0)}>{translations.CONTINUE}</Button>
      </div>
    </>
  );

  const sidebarContent = (
    <>
      {brand && <Brand image={brand.avatar} name={brand.name}/>}
      {summary?.items?.length &&
        <SummaryDesktop
          {...{
            pcj,
            summary,
            translations,
            transaction_type,
            deviceType,
            mainAction,
            subscription,
            captcha,
            termsAndConditions,
            blockedByCaptcha,
            theme,
            loadingPayButton,
            siteId,
            incentives,
            payer,
          }}
        />
      }
    </>
  );
  const footerContent = (<LegalsFooter
    termsAndConditions={termsAndConditions}
    payer={payer}
    translations={translations}
    siteId={siteId}
    deviceType={deviceType}
  />);

  const stepTitle = {
    text: step_title,
    size: ANDES_STYLES_THEME.SIZES.XS,
  }

  const stepSubTitle = {
    text: translations.PAYMENT_X_OF_2(1),
    component: 'span',
    color: ANDES_STYLES_THEME.COLORS.SECONDARY,
    weight: ANDES_STYLES_THEME.WEIGHT.REGULAR,
    SIZE: ANDES_STYLES_THEME.SIZES.M,
  }

  return (
    <Layout
      brand={brand}
      deviceType={deviceType}
      history={history}
      mainContent={mainContent}
      footerContent={footerContent}
      payer={payer}
      sidebarContent={sidebarContent}
      siteId={siteId}
      stepTitle={stepTitle}
      stepSubtitle={stepSubTitle}
      templateCase={templateCase}
      theme={theme}
      translations={translations}
      currentStep={currentStep}
      trackingPath={trackingPath}
      analytics={analytics}
      urls={urls}
      navigation={navigation}
    />
  );

};

const mapStateToProps = (state) => ({
  flow: state.page.flow,
  configs: state.configurations,
  firstRender: state.page.firstRender,
  basePath: state.configurations.basePath,
  currency: state.configurations.currency,
  captchaConfig:
  state.configurations.captcha,
  browserName:
  state.configurations.browserName,
  blockedByCaptcha: state.captchaToken.blockedByCaptcha,
  loadingPayButton: state.request.loadingPayButton,
  requestLoading: state.request.loading,
  loadingStopAnimation: state.request.loadingStopAnimation,
});

const mapDispatchToProps = (dispatch) => ({
  snackbarActions: bindActionCreators(SNACKBAR_ACTIONS, dispatch),
  stepActions: bindActionCreators(STEP_ACTIONS, dispatch),
});

CombinedPaymentAmountDesktopTemplate.propTypes = {
  flow: PropTypes.object,
  siteId: PropTypes.string,
  blockedByCaptcha: PropTypes.bool,
  requestLoading: PropTypes.bool,
  deviceType: PropTypes.string,
  loadingPayButton: PropTypes.bool,
  loadingStopAnimation: PropTypes.bool,
  stepActions: PropTypes.object,
  history: PropTypes.object,
  requestActions: PropTypes.object,
  loadingActions: PropTypes.object,
  setEmailErrorMessage: PropTypes.func,
  emailErrorMessage: PropTypes.string,
  email: PropTypes.string,
  step_model: PropTypes.object,
  rebrandingMP: PropTypes.bool,
  analytics: PropTypes.element,
  currentStep: PropTypes.string,
  trackingPath: PropTypes.string,
  urls: PropTypes.array,
  captchaToken: PropTypes.object,
  captchaConfig: PropTypes.object,
};


module.exports = connectToReduxAndInjectI18n(
  CombinedPaymentAmountDesktopTemplate,
  mapStateToProps,
  mapDispatchToProps
);
