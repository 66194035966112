const React = require('react');
const { Typography, Title } = require('@andes/typography');
const { Button } = require('@andes/button');
const CardList = require('@cow/core-components/components/CardListV2');
const PropTypes = require('prop-types');
const { ANDES_STYLES_THEME } = require('../../../../constants');

const { WEIGHT: { REGULAR, SEMI_BOLD }, COLORS: { SECONDARY, POSITIVE, PRIMARY }, SIZES: { S, M, XS } } = ANDES_STYLES_THEME;

const PaymentMethodInfo = (
  {
    id,
    title,
    paymentTitle,
    paymentDetail,
    amount,
    installments,
    translations,
    method,
    interestFree,
    interestFreeLabel,
    className = '',
    changePaymentMethod,
    actionName,
  }) => {


  const cardListProps = {
    withChevron: false,
    withDivider: false,
    items: [
      {
        id,
        method,
        description: paymentDetail,
        title: paymentTitle,
      },
    ],
  };

  return (
    <div className={`paymentMethodInfo ${className}`}>
      <div className="split-amount-info">
        <Title
          component="h2"
          size={XS}
          weight={SEMI_BOLD}
          color={PRIMARY}
        >
          {title}
        </Title>
        <Button
          hierarchy="transparent"
          onClick={() => changePaymentMethod(actionName)}
        >
          {translations.MODIFY}
        </Button>
      </div>
      <CardList
        className="payment-method-info"
        {...cardListProps}
        bottomContentList={
          <div className="description-combined-amount">
            <Typography
              size={M}
              weight={REGULAR}
              color={SECONDARY}
            >{translations.AMOUNT}</Typography>
            <div className="amount-info">
              <Typography
                size={S}
                weight={SEMI_BOLD}>{amount}</Typography>
              {
                installments &&
                <div className="installments">
                  <Typography
                    size={S}
                    weight={REGULAR}
                    color={SECONDARY}
                    component="span">
                    {installments}
                  </Typography>
                  {
                    interestFree &&
                    <Typography
                      size={S}
                      weight={REGULAR}
                      color={POSITIVE}
                      component="span">
                      {interestFreeLabel}
                    </Typography>
                  }
                </div>
              }
            </div>
          </div>
        } />
    </div>
  );
};

PaymentMethodInfo.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  paymentTitle: PropTypes.string,
  paymentDetail: PropTypes.string,
  amount: PropTypes.string,
  installments: PropTypes.object,
  translations: PropTypes.object,
  method: PropTypes.string,
  interestFree: PropTypes.bool,
  interestFreeLabel: PropTypes.string,
  className: PropTypes.string,
  changePaymentMethod: PropTypes.func,
  actionName: PropTypes.string,
};

module.exports = PaymentMethodInfo;
