const React = require('react');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const { FormProvider } = require('checkout-off-form-validation');
const { useI18n } = require('nordic/i18n');

const stepActions = require('../../../spa/actions/step');
const { getQueryParams } = require('../../../utils/Dom');
const { STEP_NEXT } = require('../../../spa/actions/types');
const { ZipcodeFormComponent } = require('./ZipcodeForm');
const translate = require('../../../translation');

const ShippingZipcodeForm = (props) => {
  const { i18n } = useI18n();
  const translations = translate(i18n);
  const { flow, history, stepAction, deviceType, countryId, data } = props;

  const handleCreateAddress = (inputs) => {
    const urlParams = getQueryParams();
    const defaultData = {
      urlParams,
      type: flow.type,
    };
    const payload = {
      ...inputs,
      isNewInterface: true,
    };

    stepAction[STEP_NEXT](payload, flow.id, defaultData, flow.type, urlParams, history);
  };

  return (
    <div className="shipping-zipcode-form">
      <h1 className="c-title">{translations.ADD_YOUR_ZIP_CODE}</h1>
      <FormProvider>
        <ZipcodeFormComponent
          countryId={countryId}
          deviceType={deviceType}
          handleCreateAddress={handleCreateAddress}
          hasZipcodeFind={data?.hasZipCodeFinder}
        />
      </FormProvider>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  flow: state.page.flow,
  countryId: state.configurations.platform.countryId,
});

const mapDispatchToProps = (dispatch) => ({
  stepAction: bindActionCreators(stepActions, dispatch),
});

module.exports = {
  ShippingZipcodeForm: connect(mapStateToProps, mapDispatchToProps)(ShippingZipcodeForm),
};
