import React from 'react';

const Cash = ({ ...props }: SVGProps) => (
  <svg
    {...props}
    width="24"
    height="17"
    viewBox="0 0 24 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.51008 9.36949L8.60661 10.6367C9.21295 11.2528 10.0638 11.7059 11.2 11.8397V12.8063H12.8V11.8087C14.3131 11.5454 15.0482 10.5842 15.0482 9.36949C15.0482 7.59882 13.4075 7.21102 12.1172 6.90604L12.0916 6.89998C11.257 6.70297 10.5543 6.53711 10.5543 6.03722C10.5543 5.56789 10.9533 5.25109 11.669 5.25109C12.4669 5.25109 13.3117 5.52095 13.9453 6.10762L14.8722 4.88735C14.3111 4.36871 13.6144 4.02874 12.8 3.88083V2.99615H11.2V3.83984C9.72927 4.05401 8.85301 5.0293 8.85301 6.17802C8.85301 7.93748 10.4518 8.30375 11.7228 8.59494L11.7746 8.60682L11.8388 8.62262C12.6672 8.82651 13.3586 8.9967 13.3586 9.55722C13.3586 10.0031 12.9127 10.4255 11.9975 10.4255C10.9181 10.4255 10.0615 9.94442 9.51008 9.36949Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
    <path
      d="M18.843 9.60629C19.7266 9.60629 20.443 8.88994 20.443 8.00629C20.443 7.12263 19.7266 6.40629 18.843 6.40629C17.9593 6.40629 17.243 7.12263 17.243 8.00629C17.243 8.88994 17.9593 9.60629 18.843 9.60629Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
    <path
      d="M6.86358 8.00629C6.86358 8.88994 6.14724 9.60629 5.26358 9.60629C4.37993 9.60629 3.66358 8.88994 3.66358 8.00629C3.66358 7.12263 4.37993 6.40629 5.26358 6.40629C6.14724 6.40629 6.86358 7.12263 6.86358 8.00629Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 0H24V16.0126H0V0ZM1.6 1.6V14.4126H22.4V1.6H1.6Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
  </svg>
);

export default React.memo(Cash);
