const { useCallback, useState, useEffect } = require('react');

const { useI18n } = require('nordic/i18n');
const { useForm, schema } = require('checkout-off-form-validation');
const { Card, CardContent } = require('@andes/card');
const { TextField } = require('@andes/textfield');
const { Button } = require('@andes/button');
const { Form } = require('@andes/form');
const { RadioButton, RadioGroup } = require('@andes/radio-button');
const { Typography } = require('@andes/typography');
const { Checkbox } = require('@andes/checkbox');

const { generateIcon } = require('../../../utils/icons');
const translate = require('../../../translation');

const AddressForm = ({ deviceType, handleCreateAddress, formConfigs, isInRebrand }) => {
  const { i18n } = useI18n();
  const translations = translate(i18n);
  const { errors, register, handleSubmit, fields, setFieldValue } = useForm();
  const hasNeighborhood = formConfigs?.neighborhood?.show;
  const hasEmail = formConfigs?.email?.show;

  const initialValues = formConfigs?.inputs;
  const [residenceOption, setResidenceOption] = useState(initialValues?.residence_option || 'residencial');
  const customClass = isInRebrand ? 'icon-rebrand`' : '';

  const [streetNumber, setStreetNumber] = useState(initialValues?.street_number || '');

  const residenceTypes = [
    {
      value: 'residencial',
      label: translations.RESIDENTIAL,
      icon: generateIcon({ type: 'icon_home', className: customClass }),
    },
    {
      value: 'laboral',
      label: translations.LABOR,
      icon: generateIcon({ type: 'generic_wallet_icon', className: customClass }),
      className: 'icon-address-type-wrapper',
    },
  ];

  const formFields = {
    number: { required: { message: translations.REQUIRED_FORM_FIELD } },
    street: { required: { message: translations.REQUIRED_FORM_FIELD } },
    phoneNumber: { required: { message: translations.REQUIRED_FORM_FIELD } },
    email: {
      custom: {
        message: translations.REQUIRED_FORM_FIELD,
        validate: (value) => hasEmail && value !== '',
      },
    },
    name: { required: { message: translations.REQUIRED_FORM_FIELD } },
    neighborhood: {
      custom: {
        message: translations.REQUIRED_FORM_FIELD,
        validate: (value) => formConfigs?.neighborhood?.required && value !== '',
      },
    },
  };

  const submitAction = useCallback(
    (data, event) => {
      event.preventDefault();

      const payload = {
        data: {
          name: data.name,
          street: data.street,
          street_number: streetNumber,
          floor: data.floor,
          between_street_one: data.betweenStreet1,
          between_street_two: data.betweenStreet2,
          phone_number: data.phoneNumber,
          residence_option: residenceOption,
          description: data.description,
          ui_components: '',
        },
      };

      if (hasEmail) {
        payload.data.email = data.email;
      }

      if (hasNeighborhood) {
        payload.data.neighborhood = data.neighborhood;
      }

      handleCreateAddress(payload.data);
    },
    [fields, residenceOption, streetNumber],
  );

  return (
    <div className="shipping-zipcode-form">
      <Form onSubmit={handleSubmit(submitAction)}>
        <div className={`page-content page-content__${deviceType}`}>
          <div className="content">
            <Card paddingSize={24}>
              <CardContent className="form-content">
                <TextField
                  className="required"
                  label={translations.FIRST_AND_LAST_NAME}
                  modifier={errors?.name && 'error'}
                  defaultValue={initialValues?.name}
                  helper={errors?.name?.message}
                  {...register('name', formFields.name)}
                />

                <TextField disabled label={translations.LABEL_ZIPCODE} value={formConfigs?.userAddress?.zip_code} />
                <TextField disabled label={translations.PROVINCE} value={formConfigs?.userAddress?.state} />
                <TextField disabled label={translations.LABEL_CITY} value={formConfigs?.userAddress?.city} />

                {hasNeighborhood && (
                  <TextField
                    className="required"
                    defaultValue={initialValues?.neighborhood}
                    label={translations.COLONY_SETTLEMENT}
                    modifier={errors?.neighborhood && 'error'}
                    helper={errors?.neighborhood?.message}
                    {...register('neighborhood', formFields.neighborhood)}
                  />
                )}

                <div className={`form-content-group__${deviceType}`}>
                  <TextField
                    defaultValue={initialValues?.street}
                    className="full-width required"
                    label={translations.STREET}
                    modifier={errors?.street && 'error'}
                    helper={errors?.street?.message}
                    {...register('street', formFields.street)}
                  />

                  <TextField
                    className="full-width"
                    name="number"
                    value={streetNumber}
                    label={translations.LABEL_NUMBER}
                    modifier={errors?.number && 'error'}
                    helper={errors?.number?.message}
                    onChange={(e) => {
                      setStreetNumber(e?.target?.value);
                    }}
                  >
                    <Checkbox
                      labelPosition="left"
                      checked={streetNumber === 'SN'}
                      className="checkbox-in-textfield  helper-street-number"
                      label={translations.LABEL_NO_NUMBER}
                      onChange={(e) => {
                        const { checked } = e.target;
                        setStreetNumber(checked ? 'SN' : '');
                      }}
                    />
                  </TextField>
                </div>

                {formConfigs?.floor?.show && (
                  <TextField
                    defaultValue={initialValues?.floor}
                    label={translations.FLOOR}
                    {...register('floor', formFields.floor)}
                  />
                )}

                <Typography size="s" color="secondary">
                  {translations.BETWEEN_WHICH_STREETS_IS_IT}
                </Typography>

                {formConfigs?.betweenStreet?.show && (
                  <>
                    <TextField
                      defaultValue={initialValues?.between_street_one}
                      label={translations.STREET_1}
                      {...register('betweenStreet1', formFields.betweenStreet1)}
                    />
                    <TextField
                      defaultValue={initialValues?.between_street_two}
                      label={translations.STREET_2}
                      {...register('betweenStreet2', formFields.betweenStreet2)}
                    />
                  </>
                )}
                <TextField
                  className="required"
                  defaultValue={initialValues?.phone_number}
                  label={translations.CONTACT_PHONE_NUMBER}
                  modifier={errors?.phoneNumber && 'error'}
                  helper={
                    errors?.phoneNumber
                      ? errors?.phoneNumber?.message
                      : translations.THEY_WILL_CALL_THIS_NUMBER_IF_THERE_IS_ANY_PROBLEM_WITH_THE_SHIPMENT
                  }
                  {...register('phoneNumber', formFields.phoneNumber)}
                />

                <RadioGroup
                  onChange={(e) => setResidenceOption(e.target.value)}
                  value={residenceOption}
                  className="list-group"
                >
                  {residenceTypes.map((item) => (
                    <RadioButton
                      label={
                        <section className="residence-options">
                          <div>{item.label}</div>
                        </section>
                      }
                      value={item.value}
                    />
                  ))}
                </RadioGroup>

                <TextField
                  multiline
                  rows={3}
                  countdown={120}
                  label={translations.ADDITIONAL_INSTRUCTIONS_FOR_DELIVERING_YOUR_PURCHASES_TO_THIS_ADDRESS}
                  defaultValue={initialValues?.description}
                  placeholder={
                    translations.DESCRIPTION_OF_THE_FACADE_REFERENCE_POINTS_TO_FIND_IT_SAFETY_INSTRUCTIONS_ETC
                  }
                  {...register('description', formFields.description)}
                />

                {hasEmail && (
                  <TextField
                    className="required"
                    defaultValue={initialValues?.email}
                    label={translations.EMAIL}
                    modifier={errors?.email && 'error'}
                    helper={
                      errors?.email
                        ? errors?.email?.message
                        : translations.WHERE_WE_WILL_SEND_YOU_THE_DETAILS_OF_YOUR_PURCHASE
                    }
                    {...register('email', formFields.email)}
                  />
                )}
              </CardContent>
            </Card>
          </div>

          <div className={`action action__${deviceType}`}>
            <Button hierarchy="loud" size="large" type="submit">
              {translations.CONTINUE}
            </Button>

            <Button hierarchy="quiet" size="large" onClick={() => window.history.back()}>
              {translations.TO_GO_BACK}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

module.exports = { AddressForm };
