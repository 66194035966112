const React = require('react');

const { bindActionCreators } = require('redux');
const { QRCodeSVG } = require('qrcode.react');
const classNames = require('classnames');
const { Card, CardContent } = require('@andes/card');
const { Title, Text } = require('@andes/typography');
const { ProgressIndicatorCircular } = require('@andes/progress-indicator-circular');
const { Button } = require('@andes/button');
const { Notification } = require('@andes/badge');
const { Message } = require('@andes/message');
const Brand = require('@cow/core-components/components/Brand');

const Page = require('../../components/Page');
const translate = require('../../translation');
const { STEP_NEXT } = require('../../spa/actions/types');
const stepActions = require('../../spa/actions/step');
const { getQueryParams } = require('../../utils/Dom');
const connectToReduxAndInjectI18n = require('../../utils/connectToRedux');
const { polling, cancelPolling, ERROR_MAX_RETRIES } = require('../../utils/polling');
const Step = require('./components/step');
const { PAGE_ACTIONS } = require('../../../constants/app');
const useCountdown = require('../../hooks/useCountdown');
const ApiService = require('../../service/api');

const POLLING_CONFIG = {
  LAP_DELAY: 4_000,
  ATTEMPT_LAPS: 225,
  ALLOWED_LAPS_WITH_ERRORS: 3,
  MAX_ATTEMPTS: 2,
};

const SniffingDesktopQR = (props) => {
  const [attempts, setAttempts] = React.useState(POLLING_CONFIG.MAX_ATTEMPTS);

  const timeLeft = useCountdown({
    expirationDateTime: props.step_model.desktop_qr.qr.expiration_id,
  });

  const expired = timeLeft <= 0 || !props.step_model.desktop_qr.qr.data;

  if (expired) {
    cancelPolling();
  }

  const goToPreviousScreen = React.useCallback(() => {
    window.history.back();
  }, []);

  const generateNewCode = React.useCallback(() => {
    const queryParams = getQueryParams();

    props.stepActions[STEP_NEXT](
      {
        id: PAGE_ACTIONS.GENERATE_NEW_QR,
        isNewInterface: true,
      },
      props.flow.id,
      {
        type: props.flow.type,
        urlParams: queryParams,
      },
      props.flow.type,
      queryParams,
      props.history,
    );
  }, [props.flow]);

  const completePolling = React.useCallback(() => {
    const queryParams = getQueryParams();

    props.stepActions[STEP_NEXT](
      {
        id: PAGE_ACTIONS.COMPLETE,
        isNewInterface: true,
      },
      props.flow.id,
      {
        type: props.flow.type,
        urlParams: queryParams,
      },
      props.flow.type,
      queryParams,
      props.history,
    );
  }, [props.flow]);

  const doPollingAttempt = React.useCallback(
    (relationId) => {
      setAttempts((previousAttempts) => previousAttempts - 1);

      if (!relationId) {
        return;
      }

      polling({
        fn: () => ApiService.getRelationById(relationId),
        stopWhen: ({ data }) => data?.status === 'closed',
        delay: POLLING_CONFIG.LAP_DELAY,
        retries: POLLING_CONFIG.ATTEMPT_LAPS,
        allowedErrors: POLLING_CONFIG.ALLOWED_LAPS_WITH_ERRORS,
      })
        .then((relation) => {
          if (!relation) {
            return;
          }

          completePolling();
        })
        .catch((error) => {
          if (attempts && error?.message === ERROR_MAX_RETRIES) {
            return;
          }

          completePolling();
        });
    },
    [completePolling],
  );

  React.useEffect(() => {
    doPollingAttempt(props.step_model.desktop_qr.qr.relation_id);
  }, [props.step_model.desktop_qr.qr.relation_id]);

  const translations = translate(props.i18n);

  return (
    <Page
      title={translations.FINISH_YOUR_PAYMENT}
      currentStep={props.currentStep}
      urls={props.urls}
      trackingPath={props.trackingPath}
      analytics={props.analytics}
      deviceType={props.deviceType}
    >
      <section className="sniffing-scan-qr">
        <Card paddingSize="32" className="sniffing-scan-qr__qr-container">
          <CardContent>
            <Title component="h1" size="s" className="sniffing-scan-qr__qr-container__title">
              {translations.SCAN_THE_QR_CODE_AND_FINISH_YOUR_PAGO}
            </Title>

            <Card className="sniffing-scan-qr__qr-container__qr-code" paddingSize="24">
              <CardContent>
                {(props.brand_logo && props.brand_name) && (
                  <div className="sniffing-scan-qr__qr-container__qr-code__brand">
                    <Brand name={props.brand_name} image={props.brand_logo} />
                  </div>
                )}

                <QRCodeSVG
                  renderAs="svg"
                  value={props.step_model.desktop_qr.qr.data}
                  className={classNames('sniffing-scan-qr__qr-container__qr-code__qr-code', {
                    'sniffing-scan-qr__qr-container__qr-code__qr-code--expired': expired,
                  })}
                />

                <Title size="m" className="sniffing-scan-qr__qr-container__qr-code__value">
                  {props.step_model.desktop_qr.qr.price_text}
                </Title>
              </CardContent>
            </Card>

            <div className="sniffing-scan-qr__qr-container__timeout">
              {expired ? (
                <>
                  <div className="sniffing-scan-qr__qr-container__timeout__message">
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Notification type="error" size="small" children={{ table: { disable: true } }} />

                    <Text size="m" color="negative">
                      {translations.EXPIRED_CODE_PLEASE_GENERATE_ANOTHER_ONE}
                    </Text>
                  </div>

                  <Button onClick={generateNewCode} size="medium">
                    {translations.GENERATE_A_NEW_CODE}
                  </Button>
                </>
              ) : (
                <>
                  <div className="sniffing-scan-qr__qr-container__timeout__message">
                    <ProgressIndicatorCircular size="xsmall" />

                    <Text size="m">{translations.YOU_HAVE_X_MINUTES_TO_SCAN_IT(timeLeft)}</Text>
                  </div>

                  <Text size="s" color="secondary">
                    {translations.DO_NOT_CLOSE_THIS_SCREEN}
                  </Text>
                </>
              )}
            </div>

            <div className="sniffing-scan-qr__qr-container__steps">
              <Step stepNumber={1} message={translations.OPEN_MERCADO_PAGO_APP_QR_CODE_ICON} />

              <Step stepNumber={2} message={translations.SCAN_THE_CODE_YOU_SEE_ON_THIS_SCREEN} />

              <Step
                stepNumber={3}
                message={translations.CHOOSE_AN_AVAILABLE_PAYMENT_METHOD_CONFIRM_THE_OPERATION_AND_RIGHT}
              />
            </div>
          </CardContent>
        </Card>

        {props.step_model.desktop_qr.disclaimer && (
          <Message color="accent" closable={false} hierarchy="quiet" className="sniffing-scan-qr__message">
            <Text size="s">
              {
                translations.FOR_THIS_PAYMENT_YOU_CANNOT_USE_CUOTAS_WITHOUT_TARJETA_OR_MONEY_AVAILABLE_IN_YOUR_MERCADO_PAGO_ACCOUNT
              }
            </Text>
          </Message>
        )}

        <Button hierarchy="quiet" onClick={goToPreviousScreen} className="sniffing-scan-qr__back-button">
          {translations.TO_GO_BACK}
        </Button>
      </section>
    </Page>
  );
};

/* istanbul ignore next: cant test it with tests */
const mapDispatchToProps = (dispatch) => ({
  stepActions: bindActionCreators(stepActions, dispatch),
});

/* istanbul ignore next: cant test it with tests */
const mapStateToProps = (state) => ({
  flow: state.page?.flow,
});

module.exports = connectToReduxAndInjectI18n(SniffingDesktopQR, mapStateToProps, mapDispatchToProps);
