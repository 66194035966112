const React = require('react');
const PropTypes = require('prop-types');

const { ProgressIndicatorCircular } = require('@andes/progress-indicator-circular');
const { connect } = require('react-redux');

const { DEVICE_TYPE } = require("../../../constants/commons");

const PageLoader = ({ loading, loadingType, forceLoading, deviceType }) => (
  <>
    {
      ((loading || forceLoading) && !loadingType)
      && (
        <ProgressIndicatorCircular size={deviceType === DEVICE_TYPE.MOBILE ? "large" : "xlarge"} modifier="fullscreen" className={"cow-loader"} />
      )
    }
    {
      ((loading || forceLoading) && loadingType === "empty")
      && <div className="loader-wrapper" />
    }
  </>
);

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  loading: state.request.loading,
  loadingType: state.request.loadingType,
  deviceType: state?.configurations?.deviceType,
});

PageLoader.defaultProps = {
  loading: false,
  loadingType: '',
  forceLoading: false,
  deviceType: DEVICE_TYPE.DESKTOP,
};

PageLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadingType: PropTypes.string,
  forceLoading: PropTypes.bool,
  deviceType: PropTypes.string,
};

if (process.env.NODE_ENV === 'test') {
  module.exports = PageLoader;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps)(PageLoader);
}
