const { useState } = require('react');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const { useI18n } = require('nordic/i18n');
const stepActions = require('../../../spa/actions/step');
const { getQueryParams } = require('../../../utils/Dom');
const { STEP_NEXT } = require('../../../spa/actions/types');
const { ShippingAddressSelectorDesktop } = require('./ShippingAddressSelectorDesktop');
const { ShippingAddressSelectorMobile } = require('./ShippingAddressSelectorMobile');
const { DEVICE_TYPE } = require('../../../../constants/commons');
const translate = require('../../../translation');

const ShippingAddressSelector = (props) => {
  const { i18n } = useI18n();
  const translations = translate(i18n);

  const { flow, history, stepAction, deviceType, data } = props;
  const isMobile = deviceType === DEVICE_TYPE.MOBILE;

  const listAddress = data?.address_list?.list || [];

  const selectAddress = (address) => {
    const urlParams = getQueryParams();

    const defaultData = {
      urlParams,
      type: flow.type,
    };

    const payload = {
      isNewInterface: true,
      user_selection: address,
    };

    stepAction[STEP_NEXT](payload, flow.id, defaultData, flow.type, urlParams, history);
  };

  return (
    <div className="shipping-address-selector">
      <h1 className="c-title">{translations.MY_ADDRESSES}</h1>

      {isMobile ? (
        <ShippingAddressSelectorMobile addresses={listAddress} handleSelectAddress={selectAddress} />
      ) : (
        <ShippingAddressSelectorDesktop addresses={listAddress} handleSelectAddress={selectAddress} />
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  flow: state.page.flow,
});

const mapDispatchToProps = (dispatch) => ({
  stepAction: bindActionCreators(stepActions, dispatch),
});

module.exports = {
  ShippingAddressSelector: connect(mapStateToProps, mapDispatchToProps)(ShippingAddressSelector),
};
