const { ERROR_FIELDS } = require('../../constants/app');

/**
 * @Deprecated - Do the validations using this Lib instead: https://github.com/melisource/fury_checkout-off-form-validation
 */
const EMAIL_INPUT_VALIDATIONS = (translations) => [
  {
    regex: '^(?!\\s*$).+',
    error: ERROR_FIELDS.REQUIRED,
    message: translations.COMPLETE_THIS_DATA,
    show: true,
  },
  {
    regex:
      "[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+|\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$",
    error: ERROR_FIELDS.INVALID_EMAIL,
    message: translations.INVALID_EMAIL_FIELD,
    show: true,
  },
];

/**
 * @Deprecated - Do the validations using this Lib instead: https://github.com/melisource/fury_checkout-off-form-validation
 */
const NEW_EMAIL_INPUT_VALIDATIONS = (translations) => [
  {
    regex: '^(?!\\s*$).+',
    error: ERROR_FIELDS.REQUIRED,
    message: translations.COMPLETE_THIS_DATA,
    show: true,
  },
  {
    regex:
      "[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+|\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$",
    error: ERROR_FIELDS.INVALID_EMAIL,
    message: translations.INVALID_EMAIL,
    show: true,
  },
];

const REQUIRED = (translations) => [
  {
    regex: '^(?!\\s*$).+',
    error: translations.COMPLETE_THIS_DATA,
    show: true,
  },
];

/**
 * @Deprecated - Do the validations using this Lib instead: https://github.com/melisource/fury_checkout-off-form-validation
 */
const DISCOUNT_CODE_VALIDATIONS = (translations) => [
  {
    regex: '^(?!\\s*$).+',
    error: ERROR_FIELDS.REQUIRED,
    message: translations.COMPLETE_THIS_DATA,
    show: true,
  },
  {
    regex: '^[a-zA-Z0-9]*$',
    error: ERROR_FIELDS.INVALID_DISCOUNT_CODE,
    message: translations.INVALID_VALUE,
    show: true,
  },
];

/**
 * @Deprecated - Do the validations using this Lib instead: https://github.com/melisource/fury_checkout-off-form-validation
 */
const validate = (value, validations) => {
  const validation = validations.find((validation) => !RegExp(validation.regex).exec(value));

  if (validation) {
    return { ...validation };
  }

  return { error: null, show: false };
};

module.exports = {
  validate,
  EMAIL_INPUT_VALIDATIONS,
  DISCOUNT_CODE_VALIDATIONS,
  NEW_EMAIL_INPUT_VALIDATIONS,
  REQUIRED
};
