const {goToNextStep} = require("../../../utils/nextStepAction");
const uuidv4 = require("uuid/v4");
const {PAGE_ACTIONS} = require("../../../../constants/app");
const {useState} = require("react");
const {COMMONS} = require('../../../../constants');

const {DELAY} = COMMONS;

const useActions = (
  {
    flow,
    history,
    stepActions,
    captcha,
    requestActions,
    captchaConfig = {},
    captchaToken = {},
    validations,
    translations,
    stepper,
    selected
  }) => {

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [delaySnackbar, setDelaySnackbar] = useState(null)
  const requestId = uuidv4();

  const handleSnackbar = (show, message = '') => {
    setSnackbarMessage(message);
    setShowSnackbar(show);
  }

  const nextStepAction = (payload) => {

    if (validations.invalid) {
      return null;
    }

    const data = {
      id: PAGE_ACTIONS.NEXT_STEP,
      values: {
        ...payload
      },
    };

    return goToNextStep(
      {
        data,
        flow,
        history,
        stepActions,
        captcha,
        requestActions,
        requestId,
        captchaConfig,
        captchaToken,
      });
  };

  const submit = () => {
    if (selected) {
      const {id, type, method} = selected;
      return nextStepAction({id, type, method});
    }
    const snackbarMessage = stepper === 1
      ? translations.SHOULD_SELECT_FIRST_PAYMENT_METHOD
      : translations.SHOULD_SELECT_SECOND_PAYMENT_METHOD;
    handleSnackbar(true, snackbarMessage);
    if (delaySnackbar) {
      clearTimeout(delaySnackbar);
    }
    return setDelaySnackbar(
      setTimeout(() => handleSnackbar(false, ''), DELAY.SIX_SECONDS)
    );
  }

  const unableCombinations = () => nextStepAction({
    switch_combination: 'false'
  });

  return {
    snackbarMessage,
    showSnackbar,
    nextStepAction,
    submit,
    unableCombinations,
  }
}

module.exports = useActions;
