/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');
const { injectI18n } = require('nordic/i18n');
const { Dropdown } = require('@andes/dropdown');

const { injectValidations } = require('../../utils/validator-provider');
const inputValuesActions = require('../../spa/actions/inputValues');
const creditCardActions = require('../../spa/actions/creditCard');
const BaseValidation = require('../BaseValidation');
const ErrorIcon = require('../icons/ErrorIcon');

const { DropdownItem } = Dropdown;
const {
  CREDIT_CARD_PC_EXPIRATION,
  CURRENT_INPUT_VALUES,
} = require('../../spa/actions/types');
const { COMMONS: {
  DATE_PLACEHOLDER: {
    MM,
    AA,
  }
} } = require('../../../constants');

/**
 * Select
 */
class SelectExpirationDate extends BaseValidation {
  constructor(props) {
    super(props);
    // Default State
    this.state = {
      error: props.error,
      invalid: props.invalid,
      step: props.step,
      unselected: props.savedValue === '',
      value: props.savedValue || '', // MM-YY
      monthValue: props.savedValue.length > 1 ? props.savedValue.substring(0, 2) : '00',
      yearValue: props.savedValue.length > 4 ? props.savedValue.substring(3, 5) : '00',
    };

    this.yearPlaceHolder = AA;
    this.monthPlaceHolder = MM;
    this.onMonthChange = this.onMonthChange.bind(this);
    this.onYearChange = this.onYearChange.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  onMonthChange(e, value) {
    const monthValue = value.length > 1 ? value : `0${value}`;
    this.updateValue(monthValue, this.state.yearValue);
  }

  onYearChange(e, value) {
    const yearValue = value.length > 1 ? value : `0${value}`;
    this.updateValue(this.state.monthValue, yearValue);
  }

  updateValue(monthValue, yearValue) {
    // Dispatch action for updating the PaymentCard component
    const expirationDate = `${monthValue}-${yearValue}`;
    this.props.creditCardActions[CREDIT_CARD_PC_EXPIRATION](expirationDate);
    this.props.inputValuesActions[CURRENT_INPUT_VALUES](`${this.state.step}_${this.props.id}`, expirationDate);

    this.setState({ monthValue, yearValue, value: expirationDate });
  }

  render() {
    const {
      monthOptions,
      yearOptions,
      label,
    } = this.props;

    // callback to create each option for the dropdown
    const addOptions = ({ value, label }, index) => (
      (index === 0 && (value === '00' || !value))
        ? (<DropdownItem value={value} title={label} disabled />)
        : (
          <DropdownItem
            name={value}
            key={`dd_${value}`}
            value={value}
            title={label}
          />
        )
    );

    return (
      <>
        <div className={`expiration-date-wrapper ${this.state.error.length ? 'error' : ''}`}>
          <div className="expiration-date__inner_wrapper">
            <Dropdown
              className="pick-month"
              name="[month]"
              type="form"
              label={label}
              placeholder={this.monthPlaceHolder}
              onChange={this.onMonthChange}
              value={this.state.monthValue}
              menuMaxHeight={220}
              modifier={this.state.error.length ? 'error' : ''}
            >
              {monthOptions.map(addOptions)}
            </Dropdown>

            <Dropdown
              className="pick-year"
              name="[year]"
              type="form"
              label=""
              placeholder={this.yearPlaceHolder}
              onChange={this.onYearChange}
              value={this.state.yearValue}
              menuMaxHeight={220}
              modifier={this.state.error.length ? 'error' : ''}
            >
              {yearOptions.map(addOptions)}
            </Dropdown>
          </div>
          {this.state.error.length ? (
            <div className="andes-form-control__bottom">
              <span className="andes-form-control__error-icon">
                <ErrorIcon />
              </span>
              <span className={`andes-form-control__message`}>{this.state.error[0]}</span>
            </div>
          ) : null}
          <input
            type="hidden"
            value={`${this.state.monthValue}-${this.state.yearValue}`}
            name="[input_expiration_date]"
          />
        </div>
      </>
    );
  }
}

/**
 * Prop Types
 */
SelectExpirationDate.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  showErrorMessage: PropTypes.bool,
  triggerOnMount: PropTypes.func,
  saveInputValue: PropTypes.bool,
  savedValue: PropTypes.string,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

/**
 * Default Props
 */

SelectExpirationDate.defaultProps = {
  id: 'sel-expiration-date',
  name: '',
  className: '',
  label: '',
  error: [],
  step: '',
  savedValue: '',
  i18n: {
    gettext: t => t,
  },
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
  creditCardActions: bindActionCreators(creditCardActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state, ownProps) => ({
  step: state.page.flow.step,
  savedValue: state.inputValues.current[`${state.page.flow.step}_${ownProps.id}`],
});

if (process.env.NODE_ENV === 'test') {
  module.exports = SelectExpirationDate;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(injectValidations(SelectExpirationDate)));
}
