import type { TypographyProps } from '@andes/typography';
import type {
  PaymentSummaryItem,
  PaymentSummarySplitPayment,
  PaymentSummaryTotal,
} from '../PaymentSummary/types';
import type { MessageProps } from '@andes/message';

import React, { useEffect, useState, useRef } from 'react';

// Andes Components
import { Card, CardContent } from '@andes/card';
import { Message } from '@andes/message';
import { Typography } from '@andes/typography';

import RowItem from '../RowItem';
import RowCollapsible from '../RowCollapsible';
import Overlay from '../Overlay';
import Chevron, { ChevronShapeEnum } from '../Icons/Chevron';
import { Colors } from '../../constants/styles';

export type PaymentSummaryMobileProps = {
  title: string;
  // Items Row
  items?: Array<PaymentSummaryItem>;
  splitPayment?: PaymentSummarySplitPayment;
  // Total Row
  total?: PaymentSummaryTotal & {
    nameObservation?: string;
    nameObservationColor?: TypographyProps['color'];
    nameObservationSize?: Exclude<TypographyProps['size'], 'xl'>;
    priceObservation?: string;
    priceObservationColor?: TypographyProps['color'];
    priceObservationSize?: Exclude<TypographyProps['size'], 'xl'>;
    helperText?: string;
  };
  aspirational?: {
    status: MessageProps['color'];
    title: string;
    label: string;
  };
  brand?: boolean;
  isCollapsible?: boolean;
  isUserLogged?: boolean;
  withNavbar?: boolean;
  autoHeightClassname?: string;
  open?: boolean;
  onCollapsed?: (opened: boolean) => void;
};

// eslint-disable-next-line complexity
function PaymentSummaryMobile({
  title,
  items,
  total,
  splitPayment,
  aspirational,
  brand = false,
  isUserLogged = false,
  isCollapsible = false,
  withNavbar = false,
  autoHeightClassname = '',
  open = false,
  onCollapsed,
}: PaymentSummaryMobileProps) {
  const [isExpanded, setIsExpanded] = useState(open);
  const [iconRotation, setRotateIcon] = useState(false);
  const [discountItem, setDiscountItem] = useState<
    PaymentSummaryItem | undefined
  >(undefined);

  const hasMounted = useRef(false);

  const isExpandedClass = isExpanded ? 'expanded' : 'closed';

  const isLogged = isUserLogged ? 'user-logged' : 'user-guest';

  const hasBrand = brand ? `${isLogged}--brand` : isLogged;

  const hasNavbar = withNavbar ? '' : 'not-navbar';

  const getDiscountItem = () => {
    if (Array.isArray(items)) {
      setDiscountItem(items.find((item) => item.type === 'coupon_discount'));
    }
  };

  const toggleExpand = () => {
    setRotateIcon(!iconRotation);
    setIsExpanded(!isExpanded);
    onCollapsed?.(!isExpanded);
  };

  useEffect(() => {
    getDiscountItem();
  }, [items]);

  useEffect(() => {
    setRotateIcon(!!open);
    setIsExpanded(!!open);
  }, [open]);

  return (
    <>
      {isCollapsible ? (
        <>
          <Card
            className={`cow-payment_summary_mobile cow-payment_summary_mobile--collapsible ${isExpandedClass} ${hasBrand} ${hasNavbar}`}
            paddingSize={0}
            shadow="elevated"
          >
            <CardContent className="collapsible-content">
              <div className={`collapsible-content__detail ${isExpandedClass}`}>
                <Typography type="body" className="title">
                  {title}
                </Typography>

                <div className="collapsible-content__detail--items">
                  {items?.map((item, index) => {
                    const isRowCollapsible =
                      Array.isArray(item.subItems) && item.subItems.length;

                    return isRowCollapsible ? (
                      <RowCollapsible
                        key={`${item.name}-${item.price}`}
                        name={item.name}
                        price={item.price}
                        subItems={item.subItems}
                        blockExpand
                        isMobile
                      />
                    ) : (
                      <RowItem
                        className="row-item"
                        key={index}
                        label={item.name}
                        value={item.price}
                        labelClassName="item-label"
                        valueClassName="item-value"
                        labelColor={item.nameColor || 'primary'}
                        valueColor={item.priceColor || 'primary'}
                        labelComplement={item.nameComplement}
                        labelComplementColor={item.nameComplementColor}
                        labelComplementClassName="item-label-complement"
                        valueCents={item.priceCents}
                        valueCentsType={item.priceCentsType}
                        valueSymbol={item.priceSymbol}
                        valueWeight={item.priceWeight}
                        valueSize={item.priceSize}
                        icon={item.icon}
                      />
                    );
                  })}
                </div>

                {splitPayment && (
                  <div className="split-payment">
                    <Typography type="body" size="s" className="title">
                      {splitPayment.label}
                    </Typography>

                    {splitPayment.methods.map((method, index) => (
                      <RowItem
                        key={index}
                        label={method.name}
                        value={method.price}
                        labelClassName="item-label"
                        valueClassName="item-value"
                        className="row-item"
                        valueSize={method.priceSize}
                        valueWeight={method.priceWeight}
                        labelColor={method.nameColor}
                        labelWeight={method.nameWeight}
                        installments={method.installments}
                        installmentsColor={method.installmentsColor}
                        installmentsWeight={method.installmentsWeight}
                        installmentsSize={method.installmentsSize}
                      />
                    ))}
                  </div>
                )}
              </div>

              <div className={`total-value--container ${isExpandedClass}`}>
                {discountItem && (
                  <RowItem
                    className="total-value--container--item"
                    label={discountItem.name}
                    value={discountItem.price}
                    labelClassName="item-label"
                    valueClassName="item-value"
                    labelColor={discountItem.nameColor || 'positive'}
                    valueColor={discountItem.priceColor || 'primary'}
                    labelComplement={discountItem.nameComplement}
                    labelComplementClassName="item-label-complement"
                  />
                )}

                {total && (
                  <RowItem
                    className="total-value--container--total"
                    label={total.name}
                    value={total.price}
                    valueCents={total.priceCents}
                    valueCentsType={total.priceCentsType}
                    labelWeight="semibold"
                    valueWeight="semibold"
                    labelClassName="item"
                    valueClassName="item"
                    valueSize={16}
                    labelObservation={total.nameObservation}
                    labelObservationColor={total.nameObservationColor}
                    labelObservationTextSize={total.nameObservationSize}
                    valueObservation={total.priceObservation}
                    valueObservationColor={
                      total.priceObservationColor || 'positive'
                    }
                    valueObservationTextSize={total.priceObservationSize}
                    observationDirection="row"
                  />
                )}
              </div>
            </CardContent>

            <div className="chevron-button" onClick={toggleExpand}>
              <Chevron
                width={24}
                height={24}
                viewBox="0 0 24 24"
                chevronShape={ChevronShapeEnum.DEFAULT}
                opacity="1"
                className={`chevron-up ${iconRotation ? 'chevron-down' : ''}`}
              />
            </div>
          </Card>

          {isExpanded && <Overlay onClick={toggleExpand} />}
        </>
      ) : (
        <Card
          className={`cow-payment_summary_mobile cow-payment_summary_mobile--static ${hasBrand} ${autoHeightClassname} ${hasNavbar}`}
          paddingSize={0}
          shadow="elevated"
        >
          <CardContent>
            {aspirational && (
              <div className="aspirational-message">
                <Message
                  color={aspirational.status}
                  hierarchy={'quiet'}
                  title={<span>{aspirational.title}</span>}
                >
                  {aspirational.label}
                </Message>
              </div>
            )}

            {items && items.length > 0 && (
              <div className="items--container">
                {items.map(
                  ({
                    name,
                    nameColor = 'primary',
                    nameWeight,
                    nameComplement,
                    nameComplementColor,
                    nameObservation,
                    icon,
                    iconAlignment,
                    price,
                    priceColor = 'primary',
                    priceWeight,
                    priceSize,
                    priceCents,
                    priceCentsType,
                    priceSymbol,
                    priceObservation,
                    type,
                    color,
                  }) => (
                    <RowItem
                      key={`${name}-${price}`}
                      label={name}
                      labelColor={color || nameColor}
                      value={price}
                      valueWeight={priceWeight}
                      valueSize={priceSize}
                      valueCents={priceCents}
                      valueCentsType={priceCentsType}
                      valueSymbol={priceSymbol}
                      valueColor={color || priceColor}
                      labelWeight={nameWeight}
                      className={`cow-payment_summary_mobile__row--${
                        type || 'product'
                      }`}
                      icon={icon}
                      iconAlignment={iconAlignment}
                      labelComplement={nameComplement}
                      labelComplementColor={nameComplementColor}
                      labelObservation={nameObservation}
                      valueObservation={priceObservation}
                    />
                  ),
                )}
              </div>
            )}

            <div
              className={`total-value--container ${items ? 'with-items' : ''}`}
            >
              {total && (
                <RowItem
                  label={total.name}
                  labelWeight={total.nameWeight || 'semibold'}
                  value={total.price}
                  valueWeight={total.priceWeight || 'semibold'}
                  valueObservation={total.priceObservation}
                  valueObservationColor={
                    total.priceObservationColor || 'positive'
                  }
                  valueCents={total.priceCents}
                  valueCentsType={total.priceCentsType}
                  labelClassName="item-label"
                  valueSymbol={total.priceSymbol}
                  valueSize={total.priceSize}
                  labelObservation={total.nameObservation}
                  labelObservationColor={total.nameObservationColor}
                  labelObservationTextSize={total.nameObservationSize}
                />
              )}
            </div>
          </CardContent>
        </Card>
      )}
    </>
  );
}

export default PaymentSummaryMobile;
