const React = require('react');

const IconWrapper = require('../IconWrapper');
const BankTransfer = require('@cow/core-components/components/Icons/Bank');

const Bank = () => (
  <IconWrapper>
    <BankTransfer className="icon-spei" />
  </IconWrapper>
);

module.exports = React.memo(Bank);
