const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const { injectI18n } = require('nordic/i18n');

const {
  SAVE_CAPTCHA_TOKEN,
  REQUEST_CAPTCHA_TOKEN,
} = require('../../spa/actions/types');
const captchaToken = require('../../spa/actions/captchaToken');
const translate = require('../../translation');

class CaptchaV2 extends React.Component {
  constructor(props) {
    super(props);
    const { i18n } = props;
    this.translation = translate(i18n);
    this.executeAction = this.executeAction.bind(this);
    this.executeErrorAction = this.executeErrorAction.bind(this);
    this.onCheckCallback = this.onCheckCallback.bind(this);
    this.onExpiredCallback = this.onExpiredCallback.bind(this);
  }

  componentDidMount() {
    if (this.props.show) {
      if (this.props.captchaToken) {
        this.props.captchaToken[REQUEST_CAPTCHA_TOKEN](true);
      }
      if (window.captchaReady) {
        this.executeAction();
      } else if (window.captchaError) {
        this.executeErrorAction();
      } else {
        window.executeAction = this.executeAction;
        window.executeErrorAction = this.executeErrorAction;
      }
    }
  }

  componentWillUnmount() {
    if (this.props.captchaToken) {
      this.props.captchaToken[REQUEST_CAPTCHA_TOKEN](false);
    }
  }

  onCheckCallback(response) {
    if (this.props.captchaToken) {
      this.props.captchaToken[SAVE_CAPTCHA_TOKEN](response);
      this.props.captchaToken[REQUEST_CAPTCHA_TOKEN](false);
    }
  }

  onExpiredCallback() {
    if (this.props.captchaToken) {
      this.props.captchaToken[SAVE_CAPTCHA_TOKEN]('');
      this.props.captchaToken[REQUEST_CAPTCHA_TOKEN](true);
    }
  }

  /**
   * Executes Captcha action to obtain the token
   */
  executeAction() {
    try {
      window.grecaptcha.enterprise.render('g-recaptcha', {
        sitekey: this.props.captcha.siteKeyV2,
        callback: this.onCheckCallback,
        'expired-callback': this.onExpiredCallback,
        'error-callback': this.executeErrorAction,
      });
    } catch (e) {
      // If there is any error when rendering captcha V2, button should be enabled again
      this.executeErrorAction();
    }
  }

  executeErrorAction() {
    if (this.props.captchaToken) {
      this.props.captchaToken[REQUEST_CAPTCHA_TOKEN](false);
    }
  }

  render() {
    // If the element doesn't need to be shown do not render it
    if (!this.props.show) { return null; }

    return (
      <>
        <div id="g-recaptcha" className="g-recaptcha" />
        <div className="text">{this.props.captchaRetry
          ? this.translation.INVALID_CAPTCHA
          : this.translation.CLICK_BOX}
        </div>
      </>
    );
  }
}

CaptchaV2.propTypes = {
  captcha: PropTypes.shape({
    siteKeyV2: PropTypes.string,
    siteKeyV3: PropTypes.string,
    lang: PropTypes.shape({
      MLA: PropTypes.string,
      MLB: PropTypes.string,
      MLM: PropTypes.string,
      MLC: PropTypes.string,
      MCO: PropTypes.string,
      MLU: PropTypes.string,
      MPE: PropTypes.string,
    }),
    action: PropTypes.string,
  }).isRequired,
  show: PropTypes.bool,
  captchaRetry: PropTypes.bool,
};

CaptchaV2.defaultProps = {
  show: false,
  captchaRetry: false,
  i18n: {
    gettext: t => t
  }
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  captchaToken: bindActionCreators(captchaToken, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  captcha: state.configurations.captcha,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = CaptchaV2;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(CaptchaV2));
}
