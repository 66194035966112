const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');

const translate = require('../../translation');

const ButtonCopy = require('../ButtonCopy');
const Badge = require('../Badge');


const Oops = (props) => {
  let classNames = 'oops';
  const { i18n } = props;
  const translations = translate(i18n);

  if (props.className) {
    classNames += ` ${props.className}`;
  }

  return (
    <div className={classNames}>
      {props.children}

      {props.message && <p className="oops__message">{props.message}</p>}
      {props.errorCode && (
        <Badge
          size="small"
          kind="generic"
          border="standard"
          content={`${translations.CODE}:`}
          className="oops__code-error"
          hierarchy="quiet"
        >
          <span>{props.errorCode}</span>
          <ButtonCopy
            error={translations.IT_WAS_NOT_POSSIBLE_TO_COPY_THE_CODE_PLEASE_TRY_AGAIN}
            value={props.errorCode}
            success={translations.READY_CODE_COPIED}
            kind="icon"
            isRebranding={props.isRebranding}
          />
        </Badge>
      )}
    </div>
  );
};

Oops.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  errorCode: PropTypes.string,
};

Oops.defaultProps = {
  message: '',
  className: '',
  i18n: {
    gettext: (t) => t,
  },
  errorCode: '',
};

module.exports = injectI18n(Oops);
