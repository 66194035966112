const React = require('react');
const PropTypes = require('prop-types');

const IconWrapper = require('../IconWrapper');

const IconHome = ({ color }) => (
  <IconWrapper>
    <svg
      className="with-custom-color"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.8716 9.0055L16.4991 9.633L17.383 8.74912L8.99912 0.365234L0.615234 8.74912L1.49912 9.633L2.12495 9.00717V18.1241H7.77197V12.4787H10.2723V18.1241H15.8716V9.0055ZM14.6216 7.7555L8.99912 2.133L3.37495 7.75717V16.8741H6.52197V11.2287H11.5223V16.8741H14.6216V7.7555Z"
        fill={color}
        fill-opacity="0.9"
      />
    </svg>
  </IconWrapper>
);
IconHome.defaultProps = {
  color: 'currentColor',
};

IconHome.propTypes = {
  color: PropTypes.string,
};

module.exports = React.memo(IconHome);
