const React = require('react');

const { useEffect, useState, useCallback} = React;

const { BUTTON_REQUEST_TRIGGERED } = require('../../spa/actions/types');
const { handleWebviewUrls } = require('../../utils/mobileWebkit');
const { sanitizeUrl } = require('../../utils/UrlUtils');

export const useExternalLink = ({ buttonAction, buttonRequest, isWebview, url }) => {
  const [sanitizedUrl, setSanitizedUrl] = useState(null);

  useEffect(() => {
    buttonAction?.[BUTTON_REQUEST_TRIGGERED]?.(false);
  }, [buttonAction]);

  useEffect(() => {
    setSanitizedUrl(sanitizeUrl(url));
  }, [url]);

  const handleClick = useCallback((event) => {
    if (!buttonRequest) {
      buttonAction?.[BUTTON_REQUEST_TRIGGERED]?.(true);
      if (isWebview && sanitizedUrl) {
        event.preventDefault();
        handleWebviewUrls(sanitizedUrl);
      }
    }
  }, [buttonRequest, buttonAction, isWebview, sanitizedUrl]);

  return { handleClick, sanitizedUrl };
};
