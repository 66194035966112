/* eslint-disable import/order */
/* eslint-disable react/jsx-no-bind */
const React = require('react');
const PropTypes = require('prop-types');

// Store
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

// Andes Components
const { Title, Text } = require('@andes/typography');
const { Message } = require('@andes/message');

// Cow Components
const Navbar = require('../../../../components/NavbarV2');
const PaymentSummaryMobile = require('@cow/core-components/components/PaymentSummaryMobile');
const CardList = require('@cow/core-components/components/CardList');
const CouponCollapsible = require('@cow/core-components/components/CouponCollapsible');
const CardMercadoCredits = require('@cow/core-components/components/MercadoCreditsBanner');
const CardSwitch = require('@cow/core-components/components/CardSwitch');
const Brand = require('@cow/core-components/components/Brand');
const GenericModal = require('@cow/core-components/components/GenericModal');
const KycModal = require('@cow/core-components/components/KycModalV2');
const StickyPayButton = require('@cow/core-components/components/StickyPayButton');
const Footer = require('@cow/core-components/components/Footer');
const { useNavbar, useControlSummary } = require('../../../../hooks/useNavbar');
const ButtonBackURLs = require('../../../../components/ButtonBackURLs');
const BankInterestsMessage = require('../../../../components/BankInterestsMessage');

// Custom Hooks
const { useActions } = require('../hooks/useActions');
const { useCardList } = require('../hooks/useCardList');
const { useLogOut } = require('../../../../hooks/useLogOut');

// i18n
const injectI18n = require('nordic/i18n/injectI18n');
const translate = require('../../../../translation');

// Internal Components
const Page = require('../../../../components/Page');
const DynamicContent = require('../../../../components/DynamicContent');
const CaptchaV2 = require('../../../../components/CaptchaV2');
const DiscountTrigger = require('../components/DiscountTrigger');

// Constants
const {
  ENVIROMENT,
  COLORS_BY_STATUS,
  CHECKOUT_CASE: { SUBSCRIPTION, GUEST, LOGGED, CREDITS },
  SITE_ID,
  DEVICE_TYPE,
  EXTERNAL_URLS,
  ASSETS,
  PAYMENT_METHOD,
} = require('../../../../../constants/commons');
const { CAPTCHA_VERSION, SUMMARY_ITEMS_TYPES } = require('../../../../../constants/app');
const { INPUTS_ID, BUTTONS } = require('../../../../../constants/ui');
const SELECTORS = require('../../../../../constants/selectors');

// Actions
const STEP_ACTIONS = require('../../../../spa/actions/step');
const SNACKBAR_ACTIONS = require('../../../../spa/actions/snackbar');
const ANIMATION_ACTIONS = require('../../../../spa/actions/animations');
const LOADING_ACTIONS = require('../../../../spa/actions/loading');
const REQUEST_ACTIONS = require('../../../../spa/actions/request');
const CAPTCHA_TOKEN_ACTIONS = require('../../../../spa/actions/captchaToken');

// Utils
const { isMobile } = require('../../../../utils/webview');
const { hasBankInterests } = require('../../../../utils/interests');
const { createToggleFunctionWithAnimation } = require('../utils/discount');
const { getMessageColor } = require('../utils/subscription');
const useHeightElement = require('../../../../hooks/useHeightElement');
const {
  hasBrand,
  isCollapsible,
  getMobileTotal,
  handleSummaryItemProps,
  PAYMENT_SUMMARY_ALIGNMENT,
} = require('../../../../utils/summary');
const {
  genericPropsTypes,
  genericDefaultValues,
  newInterfaceGenericPropsTypes,
  newInterfaceGenericDefaultValues,
} = require('../../../../utils/propTypes');
const { getCompanyTheme, getBrandNameCompany } = require('../utils/configurations');
const { getMercadoPagoIconBySiteId } = require('../../../../utils/icons');
const { hasCaptcha } = require('../../../../utils/terms-and-conditions');
const PCJ = require('../../../../utils/pcj');
const classNames = require('classnames');

const Review = (originalProps) => {
  const translations = translate(originalProps.i18n);
  const props = {
    ...PCJ.ifCandidateInjectPropsIntoSummary({
      props: originalProps,
      paymentMethod: originalProps.step_model.payment_methods[0],
      translations,
    }),
  };

  // Template custom content props
  const {
    siteId,
    flow,
    history,
    stepActions,
    snackbarActions,
    blockedByCaptcha,
    bellowIncentives,
    customCardList,
    email,
    browserName,
    requestActions,
    loadingActions,
    loadingPayButton,
    requestLoading,
    loadingStopAnimation,
    captchaConfig,
    captchaToken,
    rebrandingMP,
    showKycModal = true,
    showMlaTaxes = true,
    showTaxes = true,
    isEmailValid = true,
    emailErrorMessage,
    setEmailErrorMessage,
    showIncentives = true,
    customSummaryItems = null,
    consumerCreditsFooter = null,
    customHandlePayClick = null,
    customFooterMessage = null,
    customHandleKyc = null,
    customPrice = null,
    step_model: {
      transaction_type,
      challenge_instructions,
      payer,
      step_title,
      discount,
      summary,
      payment_methods,
      incentives,
      brand,
      terms_and_conditions,
      subscription,
      alternative_payment_method,
      captcha,
      shipping,
      main_action,
      errors,
      navigation,
      notification,
    },
    platform,
    configs,
  } = props;

  const NavbarMenuOptions = {
    title: translations.YOU_ENTERED_AS,
    changeUserLabel: translations.CHANGE_ACCOUNT,
    logOutUserLabel: translations.LOG_OUT,
  };

  const templateCase = props.templateCase || 'default';

  // Ref
  const tooltipContainer = React.createRef();

  // States
  const [discountCodeState, setDiscountCodeState] = React.useState('');
  const [discountCodeErrorMessage, setDiscountCodeErrorMessage] = React.useState(errors?.discount?.code ?? '');
  const [showDiscountModal, setShowDiscountModal] = React.useState(false);
  const [isCouponVisible, setIsCouponVisible] = React.useState(false);
  const [useAlternativePaymentMethod, setUseAlternativePaymentMethod] = React.useState(
    alternative_payment_method?.enabled,
  );
  const { handleOpenNavbar, isOpenedSummary, handleCollapsedSummary } = useControlSummary();

  const progressDone = loadingStopAnimation && requestLoading;
  const hasDiscountCodeApplied = summary.items.find((item) => item.type === SUMMARY_ITEMS_TYPES.COUPON_DISCOUNT);
  const summaryItems = customSummaryItems || summary?.items;
  const paddingContent = useHeightElement();

  // Actions Hook
  const {
    showNotification,
    changePaymentMethod,
    changeInstallments,
    dismissConsumerCredits,
    payConsumerCredits,
    sentDiscountCode,
    changeExtraData,
    changeShippingOption,
    changeShippingAddress,
    kyc,
    pay,
  } = useActions({
    siteId,
    browserName,
    flow,
    history,
    stepActions,
    snackbarActions,
    discountCode: discountCodeState,
    setDiscountCodeErrorMessage,
    payer,
    email,
    challengesInstructions: challenge_instructions,
    captcha,
    requestActions,
    captchaToken,
    captchaConfig,
    translations,
    useAlternativePaymentMethod,
    loadingActions,
    platform,
    configurations: configs,
  });

  const { logout } = useLogOut({
    flow,
    siteId,
    browserName,
    history,
    stepActions,
    snackbarActions,
    translations,
    platform,
    configurations: configs,
    discountCode: discountCodeState,
    challengesInstructions: challenge_instructions,
  });

  const handleDiscountCodeInput = (event) => {
    setDiscountCodeState(event.target.value);
    setDiscountCodeErrorMessage('');
  };

  // Card List Hook
  const { buildCardList } = useCardList({
    siteId,
    isBppFlow: incentives?.is_bpp_flow,
    translations,
    isGuest: payer.is_guest,
    payer,
    shipping,
    deviceType: props.deviceType,
    actions: {
      changePaymentMethod,
      changeInstallments,
      changeExtraData,
      changeShippingOption,
      changeShippingAddress,
    },
  });

  const cardListOptions = buildCardList(payment_methods, rebrandingMP);

  // IDs
  const discountTriggerId = 'discount-btn--trigger';

  // ===== Handle Behaviors ====
  const getElement = () => document.querySelector('.content-row');
  const getVisibleState = () => isCouponVisible;
  const setVisibleState = (state) => setIsCouponVisible(state);

  const toggleDiscount = createToggleFunctionWithAnimation(getElement, getVisibleState, setVisibleState);

  const toggleAlternativePaymentMethod = () => {
    setUseAlternativePaymentMethod((prevState) => !prevState);
  };

  // ===== Payment Summary Mobile =====
  const isPaymentSummaryCollapsible = isCollapsible(summaryItems?.length, transaction_type, summary);

  // Handle Pay Click (submit button)
  const handlePayClick = () => {
    if (!isEmailValid) {
      setEmailErrorMessage(emailErrorMessage);
      return;
    }

    if (customHandlePayClick) {
      customHandlePayClick();
      return;
    }

    pay();
  };

  // Notifications
  React.useEffect(() => {
    if (notification) {
      // TODO IMPROVE THIS BEHAVIOR TO MATCH WITH PRODUCTION
      document.getElementById(BUTTONS.DISCOUNT_TRIGGER)?.click();
      setDiscountCodeState('');

      showNotification({
        status: COLORS_BY_STATUS[notification.status?.toUpperCase()],
        component: notification.message,
        displayTimeout: 10000,
      });
    }
  }, [notification]);

  // Discount Animation
  React.useEffect(() => {
    if (discount?.is_applicable) {
      const contentContainer = document.querySelector('.content');
      const contentRowContainer = document.querySelector('.content-row');

      contentContainer.style.position = 'relative';
      contentRowContainer.classList.add('content-row--animated');

      // Set the correct size for the "content" element when the "content-row" be major
      // This is because the "content-row" is using position: absolute and the "content" element is using position: relative (For the animation purpose)
      if (contentRowContainer.offsetHeight > contentContainer.offsetHeight) {
        contentContainer.style.height = `${contentRowContainer.offsetHeight}px`;
      }
    }
  }, []);

  const theme = getCompanyTheme(configs?.internalConfigurations);
  const { showNavbar } = useNavbar(flow, payer.is_guest, configs?.isWebview);
  const paymentMethod = payment_methods?.[0];
  const hasNoDiscountMessage = !customSummaryItems?.find((item) => item?.type === 'coupon_discount');
  const canApplyPaddingOnContent = hasNoDiscountMessage && paddingContent;

  const shouldShowBankInterestsMessage = hasBankInterests(siteId) && paymentMethod?.installment?.quantity > 1;

  return (
    <Page
      title={props.stepTitle}
      currentStep={`${props.currentStep}_template_${templateCase}`}
      urls={props.urls}
      trackingPath={props.trackingPath}
      analytics={props.analytics}
      deviceType={props.deviceType}
    >
      <div className="layout layout--new-interface">
        {/* Space for Modals */}
        {!!challenge_instructions?.kyc && showKycModal && (
          <KycModal
            dispatcherSelector={`.${BUTTONS.MOBILE_PAY}`}
            description={rebrandingMP ? translations.KYC_DESCRIPTION : challenge_instructions.kyc.description}
            // TODO - TECH DEBT: Unificate the deviceType & isMobile props, it's not consistent
            deviceType={props.deviceType}
            title={rebrandingMP ? translations.KYC_TITLE : challenge_instructions.kyc.title}
            image={rebrandingMP ? ASSETS.KYC_IMAGE_REBRANDING_MOBILE : ASSETS.KYC_IMAGE}
            submitLabel={rebrandingMP ? translations.KYC_BTN_VALIDATION : translations.START}
            alternativeOptionLabel={
              rebrandingMP ? translations.KYC_BTN_PAY_WITH_ANOTHER : translations.PAY_WITH_ANOTHER
            }
            onSubmit={customHandleKyc || kyc}
            onSubmitAlternativeOption={changePaymentMethod}
            className={`kyc-modal--${challenge_instructions.kyc.type}`}
          />
        )}

        {discount?.is_applicable && (
          <GenericModal
            className="discount-modal"
            title={translations.GENERAL_CONDITIONS}
            content={<>{discount?.detail}</>}
            isOpen={showDiscountModal}
            // TODO - TECH DEBT: Unificate the deviceType & isMobile props, it's not consistent
            deviceType={props.deviceType}
            onClose={() => setShowDiscountModal(false)}
            onOpen={() => setShowDiscountModal(true)}
          />
        )}

        {/* Space for Header */}
        {showNavbar && (
          <Navbar
            browserName={browserName}
            deviceType={props.deviceType}
            flow={flow}
            history={history}
            payer={payer}
            siteId={siteId}
            stepActions={stepActions}
            snackbarActions={snackbarActions}
            theme={theme}
            translations={translations}
            onOpen={handleOpenNavbar}
            platform={platform}
            configurations={configs}
          />
        )}

        {hasBrand(brand) && (
          <Brand
            image={brand.avatar}
            name={brand.name}
            isMobile={isMobile(props.deviceType)}
            isUserLogged={!payer.is_guest}
            withNavbar={showNavbar}
          />
        )}

        <PaymentSummaryMobile
          isUserLogged={!payer.is_guest}
          title={summary?.title}
          isCollapsible={isPaymentSummaryCollapsible}
          items={
            (isPaymentSummaryCollapsible || transaction_type === SUBSCRIPTION) &&
            summary?.items
              ?.map((item) =>
                handleSummaryItemProps({
                  item,
                  summary,
                  showTaxes,
                  translations,
                  tooltipContainer,
                  setShowDiscountModal,
                  deviceType: props.deviceType,
                  isSubscription: transaction_type === SUBSCRIPTION,
                  iconAlignment: isPaymentSummaryCollapsible
                    ? PAYMENT_SUMMARY_ALIGNMENT.LEFT
                    : PAYMENT_SUMMARY_ALIGNMENT.RIGHT,
                }),
              )
              ?.filter((item) => item)
          }
          total={getMobileTotal(summary, transaction_type, subscription, customPrice)}
          brand={hasBrand(brand)}
          autoHeightClassname={transaction_type === SUBSCRIPTION ? 'auto-height' : ''}
          withNavbar={showNavbar}
          open={isOpenedSummary}
          onCollapsed={handleCollapsedSummary}
        />

        <section
          className={`layout__main-content
          ${payer.is_guest ? 'user-guest' : 'user-logged'}
          ${hasBrand(brand) ? 'cow-brand-content' : ''}
          ${isPaymentSummaryCollapsible ? '' : 'is-payment-summary-static'}
        `}
          style={{ paddingTop: canApplyPaddingOnContent }}
        >
          <div className="top-row">
            <Title component="h3" size="xs">
              {step_title}
            </Title>
            <DiscountTrigger
              isApplicable={discount?.is_applicable}
              labelToggle={translations.ENTER_A_COUPON}
              discountTriggerId={discountTriggerId}
              toggleDiscount={toggleDiscount}
            />
            {
              payment_methods?.[0].notification_single_installment &&
                 <Message 
                   key="notification_single_installment"
                   color={COLORS_BY_STATUS.INFO}
                   hierarchy="quiet"
                   closable={false} 
                   className="single_installment-notification-message"
                   >
                   {translations.REVIEW_INSTALLMENTS_ERROR}
                 </Message>
            }
          </div>

          <div className={classNames('content', { [SELECTORS.REBRANDING]: rebrandingMP })}>
            {paymentMethod?.id === PAYMENT_METHOD.SPEI && (
              <Message id="card-option__spei" hierarchy="quiet">
                {translations.CLABE_MESSAGE}
              </Message>
            )}

            {subscription?.free_trial && Object.keys(subscription?.free_trial)?.length && (
              <Message
                className="message__free-trial"
                color={getMessageColor(subscription)}
                hierarchy="quiet"
                title={subscription.free_trial.title}
              >
                {subscription.free_trial.label}
              </Message>
            )}

            <div className="content-row">
              {payer.is_guest && (
                <Title size="xs" className="title guest-subtitle" component="div">
                  {translations.REVIEW_SUBTITLE_GUEST}
                </Title>
              )}

              <DynamicContent defaultContent={<CardList items={cardListOptions} />} customContent={customCardList} />

              {siteId === SITE_ID.MLA && showMlaTaxes && paymentMethod?.installment && (
                <div className="card-taxes-mla">
                  <Text size="xs" color="secondary" component="p" className="card-taxes-mla__cost">
                    {paymentMethod?.installment?.total_finance_cost}
                  </Text>

                  {paymentMethod?.installment?.detail && (
                    <Text size="xs" color="secondary" component="p" className="card-taxes-mla__total">
                      {paymentMethod?.installment?.detail}
                    </Text>
                  )}
                </div>
              )}

              {!payer.is_guest && shouldShowBankInterestsMessage && (
                <BankInterestsMessage message={translations.BANK_INTERESTS} />
              )}

              <DynamicContent
                defaultContent={
                  <>
                    {incentives?.credits && showIncentives && (
                      <CardMercadoCredits
                        title={translations.PURCHASE_NOW_AND_PAY_LATER}
                        description={incentives?.credits?.description || ''}
                        promotion={incentives?.credits?.interest_text || ''}
                        submitLabel={translations.PAY_WITH_MERCADO_CREDIT}
                        onClose={dismissConsumerCredits}
                        onClick={payConsumerCredits}
                        isMobile={isMobile(props.deviceType)}
                        isRebrandingMP={rebrandingMP}
                      />
                    )}

                    {subscription?.validation && Object.keys(subscription?.validation)?.length && (
                      <Message
                        className="message__payment-validation"
                        color={COLORS_BY_STATUS[subscription.validation.status?.toUpperCase()]}
                        hierarchy="quiet"
                        title={subscription.validation.title}
                      >
                        {subscription.validation.label}
                      </Message>
                    )}

                    {/* Combination Card Switch */}
                    {useAlternativePaymentMethod !== null && useAlternativePaymentMethod !== undefined && (
                      <CardSwitch
                        description={translations.AM_PAYMENT_METHOD_FALLBACK}
                        icon={getMercadoPagoIconBySiteId(siteId, SITE_ID.MLA === siteId, rebrandingMP)}
                        onChange={toggleAlternativePaymentMethod}
                        value={useAlternativePaymentMethod}
                      />
                    )}

                    {/* Captcha */}
                    {captcha?.version === CAPTCHA_VERSION.V2 && (
                      <div
                        className={`payment-summary__bottom-content cow-captcha__container cow-captcha__container--${
                          payer.is_guest ? GUEST : LOGGED
                        }`}
                      >
                        <CaptchaV2
                          {...props}
                          show={captcha?.version === CAPTCHA_VERSION.V2}
                          captchaRetry={captcha?.is_retry || false}
                        />
                      </div>
                    )}
                  </>
                }
                customContent={bellowIncentives}
              />

              {payer.is_guest && shouldShowBankInterestsMessage && (
                <BankInterestsMessage message={translations.BANK_INTERESTS} />
              )}

              {customFooterMessage}

              {/* Back link */}

              {/* Footer messages */}
              <div className="footer-terms">
                {consumerCreditsFooter}

                {navigation?.back_urls?.failure?.length > 0 && (
                  <div className="footer-terms--backlink-container">
                    <ButtonBackURLs label={translations.RETURN_TO_SITE} url={navigation.back_urls.failure} />
                  </div>
                )}

                <Footer
                  requiredMessage={translations.PROCESSED_BY_MERCADO_PAGO}
                  requiredScreenReaderMessage={translations.SCREEN_READER_FOOTER_MESSAGE}
                  deviceType={props.deviceType}
                  optionalInformation={transaction_type === CREDITS ? null : hasCaptcha(terms_and_conditions?.has_captcha, translations)}
                />
              </div>
            </div>

            {/* Space for notifications */}
            <CouponCollapsible
              value={discountCodeState}
              inputId={INPUTS_ID.COUPON_CODE}
              labelInput={translations.COUPON_CODE}
              description={discountCodeErrorMessage}
              submitButtonLabel={translations.APPLY_DISCOUNT}
              style={discountCodeErrorMessage ? 'error' : undefined}
              onClick={sentDiscountCode}
              onChange={handleDiscountCodeInput}
              show={discount?.is_applicable}
              device={DEVICE_TYPE.MOBILE}
            />
          </div>
        </section>
        <StickyPayButton
          label={main_action}
          onPayClick={handlePayClick}
          withBackgroundOnHandleScroll
          securityPaymentInformationLabel={incentives?.is_secure_payment ? translations.SECURE_PAYMENT : null}
          payButtonHelperText={subscription?.instructions}
          loadingButton={loadingPayButton && !progressDone}
          disablePayButton={
            blockedByCaptcha ||
            (isCouponVisible && hasDiscountCodeApplied) ||
            (isCouponVisible && !hasDiscountCodeApplied)
          }
        />
      </div>
    </Page>
  );
};

Review.defaultProps = {
  step_model: {
    ...newInterfaceGenericDefaultValues,
  },
  ...genericDefaultValues,
  // States
  email: '',
  browserName: '',
  showKycModal: true,
  showMlaTaxes: true,
  showTaxes: true,
  showIncentives: true,
  isEmailValid: true,
  requestLoading: false,
  blockedByCaptcha: false,
  loadingPayButton: false,
  loadingStopAnimation: false,
  // Elements
  customPrice: undefined,
  customSidebar: undefined,
  customCardList: undefined,
  bellowIncentives: undefined,
  customNotifications: undefined,
  customFooterMessage: undefined,
  consumerCreditsFooter: undefined,
  consumerCreditsSummary: undefined,
  customSummaryItems: undefined,
  // Actions
  customHandlePayClick: undefined,
  customHandleKyc: undefined,
};

Review.propTypes = {
  templateCase: PropTypes.string,
  step_model: PropTypes.shape({
    ...newInterfaceGenericPropsTypes,
  }).isRequired,
  ...genericPropsTypes,
  // States
  email: PropTypes.string,
  browserName: PropTypes.string,
  requestLoading: PropTypes.bool,
  loadingPayButton: PropTypes.bool,
  blockedByCaptcha: PropTypes.bool,
  emailErrorMessage: PropTypes.string,
  loadingStopAnimation: PropTypes.bool,
  setEmailErrorMessage: PropTypes.func,
  showIncentives: PropTypes.bool,
  showKycModal: PropTypes.bool,
  showMlaTaxes: PropTypes.bool,
  showTaxes: PropTypes.bool,
  isEmailValid: PropTypes.bool,
  // Elements
  footer: PropTypes.node,
  customPrice: PropTypes.node,
  customSidebar: PropTypes.node,
  bellowIncentives: PropTypes.node,
  customFooterMessage: PropTypes.node,
  consumerCreditsFooter: PropTypes.node,
  consumerCreditsSummary: PropTypes.node,
  customSummaryItems: PropTypes.arrayOf(PropTypes.shape({})),
  // Actions
  customHandlePayClick: PropTypes.func,
  customHandleKyc: PropTypes.func,
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  snackbarActions: bindActionCreators(SNACKBAR_ACTIONS, dispatch),
  stepActions: bindActionCreators(STEP_ACTIONS, dispatch),
  animationActions: bindActionCreators(ANIMATION_ACTIONS, dispatch),
  captchaToken: bindActionCreators(CAPTCHA_TOKEN_ACTIONS, dispatch),
  requestActions: bindActionCreators(REQUEST_ACTIONS, dispatch),
  loadingActions: bindActionCreators(LOADING_ACTIONS, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  flow: state.page.flow,
  configs: state.configurations,
  firstRender: state.page.firstRender,
  basePath: state.configurations.basePath,
  captchaConfig: state.configurations.captcha,
  browserName: state.configurations.browserName,
  requestLoading: state.request.loading,
  loadingPayButton: state.request.loadingPayButton,
  loadingStopAnimation: state.request.loadingStopAnimation,
  blockedByCaptcha: state.captchaToken.blockedByCaptcha,
  currency: state.configurations.currency,
  platform: state.configurations.platform,
  rebrandingMP: state.configurations.rebrandingMP,
});

if (process.env.NODE_ENV === ENVIROMENT.TEST) {
  module.exports = Review;
} else {
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(Review));
}
