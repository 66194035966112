const React = require('react');

const IconWrapper = require('../IconWrapper');

const RebrandingUserUser = () => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.499 14.25C14.2275 14.25 17.25 11.2275 17.25 7.49905C17.25 3.77057 14.2275 0.748047 10.499 0.748047C6.77053 0.748047 3.748 3.77057 3.748 7.49905C3.748 11.2275 6.77053 14.25 10.499 14.25ZM10.499 12.75C7.59895 12.75 5.248 10.3991 5.248 7.49905C5.248 4.599 7.59895 2.24805 10.499 2.24805C13.399 2.24805 15.75 4.599 15.75 7.49905C15.75 10.3991 13.399 12.75 10.499 12.75Z"
        fill="currentColor"
        fill-opacity="0.9"
      />
      <path
        d="M4.48045 17.25C3.24861 17.25 2.25 18.2487 2.25 19.4805V20.2305H0.75V19.4805C0.75 17.4202 2.42018 15.75 4.48045 15.75H16.5C18.5711 15.75 20.25 17.429 20.25 19.5V20.25H18.75V19.5C18.75 18.2574 17.7426 17.25 16.5 17.25H4.48045Z"
        fill="currentColor"
        fill-opacity="0.9"
      />
    </svg>
  </IconWrapper>
);

module.exports = React.memo(RebrandingUserUser);
