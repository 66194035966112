import type { ButtonProps } from '@andes/button';

import React from 'react';
import { Button } from '@andes/button';
import { Modal } from '@andes/modal';
import { Typography } from '@andes/typography';

import { DeviceType } from '../../constants/commons';

const MAX_WIDTH_MOBILE = 312;
const MAX_WIDTH_DESKTOP = 480;

export type CreditsActivationProps = {
  title: string;
  description: string;
  acceptButton: string;
  denyButton: string;
  open?: boolean;
  deviceType: DeviceType;
  acceptCredits: ButtonProps['onClick'];
  denyCredits: ButtonProps['onClick'];
};

function CreditsActivation({
  title,
  description,
  acceptButton,
  denyButton,
  open = false,
  deviceType,
  acceptCredits,
  denyCredits,
}: CreditsActivationProps) {
  return (
    <>
      <Modal
        className={`cow-credits-activation`}
        open={open}
        closable={false}
        maxWidth={
          deviceType === DeviceType.MOBILE
            ? MAX_WIDTH_MOBILE
            : MAX_WIDTH_DESKTOP
        }
        title={''}
        type={deviceType === DeviceType.MOBILE ? 'card' : 'small'}
      >
        <div className="cow-credits-activation__content">
          <div className="cow-credits-activation__content__title">
            <Typography
              className="title__text"
              weight="regular"
              type="title"
              size={deviceType === DeviceType.MOBILE ? 's' : 'm'}
            >
              {title}
            </Typography>
          </div>
          <div className="cow-credits-activation__content__description">
            <Typography className="description__text" type="body" size="m">
              {description}
            </Typography>
          </div>

          <div className="cow-credits-activation__content__button-container">
            <Button
              className="cow-credits-activation__content__button-container__accept"
              hierarchy="loud"
              size="large"
              onClick={acceptCredits}
            >
              {acceptButton}
            </Button>

            <Button
              className="cow-credits-activation__content__button-container__deny"
              hierarchy="transparent"
              size="large"
              onClick={denyCredits}
            >
              {denyButton}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default React.memo(CreditsActivation);
