const { useState, useCallback } = require('react');

const { useForm } = require('checkout-off-form-validation');
const { useI18n } = require('nordic/i18n');
const { Card, CardContent } = require('@andes/card');
const { TextField } = require('@andes/textfield');
const { Button } = require('@andes/button');
const { Form } = require('@andes/form');
const { Dropdown, DropdownItem } = require('@andes/dropdown');

const translate = require('../../../translation');
const ApiService = require('../../../service/api');
const ModalZipcodeV2 = require('../../ModalZipcodeV2');

const ZipcodeFormComponent = ({ deviceType, countryId, handleCreateAddress, hasZipcodeFind }) => {
  const { i18n } = useI18n();
  const translations = translate(i18n);
  const { errors, register, handleSubmit, setFieldValue, fields } = useForm();

  const [listCities, setListCities] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);

  const findLocationByZipCode = useCallback(async (zipCode) => {
    setFieldValue('city', '');
    setFieldValue('state', '');

    try {
      const data = await ApiService.getLocations(zipCode);
      const locations = data?.locations;

      const listCities = locations.map((location) => ({ name: location.city.name }));

      setListCities(listCities);

      setFieldValue('city', listCities[0].name);
      setFieldValue('state', locations[0]?.state?.name);
    } catch (error) {
      setListCities([]);

      setFieldValue('city', '');
      setFieldValue('state', '');
    }
  });

  const formFields = {
    zipCode: {
      required: { message: translations.REQUIRED_FORM_FIELD },
      validateOnChange: false,
      onChange: async (e) => {
        setFieldValue('zipcode', e.target.value);

        if (e.target.value.length > 3) {
          findLocationByZipCode(e.target.value);
        }
      },
    },
    city: {
      validateOnChange: false,
      onChange: (_, itemValue) => setFieldValue('city', itemValue),
    },
  };

  const submitAction = useCallback(
    (data, event) => {
      event.preventDefault();

      const payload = {
        zip_code: fields.zipcode.data,
        input_state: fields.state.data,
        input_city: fields.city.data,
      };

      handleCreateAddress(payload);
    },
    [fields],
  );

  return (
    <div className="shipping-zipcode-form">
      <ModalZipcodeV2
        deviceType={deviceType}
        i18n={i18n}
        open={modalOpened}
        setOpen={setModalOpened}
        populateFields={(currentStateCB) => {
          const { zipcode } = currentStateCB();
          setFieldValue('zipcode', zipcode);
        }}
        countryId={countryId}
        callback={findLocationByZipCode}
      />

      <Form onSubmit={handleSubmit(submitAction)}>
        <div className={`page-content page-content__${deviceType}`}>
          <div className="content">
            <Card paddingSize={24}>
              <CardContent className="form-content">
                <TextField
                  id="zipcode"
                  maxLength={9}
                  label={translations.LABEL_ZIPCODE}
                  modifier={errors?.zipcode && 'error'}
                  helper={errors?.zipcode?.message}
                  value={fields?.zipcode?.data ?? ''}
                  {...register('zipcode', formFields.zipCode)}
                >
                  {hasZipcodeFind && (
                    <Button
                      size="small"
                      className="form_address_info_modal_button"
                      hierarchy="transparent"
                      onClick={() => setModalOpened(true)}
                    >
                      {translations.MY_CODE}
                    </Button>
                  )}
                </TextField>

                <div className={`form-content-group__${deviceType}`}>
                  <TextField
                    id="state"
                    className={`full-width ${deviceType}`}
                    label={translations.PROVINCE}
                    placeholder={translations.ELIGE}
                    disabled
                    value={fields?.state?.data ?? ''}
                    {...register('state')}
                  />
                  <Dropdown
                    id="city"
                    className={`full-width ${deviceType}`}
                    label={translations.MUNICIPALITY_MAYOR_OFFICE}
                    type="form"
                    value={fields?.city?.data ?? ''}
                    {...register('city', formFields.city)}
                  >
                    {listCities.map((city) => (
                      <DropdownItem value={city?.name} title={city?.name} key={city?.name} />
                    ))}
                  </Dropdown>
                </div>
              </CardContent>
            </Card>
          </div>

          <div className={`action action__${deviceType} reorder-buttons`}>
            <Button hierarchy="loud" size="large" type="submit">
              {translations.CONTINUE}
            </Button>

            <Button hierarchy="quiet" size="large" onClick={() => window.history.back()}>
              {translations.TO_GO_BACK}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

module.exports = { ZipcodeFormComponent };
