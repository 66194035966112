const React = require('react');
const PropTypes = require('prop-types');

const dictionary = require('../../containers/Optimus/dictionary');
const GroupMediaObject = require('../Groups/GroupMediaObject');

const translate = (uiComponent, props) => (
  dictionary.get([uiComponent.type]) ? (dictionary.get([uiComponent.type])({ ...uiComponent, ...props })) : ''
);

const CommonRow = ({ components, history, isRebranding }) => (
  <GroupMediaObject>
    {components.map((component) => (
      <div key={Math.random()}>{translate(component, { history, isRebranding })}</div>
    ))}
  </GroupMediaObject>
);

CommonRow.propTypes = {
  components: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      data: PropTypes.shape({
        issuer_id: PropTypes.string,
        payment_method_id: PropTypes.string,
      }),
    }),
  ),
  history: PropTypes.object,
};

CommonRow.defaultProps = {
  components: [],
  history: {},
};

module.exports = CommonRow;
