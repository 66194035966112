const React = require('react');
const { Message } = require('@andes/message');

/**
 * BankInterestsMessage component
 */
const BankInterestsMessage = ({ message }) => {
  return (
      <Message hierarchy="quiet" color="accent" className="bank_interests">
        {message}
      </Message>
  );
};

module.exports = BankInterestsMessage;