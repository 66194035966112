const React = require('react');
const PropTypes = require('prop-types');

const Tooltip = require('@andes/tooltip');
const Help = require('@andes/icons/Helper16');
const { connect } = require('react-redux');
const IconInfo = require('@andes/icons/Info16');
const { ANDES_PLACEMENT } = require('../../../constants/commons');

const IconGenericQuestionMark = require('../icons/GenericQuestionMark');
const {
  COLORS,
  COMMONS: { DEVICE_TYPE },
} = require('../../../constants');

class IconWithTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.tooltipContainer = React.createRef();

    this.getIcon = this.getIcon.bind(this);
    this.getValidSideOfTooltip = this.getValidSideOfTooltip.bind(this);
  }

  getIcon() {
    const { icon, iconColor } = this.props;

    switch (icon) {
      case 'info':
        return <IconInfo color={iconColor} />;

      case 'help':
        return <Help color={iconColor} />;

      default:
        return <IconGenericQuestionMark />;
    }
  }

  getValidSideOfTooltip(deviceType) {
    const { side } = this.props;
    const maxSpaceThatBreakTooltip = 65;

    if (deviceType === DEVICE_TYPE.DESKTOP) {
      return side || ANDES_PLACEMENT.RIGHT;
    }

    if (deviceType === DEVICE_TYPE.MOBILE) {
      return ANDES_PLACEMENT.BOTTOM;
    }

    if (this.tooltipContainer.current && this.tooltipContainer.current.offsetLeft > maxSpaceThatBreakTooltip) {
      return side || ANDES_PLACEMENT.BOTTOM;
    }

    return side;
  }

  render() {
    const { text, title, deviceType, showCloseButton, type, trigger, actions } = this.props;

    return (
      <div className="icon-with-tooltip" ref={this.tooltipContainer}>
        <Tooltip
          className="icon-with-tooltip__content"
          side={this.getValidSideOfTooltip(deviceType)}
          title={title}
          content={text}
          showCloseButton={deviceType === DEVICE_TYPE.MOBILE || showCloseButton}
          type={type}
          trigger={trigger}
          actions={actions}
          mouseLeaveDelay={deviceType === DEVICE_TYPE.MOBILE ? 0 : 2}
        >
          {this.getIcon()}
        </Tooltip>
      </div>
    );
  }
}

IconWithTooltip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  side: PropTypes.string,
  title: PropTypes.string,
  deviceType: PropTypes.string,
  showCloseButton: PropTypes.bool,
  type: PropTypes.string,
  iconColor: PropTypes.string,
  trigger: PropTypes.string,
  actions: PropTypes.node,
};

IconWithTooltip.defaultProps = {
  side: 'bottomLeft',
  title: '',
  deviceType: '',
  showCloseButton: false,
  type: 'light',
  iconColor: COLORS.MP_ANDES_BLUE_500,
};

const mapStateToProps = (state) => ({
  deviceType: state.configurations.deviceType,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = IconWithTooltip;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps)(IconWithTooltip);
}
