const React = require('react');

// Andes Components
const { Title } = require('@andes/typography');

// Custom Components
const RowCardList = require('../../../components/RowCardList');

module.exports = {
  rebranding_title: (props) => (
    <div className="rebranding__title">
      <Title size="s" component="h1">
        {props?.data?.title}
      </Title>
    </div>
  ),

  rebranding_sub_title: (props) => (
    <div className="rebranding__title_h2">
      <Title size="xs" component="h2">
        {props?.data?.title}
      </Title>
    </div>
  ),

  rebranding_card_list: (props) => <RowCardList {...props} />,
};
