const React = require("react");
const PropTypes = require("prop-types");
const { ProgressIndicatorCircular } = require("@andes/progress-indicator-circular");

const { DEVICE_TYPE } = require("../../../constants/commons");

const Processing = props => (<ProgressIndicatorCircular id="cow-loader" className="cow-loader" size={props.deviceType === DEVICE_TYPE.MOBILE ? "large" : "xlarge"} modifier={props.modifier} label={props.message} />)

Processing.propTypes = {
  modifier: PropTypes.oneOf(["inline", "block", "fullscreen"]),
  message: PropTypes.string,
  deviceType: PropTypes.string,
};

Processing.defaultProps = {
  modifier: "fullscreen",
  message: "",
  deviceType: DEVICE_TYPE.DESKTOP,
};

module.exports = Processing;
