const React = require('react');
const PropTypes = require('prop-types');
const { injectI18n } = require('nordic/i18n');

// Redux
const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

// Custom Components
const SecureFieldInput = require('../../../../../components/SecureFieldInput');

// Actions
const EXPRESS_ACTIONS = require('../../../../../spa/actions/express');
const { EXPRESS_VALIDATE_CARD } = require('../../../../../spa/actions/types');

// Utils
const translate = require('../../../../../translation');
const { getESCCookie } = require('../../../../../utils/CookieUtils');

// Constants
const {
  APP: {
    COOKIES,
    SECURE_FIELDS: {
      FIELDS: { SECURITY_CODE },
    },
  },
} = require('../../../../../../constants');

const SecurityCode = ({ id, i18n, express, requests, optionId, securityCode, escAvailable, expressActions }) => {
  const translations = translate(i18n);

  React.useEffect(() => {
    const escCookie = getESCCookie(`${COOKIES.ESC}${optionId}`);
    const escNotConsulted = !requests?.requests.find((el) => el === optionId) && !express?.[optionId]?.cardIdsValidated;
    if (escCookie && escNotConsulted && escAvailable) {
      expressActions[EXPRESS_VALIDATE_CARD](optionId, escCookie);
    }
  }, []);

  if (express?.[optionId]?.cardTokenId) {
    return null;
  }

  const toolTipCVV = securityCode?.length === 4
    ? translations.CVV_HELPER(securityCode?.length, translations.CVV_HELPER_FRONT)
    : translations.CVV_HELPER(securityCode?.length, translations.CVV_HELPER_BACK);

  return (
    <SecureFieldInput
      id={id}
      field={SECURITY_CODE}
      label={translations.CVV_LABEL}
      hint={toolTipCVV}
      isFocused={express?.secureField?.focus}
      errorMessage={express?.secureField?.error}
      showTooltip
    />
  );
};

SecurityCode.propTypes = {
  id: PropTypes.string.isRequired,
  optionId: PropTypes.string.isRequired,
  escAvailable: PropTypes.bool.isRequired,
  securityCode: PropTypes.shape({
    length: PropTypes.number.isRequired,
  }).isRequired,
  requests: PropTypes.shape({
    requests: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  express: PropTypes.shape({
    cardTokenId: PropTypes.string,
  }).isRequired,
  expressActions: PropTypes.shape({
    [EXPRESS_VALIDATE_CARD]: PropTypes.func.isRequired,
  }).isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  expressActions: bindActionCreators(EXPRESS_ACTIONS, dispatch),
});

const mapStateToProps = (state) => ({
  express: state.express,
  requests: state.request,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = injectI18n(SecurityCode);
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(SecurityCode));
}
