const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const { injectI18n } = require('nordic/i18n');

const { injectValidations } = require('../../utils/validator-provider');
const BaseInput = require('../BaseInput');
const IconCardCvv = require('../IconCardCvv');
const BaseValidation = require('../BaseValidation');
const creditCardActions = require('../../spa/actions/creditCard');
const inputValuesActions = require('../../spa/actions/inputValues');
const { CURRENT_INPUT_VALUES, CREDIT_CARD_PC_SECURITY } = require('../../spa/actions/types');
const CardHint = require('./CardHint');
const allowList = require('../../utils/securityCodeAllowlist');
const GreyIcon = require('../icons/grey');
const translate = require('../../translation');

class InputCVV extends BaseValidation {
  constructor(props) {
    super(props);
    const { i18n } = props;
    this.translations = translate(i18n);

    // Default State
    this.state = {
      error: props.error,
      invalid: props.invalid,
      value: '', // Never use a defaultValue, can't have a defaultValue for CVV
      cvvOnFront: props.data.cvv_on_front,
      // If the max length is not coming from Backend (possible bug) set 4 as default
      // Check undefined because it can receive 0
      maxLength: props.data.max_length === undefined || props.data.max_length === null ? 4 : props.data.max_length,
    };

    // Create a paymentMethod variable
    this.paymentMethod = null;

    this.onChange = this.onChange.bind(this);
    this.onGuessing = this.onGuessing.bind(this);
  }

  /**
   * Check if the props change and compare them with the state
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.paymentMethod &&
      nextProps.paymentMethod.paymentMethod.id !== this.props.paymentMethod.paymentMethod.id &&
      nextProps.paymentMethod.paymentMethod.security_code
    ) {
      this.setState({
        cvvOnFront: nextProps.paymentMethod.paymentMethod.security_code.card_location !== 'back',
        maxLength: nextProps.paymentMethod.paymentMethod.security_code.length,
        value: '',
      });
    } else if (
      this.props.creditCard.guessing.bin !== nextProps.creditCard.guessing.bin ||
      (nextProps.creditCard.guessing.paymentMethod &&
        nextProps.creditCard.guessing.paymentMethod.settings.security_code.length !== this.state.maxLength)
    ) {
      // Handle Guessing
      // if bin changed update the guessing data
      // or if the new security code length data changed but the bin is the same also update the guessing data
      this.onGuessing(nextProps.creditCard.guessing);
    }

    this.handleAutoFill(nextProps.value);
  }

  onChange(event) {
    const { step } = this.props;
    const cleanValue = event.target.value.replace(/[^0-9]/g, '');

    // Dispatch action for updating the PaymentCard component
    this.props.creditCardActions[CREDIT_CARD_PC_SECURITY](cleanValue);
    this.props.inputValuesActions[CURRENT_INPUT_VALUES](`${step}_cvv`, cleanValue);

    // Update the state
    this.setState({
      value: cleanValue,
    });
  }

  /**
   * Function that is going to execute on "guessing" event
   * @param guessingData
   */
  onGuessing(guessingData) {
    // Save the paymentMethod object
    this.paymentMethod = guessingData && guessingData.paymentMethod ? guessingData.paymentMethod : null;

    // DefaultValues
    let toUpdate = {
      cvvOnFront: this.props.data.cvvOnFront,
      maxLength: this.props.data.max_length || this.props.data.maxLength || 4,
    };

    // Update values from paymentMethod guessed
    if (this.paymentMethod) {
      toUpdate = {
        bin: guessingData.bin,
        cvvOnFront: this.paymentMethod.settings.security_code.card_location !== 'back',
        maxLength: this.paymentMethod.settings.security_code.length,
      };
    }

    this.setState(toUpdate);
  }

  /**
   * Checks if the value is of the auto-filled type
   * @param {String} value
   */
  handleAutoFill(value = '') {
    return value === '***' ? this.setState({ value: '000' }) : this.setState({ value: value.replace(/[^0-9]/g, '') });
  }

  /**
   * Add extra validations
   * @returns {*}
   */
  extraValidations() {
    const value = this.getComponentValue();

    if (this.paymentMethod && value.length !== this.state.maxLength) {
      return 'E302'; // This code is already on the response
    }

    return '';
  }

  render() {
    // If the maxLength is 0 it means that the CC / DC doesn't have security code
    if (this.state.maxLength === 0 || allowList.contains(this.props.siteId, this.state.bin)) {
      return null;
    }

    const placeHolder = `${this.translations.PLACEHOLDER_CVV} ${'1234'.substring(0, this.state.maxLength)}`;
    const { label, showIcon, name, creditCard, globalErrors, creditCardActions, error, invalid, ...inputProps } =
      this.props;

    const iconType = !this.props.iconType || this.props.iconType === 'icon_cvv';
    const isCardHint = iconType ? (
      <CardHint cvvOnFront={this.state.cvvOnFront} />
    ) : (
      <IconCardCvv cardId="virtual" show />
    );

    return (
      <BaseInput
        className="input-cvv"
        name={name}
        type="tel"
        label={label}
        error={this.state.error}
        invalid={this.state.invalid}
        onChange={this.onChange}
        onBlur={this.onBlur}
        maxLength={this.state.maxLength}
        value={this.state.value}
        placeholder={placeHolder}
        {...inputProps}
      >
        {showIcon ? <GreyIcon /> : isCardHint}
      </BaseInput>
    );
  }
}

InputCVV.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  invalid: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.shape({
    max_length: PropTypes.number,
    cvv_on_front: PropTypes.bool,
  }),
  creditCardActions: PropTypes.object,
  creditCard: PropTypes.object,
  globalErrors: PropTypes.object,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

InputCVV.defaultProps = {
  id: '',
  name: '',
  value: '',
  label: '',
  invalid: false,
  error: [],
  data: {
    max_length: 4,
    cvv_on_front: false,
  },
  creditCard: {},
  globalErrors: {},
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  creditCardActions: bindActionCreators(creditCardActions, dispatch),
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  step: state.page.flow.step,
  creditCard: state.creditCard,
  globalErrors: state.globalErrors,
  paymentMethod: state.paymentMethod,
  value: state.inputValues.current[`${state.page.flow.step}_cvv`],
});

if (process.env.NODE_ENV === 'test') {
  module.exports = InputCVV;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(injectI18n(injectValidations(InputCVV)));
}
