const React = require('react');

const { logErrorFromClient } = require('../../utils/logTags');
const { ERROR_SPA } = require('../../../constants/app');

function useCountdown({ expirationDateTime }) {
  const expirationDate = React.useMemo(() => new Date(expirationDateTime), [expirationDateTime]);

  const calculateMinutesLeft = React.useCallback((date) => {
    if (isNaN(date.getTime())) {
      const error = new Error('Invalid expiration date format.');
      logErrorFromClient(error, ERROR_SPA.USE_COUNTDOWN_INVALIDA_DATE, '[useCountdown][getInitials]');
      return 0;
    }

    const diff = (date.getTime() - new Date().getTime()) / 60_000;
    return Math.max(Math.ceil(diff), 0);
  }, []);

  const [minutesLeft, setMinutesLeft] = React.useState(() => {
    return calculateMinutesLeft(expirationDate);
  });

  React.useEffect(() => {
    setMinutesLeft(calculateMinutesLeft(expirationDate));
  }, [expirationDate]);

  React.useEffect(() => {
    if (isNaN(expirationDate.getTime())) {
      const error = new Error('Invalid expiration date format.');
      logErrorFromClient(error, ERROR_SPA.USE_COUNTDOWN_INVALIDA_DATE, '[useCountdown][useEffect]');
      return;
    }

    if (minutesLeft <= 0) {
      return;
    }

    const intervalId = setInterval(() => {
      const newMinutesLeft = calculateMinutesLeft(expirationDate);

      if (newMinutesLeft === minutesLeft) {
        return;
      }

      setMinutesLeft(newMinutesLeft);

      if (newMinutesLeft <= 0) {
        clearInterval(intervalId);
      }
    }, 1_000);

    return () => clearInterval(intervalId);
  }, [expirationDate, minutesLeft]);

  return minutesLeft;
}

module.exports = useCountdown;
