const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators, compose } = require('redux');
const { Text } = require('@andes/typography');
const { Button: AndesButton } = require('@andes/button');

const buttonActions = require('../../spa/actions/button');
const { BUTTON_HIERARCHY } = require('../../../constants/commons');
const { useExternalLink } = require('../../hooks/useExternalLink');

const buttonClass = {
  button: 'andes-button--loud',
  link: 'andes-button--link',
  quiet: 'andes-button--quiet',
};

const ButtonExternalLink = ({ buttonAction, buttonRequest, configurations, data, id, label, type, url, isRebranding }) => {
  const { handleClick, sanitizedUrl } = useExternalLink({ buttonAction, buttonRequest, isWebview: configurations.isWebview, url });

  if (!isRebranding) {
    return (
      <a
        className={`andes-button ${buttonClass[type]} button-link`}
        href={sanitizedUrl}
        id={id}
        onClick={handleClick}
        target={data?.target || '_top'}
      >
        {label}
      </a>
    );
  }

  if (type === BUTTON_HIERARCHY.LINK) {
    return (
      <Text
        className={'cow-link'}
        size="s"
        color="link"
        component="a"
        href={sanitizedUrl}
        onClick={handleClick}
        weight="semibold"
        target={data?.target || '_top'}
      >
        {label}
      </Text>
    );
  }

  return (
    <AndesButton
      className={'cow-button-external-link'}
      hierarchy={type === 'button' ? BUTTON_HIERARCHY.LOUD : type}
      href={sanitizedUrl}
      id={id}
      onClick={handleClick}
      size={'large'}
    >
      {label}
    </AndesButton>
  );
};

ButtonExternalLink.propTypes = {
  buttonAction: PropTypes.shape({
    BUTTON_REQUEST_TRIGGERED: () => {},
  }),
  buttonRequest: PropTypes.bool,
  configurations: PropTypes.PropTypes.shape({
    isWebview: PropTypes.bool,
  }),
  data: PropTypes.shape({
    target: PropTypes.string,
  }),
  id: PropTypes.string,
  label: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
  isRebranding: PropTypes.bool,
};

ButtonExternalLink.defaultProps = {
  buttonAction: {},
  buttonRequest: false,
  configurations: {},
  data: {},
  id: '',
  label: '',
  url: '',
  type: 'button',
  isRebranding: false,
};

const mapDispatchToProps = (dispatch) => ({
  buttonAction: bindActionCreators(buttonActions, dispatch),
});

const mapStateToProps = ({ button, configurations }) => ({
  buttonRequest: button.buttonRequest,
  configurations,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = ButtonExternalLink;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = compose(connect(mapStateToProps, mapDispatchToProps))(ButtonExternalLink);
}
