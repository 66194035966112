import PropTypes from 'prop-types';

const { createContext, useContext } = require('react');
const { TrackBuilder } = require('./TrackBuilder');

// Create a context for tracking events with a default value to avoid undefined errors
const TrackingContext = createContext();

// Custom hook to use the tracking function
export const useTracking = () => useContext(TrackingContext);

/**
 * Provider component to supply the tracking function via context.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components.
 */
export const TrackingProvider = ({ children , melidataBasePath = '/checkout_off'}) => {

  /**
   * Tracks an event with the given name.
   * @param {string} eventName - The name of the event to track.
   * @param {string} melidataTrackType - Type of track (view / event).
   * @returns {TrackBuilder} A new TrackBuilder instance.
   */
  const trackEvent = (eventName, melidataTrackType) => {
    return new TrackBuilder(eventName, melidataBasePath, melidataTrackType);
  };

  return (
    // Provide the trackEvent function to all child components via context
    <TrackingContext.Provider value={{ trackEvent }}>
      {children}
    </TrackingContext.Provider>
  );
};

TrackingProvider.propTypes = {
  melidataBasePath: PropTypes.string,
};
