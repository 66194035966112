const React = require('react');
const PropTypes = require('prop-types');

// Andes components
const { Message } = require('@andes/message');
// Redux
const { bindActionCreators } = require('redux');

const connectToReduxAndInjectI18n = require('../../../../../../utils/connectToRedux');
// i18n
const translate = require('../../../../../../translation');
// Custom Components
const ConsumerCredits = require('../../../components/ConsumerCredits');
// Custom hooks
const { useActions } = require('../../../hooks/useActions');
// Actions
const STEP_ACTIONS = require('../../../../../../spa/actions/step');
const REQUEST_ACTIONS = require('../../../../../../spa/actions/request');
const LOADING_ACTIONS = require('../../../../../../spa/actions/loading');
const EXPRESS_ACTIONS = require('../../../../../../spa/actions/express');
const captchaToken = require('../../../../../../spa/actions/captchaToken');
// Action Types
const {
  LOADER_PAY_BUTTON,
  EXPRESS_RESET_CARD,
  EXPRESS_CARD_TOKEN,
  EXPRESS_RESET_INSTALLMENTS,
  EXPRESS_SET_INSTALLMENTS_ERROR,
  EXPRESS_SET_SECURE_FIELDS_ERROR,
  EXPRESS_SET_SECURE_FIELDS_FOCUS,
  EXPRESS_RESET_SECURE_FIELDS,
} = require('../../../../../../spa/actions/types');
// Utils
const PCJ = require('../../../../../../utils/pcj');
const { sleep } = require('../../../../../../utils/sleep');
const { setCookieWithJSON } = require('../../../../../../utils/CookieUtils');
const { formatAmountWithInstallments } = require('../../../../../../utils/format');
const { hasBankInterests } = require('../../../../../../utils/interests');
const { localeFromSiteId } = require('../../../../../../utils/locale');
const {
  createCardToken,
  mountSecureField,
  unmountSecureField,
  remountSecureField,
  createCardTokenWithEsc,
} = require('../../../utils/secure-fields');
const {
  genericPropsTypes,
  genericDefaultValues,
  newInterfaceGenericPropsTypes,
  newInterfaceGenericDefaultValues,
} = require('../../../../../../utils/propTypes');
const { shouldRenderInstallments, shouldRenderSecurityCode } = require('../../../utils/buildPayment');
const { sendMetricBasedOnTransactionType } = require('../../../utils/metricsHandler');
const { logErrorFromClient } = require('../../../../../../utils/logTags');
const getBrowserInfo = require('../../../../../../utils/BrowserInfo');
// Constants
const {
  APP: {
    COOKIES,
    SUMMARY_ITEMS_TYPES: { COUPON_DISCOUNT },
    SECURE_FIELDS: {
      ERROR_CAUSES: { INVALID_VALUE },
    },
    ERROR_SPA: { BROWSER_INFO_ERROR },
  },
  COMMONS: {
    DEVICE_TYPE: { DESKTOP },
    PAYMENT_METHOD_ID: { CONSUMER_CREDITS },
    PAYMENT_METHOD: { CREDIT_CARD, DEBIT_CARD, PREPAID_CARD },
    ICONS_METHODS,
  },
  SELECTORS: { ANDES_BUTTON_PAY_DESKTOP },
} = require('../../../../../../../constants');
// Context
const { ReviewDesktopContext } = require('./context');
const { ACTION_REVIEW_METRICS } = require('../../../../../../../constants/metrics');

const ReviewDesktopProvider = (originalProps) => {
  const {
    sdk,
    flow,
    i18n,
    siteId,
    history,
    express,
    stepActions,
    captchaConfig,
    expressActions,
    requestActions,
    loadingActions,
    internalDeviceId,
    isWebview,
    children,
    step_model,
  } = originalProps;

  const [browserInfo, setBrowserInfo] = React.useState({});
  const [cardToken, setCardToken] = React.useState('');
  const [nextDueDate, setNextDueDate] = React.useState(null);
  const [showKycModal, setShowKycModal] = React.useState(false);
  const [paymentMethods, setPaymentMethods] = React.useState(originalProps.step_model.payment_methods);
  const [paymentMethod, setPaymentMethod] = React.useState(paymentMethods[0]);
  const [showConsumerCreditsInfos, setShowConsumerCreditsInfos] = React.useState(false);
  const { locale, currency } = localeFromSiteId(siteId);

  const translations = translate(i18n);

  const props = PCJ.ifCandidateInjectPropsIntoSummary({
    translations,
    props: originalProps,
    paymentMethod,
  });

  const {
    step_model: {
      payment_methods,
      summary,
      payer,
      captcha,
      challenge_instructions: challengesInstructions,
      transaction_type: transactionType,
    },
  } = props;

  const { changePaymentMethod, reviewExpressPay, reviewExpressKyC } = useActions({
    flow,
    payer,
    captcha,
    history,
    stepActions,
    captchaToken,
    captchaConfig,
    requestActions,
    loadingActions,
    challengesInstructions,
  });

  const handleConsumerCreditsChange = (paymentMethodValue) => {
    if (paymentMethodValue.id === CONSUMER_CREDITS) {
      setNextDueDate(paymentMethodValue.next_due_date);
      setShowConsumerCreditsInfos(true);
      return;
    }

    setNextDueDate(null);
    setShowConsumerCreditsInfos(false);
  };

  React.useEffect(() => {
    handleConsumerCreditsChange(paymentMethod);

    sendMetricBasedOnTransactionType({transactionType, device: DESKTOP, action: ACTION_REVIEW_METRICS.LOADED});
    if (window?.hj) {
      window.hj('event', 'review_express_redesigned');
    }

    try {
      setBrowserInfo(getBrowserInfo(originalProps.initialStore));
    } catch (error) {
      logErrorFromClient(error, BROWSER_INFO_ERROR, '[express][3ds-browserinfo]');
    }
  }, []);

  const secureFieldActions = React.useMemo(
    () => ({
      resetSecureField: expressActions[EXPRESS_RESET_SECURE_FIELDS],
      setSecureFieldError: expressActions[EXPRESS_SET_SECURE_FIELDS_ERROR],
      setSecureFieldFocus: expressActions[EXPRESS_SET_SECURE_FIELDS_FOCUS],
    }),
    [expressActions],
  );

  const showBankInterests = () => hasBankInterests(siteId) && paymentMethod?.type === CREDIT_CARD;

  const getCustomSummaryItems = () => {
    const customSummaryItems = summary?.items?.filter((item) => item.type !== COUPON_DISCOUNT);
    const installmentIndex = express?.installments?.[paymentMethod.option_id]?.value;
    const isAccountMoneyBlack = paymentMethod?.id === ICONS_METHODS.ICON_ACCOUNT_MONEY_BLACK;

    step_model.summary.total.detail = '';

    const isValidInstallment = installmentIndex && installmentIndex !== '' && !isAccountMoneyBlack;
    const installment = isValidInstallment ? paymentMethod.installments?.[installmentIndex - 1] : null;

    if (isValidInstallment && installment?.is_interest_free) {
      step_model.summary.total.detail = translations.NO_INTEREST();
    }
    return [...customSummaryItems, paymentMethod?.discount];
  };

  const handleSecureFieldChange = React.useCallback(
    (paymentMethodValue) => {
      remountSecureField(paymentMethodValue, secureFieldActions);
    },
    [secureFieldActions],
  );

  const handleInstallmentsChange = React.useCallback(
    (paymentMethodValue) => {
      expressActions[EXPRESS_RESET_INSTALLMENTS](paymentMethodValue.option_id);
    },
    [expressActions],
  );

  const handlePaymentMethodChange = React.useCallback(
    (paymentMethodId) => {
      const findPaymentMethod = paymentMethods?.find(
        (paymentMethodItem) => paymentMethodItem.option_id === paymentMethodId,
      );

      if (paymentMethodId === paymentMethod.option_id) {
        return;
      }

      handleInstallmentsChange(findPaymentMethod);
      handleSecureFieldChange(findPaymentMethod);
      handleConsumerCreditsChange(findPaymentMethod);
      setPaymentMethod(findPaymentMethod);
      setShowKycModal(false);
    },
    [paymentMethods, paymentMethod.option_id, handleInstallmentsChange, handleSecureFieldChange],
  );

  const handleFieldsValidation = React.useCallback(
    async (securityCodeCardToken) => {
      let isSecurityCodeValid = true;
      let isInstallmentsValid = true;

      const renderInstallments = shouldRenderInstallments(paymentMethod);
      const renderSecurityCode = shouldRenderSecurityCode(paymentMethod);

      if (!renderSecurityCode && !renderInstallments) {
        return true;
      }

      if (renderSecurityCode && !securityCodeCardToken) {
        const { error } = await createCardToken();

        if (error) {
          isSecurityCodeValid = false;
          secureFieldActions.setSecureFieldError(
            Array.isArray(error) && error.find((err) => err.cause === INVALID_VALUE)
              ? translations.CVV_IS_REQUIRED
              : translations.CVV_INCOMPLETE,
          );
        }
      }

      if (renderInstallments) {
        isInstallmentsValid = express?.installments?.[paymentMethod.option_id]?.value;
        expressActions[EXPRESS_SET_INSTALLMENTS_ERROR](paymentMethod.option_id, !isInstallmentsValid);
      }

      return isInstallmentsValid && isSecurityCodeValid;
    },
    [paymentMethod, secureFieldActions, translations, express, expressActions],
  );

  const handleChangePaymentMethod = React.useCallback(() => {
    sendMetricBasedOnTransactionType({transactionType, device: DESKTOP, action: ACTION_REVIEW_METRICS.CHANGED});
    unmountSecureField();
    changePaymentMethod();
  }, [transactionType, changePaymentMethod]);

  const handleSubmit = React.useCallback(
    ({ action, cardTokenId, installments, tokenWithEsc }) => {
      const payload = {
        card_token: cardTokenId,
        token_generated_with_esc: tokenWithEsc,
        installments: String(installments),
        payer: {
          email: payer?.email?.value,
        },
        payment_method: {
          type: paymentMethod?.type,
          method: paymentMethod?.id,
          id: paymentMethod?.option_id,
        },
      };
      if (isWebview) {
        payload.browser_info = {
          browser_accept_header: browserInfo.accept_header,
          browser_color_depth: browserInfo.color_depth,
          browser_ip: browserInfo.ip,
          browser_java_enabled: browserInfo.java_enabled,
          browser_language: browserInfo.language,
          browser_screen_height: browserInfo.screen_height,
          browser_screen_width: browserInfo.screen_width,
          browser_timezone: browserInfo.timezone_offset,
          browser_user_agent: browserInfo.user_agent,
        };
      }

      unmountSecureField();
      action(payload);
    },
    [payer?.email?.value, paymentMethod?.type, paymentMethod?.id, paymentMethod?.option_id, isWebview, browserInfo],
  );

  const handleKyc = React.useCallback(() => {
    const cardId = paymentMethod?.option_id;
    const cardTokenId = expressActions[EXPRESS_CARD_TOKEN](cardId) || cardToken;
    const tokenWithEsc = express?.tokenWithEsc || false;
    const installments = express?.installments?.[cardId]?.value || 1;
    handleSubmit({ action: reviewExpressKyC, cardTokenId, installments, tokenWithEsc });
  }, [paymentMethod?.option_id, expressActions, cardToken, express?.tokenWithEsc, express?.installments, handleSubmit, reviewExpressKyC]);

  const handlePayClick = React.useCallback(async () => {
    const cardId = paymentMethod?.option_id;
    const installments = express?.installments?.[cardId]?.value || 1;
    let cardTokenId = expressActions[EXPRESS_CARD_TOKEN](cardId) || cardToken;
    const tokenWithEsc = express?.tokenWithEsc || false;

    if (!(await handleFieldsValidation(cardTokenId))) {
      setShowKycModal(false);
      return;
    }

    if (
      !cardTokenId &&
      (paymentMethod.type === CREDIT_CARD || paymentMethod.type === DEBIT_CARD || paymentMethod.type === PREPAID_CARD)
    ) {
      loadingActions[LOADER_PAY_BUTTON](true);

      const { error, response } = await createCardTokenWithEsc(cardId, internalDeviceId);

      if (error) {
        loadingActions[LOADER_PAY_BUTTON](false);
        secureFieldActions.setSecureFieldError(translations.INVALID_CVV);
        return;
      }

      cardTokenId = response.id;

      if (response.esc) {
        setCookieWithJSON(`${COOKIES.ESC}${cardId}`, { esc: response.esc, internalDeviceId }, COOKIES.COOKIE_EXPIRES_ONE_YEAR);
      }

      setCardToken(cardTokenId);
      expressActions[EXPRESS_RESET_CARD](cardId);
    }

    setShowKycModal(true);

    if (challengesInstructions?.kyc) {
      if (!showKycModal) {
        sleep().then(() => {
          document.querySelector(ANDES_BUTTON_PAY_DESKTOP)?.click();
        });
      }

      loadingActions[LOADER_PAY_BUTTON](false);
      return;
    }

    sendMetricBasedOnTransactionType({transactionType, device: DESKTOP, action: ACTION_REVIEW_METRICS.PAY});

    handleSubmit({ action: reviewExpressPay, cardTokenId, installments, tokenWithEsc });
  }, [paymentMethod?.option_id, paymentMethod.type, express?.installments, express?.tokenWithEsc, expressActions, cardToken, handleFieldsValidation, challengesInstructions?.kyc, transactionType, handleSubmit, reviewExpressPay, loadingActions, internalDeviceId, secureFieldActions, translations.INVALID_CVV, showKycModal]);

 

  React.useEffect(() => {
    const pm = payment_methods?.find((pm) => pm.option_id === paymentMethod.option_id);
    setPaymentMethods(payment_methods);
    setPaymentMethod(pm);
  }, [paymentMethod.option_id, payment_methods]);

  React.useEffect(() => {
    if (sdk.loaded) {
      mountSecureField(paymentMethod, secureFieldActions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk.loaded]);

  const contextValue = React.useMemo(() => ({
      deviceType: DESKTOP,
      templateCase: transactionType,
      showIncentives: true,
      showMlaTaxes: false,
      showKycModal,
      customHandleKyc: handleKyc,
      customSummaryItems: getCustomSummaryItems(),
      customHandlePayClick: handlePayClick,
      customPrice:
        express?.installments?.[paymentMethod.option_id]?.price ||
        formatAmountWithInstallments(siteId, locale, currency, paymentMethod?.total_amount),
      customFooterMessage: showBankInterests() && (
        <Message hierarchy="quiet" color="accent" className="review-express__bank_interests">
          {translations.BANK_INTERESTS}
        </Message>
      ),
      consumerCreditsFooter: showConsumerCreditsInfos && (
        <ConsumerCredits className="review-express__consumer-credits__footer">
          <ConsumerCredits.Legals siteId={siteId} />
        </ConsumerCredits>
      ),
      consumerCreditsSummary: showConsumerCreditsInfos && (
        <ConsumerCredits className="review-express__consumer-credits__summary">
          <ConsumerCredits.NextDueDate date={nextDueDate} siteId={siteId} />
          <ConsumerCredits.TermsAndConditions
            siteId={siteId}
            deviceType={DESKTOP}
            contractUrl={express?.installments?.[paymentMethod.option_id]?.contract}
          />
        </ConsumerCredits>
      ),
      paymentMethod,
      handlePaymentMethodChange,
      handleChangePaymentMethod,
      ...props,
    }), [transactionType, showKycModal, handleKyc, getCustomSummaryItems, handlePayClick, express?.installments, paymentMethod, siteId, locale, currency, showBankInterests, translations.BANK_INTERESTS, showConsumerCreditsInfos, nextDueDate, handlePaymentMethodChange, handleChangePaymentMethod]);

  return <ReviewDesktopContext.Provider value={contextValue}>{children}</ReviewDesktopContext.Provider>;
};

ReviewDesktopProvider.defaultProps = {
  ...genericDefaultValues,
  step_model: {
    ...newInterfaceGenericDefaultValues,
  },
  isWebview: false,
};

ReviewDesktopProvider.propTypes = {
  ...genericPropsTypes,
  templateCase: PropTypes.string,
  step_model: PropTypes.shape({
    ...newInterfaceGenericPropsTypes,
  }).isRequired,
  isWebview: PropTypes.bool,
};

// Map all the actions with the dispatchers on the props
const mapDispatchToProps = (dispatch) => ({
  stepActions: bindActionCreators(STEP_ACTIONS, dispatch),
  loadingActions: bindActionCreators(LOADING_ACTIONS, dispatch),
  requestActions: bindActionCreators(REQUEST_ACTIONS, dispatch),
  expressActions: bindActionCreators(EXPRESS_ACTIONS, dispatch),
  captchaToken: bindActionCreators(captchaToken, dispatch),
});

// Generate the state (store) using the reducers
const mapStateToProps = (state) => ({
  flow: state.page.flow,
  sdk: state.mercadopagoSdk,
  firstRender: state.page.firstRender,
  basePath: state.configurations.basePath,
  siteId: state.configurations.platform.siteId,
  captchaConfig: state.configurations.captcha,
  express: state.express,
  currency: state.configurations.currency,
  isWebview: state.configurations.isWebview,
});

module.exports = connectToReduxAndInjectI18n(ReviewDesktopProvider, mapStateToProps, mapDispatchToProps);
