const React = require("react");
const PaymentMethodCardList = require("@cow/core-components/components/PaymentMethodCardList");
const PropTypes = require("prop-types");
const {Typography, Title} = require("@andes/typography");
const {DEVICE_TYPE: {DESKTOP}} = require('../../../../constants/commons');
const PaymentRow = require('./PaymentRow');
const {ANDES_STYLES_THEME} = require("../../../../constants");
const {getPaymentMethodUITypeIcon} = require("../../../utils/icons");
const currencyUtil = require("../../../utils/currency");

const OptionListCombination = (
  {
    paymentOptions,
    translations,
    stepper = 1,
    selected,
    handleSelect,
    deviceType,
    siteId,
    rebrandingMP,
    totalAmountPay,
    currency
  }) => {

  const items = paymentOptions.map((item) => ({
    id: item.option_id,
    method: item.id,
    type: item.type,
    title: item.title,
    description: item.detail,
    fullTitle: null,
    withPromotion: item.promotional,
    additionalInfo: item.additional_info,
    paymentMethodId: getPaymentMethodUITypeIcon({
      siteId,
      id: item.id,
      type: item.type,
      method: item.method,
      issuerName: item.issuer_name,
      rebrandingMP,
    })
  }));

  const handleChange = (id) => handleSelect(items.find(item => item.id === id) ?? {});

  return (
    <section className="options-combinations">
      <div className="options-combinations__title">
        <Typography
          size={deviceType === DESKTOP ? ANDES_STYLES_THEME.SIZES.S : ANDES_STYLES_THEME.SIZES.XS}
          color={ANDES_STYLES_THEME.COLORS.SECONDARY}
          component="span"
        >
          {translations.PAYMENT_X_OF_2(stepper)}
        </Typography>
        <Title
          size={ANDES_STYLES_THEME.SIZES.XS}
          color={ANDES_STYLES_THEME.COLORS.PRIMARY}
          component="h1"
        >
          {stepper === 1 ? translations.CHOOSE_FIRST_METHOD : translations.CHOOSE_SECOND_METHOD}
        </Title>
      </div>
      {stepper === 2 && <PaymentRow translations={translations} value={currencyUtil.parseAmount(totalAmountPay, currency)} deviceType={deviceType}/>}
      <div className="options-combinations__list">
        <PaymentMethodCardList
          items={items}
          withDividers
          deviceType={deviceType}
          currentSelected={selected?.id}
          onChange={handleChange}
        />
      </div>
    </section>
  );
}

OptionListCombination.propTypes = {
  paymentOptions: PropTypes.array.isRequired,
  translations: PropTypes.object,
  stepper: PropTypes.number,
  selected: PropTypes.object,
  handleSelect: PropTypes.func,
  deviceType: PropTypes.string,
  rebrandingMP: PropTypes.bool,
  siteId: PropTypes.string,
  totalAmountPay: PropTypes.number,
}

module.exports = OptionListCombination;
