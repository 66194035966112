const React = require('react');
const PropTypes = require('prop-types');

const CaptchaTerms = require('@cow/core-components/components/CaptchaTerms');
const Legals = require('@cow/core-components/components/Legals');

const { isMobile } = require('../../utils/webview');
const { SITE_ID, ASSETS, EXTERNAL_URLS } = require('../../../constants/commons');

const LegalsFooter = ({ termsAndConditions, siteId, payer, translations, deviceType }) => {
  return (
    <div className="footer-terms">
      <>
        {termsAndConditions?.has_legals && (
          <Legals
            // TODO: Create a Help component to avoid mix the siteId with this Legals component (ANK-2811 & ANK-2812)
            isMCO={siteId === SITE_ID.MCO}
            isMobile={isMobile(deviceType)}
            isUserLogged={!payer.is_guest}
            brand={{
              processByLabel: translations.PROCESSED_BY,
              image: ASSETS.MERCADOPAGO_LOGO,
              imageAlt: 'Mercado Pago',
            }}
            terms={{
              acceptLabel: translations.ACCEPT_THE_TERMS,
              termAndConditionsLabel: translations.TERMS_AND_CONDITIONS,
              termsAndConditionsLink: EXTERNAL_URLS.LEGAL_TERMS,
              brandLabel: translations.OF_MP,
            }}
            help={{
              label: translations.HELP_TO_PROTECT_YOUR_PURCHASES,
              link: EXTERNAL_URLS.PROTECT_YOUR_PURCHASE,
            }}
          />
        )}
      </>
      <>
        {termsAndConditions?.has_captcha && (
          <CaptchaTerms
            protectedByLabel={translations.PROTECTED_BY_RECAPTCHA}
            privacyLabel={translations.PRIVACY}
            privacyLink={EXTERNAL_URLS.CAPTCHA_PRIVACY}
            conditionsLabel={translations.CONDITIONS}
            conditionsLink={EXTERNAL_URLS.CAPTCHA_TERMS}
          />
        )}
      </>
    </div>
  )
};

LegalsFooter.propTypes = {
  termsAndConditions: PropTypes.object,
  siteId: PropTypes.string,
  payer: PropTypes.object,
  translations: PropTypes.object,
};

module.exports = LegalsFooter;
