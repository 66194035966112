const React = require('react');

const { ValidatorProvider } = require('../../../utils/validator-provider');
const { getErrorProps, defaultProps } = require('../helper');
const GroupIdentificationDate = require('../../../components/Groups/GroupIdentification');
const Form = require('../../../components/Form');
const GroupSummary = require('../../../components/Groups/GroupSummary');
const GroupTotalSummary = require('../../../components/Groups/GroupTotalSummary');
const GroupRow = require('../../../components/Groups/GroupRow');
const GroupMediaObject = require('../../../components/Groups/GroupMediaObject');
const GroupRowDetails = require('../../../components/Groups/GroupRowDetails');
const GroupRowDropdown = require('../../../components/Groups/GroupRowDropdown');
const GroupLegals = require('../../../components/Groups/GroupLegals');
const GroupRedirectDescription = require('../../../components/Groups/GroupRedirectDescription');
const Congrats = require('../../../components/Groups/Congrats');
const OrderedList = require('../../../components/Groups/OrderedList');
const Navbar = require('../../../components/Groups/Navbar');
const MercadoPago = require('../../../components/icons/MercadoPago');
const MercadoLibre = require('../../../components/icons/MercadoLibre');
const Login = require('../../../components/Groups/Login');
const Scroller = require('../../../components/Groups/Scroller');
const NestedPages = require('../../../components/Groups/NestedPages');
const Page = require('../../../components/Groups/Page');
const StickyContainer = require('../../../components/Groups/StickyContainer');
const CardNumberWithIssuer = require('../../../components/CardNumberWithIssuer');
const OptionList = require('../../../components/OptionsList');
const GroupCoupon = require('../../../components/Groups/GroupCoupon');
const GroupAnimated = require('../../../components/Groups/GroupAnimated');
const GroupCongratsSummary = require('../../../components/Groups/GroupCongratsSummary');
const GroupBackUrl = require('../../../components/Groups/GroupBackUrl');
const GroupRowBanner = require('../../../components/Groups/GroupRowBanner');
const GroupChallenge = require('../../../components/Groups/Challenge');
const Modal = require('../../../components/Modal');
const GroupMenu = require('../../../components/Groups/GroupMenu');
const UserHeader = require('../../../components/Groups/UserHeader');
const GroupAnimatedContent = require('../../../components/Groups/GroupAnimatedContent');
const GroupSidebar = require('../../../components/Groups/GroupSidebar');
const GroupLink = require('../../../components/Groups/GroupLink');
const { replaceChar } = require('../../../utils/Strings');
const CvvWithEsc = require('../../../components/Groups/CvvWithEsc');
const Disclaimer = require('../../../components/Groups/Disclaimer');
const BottomSheetEmail = require('../../../components/BottomSheetEmail');
const GroupCard = require('../../../components/Groups/GroupCard');

const {
  ID: { LOGIN_ML },
} = require('../../../../constants/app');
const {
  COMMONS: { TOOLTIP_TYPE, ANDES_PLACEMENT },
  APP: { ICON },
} = require('../../../../constants');
const NavbarV2 = require('../../Navbar');

module.exports = {
  group_form: (props) => (
    <ValidatorProvider {...defaultProps()} history={props.history} type={props.type} siteId={props.siteId}>
      <Form
        {...defaultProps(props)}
        className={props.type}
        method="POST"
        onSubmit={props.onSubmit}
        id={props.id}
        history={props.history}
        clearAll={props.clear_all}
        tokenCaptchaV3Needed={props.token_captcha_v3_needed}
      >
        {props.children}
      </Form>
    </ValidatorProvider>
  ),
  // Temporary Group Form for Experiment in MLC - EndGame
  group_experiment_form: (props) => (
    <ValidatorProvider {...defaultProps()} history={props.history} type={props.type}>
      <Form
        {...defaultProps(props)}
        className={props.type}
        method="POST"
        onSubmit={props.onSubmit}
        id={props.id}
        history={props.history}
        clearAll={props.clear_all}
        tokenCaptchaV3Needed={props.token_captcha_v3_needed}
      >
        {props.children}
      </Form>
    </ValidatorProvider>
  ),
  group_form_scroller: (props) => (
    <Scroller key={Math.random()} className={props.kind || ''}>
      <ValidatorProvider {...defaultProps()} history={props.history} type={props.type}>
        <Form
          {...defaultProps(props)}
          className={props.type}
          method="POST"
          id={props.id}
          process={props.process}
          onSubmit={props.onSubmit}
          history={props.history}
          clearAll={props.clear_all}
        >
          {props.children}
        </Form>
      </ValidatorProvider>
    </Scroller>
  ),
  group_controls: (props) => (
    <div {...defaultProps()} className="ui-card" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_card_andes: (props) => (
    <GroupCard className="group-card" key={Math.random()}>
      {props.children}
    </GroupCard>
  ),
  group_identification: (props) => (
    <GroupIdentificationDate {...defaultProps(props)} {...getErrorProps(props)} key={Math.random()}>
      {props.children}
    </GroupIdentificationDate>
  ),
  group_content: (props) => (
    <div {...defaultProps()} className="layout__col-content" id={props.id}>
      {props.children}
    </div>
  ),
  group_sidebar: (props) => (
    <GroupSidebar {...defaultProps()} {...props} className="layout__col-sidebar" trackEvent={props.track_event} />
  ),
  group_hr: (props) => (
    <div {...defaultProps()} className="group-hr" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_summary: (props) => (
    <GroupSummary {...defaultProps()} key={Math.random()}>
      {props.children}
    </GroupSummary>
  ),
  group_total_summary: (props) => (
    <GroupTotalSummary {...defaultProps()} deviceType={props.deviceType} key={Math.random()}>
      {props.children}
    </GroupTotalSummary>
  ),
  /**
   * @deprecated This reference type is deprecated due to the integration of the new Card Form developed by Bricks
   * and will be removed in the future.
   *
   * @description
   * Currently, this function is used for split payment, subscription flow, and checkout modal integrations.
   */
  group_card_form: (props) => (
    <div className="card-form-group" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_payment_method_row_with_cvv: (props) => (
    <div className="group-payment-method-row-with-cvv" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_card_with_issuer: (props) => (
    <CardNumberWithIssuer {...getErrorProps(props)} amount={props.amount}>
      {props.children}
    </CardNumberWithIssuer>
  ),
  group_button: (props) => (
    <div className="group-button" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_row: (props) => (
    <GroupRow {...defaultProps()} key={Math.random()}>
      {props.children}
    </GroupRow>
  ),
  group_row_instructions: (props) => (
    <div className="row-instructions" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_disabled_text: (props) => (
    <div className="disabled-text" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_detail_login: (props) => (
    <div className="group_detail_login" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_media_object: (props) => (
    <GroupMediaObject {...defaultProps()} {...props}>
      {props.children}
    </GroupMediaObject>
  ),
  group_row_details: (props) => <GroupRowDetails {...defaultProps(props)} value={props.value} key={Math.random()} />,
  group_legals: (props) => (
    <GroupLegals {...defaultProps(props)} key={Math.random()}>
      {props.children}
    </GroupLegals>
  ),
  group_row_dropdown: (props) => (
    <GroupRowDropdown {...defaultProps(props)} wording={props.label} value={props.value} key={Math.random()} />
  ),
  group_congrats_rejected: (props) => (
    <Congrats {...defaultProps(props)} kind="rejected" key={Math.random()}>
      {props.children}
    </Congrats>
  ),
  group_challenge: (props) => (
    <GroupChallenge {...defaultProps(props)} challenge={props.challenge}>
      {props.children}
    </GroupChallenge>
  ),
  group_login: (props) => (
    <Login {...defaultProps(props)} key={Math.random()}>
      {props.children}
    </Login>
  ),
  group_login_header: (props) => (
    <header {...defaultProps(props)} id={props.id} className="login-header">
      {props.id === LOGIN_ML ? <MercadoLibre /> : <MercadoPago />}
      <div className="login-header__container">{props.children}</div>
    </header>
  ),
  group_login_titles: (props) => (
    <div className="login-titles" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_login_content: (props) => (
    <div className="login-content" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_congrats_approved: (props) => (
    <Congrats {...defaultProps(props)} kind="approved" key={Math.random()}>
      {props.children}
    </Congrats>
  ),
  group_congrats_wallet: (props) => (
    <Congrats {...defaultProps(props)} kind="wallet-payment" key={Math.random()}>
      {props.children}
    </Congrats>
  ),
  group_congrats_instructions: (props) => (
    <Congrats {...defaultProps(props)} id={props.id} kind="instructions" key={Math.random()}>
      {props.children}
    </Congrats>
  ),
  group_congrats_recover: (props) => (
    <Congrats {...defaultProps(props)} kind="recover" key={Math.random()}>
      {props.children}
    </Congrats>
  ),
  group_congrats_header: (props) => (
    <header {...defaultProps(props)} className="congrats-header" key={Math.random()}>
      <div className="congrats-header__container">{props.children}</div>
    </header>
  ),
  group_congrats_content: (props) => (
    <div id={props.id} className="congrats-content" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_ui_card: (props) => (
    <div className="ui-card-wrapper" id={props.id} key={Math.random()}>
      <div className="ui-card">{props.children}</div>
    </div>
  ),
  group_ordered_list: (props) => (
    <OrderedList {...props} {...defaultProps(props)} key={Math.random()}>
      {props.children}
    </OrderedList>
  ),
  group_congrats_titles: (props) => (
    <div className="congrats-titles" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_congrats_shipping: (props) => (
    <div className="group-congrats-shipping" key={Math.random()}>
      {props.children}
    </div>
  ),
  // TODO Delete when growth coupon incentive ends
  group_congrats_growth_incentive: (props) => (
    <div className="group-congrats-growth-incentive" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_congrats_shipping_domicile: (props) => (
    <div className="group_congrats_shipping_domicile" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_row_cvv: (props) => <CvvWithEsc>{props.children}</CvvWithEsc>,
  group_scroller: (props) => <Scroller className={props.kind || ''}>{props.children}</Scroller>,
  group_summary_header: (props) => (
    <div className="summary-header" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_navbar_brand: (props) => <Navbar siteId={props.siteId}>{props.children}</Navbar>,
  group_navbar_cow: ({ history, siteId, ...props }) => <NavbarV2 {...{ ...props.value[0], siteId, history }} />,
  group_navbar_ml: (props) => (
    <Navbar theme="ml" siteId={props.siteId}>
      {props.children}
    </Navbar>
  ),
  group_navbar_mp: (props) => (
    <Navbar theme="mp" siteId={props.siteId}>
      {props.children}
    </Navbar>
  ),
  group_no_payment_option: (props) => (
    <div className="ui-card no-payment-option" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_nested_pages: (props) => <NestedPages {...defaultProps()}>{props.children}</NestedPages>,
  group_page: (props) => (
    <Page path={props.path} tracking={props.tracking} stepTitle={props.step_title} {...defaultProps()}>
      {props.children}
    </Page>
  ),
  group_sticky_container: (props) => (
    <StickyContainer
      {...defaultProps()}
      key={Math.random()}
      hasBackUrl={props.has_back_url}
      hasDescription={props.has_description}
    >
      {props.children}
    </StickyContainer>
  ),
  group_generic: (props) => (
    <div className="group-generic" id={props.id} key={Math.random()}>
      {props.children}
    </div>
  ),
  group_wallet_logout: (props) => (
    <div className="group-wallet-logout" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_split_congrats: (props) => (
    <div className="group-split-congrats" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_split_card_review: (props) => (
    <div className="group-split-card-review" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_generic_message: (props) => (
    <div className="group-generic-message" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_back_url: (props) => <GroupBackUrl>{props.children}</GroupBackUrl>,
  group_row_selectable: (props) => {
    const options = [
      {
        id: `${props.id}_child`,
        name: props.name,
        value: `[${props.id}]`,
      },
    ];

    return (
      <div className="group_row_selectable">
        <OptionList {...defaultProps(props)} className={props.id} options={options}>
          <GroupRow>{props.children}</GroupRow>
        </OptionList>
      </div>
    );
  },
  group_captcha: (props) => (
    <div className={`group-captcha ${props.guest ? 'guest' : 'logged'}`} key={Math.random()}>
      {props.children}
    </div>
  ),
  group_coupon: (props) => <GroupCoupon>{props.children}</GroupCoupon>,
  group_animated: (props) => (
    <GroupAnimated animation={props.animation} animationContent={props.animation_content} id={props.id}>
      {props.children}
    </GroupAnimated>
  ),
  group_coupon_disabled: (props) => <GroupCoupon applied>{props.children}</GroupCoupon>,
  group_congrats_summary: (props) => <GroupCongratsSummary {...defaultProps()}>{props.children}</GroupCongratsSummary>,
  group_congrats_redirect: (props) => (
    <Congrats {...defaultProps(props)} kind="redirect">
      {props.children}
    </Congrats>
  ),
  group_redirect_loader: (props) => (
    <div className="group-redirect-loader" key={Math.random()}>
      {props.children}
    </div>
  ),
  group_redirect_description: (props) => (
    <GroupRedirectDescription key={Math.random()}>{props.children}</GroupRedirectDescription>
  ),
  group_animated_content: (props) => <GroupAnimatedContent {...props} trackEvent={props.track_event} />,
  group_not_animated_content: (props) => <div className="content">{props.children}</div>,
  group_slidable: (props) => <div className="slidable">{props.children}</div>,
  group_flex: (props) => (
    <div
      id={replaceChar(props.id, /_/g, '-')}
      className={`${props.isRebranding ? 'hasRebranding' : ''} group-flex`}
      style={{ display: 'flex' }}
    >
      {props.children}
    </div>
  ),
  group_row_banner: (props) => <GroupRowBanner label={props.label}>{props.children}</GroupRowBanner>,
  group_modal: (props) => (
    <Modal title={props.title} initialVisibility={props.show} target={props.target} className="group-modal">
      {props.children}
    </Modal>
  ),
  group_modal_shield: (props) => (
    <Modal title={props.title} initialVisibility={props.show} className="shield-modal">
      {props.children}
    </Modal>
  ),
  group_modal_shield_without_icon: (props) => (
    <Modal title={props.title} initialVisibility={props.show} className="shield-modal without-icon">
      {props.children}
    </Modal>
  ),
  // Temporary Group Modal for Experiment in MLC - EndGame
  group_modal_shield_endgame: (props) => (
    <Modal
      title={props.title}
      initialVisibility={props.show}
      id={props.id}
      target={props.target}
      className="shield-endgame"
    >
      {props.children}
    </Modal>
  ),
  group_generic_row: (props) => (
    <div className="group-generic-block" {...defaultProps()}>
      {props.children}
    </div>
  ),
  group_menu: (props) => <GroupMenu>{props.children}</GroupMenu>,
  group_user_header: (props) => <UserHeader id={props.id}>{props.children}</UserHeader>,
  group_link: (props) => (
    <GroupLink
      {...defaultProps()}
      className={props.id}
      href={props.url}
      target={props.target}
      trackEvent={props.track_event}
    >
      {props.children}
    </GroupLink>
  ),
  group_bank_selection: (props) => <div className="ui-card group-bank-selection">{props.children}</div>,
  disclaimer: (props) => (
    <Disclaimer
      iconName="shieldBPPDisabled"
      message={props.message}
      tooltipSide={ANDES_PLACEMENT.TOP_RIGHT}
      tooltipType={TOOLTIP_TYPE.DARK}
      tooltipContent={props.tooltipContent}
      tooltipIcon={ICON.INFO}
    />
  ),
  group_bottom_sheet: (props) => (
    <BottomSheetEmail title={props.title} {...defaultProps(props)} {...props}>
      {props.children}
    </BottomSheetEmail>
  ),
};
