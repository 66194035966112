const React = require('react');
const { SecurityCodeMode } = require('internal-checkout-bricks/lib/secureFields/types/enums');
const { getESCCookie } = require('../../utils/CookieUtils');

const { getTokenizationMethods, useSmartTokenization: useSmartTokenizationBricks, generateCardToken } = require('internal-checkout-bricks/lib/smartTokenization/client');
const { ERROR_SPA, COOKIES } = require('../../../constants/app');
const { logErrorFromClient } = require('../../utils/logTags');
const { CCAPSmartTokenizationTokenizationType } = require('internal-checkout-bricks/lib/smartTokenization/types/enums');

const SmartTokenizationContext = React.createContext();

function SmartTokenizationProvider({ children, publicKey, meliSessionId, internalDeviceId }) {
  const { generateCardTokenInputCVV } = useSmartTokenizationBricks();
  const [validatedCards, setValidatedCards] = React.useState([]);

  const handleGenerateTokenInputCVV = async () => {
    const cardToken = await generateCardTokenInputCVV();

    return cardToken
  };

  const handleGenerateTokenWithEsc = async () => {
    const cardToken = await generateCardToken({
      publicKey,
    });

    return cardToken
  };

  const checkTokenizationMethods = async ({ cardId, paymentMethodId, securityCodeLenght, amount }) => {
    const serviceRoutePrefix = window?.env?.apiBasePath;
    const keyEsc = `${COOKIES.ESC}${cardId}`;
    const escCookie = getESCCookie(keyEsc);
    const esc = escCookie?.esc || escCookie;
    const internalDeviceIdCookie = escCookie?.internalDeviceId || internalDeviceId;

    try {
      const tokenizationProps = {
        serviceRoutePrefix,
        cardId,
        paymentMethodId,
        securityCodeMode: SecurityCodeMode.MANDATORY,
        securityCodeLenght,
        amount,
      }

      if(esc) {
        tokenizationProps.escConfiguration = {
          esc: esc,
          fingerprint: internalDeviceIdCookie
        }
      }

      const response = await getTokenizationMethods(tokenizationProps);
      const card = {
        cardId,
        paymentMethodId,
        shouldRenderInputCVV: response?.shouldRenderInputCVV,
        tokenizationType: response?.tokenizationType
      };

      setValidatedCards(prevState => [...prevState, card]);

      return response;
    } catch (error) {
      logErrorFromClient(error, ERROR_SPA.SMART_TOKENIZATION_CHECK_CARDID_ERROR, '[SmartTokenizationProvider][checkRequiredCVV]', {
        name: error.name,
        message: error.message,
        details: error.details,
      });
    }
  };

  const handleTokenizationWithCCAP = async (paymentMethod) => {
    const cardId = paymentMethod?.option_id;
    const validatedCard = validatedCards.find(card => card.cardId === paymentMethod?.option_id)

    let response = null
    let error = null

    try {
      if(validatedCard?.shouldRenderInputCVV || !validatedCard) {
        const { cardToken } = await handleGenerateTokenInputCVV({ cardId });
        response = { cardToken, tokenizationType: validatedCard?.tokenizationType }
      } else {
        const { cardToken } = await handleGenerateTokenWithEsc({ cardId });
        response = {cardToken, tokenizationType: validatedCard?.tokenizationType}
      }
    } catch (err) {
      logErrorFromClient(err, ERROR_SPA.SMART_TOKENIZATION_GENERATE_TOKEN_ESC_ERROR, '[SmartTokenizationProvider][handleGenerateTokenWithEsc]', {
        name: err.name,
        message: err.message,
        details: err.details,
      });

      error = err?.details
    }

    return { response, error }
  }

  return (
    <SmartTokenizationContext.Provider value={{
      validatedCards,
      publicKey,
      meliSessionId,
      handleTokenizationWithCCAP,
      checkTokenizationMethods,
      handleGenerateTokenInputCVV,
      handleGenerateTokenWithEsc
    }}>
      {children}
    </SmartTokenizationContext.Provider>
  );
}

const useSmartTokenizationContext = () => {
  const context = React.useContext(SmartTokenizationContext);
  if (context === undefined) return {}
  return context;
}

module.exports = { useSmartTokenizationContext, SmartTokenizationProvider };
