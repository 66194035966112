const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const { ANIMATION_TOGGLE } = require('../../spa/actions/types');
const animationActions = require('../../spa/actions/animations');
const Button = require('@cow/core-components/components/ToggleButton');

class ToggleButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.props.targets.forEach((target) => this.props.animationActions[ANIMATION_TOGGLE](target, this.state.show));
  }

  toggle() {
    this.setState((prevState) => ({ show: !prevState.show }));
    this.props.targets.forEach((target) => this.props.animationActions[ANIMATION_TOGGLE](target, !this.state.show));
  }

  render() {
    return (
      <div className={`toggle-button-wrapper ${this.state.show ? 'toggle-show' : 'toggle-hide'}`} onClick={this.toggle}>
        <Button labelToggle={this.props.text} />
      </div>
    );
  }
}

ToggleButton.propTypes = {
  targets: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string,
  show: PropTypes.bool,
};

ToggleButton.defaultProps = {
  targets: [],
  text: '',
  show: false,
};

const mapDispatchToProps = (dispatch) => ({
  animationActions: bindActionCreators(animationActions, dispatch),
});

if (process.env.NODE_ENV === 'test') {
  module.exports = ToggleButton;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(null, mapDispatchToProps)(ToggleButton);
}
