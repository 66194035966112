const {getPaymentMethodUITypeIcon} = require('../../../utils/icons');
const {ANDES_STYLES_THEME} = require('../../../../constants');

export const propsPaymentMethodInfo = (
  {
    siteId,
    translations,
    rebrandingMP,
    paymentMethods = [],
    isSplitCard = false
  }) => {

  const getTitle = (index) => {
    if (isSplitCard) {
      return index === 0 ? translations.FIRST_CARD : translations.SECOND_CARD;
    }
    return index === 0 ? translations.FIRST_PAYMENT : translations.SECOND_PAYMENT;
  };

  return paymentMethods?.map((paymentMethod, index) => {

    const method = getPaymentMethodUITypeIcon({
      siteId,
      id: paymentMethod.id,
      type: paymentMethod.type,
      method: paymentMethod.method,
      issuerName: paymentMethod.issuer_name,
      rebrandingMP,
    });

    return {
      id: paymentMethod?.id,
      title: getTitle(index),
      method,
      installments: paymentMethod?.installment?.detail,
      paymentTitle: paymentMethod?.title,
      paymentDetail: paymentMethod?.detail,
      amount: paymentMethod?.amount_details?.text_value,
      translations,
      interestFree: paymentMethod?.installment?.is_interest_free,
      interestFreeLabel: paymentMethod?.installment?.discount_detail,
      className: index > 0 ? 'second-payment' : '',
    };
  });
};

export const splitPaymentsProps = (paymentMethods, label) => ({
  label,
  methods: paymentMethods.map(({title: name, amount_details, installment}) => ({
    name,
    price: amount_details?.text_value,
    installments: installment?.detail,
    nameColor: ANDES_STYLES_THEME.COLORS.SECONDARY,
    priceColor: ANDES_STYLES_THEME.COLORS.PRIMARY,
    priceSize: ANDES_STYLES_THEME.SIZES.S,
    priceWeight: ANDES_STYLES_THEME.WEIGHT.SEMI_BOLD,
  })),
});

export const propsPCJForMobile = (
  name,
  price,
  icon = null,
  nameColor = ANDES_STYLES_THEME.COLORS.SECONDARY,
  nameSize = ANDES_STYLES_THEME.SIZES.S,
  priceColor = ANDES_STYLES_THEME.COLORS.SECONDARY,
  priceSize = ANDES_STYLES_THEME.SIZES.S,
) => ({
  name,
  price,
  icon,
  nameSize,
  priceSize,
  priceColor,
  type: ANDES_STYLES_THEME.COLORS.SECONDARY,
  nameColor,
});

