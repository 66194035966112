/* global melidata */
const MelidataEventData = require('./melidataEventData');
const { MELIDATA_TRACKING_TYPE_EVENT  } = require('../../constants/app');

/**
 * [COW-2722] - duplicate tracking
 * aux variables to store the last tracking path used by analytics and melidata
 * if the value is the same we can asume there is a double track being fired
 * and prevent the next event to fire to prevent duplicate trackings
 * @TODO we should analyze why this re-rendering of the pages or multiple history pushes are being fired
 */
let melidataLastTrackingPath = '';

const Tracker = {
  updateMelidataPath: (path) => {
    if (melidataLastTrackingPath !== path) {
      // update last path tracked [melidata]
      melidataLastTrackingPath = path;
      melidata('send', 'view', {
        path,
        data: {},
      });
    }
  },
  trackMelidata: (melidataTracking, stateConfigs, forceTrack) => {
    if ((typeof melidata !== 'undefined') && ((melidataLastTrackingPath !== melidataTracking.path) || forceTrack)) {
      // update last path tracked [melidata]
      melidataLastTrackingPath = melidataTracking.path;

      // Custom way to do melidata('clean') in order to avoid experiments cleaning.
      if (typeof melidata_namespace !== 'undefined' && melidata_namespace.actual_track) {
        melidata_namespace.actual_track.event_data = {};
      } else {
        melidata('clean');
      }

      const eventData = new MelidataEventData(stateConfigs);

      melidata('add', 'event_data', eventData.build(melidataTracking.payload));

      /**
       * Temp: Una vez que se agregan experimentos, la segunda vez nos
       * está sumando mal los siguentes track, por esto vamos a modificar el track a mano
       * por el momento
       * *Original*: melidata('add', 'experiments', experiments);
       */

      if (
        typeof melidata_namespace !== 'undefined' &&
        melidata_namespace.actual_track &&
        melidata_namespace.actual_track.experiments
      ) {
        const experimentsTrack = melidata_namespace.actual_track.experiments;

        // append incoming experiments
        const experiments = melidataTracking.experiments || {};

        Object.keys(experiments).forEach((expName) => {
          const exp = experiments[expName];

          experimentsTrack[expName] = exp.id || exp;
        });

        // Force track cleanup
        try {
          if (experimentsTrack['0']) {
            delete experimentsTrack['0'];
          }
        } catch (e) {
          // do nothing
        }
      }

      melidata('send', 'view', {
        path: melidataTracking.path,
        data: {},
      });
    }
  },

  /**
   * Melidata: Page tracker
   * @param {String} path
   * @param {Object} dimensions
   * @example
   * App.Track.melidataPage({
   *     path: '/cart/my_cart/',
   *     dimensions: {
   *         dimension1: 'dimension1',
   *     },
   * });
   */
  trackMelidataPage: ({ path = null, dimensions = null, experiments = null, data = {} } = {}) => {
    // If one of this parameters is empty do not track
    if (!path) {
      return;
    }

    if (typeof melidata !== 'undefined') {
      melidata('createViewTrack', data);
      melidata('withPath', path, data);

      if (dimensions) {
        melidata('withData', dimensions, data);
      }

      if (experiments) {
        melidata('withExperiments', experiments, data);
      }

      melidata('sendTrack', data);
    }
  },

  /**
   * Melidata: Event tracker
   * @param {String} path
   * @param {String} action
   * @example
   * App.Track.melidataEvent({
   *     path: '/cart/my_cart/',
   *     action: 'add_to_cart',
   * });
   */
  trackMelidataEvent: ({ path = null, data = null, clean = false, trackType = MELIDATA_TRACKING_TYPE_EVENT } = {}) => {
    // If one of this parameters is empty do not track
    if (!path || !window.melidata) {
      return;
    }

    const actionType = clean ? 'cleanAndSend' : 'send';

    if (data) {
      window.melidata(actionType, trackType, {
        path,
        data,
      });
    } else {
      window.melidata(actionType, trackType, {
        path,
      });
    }
  },
};

module.exports = Tracker;
