const { isInternalDeepLink, isInternalUrl, createInternalWebviewDeeplink, isExternalDeepLinkFromBackUrl } = require('./deeplinks');
const { logErrorFromClient } = require('./logTags');
const {
  ERROR_SPA: { MOBILE_WEBKIT_UTIL_ERROR },
} = require('../../constants/app');

/**
 * Log errors from webkit
 */
const handleError = (error, url) => {
  const logError = { name: 'mobileWebkit', message: error?.message };
  logErrorFromClient(logError, MOBILE_WEBKIT_UTIL_ERROR, '[mobileWebkit][handleError]', {
    ...(error || {}),
    url,
  });
};

/**
 * Opens a link outside the native application.
 * @param {*} url
 */
const openLinkOutsideApp = (url) => {
  const { MobileWebKit } = window;

  MobileWebKit.navigation
    .openLinkOutsideApp(url)
    .then(() => MobileWebKit.navigation.pop())
    .catch((error) => handleError(error, url));
};

/**
 * Close native activity
 */
const nativeClose = () => {
  const { MobileWebKit } = window;
  const url = window?.location?.href;
  MobileWebKit.navigation.pop().catch((error) => handleError(error, url));
};

/**
 * Redirects internal deeplinks using bridge methods
 * @param {string} deeplink
 */
const redirectInternalDeeplink = (deeplink) => {
  const { MobileWebKit } = window;

  MobileWebKit.navigation.push(deeplink, { replace: true }).catch((error) => handleError(error, deeplink));
};

/**
 * Handle all urls externals to checkout-off inside webview context
 * @TODO this should be moved to a redirection util to wrap all the redirects in the application
 * @param {string} url
 */
const handleWebviewUrls = (url, configurations) => {
  const appName = configurations?.nativeApp?.name;
  const _isInternalUrl = isInternalUrl(url);
  const _isInternalDeepLink = isInternalDeepLink(url);

  if (!_isInternalDeepLink && !_isInternalUrl) {
    let deeplink = null;

    // it's an non mercadopago/mercadolibre url
    if (isExternalDeepLinkFromBackUrl(url)) {
      deeplink = url.replace('http://', '');
    }
    openLinkOutsideApp(deeplink || url);
  } else {
    // here you can have 2 posible values: deeplink mp/ml or mp/ml urls
    let internalDeeplink = url; // already is a deeplink by default

    if (!_isInternalDeepLink) {
      internalDeeplink = createInternalWebviewDeeplink(url, {}, appName);
    }

    redirectInternalDeeplink(internalDeeplink);
  }
};

module.exports = {
  openLinkOutsideApp,
  nativeClose,
  redirectInternalDeeplink,
  handleWebviewUrls,
  handleError,
};
