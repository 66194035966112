const React = require('react');

// Custom Components
const SecurityCode = require('../components/SecurityCode');
const InstallmentsSelector = require('../components/InstallmentsSelector');
const SmartTokenizationInput = require('../../../../components/SmartTokenizationInput');
const { ICONS_METHODS } = require('../../../../../constants/commons');
const { Text } = require('@andes/typography');
const { useSmartTokenizationContext } = require('../../../../components/SmartTokenizationProvider');

const shouldRenderSecurityCode = (pm) => !!pm?.security_code;

const shouldRenderInstallments = (pm) => pm?.installments?.length !== 0 && !!pm?.show_installments;

const getPaymentMethodId = (paymentMethod, rebrandingMP) => {
  if (rebrandingMP) {
    if (paymentMethod?.id === ICONS_METHODS.ICON_ACCOUNT_MONEY_BLACK) {
      return ICONS_METHODS.ICON_ACCOUNT_MONEY_YELLOW;
    }

    if (paymentMethod?.id === ICONS_METHODS.ICON_CONSUMER_CREDITS) {
      return ICONS_METHODS.ICON_MERCADO_CREDITO_YELLOW;
    }
  }

  return paymentMethod?.id;
};

const shouldRenderCollapsedContent = ({ paymentMethod, express, selectedMethod, hasCCAPTokenizationFlagEnabled }) => {
  const cardToken = express?.[paymentMethod?.option_id]?.cardTokenId;
  const renderInstallments = shouldRenderInstallments(paymentMethod);
  const paymentMethodId = getPaymentMethodId(paymentMethod, false);
  const canRenderCollapsed = hasCCAPTokenizationFlagEnabled ? selectedMethod?.option_id === paymentMethod?.option_id : true;

  if (!canRenderCollapsed) {
    return false;
  }

  if (cardToken) {
    return renderInstallments;
  }

  return shouldRenderSecurityCode(paymentMethod) || renderInstallments;
};

const CollapsedContent = ({ paymentMethod, discount, hasCCAPTokenizationFlagEnabled }) => {
  const smartTokenizationContext = useSmartTokenizationContext();
  const canRenderSmartTokenizationInput =
    shouldRenderSecurityCode(paymentMethod) &&
    hasCCAPTokenizationFlagEnabled &&
    smartTokenizationContext?.meliSessionId

  const canRenderSecurityCode = !!paymentMethod?.security_code;

  return (
    <div className="review-express__collapsed-content">
      {canRenderSmartTokenizationInput && canRenderSecurityCode && (
        <SmartTokenizationInput
          paymentMethod={paymentMethod}
          securityCode={paymentMethod?.security_code}
          id={`${paymentMethod?.id}_${paymentMethod?.option_id}`}
        />
      )}

      {!canRenderSmartTokenizationInput && canRenderSecurityCode && (
        <SecurityCode
          optionId={paymentMethod?.option_id}
          securityCode={paymentMethod?.security_code}
          escAvailable={paymentMethod?.esc_available}
          id={`${paymentMethod?.id}_${paymentMethod?.option_id}`}
        />
      )}

      {shouldRenderInstallments(paymentMethod) && (
        <InstallmentsSelector
          discount={discount}
          paymentMethod={paymentMethod?.option_id}
          items={paymentMethod?.installments}
        />
      )}
    </div>
  );
};

const CURRENCY_LABEL = 'Dinero';

const buildPaymentCardList = ({
  paymentMethods,
  selectedMethod,
  translations,
  discount,
  express,
  hasCCAPTokenizationFlagEnabled,
  rebrandingMP,
}) =>
  paymentMethods.map((paymentMethod) => {
    const isMercadoPagoCard =
      paymentMethod?.title.includes(translations.MP) && !paymentMethod?.title.includes(CURRENCY_LABEL);
    const title = isMercadoPagoCard ? paymentMethod?.title.split('\n')[0] : paymentMethod?.title;
    const description = isMercadoPagoCard ? (
      <Text size="m">{paymentMethod?.title.split('\n')[1]}</Text>
    ) : (
      paymentMethod?.detail
    );
    let collapsedContent = null;

    const paymentMethodId = getPaymentMethodId(paymentMethod, rebrandingMP);

    const highlightBadge = paymentMethod?.recommended && {
      size: 'small',
      text: translations.RECOMMENDED_PAYMENT_METHOD,
    };


    collapsedContent = shouldRenderCollapsedContent({ paymentMethod, express, selectedMethod, hasCCAPTokenizationFlagEnabled }) && (
      <CollapsedContent
        paymentMethod={paymentMethod}
        discount={discount}
        hasCCAPTokenizationFlagEnabled={hasCCAPTokenizationFlagEnabled}
      />
    );

    return {
      id: paymentMethod?.option_id,
      title,
      description,
      withPromotion: paymentMethod?.promotional,
      additionalInfo: paymentMethod?.additional_info,
      paymentMethodId,
      collapsedContent,
      highlightBadge,
    };
  });

const usePaymentCardList = ({
  paymentMethods,
  selectedMethod,
  translations,
  discount,
  express,
  hasCCAPTokenizationFlagEnabled,
  rebrandingMP,
}) => {
  const [paymentCardList, setPaymentCardList] = React.useState(
    buildPaymentCardList({
      express,
      discount,
      translations,
      paymentMethods,
      selectedMethod,
      hasCCAPTokenizationFlagEnabled,
      rebrandingMP,
    }),
  );

  React.useEffect(() => {
    setPaymentCardList(
      buildPaymentCardList({
        express,
        discount,
        translations,
        paymentMethods,
        selectedMethod,
        hasCCAPTokenizationFlagEnabled,
        rebrandingMP,
      }),
    );
  }, [express, paymentMethods]);

  return {
    paymentCardList,
    shouldRenderInstallments,
    shouldRenderSecurityCode,
  };
};

module.exports = { usePaymentCardList };
