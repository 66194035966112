const React = require('react');

const { connect } = require('react-redux');

const IconOnlinePaymentMethod = require('../../components/IconOnlinePaymentMethod');

const IconOnlinePaymentMethodContainer = (props) => <IconOnlinePaymentMethod {...props} />;

const mapStateToProps = (state) => ({
  siteId: state.configurations.platform.siteId,
  isRebranding: state.configurations.rebrandingMP,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = IconOnlinePaymentMethodContainer;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, null)(IconOnlinePaymentMethodContainer);
}
