module.exports = {
  COW_SELECT: 'cow-select',
  ROW_COMBINATION: '.combination_row',
  TERMS_AND_CONDITIONS_BASE_CLASS: 'credits-conditions',
  DROPDOWN_NAVBAR: '.cow-navbar-v2 .andes-floating-menu',
  POPPER: '.cow-navbar-v2 [data-tippy-root]',
  NOT_NAVBAR: '--not-navbar',
  LOGGED: '--logged',
  ANDES_BUTTON_PAY_DESKTOP: '.cow-payment_summary__button',
  ANDES_BUTTON_PAY_MOBILE: '.cow-sticky__pay__button',
  POPPER: '.cow-navbar-v2 [data-tippy-root]',
  REBRANDING: 'rebranding',
  COMBINED_PAYMENT_REVIEW: 'combined-payment-review-v2',
  AUTO_HEIGHT: 'auto-height',
};
