/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
const React = require('react');
const PropTypes = require('prop-types');

// Redux
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');
// i18n
const injectI18n = require('nordic/i18n/injectI18n');
const translate = require('../../../../translation');
// Andes components
const { Title } = require('@andes/typography');
const { Message } = require('@andes/message');

// COW Components
const CardList = require('@cow/core-components/components/CardListV2');
const Navbar = require('@cow/core-components/components/NavbarV2');
const Brand = require('@cow/core-components/components/Brand');
const PaymentSummary = require('@cow/core-components/components/PaymentSummary');
const GenericModal = require('@cow/core-components/components/GenericModal');

// Internal Components
const Page = require('../../../../components/Page');
const CallToActionButtons = require('../../../card-form/templates/components/CallToActionButtons');
// Constants
const {
  ENVIROMENT,
  SITE_ID,
  MESSAGE_HIERARCHY,
  THEME,
  CHECKOUT_TYPE,
  CARDLIST_V2_TYPES,
} = require('../../../../../constants/commons');
// Actions
const STEP_ACTIONS = require('../../../../spa/actions/step');
const SNACKBAR_ACTIONS = require('../../../../spa/actions/snackbar');
// Hooks
const { useActions } = require('../hooks/useActions');
const { useLogOut } = require('../../../../hooks/useLogOut');
// Utils
const { generateItems, hasThirdPartyInstallments } = require('../utils/InstallmentList');
const { getTitleScreenReader } = require('../utils/StepTitleScreenReader');
const { getCompanyTheme, getBrandNameCompany } = require('../../../review/templates/utils/configurations');
const { isMobile } = require('../../../../utils/webview');
const { handleSummaryItemProps, PAYMENT_SUMMARY_ALIGNMENT } = require('../../../../utils/summary');
const {
  newInterfaceGenericPropsTypes,
  newInterfaceGenericDefaultValues,
  genericPropsTypes,
  genericDefaultValues,
} = require('../../../../utils/propTypes');

const Installments = (props) => {
  const tooltipContainer = React.useRef(null);
  const titleRef = React.useRef(null);

  // States
  const [showDiscountModal, setShowDiscountModal] = React.useState(false);

  const {
    templateCase,
    i18n,
    step_model: { installments, step_title, brand, summary, payer, discount },
    history,
    flow,
    stepActions,
    snackbarActions,
    siteId,
    browserName,
    deviceType,
    platform,
    configs,
  } = props;
  const translations = translate(i18n);

  const NavbarMenuOptions = {
    title: translations.YOU_ENTERED_AS,
    changeUserLabel: translations.CHANGE_ACCOUNT,
    logOutUserLabel: translations.LOG_OUT,
  };

  const { changeInputCard, selectInstallment } = useActions({ history, flow, stepActions });

  const { logout } = useLogOut({
    flow,
    siteId,
    browserName,
    history,
    stepActions,
    snackbarActions,
    translations,
    platform,
    configurations: configs,
  });

  const theme = getCompanyTheme(configs?.internalConfigurations);

  React.useEffect(() => {
    if (titleRef?.current) {
      titleRef.current.focus();
    }
  }, []);

  return (
    <Page
      title={step_title}
      // Review current step prop name
      currentStep={`${props.flow.step}_template_${templateCase}`}
      urls={props.urls}
      trackingPath={props.trackingPath}
      analytics={props.analytics}
      deviceType={props.deviceType}
    >
      <div className="layout layout--new-interface">
        {discount?.is_applicable && (
          <GenericModal
            className="discount-modal"
            title={translations.GENERAL_CONDITIONS}
            content={discount?.detail}
            isOpen={showDiscountModal}
            // TODO - TECH DEBT: Unificate the deviceType & isMobile props, it's not consistent
            deviceType={props.deviceType}
            onClose={() => setShowDiscountModal(false)}
            onOpen={() => setShowDiscountModal(true)}
          />
        )}

        {/* Space for  Header */}
        {!payer?.is_guest && flow.type !== CHECKOUT_TYPE.EMBED && (
          <Navbar
            user={{
              fullName: payer.name,
              firstname: payer.first_name,
              lastname: payer.last_name,
              email: payer.email.value,
              avatar: payer.avatar,
            }}
            menuOptions={NavbarMenuOptions}
            theme={theme}
            isMLB={siteId === SITE_ID.MLB}
            isMobile={isMobile(props.deviceType)}
            logOut={() => logout({ changeUser: false })}
            changeUser={() => logout({ changeUser: true })}
            altLogo={getBrandNameCompany(theme, translations)}
            altUser={payer.name}
            altClosedMenu={translations.YOU_ARE_USING_YOUR_ACCOUNT(payer.first_name)}
            altOpenedMenu={translations.CLOSE_ACCOUNT_OPTION}
          />
        )}

        <section className="layout__col-content">
          <div className="top-row">
            <Title
              component="h1"
              size="s"
              aria-label={getTitleScreenReader(installments, step_title, translations)}
              ref={titleRef}
              tabIndex={-1}
            >
              {step_title}
            </Title>
          </div>
          <div>
            <CardList
              items={generateItems({ installments, selectInstallment, translations, siteId, deviceType })}
              deviceType={deviceType}
              type={CARDLIST_V2_TYPES.INSTALLMENTS}
            />
            {hasThirdPartyInstallments(installments) && (
              <Message
                hierarchy={MESSAGE_HIERARCHY.QUIET}
                id="third-party-disclaimer"
                aria-hidden
              >{`${translations.THIRD_PARTY_DISCLAIMER}`}</Message>
            )}
          </div>
          <CallToActionButtons
            backButtonOptions={{
              label: translations.TO_GO_BACK,
              action: changeInputCard,
              hierarchy: 'transparent',
            }}
          />
        </section>

        {/* Summary + brand sidebar */}
        <section className="layout__col-sidebar">
          {brand && <Brand image={brand.avatar} name={brand.name} />}

          <PaymentSummary
            title={summary?.title}
            items={summary?.items?.map((item) =>
              handleSummaryItemProps({
                item,
                summary,
                translations,
                deviceType,
                tooltipContainer,
                setShowDiscountModal,
                iconAlignment: PAYMENT_SUMMARY_ALIGNMENT.LEFT,
              }),
            )}
            total={
              summary.items.length > 1 && {
                name: summary?.total?.name,
                nameWeight: 'regular',
                price: summary?.total?.price.text_value,
                priceWeight: 'regular',
                priceCents: summary?.total?.price.cents,
                priceSize: 16,
                textSize: 'm',
              }
            }
            theme={THEME.DARK}
            companyTheme={getCompanyTheme(configs?.internalConfigurations)}
            showSubmitButton={false}
            isFullAndes
          />
        </section>
      </div>
    </Page>
  );
};

Installments.propTypes = {
  templateCase: PropTypes.string,
  step_model: PropTypes.shape({
    ...newInterfaceGenericPropsTypes,
    installments: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number,
        price: PropTypes.shape({
          text_value: PropTypes.string,
          cents: PropTypes.string,
          amount: PropTypes.string,
          currency_id: PropTypes.string,
          currency_symbol: PropTypes.string,
          decimal_separator: PropTypes.string,
        }),
        rate: PropTypes.number,
        detail: PropTypes.shape({
          text_value: PropTypes.string,
          cents: PropTypes.string,
          amount: PropTypes.string,
          currency_id: PropTypes.string,
          currency_symbol: PropTypes.string,
          decimal_separator: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
  ...genericPropsTypes,
};

Installments.defaultProps = {
  step_model: {
    ...newInterfaceGenericDefaultValues,
    installments: [],
  },
  templateCase: 'default',
  ...genericDefaultValues,
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  snackbarActions: bindActionCreators(SNACKBAR_ACTIONS, dispatch),
  stepActions: bindActionCreators(STEP_ACTIONS, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  flow: state.page.flow,
  configs: state.configurations,
  firstRender: state.page.firstRender,
  basePath: state.configurations.basePath,
  browserName: state.configurations.browserName,
  platform: state.configurations.platform,
});

if (process.env.NODE_ENV === ENVIROMENT.TEST) {
  module.exports = Installments;
} else {
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(Installments));
}
