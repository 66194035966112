const React = require("react");

const {getErrorProps, defaultSecurityDisables} = require("../../../containers/Optimus/helper");
const InputSplitAmount = require("../../../components/InputSplitAmount");
const currencyUtil = require("../../../utils/currency");
const calcPercentage = require("../../../utils/calcPercentage");
const ProgressIndicatorLinear = require("../../../components/ProgressIndicatorLinear");
const CardList = require("@cow/core-components/components/CardListV2");
const {getPaymentMethodUITypeIcon} = require("../../../utils/icons");
const {REQUIRED} = require('../../../utils/regex');

const {useState, useMemo, useEffect} = React;

const AmountForm = (
  {
    paymentMethod,
    siteId,
    rebrandingMP,
    translations,
    currency,
    nextStepAction,
    value,
    setValue,
    setValidations,
    ...props
  }) => {

  const [pendingAmount, setPendingAmount] = useState(value);

  const data = {
    initial_value: 0,
    total: paymentMethod.total_amount,
    max_amount: paymentMethod?.balance ?? 0,
    min_allowed_amount: paymentMethod?.min_allowed_amount,
    max_allowed_amount: paymentMethod?.max_allowed_amount,
  };

  useEffect(() => {
    setPendingAmount(data?.total - value);
  }, [value]);

  const method = useMemo(() => getPaymentMethodUITypeIcon({
      siteId,
      id: paymentMethod?.id,
      type: paymentMethod?.type,
      method: paymentMethod?.method,
      issuerName: paymentMethod?.issuer_name,
      rebrandingMP,
    }), [siteId, paymentMethod, rebrandingMP]);

  const cardListProps = useMemo(() => ({
    withChevron: false,
    withDivider: false,
    items: [
      {
        id: paymentMethod?.id,
        method,
        description: paymentMethod?.detail,
        title: paymentMethod?.title,
      },
    ],
  }), [paymentMethod]);

  return (
    <div className="content-split-amount">
      <CardList
        className="payment-method-info"
        {...cardListProps}
        bottomContentList={
          <>
            <InputSplitAmount
              {...getErrorProps(props)}
              {...defaultSecurityDisables(props.publicKey)}
              id="split-amount-input"
              name={paymentMethod?.id}
              disabled={false}
              paymentMethod={paymentMethod}
              textInstallment={null}
              changedValue={false}
              data={data}
              label={translations.AMOUNT_TO_PAY_WITH_THIS_MEANS}
              onChange={(value) => setValue(value)}
              validateCallback={setValidations}
              validations={REQUIRED(translations)}
              isCombination
              isNewInterface
            />
            <ProgressIndicatorLinear
              counterText={translations.REMAINING_AMOUNT}
              counterValue={pendingAmount || currencyUtil.parseAmount('0', currency)}
              progressPercentage={
                value ? parseInt(calcPercentage(value, data.total), 10) : 0
              }
            />
          </>
        }/>
    </div>
  )

}

module.exports = AmountForm;
