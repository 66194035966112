const uuidv4 = require('uuid/v4');

const { getQueryParams, isIframe } = require('./Dom');
const { trackNewRelicError } = require('./newRelic');
const { logErrorKibana } = require('../service/api');
const { ERROR_IDENTIFIER } = require('../../constants/app');

const getTagsFromURL = () => {
  const url = getQueryParams();
  const urlParams = new URLSearchParams(url);
  const preferenceId = urlParams.get('preference-id') || null;
  const routerRequestId = urlParams.get('router-request-id') || null;
  const sniffing = urlParams.get('sniffing') || null;
  const collectorId = preferenceId ? preferenceId.split('-')[0] : null;

  return {
    router_request_id: routerRequestId,
    preference_id: preferenceId,
    ip: urlParams.get('p') || null,
    collector_id: collectorId,
    sniffing,
  };
};

const getTagsFromState = () => {
  const loadedState = window.__PRELOADED_STATE__ || {};
  const { siteId: site_id, deviceType, device, initialStore, userId: user_id = null } = loadedState;

  return {
    browser: initialStore?.configurations?.browserName,
    device: deviceType,
    webview: device?.webView,
    native_webview: device?.nativeWebview,
    type: initialStore?.page?.data?.checkout?.type,
    flow_id: initialStore?.page?.flow?.id,
    site_id,
    step: initialStore?.page?.flow?.step,
    user_id,
    os_name: device?.osName,
  };
};

const getTags = (data = {}) => {
  const tags = {
    action: data.action || null,
    app_candidate: data.app_candidate || null,
    browser: data.browser || null,
    cho_version: data.cho_version || null,
    collector_id: data.collector_id || null,
    current_step: data.current_step || null,
    device: data.device || null,
    error_from_browser: data.error_from_browser || null,
    error_code: data.error_code || null,
    flow_id: data.flow_id || null,
    from_widget: data.from_widget || null,
    http_status: data.http_status || null,
    http_q_status: data.http_q_status || null,
    http_method: data.http_method || null,
    ip: data.ip || null,
    javascript_type_error: data.javascript_type_error || null,
    native_webview: data.native_webview || null,
    payment_id: data.payment_id || null,
    preference_id: data.preference_id || null,
    public_key: data.public_key || null,
    responseTime: data.responseTime || null,
    router_request_id: data.router_request_id || null,
    service: data.service || null,
    site_id: data.site_id || null,
    stack: data.stack || null,
    step: data.step || null,
    type: data.type || null,
    type_error: data.type_error || null,
    group_error: data.group_error || null,
    origin_error: data.origin_error || null,
    user_id: data.user_id || null,
    webview: data.webview || null,
    x_request_id: data.x_request_id || null,
    os_name: data.os_name || null,
    sniffing: data.sniffing || null,
    iframe: data.iframe || null,
  };

  Object.keys(tags).forEach((item) => {
    if (tags[item] === null) {
      delete tags[item];
    }
  });

  // ignore eslint rule, because, if http_status is (null, undefined, string, etc ...) the conditional will be ignored
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(tags.http_status)) {
    // do not send http status and status pattern if there is no http status code
    delete tags.http_q_status;
    delete tags.http_status;
  }

  return tags;
};

const getErrorPayload = (error, payload = {}) => ({
  name: error?.name,
  message: error?.message,
  stack: payload,
});

const parseErrorMessage = (error) => {
  let parsedError = error;
  try {
    if (error && !error.name) {
      // there are errors that comes from library that haves custom structure, in that case, we stringify all the error.
      parsedError = { ...error, name: JSON.stringify(error) };
    }
  } catch (err) {
    // DO nothing
  }
  return parsedError;
};

/**
 * Logs client-side errors to monitoring services (NewRelic and Kibana).
 * @param {Error} error - The error object to be logged
 * @param {string} group_error - Category/group identifier for the error
 * @param {string} origin_error - Origin/source of the error
 * @param {Object} [customTags={}] - Additional custom tags to include in error data
 * @returns {Object} Object containing the error data in the property 'data_error'
 */
const logErrorFromClient = (error, group_error, origin_error, customTags = {}) => {
  if (!error.message || !error.name) {
    globalThis.console.error('Error should have a name and a message', error);
  }

  const data = {
    error_code: `${ERROR_IDENTIFIER.FRONTEND}-${uuidv4()}`,
    error_message: error?.message,
    ...getTagsFromState(),
    ...getTagsFromURL(),
    error_from_browser: true,
    javascript_type_error: error?.name,
    group_error,
    origin_error: `${origin_error?.replace(/\[/g, '').replace(/\]/g, '_')}`, // hotfix because [] are not allowed in tags
    iframe: isIframe(),
    ...customTags,
    stack: error?.stack?.replace(/(\r\n|\n|\r)/gm, ''),
  };

  const tags = getTags(data);
  const parsedError = parseErrorMessage(error);
  const errorPayload = getErrorPayload(parsedError, tags);

  trackNewRelicError(errorPayload);
  logErrorKibana(`[${error?.name}] ${error?.message}`, data);

  return { data_error: data };
};

module.exports = {
  getErrorPayload,
  getTags,
  getTagsFromState,
  getTagsFromURL,
  parseErrorMessage,
  logErrorFromClient,
};
