const React = require('react');
const PropTypes = require('prop-types');
const { bindActionCreators } = require('redux');
const { Button: AndesButton } = require('@andes/button');
const ArrowLeft = require('@andes/icons/ArrowLeft16');

const buttonActions = require('../../spa/actions/button');
const connectToReduxAndInjectI18n = require('../../utils/connectToRedux');
const { useExternalLink } = require('../../hooks/useExternalLink');

const {
  COMMONS: { CHECKOUT_TYPE },
  APP: { PAGE_NAME, STEPS, TARGET },
} = require('../../../constants');
const {hasDangerousExtension} = require('../../utils/UrlUtils');

const BUTTON_SIZE = {
  SMALL: 'small',
  LARGE: 'large',
  MEDIUM: 'medium',
};

const { useMemo } = React;

const getLocation = (history) => {
  if (typeof history !== 'undefined') {
    return history?.location;
  }

  if (typeof window !== 'undefined') {
    return window?.location;
  }

  return {};
};

const ButtonBackURLs = ({ id, url, step, label, history, isWebview, buttonAction, buttonRequest }) => {
  const { handleClick, sanitizedUrl } = useExternalLink({url, isWebview, buttonAction, buttonRequest});

  const location = getLocation(history);
  const size = step.includes(PAGE_NAME.CONGRATS) ? BUTTON_SIZE.LARGE: BUTTON_SIZE.MEDIUM;
  const isModal = !!location?.pathname?.includes(CHECKOUT_TYPE.MODAL);
  const target = step === STEPS.CONGRATS_APPROVED && isModal ? TARGET.TOP : TARGET.SELF;

  const isDangerousURL = useMemo(() => hasDangerousExtension(url), [url]);

  return (
    <>{
      !isDangerousURL &&
        <AndesButton
          hierarchy="transparent"
          className="cow-button-back-to-site"
          id={id}
          size={size}
          target={target}
          onClick={handleClick}
          href={sanitizedUrl}
        >
          <ArrowLeft />
          {label}
        </AndesButton>
    }</>
  );
};

ButtonBackURLs.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  label: PropTypes.string,
  isWebview: PropTypes.bool,
  buttonRequest: PropTypes.bool,
  buttonAction: PropTypes.shape({
    BUTTON_REQUEST_TRIGGERED: () => {},
  }),
};

ButtonBackURLs.defaultProps = {
  id: '',
  url: '',
  label: '',
  buttonAction: {},
  buttonRequest: false,
  isWebview: false,
};

const mapDispatchToProps = (dispatch) => ({
  buttonAction: bindActionCreators(buttonActions, dispatch),
});

const mapStateToProps = ({ page, button, configurations }) => ({
  step: page.flow.step,
  buttonRequest: button.buttonRequest,
  isWebview: configurations.isWebview,
});

module.exports = connectToReduxAndInjectI18n(
  ButtonBackURLs,
  mapStateToProps,
  mapDispatchToProps,
);
