import type { InputCardProps } from '../InputCard';

import React from 'react';

import InputCard from '../InputCard';
import Email from '../Icons/Email';

export type EmailInputCardProps = Pick<
  InputCardProps,
  | 'device'
  | 'helper'
  | 'icon'
  | 'id'
  | 'label'
  | 'onChange'
  | 'onError'
  | 'style'
  | 'validations'
  | 'value'
  | 'placeHolder'
>;

export default function EmailInputCard({
  value,
  onError,
  onChange,
  label,
  helper,
  id,
  validations,
  style,
  icon = <Email width={36} height={28} className="email-input-card__icon" />,
  device = 'desktop',
  placeHolder = '',
}: EmailInputCardProps) {
  return (
    <InputCard
      containerClassName={`email-input-card__${device}`}
      label={label}
      helper={helper}
      value={value}
      icon={icon}
      id={id}
      onChange={onChange}
      onError={onError}
      validations={validations}
      style={style}
      device={device}
      placeHolder={placeHolder}
    />
  );
}
