/* eslint-disable jsx-a11y/label-has-associated-control */
const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const { Image } = require('nordic/image');
const { Tooltip } = require('@andes/tooltip');

const ZeroDollarMessage = require('../ZeroDollarMessage');

const GreyIcon = require('../icons/grey');
const ErrorIcon = require('../icons/ErrorIcon');
const DefaultCardIcon = require('../icons/defaultCardIcon');
const QuestionMarkIcon = require('../icons/GenericQuestionMark');

const SecureFieldInput = (props) => {
  const { id, hint, field, label, isFocused, showCvvIcon, showTooltip, errorMessage, paymentMethodIcon } = props;

  /** this is just to avoid adding classes in camel case */
  const fieldNameToKebabCase = field.replace(/[A-Z]/g, (c) => `-${c.toLowerCase()}`);
  const containerClass = classNames('cow-secure-fields', fieldNameToKebabCase);

  const showHint = hint && !showTooltip;
  const showError = errorMessage;

  const formControlClass = classNames({
    'andes-form-control--focused': isFocused,
    'andes-form-control--error': showError,
  });

  const isCVV = field === 'securityCode';
  const isCardNumber = field === 'cardNumber';
  const shouldRenderCardIcon = paymentMethodIcon && isCardNumber;

  const cardIconClass = classNames('cow-secure-field-card-icon', {
    'icon-card--pm': shouldRenderCardIcon,
    'icon-card--default': !shouldRenderCardIcon,
  });

  const renderCardIcon = () => (
    <>
      {isCardNumber && (
        <div className={cardIconClass}>
          {shouldRenderCardIcon ? (
            <Image src={paymentMethodIcon} lazyload="off" alt="payment-method" />
          ) : (
            <DefaultCardIcon />
          )}
        </div>
      )}
    </>
  );

  const renderCvvIcon = () => {
    return (
      <div className="andes-form-control__embedded">
        <GreyIcon />
      </div>
    );
  };

  const renderCvvTooltip = () => {
    return (
      <div className="security-code-tooltip">
        <Tooltip content={hint} type="dark" side="bottomLeft" closable>
          <div className="andes-form-control__embedded">
            <QuestionMarkIcon />
          </div>
        </Tooltip>
      </div>
    );
  };

  return (
    <>
      {/* TODO delete after zero dollar is being done by bricks. */}
      {fieldNameToKebabCase === 'card-number' && <ZeroDollarMessage />}

      <div className={containerClass}>
        <div
          className={`andes-form-control andes-form-control--textfield andes-form-control--default ${formControlClass}`}
        >
          <label>
            <span className="andes-form-control__label">{label}</span>
            <div className="andes-form-control__control">
              <div id={id} className="andes-form-control__field" />
              {showCvvIcon && isCVV && renderCvvIcon()}
              {showTooltip && isCVV && hint && renderCvvTooltip()}
              {renderCardIcon()}
            </div>
          </label>
          {(showHint || showError) && (
            <div className="andes-form-control__bottom">
              {errorMessage && (
                <span className="andes-form-control__error-icon">
                  <ErrorIcon />
                </span>
              )}
              <span className="andes-form-control__message">{errorMessage || hint}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

SecureFieldInput.propTypes = {
  id: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.string,
  isFocused: PropTypes.bool,
  showCvvIcon: PropTypes.bool,
  showTooltip: PropTypes.bool,
  errorMessage: PropTypes.string,
  paymentMethodIcon: PropTypes.string,
  field: PropTypes.oneOf(['cardNumber', 'expirationDate', 'securityCode']),
};

SecureFieldInput.defaultProps = {
  id: '',
  hint: '',
  field: '',
  label: '',
  isFocused: false,
  showCvvIcon: false,
  showTooltip: false,
  paymentMethodIcon: '',
  errorMessage: '',
};

module.exports = SecureFieldInput;
