const React = require('react');
const { useState } = require('react');
const PropTypes = require('prop-types');

const { Typography } = require('@andes/typography');
const EmailInputCard = require('@cow/core-components/components/EmailInputCard');

const BaseTemplate = require('./index');
const translate = require('../../../../translation');
const {
  DEVICE_TYPE: { MOBILE },
  CHECKOUT_CASE: { GUEST },
} = require('../../../../../constants/commons');

const { NEW_EMAIL_INPUT_VALIDATIONS } = require('../../../../utils/regex');

const { INPUTS_ID } = require('../../../../../constants/ui');

const {
  genericPropsTypes,
  newInterfaceGenericPropsTypes,
} = require('../../../../utils/propTypes');
const connectToReduxAndInjectI18n = require("../../../../utils/connectToRedux");

const CombinedPaymentReviewGuestMobileTemplate = (props) => {

  const {
    step_model: {
      payer,
      errors,
    }
  } = props;

  const translations = translate(props.i18n);

  const [email, setEmail] = useState(payer?.email?.value ?? '');
  const [emailErrorMessage, setEmailErrorMessage] = useState(errors?.payer?.email ?? '');

  const emailErrors = NEW_EMAIL_INPUT_VALIDATIONS(translations);

  const handleEmailInput = (event) => {
    setEmail(event?.target?.value);
    setEmailErrorMessage('');
  };

  const handleEmailInputError = (data) => {
    setEmailErrorMessage(data?.message);
  };

  // Update emails message with flow response
  React.useEffect(() => {
    setEmailErrorMessage(errors?.payer?.email ?? '');
  }, [errors?.payer?.email]);

  return (
    <BaseTemplate
      templateCase={GUEST}
      email={email}
      setEmail={setEmail}
      setEmailErrorMessage={setEmailErrorMessage}
      emailErrorMessage={emailErrorMessage}
      emailComponent={
        payer?.email?.is_required && (
          <div className="guest-email">
            <Typography className="email-title" size="m" weight="semibold">
              {translations.RECEIVE_PAYMENT_DETAILS}
            </Typography>
            <EmailInputCard
              deviceType={MOBILE}
              icon={null}
              id={INPUTS_ID.USER_EMAIL}
              label={translations.EMAIL}
              helper={emailErrorMessage || translations.SEND_ONLY_PAYMENT_INFO_FOR_AVAILABILITY}
              value={email}
              onChange={handleEmailInput}
              onError={handleEmailInputError}
              style={emailErrorMessage ? 'error' : undefined}
              validations={emailErrors}
              placeHolder={translations.PLACEHOLDER_EMAIL}
            />
          </div>
        )
      }
      {...props}
    />
  );
};

CombinedPaymentReviewGuestMobileTemplate.propTypes = {
  templateCase: PropTypes.string,
  step_model: PropTypes.shape({
    ...newInterfaceGenericPropsTypes,
  }).isRequired,
  ...genericPropsTypes,
};

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  flow: state.page.flow,
  firstRender: state.page.firstRender,
  basePath: state.configurations.basePath,
  captchaConfig: state.configurations.captcha,
});

module.exports = connectToReduxAndInjectI18n(
  CombinedPaymentReviewGuestMobileTemplate,
  mapStateToProps
);
