module.exports = {
  MLA_CONSUMER_CREDITS_TERMS_AND_CONDITIONS: 'https://www.mercadolibre.com.ar/credits/consumer/conditions/general',
  MLM_CONSUMER_CREDITS_TERMS_AND_CONDITIONS: 'https://www.mercadolibre.com.mx/credits/consumer/conditions/general',
  MLB_CONSUMER_CREDITS_TERMS_AND_CONDITIONS: 'https://www.mercadolivre.com.br/credits/consumer/conditions/general',
  TERMS_AND_CONDITIONS: {
    MLA: 'https://www.mercadolibre.com.ar/credits/consumer/conditions/general',
    MLB: 'https://www.mercadolivre.com.br/credits/consumer/conditions/general',
    MLM: 'https://www.mercadolibre.com.mx/credits/consumer/conditions/general'
  }
};
