const { ShippingUserType } = require('../../../components/Shipping/ShippingUserType');
const { ShippingZipcodeForm } = require('../../../components/Shipping/ShippingZipcodeForm');
const { ShippingType } = require('../../../components/Shipping/ShippingType');
const { ShippingReview } = require('../../../components/Shipping/ShippingReview');
const { ShippingAddressSelector } = require('../../../components/Shipping/ShippingAddressSelector');
const { ShippingOptions } = require('../../../components/Shipping/ShippingOptions');
const { ShippingAddressForm } = require('../../../components/Shipping/ShippingAddressForm');

module.exports = {
  shipping_user_type: (props) => <ShippingUserType {...props} />,
  shipping_type: (props) => <ShippingType {...props} />,
  shipping_zipcode_form: (props) => <ShippingZipcodeForm {...props} />,
  shipping_review: (props) => <ShippingReview {...props} />,
  shipping_address_selector: (props) => <ShippingAddressSelector {...props} />,
  shipping_options: (props) => <ShippingOptions {...props} />,
  shipping_address_form: (props) => <ShippingAddressForm {...props} />,
};
