const React = require('react');
const PropTypes = require('prop-types');
const { Button } = require('@andes/button');

const ButtonCloseModalLink = ({ kind, name, text, id }) => (
  <Button
    id={id}
    hierarchy={kind}
    type='submit'
    data-close-payment
    name={name}
    fullWidth
  >
    {text}
  </Button>
);

ButtonCloseModalLink.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  kind: PropTypes.oneOf(['link', 'loud', 'quiet', 'transparent']),
};

ButtonCloseModalLink.defaultProps = {
  id: null,
  name: '',
  text: '',
  kind: 'link',
};

module.exports = ButtonCloseModalLink;
