const React = require('react');
const PropTypes = require('prop-types');

const PaymentSummary = require('@cow/core-components/components/PaymentSummary');

const {handleSummaryItemProps, PAYMENT_SUMMARY_ALIGNMENT} = require('../../../utils/summary');
const {COMMONS, ANDES_STYLES_THEME, LINKS} = require('../../../../constants');
const PCJSummary = require('./PCJSummary');
const TyCCredits = require('../../../containers/TyCCredits');
const {CAPTCHA_VERSION, USER_TYPES} = require('../../../../constants/app');
const CaptchaV2 = require('../../../components/CaptchaV2');

const {TERMS_AND_CONDITIONS} = LINKS;

const {CHECKOUT_CASE: {SUBSCRIPTION}, THEME} = COMMONS;
const SummaryDesktop = (props) => {

  const {
    payer,
    summary,
    captcha,
    translations,
    tooltipContainer,
    setShowDiscountModal,
    transactionType,
    deviceType,
    mainAction,
    subscription,
    termsAndConditions,
    blockedByCaptcha,
    isCouponVisible,
    hasDiscountCodeApplied,
    theme,
    pcj,
    loadingPayButton,
    progressDone,
    onPayClick,
    siteId,
    incentives,
  } = props;

  const SummaryTheme = React.useMemo(() => transactionType === SUBSCRIPTION || subscription !== null ? THEME.DARK : THEME.LIGHT, [transactionType, subscription]);

  const itemsForSummary = !pcj?.is_applicable ? summary?.items?.map((item) =>
    handleSummaryItemProps({
      item,
      summary,
      translations,
      deviceType,
      tooltipContainer,
      setShowDiscountModal,
      iconAlignment: PAYMENT_SUMMARY_ALIGNMENT.LEFT,
      isSubscription: transactionType === SUBSCRIPTION,
    }),
  ) : [];

  const totalForSummary = {
    name: summary?.total?.name,
    nameWeight: ANDES_STYLES_THEME.WEIGHT.BOLD,
    price: summary?.total?.price.text_value,
    priceWeight: ANDES_STYLES_THEME.WEIGHT.BOLD,
    priceCents: summary?.total?.price.cents,
    priceSize: 16,
    textSize: ANDES_STYLES_THEME.SIZES.M,
  };

  const tyCGeneralLink = TERMS_AND_CONDITIONS[siteId.toUpperCase()];

  return (
    <div className="sidebar--default">
      <PaymentSummary
        title={summary?.title}
        items={itemsForSummary}
        total={!pcj ? totalForSummary : null}
        buttonText={mainAction}
        onPayClick={onPayClick}
        theme={SummaryTheme}
        companyTheme={theme}
        securityPaymentInformationLabel={incentives?.is_secure_payment ? translations.SECURE_PAYMENT : null}
        bottomContent={
          <>
            {
              pcj?.is_applicable && <>
                <PCJSummary
                  pcj={pcj}
                  translations={translations}
                  items={summary.items}
                  totalSummary={totalForSummary}
                />
              </>
            }
            {
              termsAndConditions?.credits?.has_detail &&
              <div className="summary-total">
                <TyCCredits text={termsAndConditions?.credits?.text} contractUrl={termsAndConditions?.credits?.detail}
                            tycUrl={tyCGeneralLink}/>
              </div>
            }
            {captcha?.version === CAPTCHA_VERSION.V2 && (
              <div
                className={`payment-summary__bottom-content cow-captcha__container cow-captcha__container--${
                  payer.is_guest ? USER_TYPES.GUEST : USER_TYPES.LOGGED
                }`}
              >
                <CaptchaV2
                  {...props}
                  show={captcha?.version === CAPTCHA_VERSION.V2}
                  captchaRetry={captcha?.is_retry || false}
                />
              </div>
            )}
          </>
        }
        disablePayButton={blockedByCaptcha || (isCouponVisible && !hasDiscountCodeApplied)}
        loadingButton={loadingPayButton && !progressDone}
      />
    </div>
  );
};

SummaryDesktop.propTypes = {
  summary: PropTypes.object,
  translations: PropTypes.object,
  setShowDiscountModal: PropTypes.func,
  transactionType: PropTypes.string,
  deviceType: PropTypes.string,
  mainAction: PropTypes.string,
  subscription: PropTypes.func,
  termsAndConditions: PropTypes.object,
  blockedByCaptcha: PropTypes.bool,
  isCouponVisible: PropTypes.bool,
  hasDiscountCodeApplied: PropTypes.bool,
  theme: PropTypes.string,
  pcj: PropTypes.object,
  loadingPayButton: PropTypes.bool,
  progressDone: PropTypes.bool,
  tooltipContainer: PropTypes.object,
  onPayClick: PropTypes.func,
  siteId: PropTypes.string,
  incentives: PropTypes.object,
  payer: PropTypes.object,
  captcha: PropTypes.object,
};

module.exports = SummaryDesktop;
