const { ICONS_METHODS } = require('../../../../../constants/commons');

const shouldRenderSecurityCode = (pm) => !!pm?.security_code;

const shouldRenderInstallments = (pm) => pm?.installments?.length !== 0 && !!pm?.show_installments;

const shouldRenderCollapsedContent = (pm, express) => {
    const cardToken = express?.[pm?.option_id]?.cardTokenId;
    const renderInstallments = shouldRenderInstallments(pm);

    if (cardToken) {
        return renderInstallments;
    }

    return shouldRenderSecurityCode(pm) || renderInstallments;
};

const getPaymentMethodId = (paymentMethod, rebrandingMP) => {
    if (rebrandingMP) {
      if (paymentMethod?.id === ICONS_METHODS.ICON_ACCOUNT_MONEY_BLACK) {
        return ICONS_METHODS.ICON_ACCOUNT_MONEY_YELLOW;
      }
  
      if (paymentMethod?.id === ICONS_METHODS.ICON_CONSUMER_CREDITS) {
        return ICONS_METHODS.ICON_MERCADO_CREDITO_YELLOW;
      }
    }
  
    return paymentMethod?.id;
};

module.exports = {
    shouldRenderSecurityCode,
    shouldRenderInstallments,
    shouldRenderCollapsedContent,
    getPaymentMethodId
};