const React = require('react');
const PropTypes = require('prop-types');

const {Card} = require('@andes/card');
const { CardSecondary, CardContent } = require('@andes/card');

const IconWithTooltip = require('../IconWithTooltip');

const PaymentDetailRow = ({
  text,
  value,
  deviceType,
  tooltipText,
  tooltipSide,
  tooltipIcon,
  showTooltip,
  tooltipAction,
  tooltipType,
}) => (
  <Card className="payment-detail__row">
    <CardSecondary className="payment-detail__secondary">
      <CardContent className="payment-detail__content">
        <span className="payment-detail__text">
          {text}
          {showTooltip ? (
            <IconWithTooltip
              deviceType={deviceType}
              icon={tooltipIcon}
              side={tooltipSide}
              text={tooltipText}
              actions={tooltipAction}
              type={tooltipType}
            />
          ) : null}
        </span>
        <span className="payment-detail__value">{value}</span>
      </CardContent>
    </CardSecondary>
  </Card>
);

PaymentDetailRow.defaultProps = {
  text: '',
  value: '',
  deviceType: '',
  history: {
    push: () => {},
    pop: () => {},
  },
  tooltipText: '',
  showTooltip: false,
  tooltipAction: null,
};

PaymentDetailRow.propTypes = {
  text: PropTypes.string,
  value: PropTypes.string,
  deviceType: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
    pop: PropTypes.func,
  }),
  tooltipText: PropTypes.string,
  showTooltip: PropTypes.bool,
  tooltipSide: PropTypes.string,
  tooltipIcon: PropTypes.string,
  tooltipAction: PropTypes.node,
  tooltipType: PropTypes.string,
};

module.exports = PaymentDetailRow;
