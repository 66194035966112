// Constants
const { PAGE_ACTIONS } = require('../../../../constants/app');
// Actions
const { STEP_NEXT } = require('../../../spa/actions/types');
// Utils
const { getQueryParams } = require('../../../utils/Dom');

export const useActions = ({ flow, history, stepActions }) => {
  const nextStepAction = (data) => {
    const queryParams = getQueryParams();
    stepActions[STEP_NEXT](
      data,
      flow.id,
      {
        type: flow.type,
        urlParams: queryParams,
      },
      flow.type,
      queryParams,
      history,
    );
  };

  // ===== STEP ACTIONS =====
  const acceptConsumerCredits = () => {
    const data = {
      id: PAGE_ACTIONS.ACCEPT_CONSUMER_CREDITS,
      values: {},
    };
    nextStepAction(data);
  };

  const dismissConsumerCredits = () => {
    const data = {
      id: PAGE_ACTIONS.DISMISS_CONSUMER_CREDITS,
      values: {},
    };
    nextStepAction(data);
  };

  return {
    acceptConsumerCredits,
    dismissConsumerCredits,
  };
};