const React = require('react');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const Page = require('../../../../components/Page');
const ButtonBackURLs = require('../../../../components/ButtonBackURLs');
const stepActionCreators = require('../../../../spa/actions/step');
const Optimus = require('../../../../containers/Optimus');
const CardListGuestSection = require('../components/CardListGuestSection');
const PaidWithCashSection = require('../components/PaidWithCashSection');
const PaymentOptionPropTypes = require('../templatePropTypes');
const Footer = require('@cow/core-components/components/Footer');
const {
  COMMONS: {
    CHECKOUT_TYPE: { MODAL },
  },
  SELECTORS,
} = require('../../../../../constants');
const { Title } = require('@andes/typography');
const classNames = require('classnames');

const PaymentOptionsDesktopGuest = (props) => (
  <Page
    title={props.frontendData.pageTitle}
    currentStep={props.currentStep}
    urls={props.urls}
    trackingPath={props.trackingPath}
    analytics={props.analytics}
    deviceType={props.deviceType}
  >
    <section className="payment-option-desktop-screen payment-option-desktop-screen--guest" >
      <div className="payment-option-desktop-screen__content">
        <Title component="h1" size="s" className="payment-option-desktop-screen__content-title">
          {props.frontendData.pageTitle}
        </Title>

        <div
          className={classNames('payment-option-desktop-screen__content-card-list', {
            [SELECTORS.REBRANDING]: props.configs?.rebrandingMP,
          })}
        >
          {!props.frontendData.showPayWithCashScreen && (
            <CardListGuestSection
              otherPaymentMethods={props.frontendData.otherPaymentMethods}
              goToScreen={props.frontendData.goToScreen}
              loginSection={props.frontendData.loginSection}
              guestSection={props.frontendData.guestSection}
              deviceType={props.deviceType}
              showPayWithCashSection={props.frontendData.showPayWithCash}
              isTheOptionToPayWithCash={props.frontendData.isTheOptionToPayWithCash}
            />
          )}

          {props.frontendData.showPayWithCashScreen && (
            <PaidWithCashSection
              {...props.frontendData.payWithCash[props.frontendData.showPayWithCashScreen]}
              onClickMethod={props.frontendData.goToStorePage}
              onNavigationBack={props.frontendData.hidePayWithCash}
            />
          )}

          {props.frontendData.backButton?.url && props.frontendData.backButton?.title && (
            <ButtonBackURLs label={props.frontendData.backButton.title} url={props.frontendData.backButton.url} />
          )}
        </div>
      </div>

      <Optimus
        components={props.frontendData.summary}
        history={props.history}
        siteId={props.siteId}
        urls={props.urls}
        publicKey={props.publicKey}
        deviceType={props.deviceType}
        captcha={props.captcha}
        hiddenComponents={props.hidden_components}
      />
      { // INFO: The Footer component escapes when it is MODAL because, in modal, the footer is incorporated into the content section.
        props.checkoutType !== MODAL &&
        !props.frontendData.showPayWithCashScreen &&
        <section className="layout__col-footer">
          <Footer
            requiredMessage={props.frontendData.footer.requiredMessage}
            requiredScreenReaderMessage={props.frontendData.footer.requiredScreenReaderMessage}
            deviceType={props.deviceType}
          />
        </section>
      }
    </section>


  </Page>
);

PaymentOptionsDesktopGuest.propTypes = PaymentOptionPropTypes;

/* istanbul ignore next: cant test it with tests */
const mapStateToProps = (state) => ({
  flow: state.page.flow,
});

/* istanbul ignore next: cant test it with tests */
const mapDispatchToProps = (dispatch) => ({
  stepActions: bindActionCreators(stepActionCreators, dispatch),
});

if (process.env.NODE_ENV === 'test') {
  module.exports = PaymentOptionsDesktopGuest;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(PaymentOptionsDesktopGuest);
}
