const { REQUEST_STARTED, REQUEST_FINISHED, SAVE_CAPTCHA_TOKEN } = require('../spa/actions/types');

const generateTokenCaptchaV3 = ({requestActions, requestId, captchaConfig, captchaToken}) => {
  requestActions[REQUEST_STARTED]({ requestId });
  const promiseToken = new Promise((resolve, reject) => {
    try {
      return window.grecaptcha.enterprise
        .execute(captchaConfig.siteKeyV3, { action: captchaConfig.action })
        .then((token) => {
          requestActions[REQUEST_FINISHED]({
            requestId,
            leaveLoading: true,
          });
          if (captchaToken) {
            captchaToken[SAVE_CAPTCHA_TOKEN](token);
          }
          resolve(token);
        });
    } catch (error) {
      requestActions[REQUEST_FINISHED]({
        requestId,
        leaveLoading: true,
      });
      reject(error);
    }
  });
  return promiseToken;
};

module.exports = generateTokenCaptchaV3;
