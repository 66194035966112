const React = require('react');
const PropTypes = require('prop-types');
const { injectI18n } = require('nordic/i18n');
const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const CardList = require('@cow/core-components/components/CardList');

const translate = require('../../translation');
const stepActions = require('../../spa/actions/step');
const { getPaymentMethodIcon } = require('../../utils/icons');
const { STEP_NEXT } = require('../../spa/actions/types');
const { getQueryParams } = require('../../utils/Dom');
const { PAGE_ACTIONS } = require('../../../constants/app');

const RowCardList = (props) => {
  const { data, i18n, flow, history, stepAction, siteId } = props;
  const translations = translate(i18n);

  const onClick = () => {
    const urlParams = getQueryParams();
    const defaultData = { urlParams, type: flow.type };
    const formData = { isNewInterface: true, id: PAGE_ACTIONS.CHANGE_PAYMENT_OPTION };
    stepAction[STEP_NEXT](formData, flow.id, defaultData, flow.type, urlParams, history);
  };

  return (
    <div className="row-card-list">
      <CardList
        items={[
          {
            onClick,
            id: data.paymentMethodId,
            title: data.paymentMethodName,
            description: data.description,
            callToAction: <span className="card-option-cta">{translations.MODIFY}</span>,
            icon: getPaymentMethodIcon({
              siteId,
              type: data.paymentMethodType,
              issuerID: data.issuerID || data.paymentMethodId,
              issuerName: data.issuerName || data.paymentMethodId,
            }),
          },
        ]}
      />
    </div>
  );
};

RowCardList.propTypes = {
  siteId: PropTypes.string.isRequired,
  data: PropTypes.shape({
    detail: PropTypes.string,
    paymentMethodId: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  flow: state.page.flow,
});

const mapDispatchToProps = (dispatch) => ({
  stepAction: bindActionCreators(stepActions, dispatch),
});

if (process.env.NODE_ENV === 'test') {
  module.exports = RowCardList;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(RowCardList));
}