const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const { schema, useForm } = require('checkout-off-form-validation');
const { injectI18n } = require('nordic/i18n');
const { Form } = require('@andes/form');
const { TextField } = require('@andes/textfield');
const { Card, CardContent } = require('@andes/card');

const InputIdentification = require('../InputIdenfication');
const FormActions = require('../FormActions');
const translate = require('../../translation');
const stepActions = require('../../spa/actions/step');
const inputValuesActions = require('../../spa/actions/inputValues');
const { getQueryParams } = require('../../utils/Dom');
const { formatDocument, formatString } = require('../../utils/format');
const { CURRENT_INPUT_VALUES } = require('../../spa/actions/types');
const { STEP_NEXT } = require('../../spa/actions/types');
const { getDisplayEmail } = require('../../utils/emailUtils')
const {
  CHECKOUT_TYPE: { MODAL },
} = require('../../../constants/commons');

const {
  DOCUMENTS: { CPF, CPF_PLACEHOLDER, CNPJ_PLACEHOLDER, CPF_MASK_LENGTH, CNPJ_MASK_LENGTH },
} = require('../../../constants/app');

const FormPersonalInfo = (props) => {
  const { flow, form_type, history, i18n, inputValuesAction, stepAction } = props;
  const { errors, clearAllErrors, handleSubmit, registerSchema, setFieldOptions } = useForm();

  const translations = translate(i18n);

  const formValues = props?.form_data?.values;
  const identificationTypes = props?.form_data?.identification_types?.map((t) => ({ title: t.id }));
  const identificationNumber = formatDocument(formValues.identification_number, formValues.identification_type);
  const identificationSelection = identificationTypes.findIndex((t) => t.title === formValues.identification_type);

  const getIdentificationFields = (isCPF) => ({
    firstNameLabel: isCPF ? translations.LABEL_NATURAL_NAME : translations.LABEL_LEGAL_NAME,
    firstNamePlaceholder: isCPF ? translations.PLACEHOLDER_NATURAL_NAME : translations.PLACEHOLDER_LEGAL_NAME,
    identificationPlaceholder: isCPF ? CPF_PLACEHOLDER : CNPJ_PLACEHOLDER,
  });

  const getFirstNameValidationTranslation = (isCPF) =>  isCPF ? translations.INVALID_FIRST_NAME : translations.INVALID_LEGAL_NAME;

  const getIdentificationLengthValidation = (isCPF) => (isCPF ? CPF_MASK_LENGTH : CNPJ_MASK_LENGTH);

  const [identificationType, setIdentificationType] = React.useState(formValues.identification_type || CPF);
  const [identificationFields, setIdentificationFields] = React.useState(
    getIdentificationFields(identificationType === CPF),
  );

  const formFields = registerSchema(
    schema.shape({
      identificationNumber: schema
        .options()
        .required({ message: translations.REQUIRED_FORM_FIELD })
        .document({ type: identificationType, message: translations.INVALID_IDENTIFICATION_NUMBER })
        .min({
          length: getIdentificationLengthValidation(identificationType === CPF),
          message: translations.UNCOMPLETED_IDENTIFICATION_NUMBER,
        })
        .validateOnChange(false),
      firstName: schema
        .options()
        .required({ message: translations.REQUIRED_FORM_FIELD })
        .min({ length: 2, message: getFirstNameValidationTranslation(identificationType === CPF) })
        .max({ length: 80, message: getFirstNameValidationTranslation(identificationType === CPF) })
        .onChange((e) => formatString(e.target.value, e.target))
        .validateOnChange(false),
      lastName: schema
        .options()
        .required({ message: translations.REQUIRED_FORM_FIELD })
        .min({ length: 2, message: translations.INVALID_LAST_NAME })
        .max({ length: 80, message: translations.INVALID_LAST_NAME })
        .onChange((e) => formatString(e.target.value, e.target))
        .validateOnChange(false),
      email: schema
        .options()
        .required({ message: translations.REQUIRED_FORM_FIELD })
        .email({ message: translations.INVALID_EMAIL })
        .validateOnChange(false),
    }),
  );

  const setInputStateValues = React.useCallback(
    (values) => inputValuesAction[CURRENT_INPUT_VALUES](`${flow.step}_${form_type}_values`, values),
    [props],
  );

  const handleIdentificationTypeClick = (item) => {
    const isCPF = item.title === CPF;

    clearAllErrors();
    setIdentificationType(item.title);
    setIdentificationFields(getIdentificationFields(isCPF));

    setFieldOptions('identificationNumber', {
      document: {
        type: item.title,
        message: translations.INVALID_IDENTIFICATION_NUMBER,
      },
    });

    setFieldOptions('firstName', {
      min: {
        length: 2,
        message: getFirstNameValidationTranslation(isCPF),
      },
      max: {
        length: 80,
        message: getFirstNameValidationTranslation(isCPF),
      },
    });
  };

  const onSubmit = (data, event) => {
    event.preventDefault();

    setInputStateValues({
      email: formValues?.email || data?.email,
      last_name: data.lastName,
      first_name: data.firstName,
      identification_number: data.identificationNumber,
      identification_type: identificationType,
    });

    const urlParams = getQueryParams();
    const defaultData = { urlParams, type: flow.type };

    const formData = {
      ...data,
      email: formValues?.email || data?.email,
      identificationType,
      isNewInterface: true,
    };

    stepAction[STEP_NEXT](formData, flow.id, defaultData, flow.type, urlParams, history);
  };

  const displayEmail = getDisplayEmail(formValues);

  return (
    <div className={`${props.checkoutType === MODAL && 'scroller'}`}>
      <Card>
        <CardContent>
          <Form className="form_personal_info" id={props.form}>
            <InputIdentification
              id="identification_number"
              className="form_personal_field"
              label={translations.LABEL_IDENTIFICATION_NUMBER}
              placeholder={identificationFields.identificationPlaceholder}
              defaultValue={identificationNumber}
              defaultSelection={identificationSelection !== -1 ? identificationSelection : 0}
              identificationType={identificationType}
              list={{ content: identificationTypes, onClick: handleIdentificationTypeClick }}
              helper={errors?.identificationNumber?.message}
              modifier={errors?.identificationNumber && 'error'}
              {...formFields?.identificationNumber}
            />

            <div className="form_personal_double_field">
              <TextField
                id="first_name"
                className="form_personal_field"
                label={identificationFields.firstNameLabel}
                placeholder={identificationFields.firstNamePlaceholder}
                helper={errors?.firstName?.message}
                modifier={errors?.firstName && 'error'}
                defaultValue={formValues.first_name || ''}
                {...formFields?.firstName}
              />

              {identificationType === CPF && (
                <TextField
                  id="last_name"
                  className="form_personal_field"
                  label={translations.LABEL_LAST_NAME}
                  placeholder={translations.PLACEHOLDER_LAST_NAME}
                  helper={errors?.lastName?.message}
                  modifier={errors?.lastName && 'error'}
                  defaultValue={formValues.last_name || ''}
                  {...formFields?.lastName}
                />
              )}
            </div>

            <TextField
              id="email"
              className="form_personal_field"
              label={translations.LABEL_EMAIL}
              placeholder={translations.PLACEHOLDER_EMAIL}
              helper={errors?.email?.message}
              modifier={errors?.email && 'error'}
              defaultValue={displayEmail}
              disabled={formValues.email}
              {...formFields?.email}
            />
          </Form>
        </CardContent>
      </Card>

      <FormActions.Container>
        <FormActions.BackButton text={translations.TO_GO_BACK} handleBack={() => window.history.back()} />
        <FormActions.SubmitButton text={translations.CONTINUE} handleSubmit={handleSubmit(onSubmit)} />
      </FormActions.Container>
    </div>
  );
};

FormPersonalInfo.propTypes = {
  siteId: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  form_type: PropTypes.string.isRequired,
  form_data: PropTypes.shape({
    values: PropTypes.shape({
      email: PropTypes.string,
      last_name: PropTypes.string,
      first_name: PropTypes.string,
      identification_type: PropTypes.string,
      identification_number: PropTypes.string,
    }).isRequired,
    identification_types: PropTypes.array.isRequired,
  }).isRequired,
  stepAction: PropTypes.shape({
    [STEP_NEXT]: PropTypes.func.isRequired,
  }).isRequired,
  inputValuesAction: PropTypes.shape({
    [CURRENT_INPUT_VALUES]: PropTypes.func.isRequired,
  }).isRequired,
  flow: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    step: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    back: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state, props) => {
  const inputStateValues = state.inputValues.current[`${state.page.flow.step}_${props.form_type}_values`];
  return {
    flow: state.page.flow,
    checkoutType: state.configurations.checkoutType,
    form_data: {
      ...props.form_data,
      values: inputStateValues || props.form_data.values,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  stepAction: bindActionCreators(stepActions, dispatch),
  inputValuesAction: bindActionCreators(inputValuesActions, dispatch),
});

if (process.env.NODE_ENV === 'test') {
  module.exports = FormPersonalInfo;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(FormPersonalInfo));
}
