const { Card, CardContent } = require('@andes/card');

const groupCard = ({ children, padding = 32, className }) => (
  <Card
    className={className}
    paddingSize={padding}>
    <CardContent>
      {children}
    </CardContent>
  </Card>
);

module.exports = groupCard;
