import React from 'react';

const BoletoBancario = ({ ...props }: SVGProps) => (
  <svg
    {...props}
    width="24"
    height="15"
    viewBox="0 0 24 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 14.4102V0H1.60114V14.4102H0Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
    <path
      d="M6.39388 14.4102V0H7.99502V14.4102H6.39388Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
    <path
      d="M2.35882 0V14.4102H3.95996V0H2.35882Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
    <path
      d="M10.3666 14.4102V0H11.9678V14.4102H10.3666Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
    <path
      d="M12.7974 0V14.4102H14.3985V0H12.7974Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
    <path
      d="M17.5976 14.4102V0H19.1987V14.4102H17.5976Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
    <path
      d="M20.023 0V14.4102H21.6241V0H20.023Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
    <path
      d="M22.3989 14.4102V0H24V14.4102H22.3989Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
  </svg>
);

export default React.memo(BoletoBancario);
