const getDeliveryDay = (date) => new Date(date).getDate();

const getDeliveryMonthTranslated = (date, translations) => {
  const month = new Date(date).getMonth();
  return {
    1: translations.JANUARY,
    2: translations.FEBRUARY,
    3: translations.MARCH,
    4: translations.APRIL,
    5: translations.MAY,
    6: translations.JUNE,
    7: translations.JULY,
    8: translations.AUGUST,
    9: translations.SEPTEMBER,
    10: translations.OCTOBER,
    11: translations.NOVEMBER,
    12: translations.DECEMBER,
  }[month + 1];
};

module.exports = {
  getDeliveryDay,
  getDeliveryMonthTranslated,
};
