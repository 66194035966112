const React = require('react');
const PropTypes = require('prop-types');


const { Title, Typography } = require('@andes/typography');
const { ANDES_STYLES_THEME } = require('../../../constants');

const Desktop = (props) => {

  const {
    mainContent,
    sidebarContent,
    modalsContent,
    footerContent,
    navbarComponent,
    stepTitle,
    stepSubtitle,
  } = props;


  return (
    <div className="layout layout--new-interface">
      {modalsContent}
      {navbarComponent}
      <section className="layout__col-content">
        {
          stepSubtitle &&
          <Typography
            className="layout__title"
            component={stepSubtitle?.component || 'h3'}
            size={stepSubtitle?.size || ANDES_STYLES_THEME.SIZES.S}
            weight={stepSubtitle?.weight || ANDES_STYLES_THEME.WEIGHT.REGULAR}
            color={stepSubtitle?.color}
          >
            {stepSubtitle?.text}
          </Typography>
        }
        <Title
          className="layout__title"
          component={stepTitle?.component || 'h1'}
          size={stepTitle?.size || ANDES_STYLES_THEME.SIZES.S}
          weight={stepTitle?.weight || ANDES_STYLES_THEME.WEIGHT.REGULAR}
        >
          {stepTitle?.text}
        </Title>
        {mainContent}
      </section>
      <section className="layout__col-sidebar">
        {sidebarContent}
      </section>
      {footerContent}
    </div>
  );
};

Desktop.propTypes = {
  mainContent: PropTypes.node,
  sidebarContent: PropTypes.node,
  modalsContent: PropTypes.node,
  navbarComponent: PropTypes.node,
  footerContent: PropTypes.node,
  stepTitle: PropTypes.shape({
    text: PropTypes.string,
    color: PropTypes.string,
    weight: PropTypes.string,
    size: PropTypes.string,
    component: PropTypes.string,
  }),
  stepSubtitle: PropTypes.shape({
    text: PropTypes.string,
    color: PropTypes.string,
    weight: PropTypes.string,
    size: PropTypes.string,
    component: PropTypes.string,
  }),
};

module.exports = Desktop;
