const { COOKIES } = require('../../../../../../constants/app');
const { setCookieWithJSON } = require('../../../../../utils/CookieUtils');
const { createCardTokenWithEsc } = require('../secure-fields');


const handleCardTokenizationWithCCAP = async (
  handleTokenizationWithCCAP,
  paymentMethod,
) => {
  const { response, error } = await handleTokenizationWithCCAP(paymentMethod);
  const tokenizationType = response?.tokenizationType
  return { cardTokenId: response?.cardToken, error: !!error, tokenizationType };
};

const handleCardTokenizationWithSDK = async (
  cardId,
  internalDeviceId
) => {
  const { error, response } = await createCardTokenWithEsc(cardId, internalDeviceId);
  const cardTokenId = response?.id;

  if (response?.esc) {
    setCookieWithJSON(`${COOKIES.ESC}${cardId}`, {esc: response.esc, internalDeviceId}, COOKIES.COOKIE_EXPIRES_ONE_YEAR);
  }

  const tokenizationType = response?.esc ? 'with_esc_legacy' : 'with_cvv_legacy';

  return { cardTokenId, error: !!error, tokenizationType };
};

/**
 * Handles card tokenization process for both CCAP and ESC flows
 * @param {Object} params Configuration object
 * @param {boolean} params.hasCCAPTokenizationFlagEnabled Whether CCAP tokenization is enabled
 * @param {Object} params.handleTokenizationWithCCAP Function to handle tokenization with CCAP
 * @param {Object} params.paymentMethod Payment method details
 * @param {string} params.cardId Card identifier
 * @param {string} params.internalDeviceId Device identifier
 * @param {Function} params.createCardTokenWithEsc Function to create card token with ESC
 * @param {Object} params.loadingActions Loading action creators
 * @param {Object} params.secureFieldActions Secure field action creators
 * @param {Object} params.translations Translation strings
 * @returns {Promise<{cardTokenId: string|null, error: boolean}>}
 */

const handleCardTokenization = async ({
  hasCCAPTokenizationFlagEnabled,
  handleTokenizationWithCCAP,
  paymentMethod,
  cardId,
  internalDeviceId
}) => {
  if (hasCCAPTokenizationFlagEnabled) {
    return handleCardTokenizationWithCCAP(
      handleTokenizationWithCCAP,
      paymentMethod
    );
  }

  return handleCardTokenizationWithSDK(
    cardId,
    internalDeviceId
  );
};

module.exports = handleCardTokenization;
