const React = require('react');
const { useState } = require('react');
const PropTypes = require('prop-types');

const { Title } = require('@andes/typography');
const EmailInputCard = require('@cow/core-components/components/EmailInputCard');

const BaseTemplate = require('./index');
const translate = require('../../../../translation');
const {
  CHECKOUT_CASE: { GUEST },
} = require('../../../../../constants/commons');

const {
  SIZES: { XS },
  COLORS: { PRIMARY },
  WEIGHT: { SEMI_BOLD }
} = require('../../../../../constants/andesStylesTheme');

const { NEW_EMAIL_INPUT_VALIDATIONS } = require('../../../../utils/regex');

const { INPUTS_ID } = require('../../../../../constants/ui');


// Utils
const {
  genericPropsTypes,
  newInterfaceGenericPropsTypes,
} = require('../../../../utils/propTypes');
const connectToReduxAndInjectI18n = require("../../../../utils/connectToRedux");

const CombinedPaymentReviewGuestDesktopTemplate = (props) => {

  const {
    step_model: {
      payer,
      errors,
    }
  } = props;

  const translations = translate(props.i18n);

  const [email, setEmail] = useState(payer?.email?.value ?? '');
  const [emailErrorMessage, setEmailErrorMessage] = useState(errors?.payer?.email ?? '');

  const emailErrors = NEW_EMAIL_INPUT_VALIDATIONS(translations);

  const handleEmailInput = (event) => {
    setEmail(event?.target?.value);
    setEmailErrorMessage('');
  };

  const handleEmailInputError = (data) => {
    setEmailErrorMessage(data?.message);
  };

  // Update emails message with flow response
  React.useEffect(() => {
    setEmailErrorMessage(errors?.payer?.email ?? '');
  }, [errors?.payer?.email]);

  return (
    <BaseTemplate
      templateCase={GUEST}
      email={email}
      setEmail={setEmail}
      setEmailErrorMessage={setEmailErrorMessage}
      emailErrorMessage={emailErrorMessage}
      emailComponent={
        payer?.email?.is_required && (
          <div className="guest-email">
            <Title component="h2"
                   size={XS}
                   weight={SEMI_BOLD}
                   color={PRIMARY}>
              {translations.RECEIVE_PAYMENT_DETAILS}
            </Title>
            <EmailInputCard
              id={INPUTS_ID.USER_EMAIL}
              icon={null}
              label={translations.EMAIL}
              helper={emailErrorMessage || translations.SEND_ONLY_PAYMENT_INFO_FOR_AVAILABILITY}
              value={email}
              onChange={handleEmailInput}
              onError={handleEmailInputError}
              style={emailErrorMessage ? 'error' : undefined}
              placeHolder={translations.PLACEHOLDER_EMAIL}
              validations={emailErrors}
            />
          </div>
        )
      }
      {...props}
    />
  );
};

CombinedPaymentReviewGuestDesktopTemplate.propTypes = {
  templateCase: PropTypes.string,
  step_model: PropTypes.shape({
    ...newInterfaceGenericPropsTypes,
  }).isRequired,
  ...genericPropsTypes,
};

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  flow: state.page.flow,
  firstRender: state.page.firstRender,
  basePath: state.configurations.basePath,
  captchaConfig: state.configurations.captcha,
});

module.exports = connectToReduxAndInjectI18n(
  CombinedPaymentReviewGuestDesktopTemplate,
  mapStateToProps
);

