const { useEffect, useCallback } = require('react');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const { FormProvider } = require('checkout-off-form-validation');
const { useI18n } = require('nordic/i18n');
const { Snackbar } = require('@andes/snackbar');

const stepActions = require('../../../spa/actions/step');
const { getQueryParams } = require('../../../utils/Dom');
const { STEP_NEXT } = require('../../../spa/actions/types');

const { AddressForm } = require('./AddressForm');
const translate = require('../../../translation');

const ShippingAddressForm = (props) => {
  const { i18n } = useI18n();
  const translations = translate(i18n);
  const { flow, history, stepAction, deviceType, countryId, data, configurations } = props;

  const SNACKBAR_DELAY = 3000;
  const hasError = data?.error_message !== '';
  const { rebrandingMP } = configurations;

  const formConfigs = {
    userAddress: {
      city: data?.city,
      state: data?.state,
      zip_code: data?.zip_code,
    },
    neighborhood: {
      required: data?.is_neighborhood_required,
      show: data?.show_neighborhood_input,
    },
    email: {
      show: data?.show_email_input,
    },
    betweenStreet: {
      show: data?.show_between_street_input,
    },
    floor: {
      show: data?.show_floor_input,
    },
    inputs: data,
  };

  const handleCreateAddress = (inputs) => {
    const urlParams = getQueryParams();
    const defaultData = {
      urlParams,
      type: flow.type,
    };
    const payload = {
      ...inputs,
      isNewInterface: true,
    };

    stepAction[STEP_NEXT](payload, flow.id, defaultData, flow.type, urlParams, history);
  };

  return (
    <div className="shipping-address-form">
      <h1 className="c-title">{translations.ENTER_YOUR_ADDRESS}</h1>
      <FormProvider>
        <AddressForm
          isInRebrand={rebrandingMP}
          formConfigs={formConfigs}
          countryId={countryId}
          deviceType={deviceType}
          handleCreateAddress={handleCreateAddress}
        />
      </FormProvider>

      {hasError && <Snackbar delay={SNACKBAR_DELAY} message={data.error_message} show />}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  flow: state.page.flow,
  configurations: state.configurations,
  countryId: state.configurations.platform.countryId,
});

const mapDispatchToProps = (dispatch) => ({
  stepAction: bindActionCreators(stepActions, dispatch),
});

module.exports = {
  ShippingAddressForm: connect(mapStateToProps, mapDispatchToProps)(ShippingAddressForm),
};
