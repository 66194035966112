const {goToNextStep} = require('../../../utils/nextStepAction');
const {pay: payUtil} = require('../../../utils/pay');
const uuidv4 = require('uuid/v4');
const {PAGE_ACTIONS} = require("../../../../constants/app");

const useActions = (
  {
    flow,
    history,
    stepActions,
    captcha,
    requestActions,
    captchaConfig = {},
    captchaToken = {},
    challengesInstructions,
    payer,
    loadingActions,
    setEmailErrorMessage,
    email,
    translations,
    emailErrorMessage,
  }) => {

  const requestId = uuidv4();

  const changePaymentMethod = (paymentMethod) => {
    const data = {
      id: paymentMethod,
      values: {},
    };
    return goToNextStep(
      {
        data,
        flow,
        history,
        stepActions,
        captcha,
        requestActions,
        requestId,
        captchaConfig,
        captchaToken,
      });
  };

  const pay = () => {
    payUtil({
      flow,
      stepActions,
      history,
      captcha,
      challengesInstructions,
      payer,
      loadingActions,
      setEmailErrorMessage,
      email,
      translations,
      emailErrorMessage,
      requestActions,
      requestId,
      captchaConfig,
      captchaToken,
    });
  };

  const changeShippingOption = () => {
    const data = {
      id: PAGE_ACTIONS.CHANGE_SHIPPING_OPTION,
      values: {},
    };
    return goToNextStep({
      data,
      flow,
      history,
      stepActions,
      captcha,
      requestActions,
      requestId,
      captchaConfig,
      captchaToken,
    });
  };

  const changeShippingAddress = () => {
    const data = {
      id: PAGE_ACTIONS.CHANGE_SHIPPING_ADDRESS,
      values: {},
    };
    return goToNextStep({
      data,
      flow,
      history,
      stepActions,
      captcha,
      requestActions,
      requestId,
      captchaConfig,
      captchaToken,
    });
  };

  return {
    changePaymentMethod,
    pay,
    changeShippingOption,
    changeShippingAddress,
  };
};

module.exports = useActions;

