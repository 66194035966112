const { useCallback } = require('react');
const { Card } = require('@andes/card');
const { List, ListItem } = require('@andes/list');
const { RadioList, RadioListItem } = require('@andes/radio-list');
const { Typography } = require('@andes/typography');
const { useI18n } = require('nordic/i18n');
const translate = require('../../../translation');

const ShippingLocalPickup = ({ onSelectOption, isDesktop, selectedShippingOptionId }) => {
  const { i18n } = useI18n();
  const translations = translate(i18n);

  const renderTitle = useCallback(() => translations.PICK_UP_AT_SELLER_S_STORE, []);

  return (
    <>
      <Typography
        weight={isDesktop && 'semibold'}
        size={isDesktop ? 'l' : 'm'}
        color={isDesktop ? 'secondary' : 'primary'}
        className={isDesktop ? 'shipping-option-title' : ''}
      >
        {translations.WITHDRAWAL}
      </Typography>

      <Card>
        {isDesktop ? (
          <RadioList srLabel="" value={selectedShippingOptionId} onChange={(_, value) => onSelectOption(value)}>
            <RadioListItem className="shipping-option-radio" title={renderTitle()} value="local_pickup_row" />
          </RadioList>
        ) : (
          <List srLabel="">
            <ListItem
              className="shipping-option"
              title={renderTitle()}
              chevron
              onClick={() => onSelectOption('local_pickup_row')}
            />
          </List>
        )}
      </Card>
    </>
  );
};

module.exports = {
  ShippingLocalPickup,
};
