export const ACTIONS = {
  SELECTED_PAYMENT_OPTION: 'selected_payment_option',
  OPTION_OPEN_FINANCE_BANK: 'option_open_finance_bank',
  SAVED_CARD: 'saved_card',
  SWITCH_COMBINATION: 'switch_combination',
};

export const MESSAGE_LOCATIONS = {
  above: 'above',
  below: 'below',
};

export const PAYMENT_METHOD_STATUS = {
  ACTIVE: 'active',
  DISABLED_BY_TAX_AMOUNT: 'disabled_by_tax_amount',
};

export const SECTIONS_TYPES = {
  LOGIN_SECTION: 'login_section',
  GUEST_SECTION: 'guest_section',
};

export const PAYMENT_METHOD_ID = {
  CLABE: 'clabe'
};
