const React = require('react');
const PropTypes = require('prop-types');

const { Thumbnail } = require('@andes/thumbnail');

const UserAvatar = (props) => (
  <div className="user-avatar">
    <Thumbnail size='32'>
      <img alt="avatar" src={props.image} />
    </Thumbnail>
  </div>
);

UserAvatar.propTypes = {
  image: PropTypes.string,
};

UserAvatar.defaultProps = {
  image: '', // TODO: get default image URL
};

module.exports = UserAvatar;
