const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');
const CardList = require('@cow/core-components/components/CardListV2');
const { Switch } = require('@andes/switch');
const { Text } = require('@andes/typography');

const PaymentOptionPropTypes = require('../../templatePropTypes');
const translate = require('../../../../../translation');
const { PAYMENT_METHOD_STATUS } = require('../../constants/constant');
const { transformIssuer } = require('../../../../../utils/Strings');

const CardListLoggedSection = (props) => {
  const [toggleCombination, setToggleCombination] = React.useState(false);
  const translations = translate(props.i18n);

  const goToCombination = React.useCallback(
    (checked) => {
      setToggleCombination(checked);
      props.goToCombinationScreen();
    },
    [props.goToCombinationScreen],
  );

  const rows = React.useMemo(() => {
    if (!props?.rows?.length) {
      return [];
    }

    const getMethodName = (card) => {
      if (card.method === 'visa' && card.issuer?.name === 'citibanamex') {
        return 'citibanamex';
      }

      return card.method;
    };

    return props.rows.map((card) => {
      const isMercadoPagoCard = card?.title.includes(translations.MP);
      const title = isMercadoPagoCard ? card?.title.split('\n')[0] : card?.title;
      const description = isMercadoPagoCard ? <Text size="m">{card?.title.split('\n')[1]}</Text> : card?.description;

      const row = {
        ...card,
        title,
        description,
        method: getMethodName(card),
        issuedBy: transformIssuer(card?.issuer?.name),
        withChevron: true,
        onClick: () => props.goToScreen(card),
      };

      return row;
    });
  }, [props.rows, props.goToScreen]);

  return (
    <>
      {props.hasCombination && (
        <CardList
          idList="combination-row"
          selectable={false}
          deviceType={props.deviceType}
          items={[
            {
              // TODO this is hardcoded on the cow core component, DON'T MODIFY until we add types for this component
              id: 'combination-row',
              title: <span aria-hidden>{props.hasCombination.title}</span>,
              rightContent: (
                <Switch checked={toggleCombination} onChange={goToCombination} srLabel={props.hasCombination.title} />
              ),
            },
          ]}
        />
      )}

      {!!props.paymentByAccountMoney &&
        props.paymentByAccountMoney.status !== PAYMENT_METHOD_STATUS.DISABLED_BY_TAX_AMOUNT && (
          <CardList
            idList="balance"
            bottomContentList={props.paymentByAccountMoney.message}
            deviceType={props.deviceType}
            items={[
              {
                ...props.paymentByAccountMoney,
                withChevron: true,
                onClick: () => props.goToScreen(props.paymentByAccountMoney),
              },
            ]}
          />
        )}

      {!!rows.length && (
        <CardList
          idList="payment-methods"
          maxItemsToDisplay={props.moreThanTwoCards ? 2 : Infinity}
          showMoreItemsLabel={props.moreThanTwoCards ? translations.SHOW_MORE_SAVED_CARDS : ''}
          withDividers
          deviceType={props.deviceType}
          items={rows}
        />
      )}

      {!!props.creditsAndOpenFinance.length && (
        <CardList
          idList="credits-and-opfinance-options"
          withDividers
          deviceType={props.deviceType}
          items={props.creditsAndOpenFinance.map((card) => ({
            ...card,
            withChevron: true,
            method: card.bank_logo || card.method,
            onClick: () => props.goToScreen(card),
          }))}
        />
      )}

      {!!props.otherPaymentMethods.length && (
        <CardList
          idList="other-options"
          withDividers
          deviceType={props.deviceType}
          items={props.otherPaymentMethods.map((paymentMethod) => ({
            ...paymentMethod,
            withChevron: true,
            onClick: () => {
              if (props.isTheOptionToPayWithCash(paymentMethod)) {
                props.showPayWithCashSection(paymentMethod.id);

                return;
              }

              props.goToScreen(paymentMethod);
            },
          }))}
        />
      )}
    </>
  );
};

CardListLoggedSection.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  hasCombination: PaymentOptionPropTypes.frontendData.combination,
  paymentByAccountMoney: PaymentOptionPropTypes.frontendData.paymentByAccountMoney,
  rows: PaymentOptionPropTypes.frontendData.rows,
  moreThanTwoCards: PaymentOptionPropTypes.frontendData.moreThanTwoCards,
  creditsAndOpenFinance: PaymentOptionPropTypes.frontendData.creditsAndOpenFinance,
  otherPaymentMethods: PaymentOptionPropTypes.frontendData.otherPaymentMethods,
  goToScreen: PropTypes.func.isRequired,
  deviceType: PropTypes.string.isRequired,
  showPayWithCashSection: PropTypes.func.isRequired,
  isTheOptionToPayWithCash: PropTypes.func.isRequired,
  siteId: PropTypes.string.isRequired,
};

module.exports = injectI18n(CardListLoggedSection);
