const React = require("react");
const PropTypes = require("prop-types");

const PaymentDetailRow = require("../../../components/PaymentDetailRow");

const {
  COMMONS: {
    DEVICE_TYPE: {DESKTOP},
    TOOLTIP_TYPE: {DARK},
    ANDES_PLACEMENT: {RIGHT, TOP_LEFT}
  },
  APP: {ICON: {INFO}},
} = require('../../../../constants');

const PaymentRow = ({translations, deviceType, value = ""}) => (
  <PaymentDetailRow
    text={translations.REMAINING_VALUE}
    deviceType={deviceType}
    tooltipText={translations.DEFINED_ACCORDING_TO_THE_AMOUNT_YOU_CHOSE_TO_PAY_WITH_THE_FIRST_MEANS}
    tooltipSide={deviceType === DESKTOP ? RIGHT : TOP_LEFT}
    tooltipIcon={INFO}
    tooltipType={DARK}
    showTooltip
    value={value}
  />
);

PaymentRow.propTypes = {
  translations: PropTypes.object,
  deviceType: PropTypes.string,
  value: PropTypes.string,
};

module.exports = PaymentRow;
