const { useCallback, useState } = require('react');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const { Button } = require('@andes/button');
const { useI18n } = require('nordic/i18n');

const stepActions = require('../../../spa/actions/step');
const { getQueryParams } = require('../../../utils/Dom');
const { STEP_NEXT } = require('../../../spa/actions/types');

const { Address } = require('./Address');
const { ShippingCost } = require('./ShippingCost');
const { DEVICE_TYPE } = require('../../../../constants/commons');
const translate = require('../../../translation');

const ShippingOptions = (props) => {
  const { flow, data, history, stepAction, configurations, deviceType, configs } = props;
  const { zipcode_text, shipping_option } = data ?? {};
  const { options } = shipping_option ?? {};
  const { currency } = configurations ?? {};
  const isDesktop = deviceType === DEVICE_TYPE.DESKTOP;

  const { i18n } = useI18n();
  const translations = translate(i18n);
  const [selectedShippingOptionId, setSelectedShippingOptionId] = useState(() =>
    options.length ? options[0].id : undefined,
  );

  const handleNextStep = useCallback(
    (id) => {
      const urlParams = getQueryParams();

      const defaultData = {
        urlParams,
        type: flow?.type,
      };

      const payload = {
        isNewInterface: true,
        selected_shipping_id: String(id),
      };

      stepAction[STEP_NEXT](payload, flow.id, defaultData, flow.type, urlParams, history);
    },
    [flow, history],
  );

  const onSelectOption = (id) => {
    (isDesktop ? setSelectedShippingOptionId : handleNextStep)(id);
  };

  return (
    <div className="shipping-options-container">
      <h1 className="c-title">{translations.CHOOSE_WHEN_TO_RECEIVE_THE_ORDER}</h1>
      <div className="shipping-content">
        <Address
          zipcode_text={zipcode_text}
          onSelectOption={handleNextStep}
          deviceType={deviceType}
          isRebrand={configs?.rebrandingMP}
        />

        <ShippingCost
          options={options}
          currency={currency}
          onSelectOption={onSelectOption}
          isDesktop={isDesktop}
          selectedShippingOptionId={selectedShippingOptionId}
        />

        <div className="shipping-footer">
          <Button hierarchy="quiet" fullWidth={!isDesktop} onClick={() => window.history.back()}>
            {translations.TO_GO_BACK}
          </Button>

          {isDesktop ? (
            <Button onClick={() => handleNextStep(selectedShippingOptionId)}>{translations.CONTINUE}</Button>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  flow: state.page.flow,
  configurations: state.configurations,
  configs: state.configurations,
});

const mapDispatchToProps = (dispatch) => ({
  stepAction: bindActionCreators(stepActions, dispatch),
});

module.exports = {
  ShippingOptions: connect(mapStateToProps, mapDispatchToProps)(ShippingOptions),
};
