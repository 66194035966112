const React = require('react');
const { InternalProviderBricks } = require('internal-checkout-bricks');
const {
  SmartTokenizationProvider: SmartTokenizationProviderBricks,
} = require('internal-checkout-bricks/lib/smartTokenization/client');
const { SmartTokenizationProvider } = require('../../SmartTokenizationProvider');
const PropTypes = require('prop-types');
const { getMelisessionId } = require('../../../utils/meliSessionIdUtil');

function withSmartTokenizationProvider(Component) {
  const WithSmartTokenizationProvider = (props) => {
    const meliSessionId = getMelisessionId(props.initialStore?.configurations) || props?.siteSecurity?.session_id;
    if (!props?.ccapTokenizationFlagEnabled || !meliSessionId) return <Component {...props} />;

    return (
      <InternalProviderBricks
        internalBricksData={props.internalBricksData}
        internalBricksIntegrationData={{
          meliSessionId,
        }}
      >
        <SmartTokenizationProviderBricks>
          <SmartTokenizationProvider {...props} meliSessionId={meliSessionId}>
            <Component {...props} />
          </SmartTokenizationProvider>
        </SmartTokenizationProviderBricks>
      </InternalProviderBricks>
    );
  };

  WithSmartTokenizationProvider.propTypes = {
    internalBricksData: PropTypes.shape({}),
    initialStore: PropTypes.shape({}),
  };

  return WithSmartTokenizationProvider;
}

module.exports = withSmartTokenizationProvider;
