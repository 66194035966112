const React = require('react');
const PropTypes = require('prop-types');

const {Title, Typography} = require('@andes/typography');
const classnames = require('classnames');

const {ANDES_STYLES_THEME} = require('../../../constants');
const {hasBrand} = require('../../utils/summary');


const Mobile = (props) => {

  const {
    mainContent,
    footerContent,
    sidebarContent,
    modalsContent,
    navbarComponent,
    brandComponent,
    brand,
    isPaymentSummaryCollapsible,
    stepTitle,
    payer,
    stepSubtitle,
  } = props;


  return (
    <>
      <div className="layout layout--new-interface">
        {modalsContent}
        {navbarComponent}

        {brandComponent}

        {
          sidebarContent &&
          <section className="layout__col-sidebar">
            {sidebarContent}
          </section>
        }

        <section className={
          classnames(
            'layout__main-content',
            payer.is_guest ? 'user-guest' : 'user-logged',
            {
              'cow-brand-content': hasBrand(brand),
              'is-payment-summary-static': isPaymentSummaryCollapsible,
            },
          )}
        >
          {
            stepSubtitle &&
            <Typography
              className="layout__title"
              component={stepSubtitle?.component || 'h3'}
              size={stepSubtitle?.size || ANDES_STYLES_THEME.SIZES.S}
              weight={stepSubtitle?.weight || ANDES_STYLES_THEME.WEIGHT.REGULAR}
              color={stepSubtitle?.color}
            >
              {stepSubtitle?.text}
            </Typography>
          }
          <Title
            className="layout__title"
            component={stepTitle?.component || 'h1'}
            size={stepTitle?.size || ANDES_STYLES_THEME.SIZES.S}
            weight={stepTitle?.weight || ANDES_STYLES_THEME.WEIGHT.REGULAR}
          >
            {stepTitle?.text}
          </Title>
          {mainContent}
        </section>
        {footerContent}
      </div>
    </>
  );
};

Mobile.propTypes = {
  mainContent: PropTypes.node,
  footerContent: PropTypes.node,
  sidebarContent: PropTypes.node,
  modalsContent: PropTypes.node,
  navbarComponent: PropTypes.node,
  brandComponent: PropTypes.node,
  brand: PropTypes.bool,
  isPaymentSummaryCollapsible: PropTypes.bool,
  stepTitle: PropTypes.shape({
    text: PropTypes.string,
    color: PropTypes.string,
    weight: PropTypes.string,
    size: PropTypes.string,
    component: PropTypes.string,
  }),
  stepSubtitle: PropTypes.shape({
    text: PropTypes.string,
    color: PropTypes.string,
    weight: PropTypes.string,
    size: PropTypes.string,
    component: PropTypes.string,
  }),
  payer: PropTypes.object,
};


module.exports = Mobile;
