const React = require('react');
const PropTypes = require('prop-types');

const { Text } = require('@andes/typography');

const Step = ({ stepNumber, message }) => (
  <div className="sniffing-scan-qr__qr-container__steps__step">
    <Text size="m" weight="semibold" className="sniffing-scan-qr__qr-container__steps__step__number">
      {stepNumber}.
    </Text>

    <Text size="m">{message}</Text>
  </div>
);

Step.propTypes = {
  stepNumber: PropTypes.number,
  message: PropTypes.string,
};

module.exports = Step;
