/* global window */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
const React = require('react');
const { Button: AndesButton } = require('@andes/button');
const { Text } = require('@andes/typography');

const { defaultProps } = require('../helper');
const Button = require('../../Button/ButtonWithStore');
const ButtonStep = require('../../../components/ButtonStep');
const ButtonPopup = require('../../../components/ButtonPopup');
const ButtonForm = require('../../../components/ButtonForm');
const ButtonCopy = require('../../../components/ButtonCopy');
const LogoutButton = require('../../../components/LogoutButton');
const IconChevronBack = require('../../../components/icons/BlueChevron');
const LogoutRowButton = require('../../../components/LogoutRow');
const ButtonCloseModalLink = require('../../../components/ButtonCloseModalLink');
const ToggleButton = require('../../../components/ToggleButton');
const ButtonBackArrow = require('../../../components/ButtonBackArrow');
const ButtonTriggerAction = require('../../../components/ButtonTriggerAction');
const ButtonOpenModal = require('../../../components/ButtonOpenModal');
const ButtonExternalLink = require('../../../components/ButtonExternalLink');
const ButtonMoveStep = require('../../../components/ButtonMoveStep');
const ButtonCloseModal = require('../../../components/ButtonCloseModal');
const ButtonBackURLs = require('../../../components/ButtonBackURLs');
const { replaceChar } = require('../../../utils/Strings');

const {
  COMMONS: { BUTTON_HIERARCHY: HIERARCHY },
} = require('../../../../constants');

module.exports = {
  button: (props) => (
    <Button {...defaultProps()} id={props.id} name={props.name} text={props.label} icon={props.icon} />
  ),
  button_secondary: (props) => (
    <Button
      {...defaultProps()}
      type="submit"
      name={props.name}
      text={props.label}
      kind={HIERARCHY.TRANSPARENT}
      blockLoading
    />
  ),
  button_tertiary: (props) => (
    <Button
      {...defaultProps()}
      type="submit"
      name={props.name}
      text={props.label}
      kind={HIERARCHY.QUIET}
      blockLoading
    />
  ),
  button_back: (props) => (
    <Button
      {...defaultProps()}
      name={props.name}
      text={props.label}
      kind={HIERARCHY.QUIET}
      blockLoading
      onClick={(event) => {
        event.preventDefault();
        window.history.back();
      }}
    />
  ),
  button_back_to_option: (props) => (
    <Button
      {...defaultProps()}
      id={props.id}
      name={props.name}
      text={props.label}
      icon={props.icon}
      kind={HIERARCHY.QUIET}
    />
  ),
  button_back_default: (props) => (
    <Button
      {...defaultProps()}
      name={props.name}
      text={props.label}
      kind={HIERARCHY.QUIET}
      blockLoading
      trackEvent={props.track_event}
      isDefault
      onClick={(event) => {
        event.preventDefault();
        window.history.back();
      }}
    />
  ),
  button_back_chevron: () => (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <button
      id="button_back_chevron"
      type="button"
      className="andes-button--link"
      aria-label="back"
      onClick={(event) => {
        event.preventDefault();
        window.history.back();
      }}
    >
      <IconChevronBack />
    </button>
  ),
  button_back_arrow: () => (
    <ButtonBackArrow
      kind="link"
      onClick={(event) => {
        event.preventDefault();
        window.history.back();
      }}
    />
  ),
  button_back_arrow_form: (props) => <ButtonBackArrow kind="form" renderForm name={props.name} />,
  button_sub_step: (props) => (
    <Button
      {...defaultProps()}
      kind={HIERARCHY.LOUD}
      name={props.name}
      text={props.label}
      onClick={(event) => {
        event.preventDefault();
        if (props.next_page) {
          location.hash = `#${props.next_page}`; // eslint-disable-line
        }
      }}
    />
  ),
  button_link_sub_step: (props) => (
    <ButtonExternalLink
      {...defaultProps()}
      id={props.id}
      data={props.data}
      label={props.label}
      trackEvent={props.track_event}
      url={props.value}
      type="link"
      isRebranding={props.isRebranding}
    />
  ),
  button_link: (props) => (
    <Button {...defaultProps()} name={props.name} text={props.label} kind={HIERARCHY.LINK} blockLoading />
  ),
  button_link_quiet: (props) => (
    <Button {...defaultProps()} name={props.name} text={props.label} kind={HIERARCHY.QUIET} blockLoading />
  ),
  button_logout: (props) => (
    <LogoutButton
      {...defaultProps()}
      className="button-step"
      history={props.history}
      text={props.label}
      changeUser={props.change_user}
      internalConfigurations={props.internalConfigurations}
      platform={props.platform}
    />
  ),
  button_step: (props) => <ButtonStep {...defaultProps()} value={props.value} label={props.label} />,
  button_step_tertiary: (props) => (
    <ButtonStep {...defaultProps()} value={props.value} label={props.label} kind={HIERARCHY.QUIET} blockLoading />
  ),
  button_step_back: (props) => (
    <Button
      {...defaultProps()}
      id="button_step_back"
      type="button"
      text={props.label}
      kind={HIERARCHY.TRANSPARENT}
      onClick={(event) => {
        event.preventDefault();
        window.history.back();
      }}
    />
  ),
  button_step_popup: (props) => (
    <ButtonPopup {...defaultProps()} id="button_popup" text={props.label} kind={HIERARCHY.LOUD} />
  ),
  button_step_primary: (props) => (
    <ButtonStep {...defaultProps()} value={props.value} label={props.label} kind={HIERARCHY.LOUD} />
  ),
  external_link: (props) => (
    <ButtonBackURLs
      label={props.label}
      url={props.value}
    />
  ),
  button_close_link: (props) => (
    <Button {...defaultProps()} kind={HIERARCHY.LINK} name={props.name} text={props.label} onClick={props.onClick} />
  ),
  button_track: (props) => (
    <Button
      {...defaultProps()}
      blockLoading
      id={props.id}
      name={props.name}
      text={props.label}
      icon={props.icon}
      kind={props.kind}
      trackEvent={props.track_event}
    />
  ),
  link_track: (props) => (
    <a
      {...defaultProps()}
      onClick={(e) => {
        if (props.data.category) {
          e.preventDefault();
          window.open(props.value, props.data.target);
        }
      }}
      href={props.value}
      target={props.data && props.data.target ? props.data.target : '_self'}
      className={replaceChar(props.id, /_/g, '-')}
    >
      {props.label}
    </a>
  ),
  link: (props) =>
    props.isRebranding ? (
      <Text
        className="cow-link"
        size="s"
        color="link"
        component="a"
        href={props.value}
        weight="semibold"
        target={props.data && props.data.target ? props.data.target : '_self'}
      >
        {props.label}
      </Text>
    ) : (
      <a
        {...defaultProps()}
        href={props.value}
        target={props.data && props.data.target ? props.data.target : '_self'}
        className={replaceChar(props.id, /_/g, '-')}
      >
        {props.label}
      </a>
    ),
  button_external_link: (props) => (
    <ButtonExternalLink
      {...defaultProps()}
      id={props.id}
      data={props.data}
      label={props.label}
      trackEvent={props.track_event}
      url={props.value}
      isRebranding={props.isRebranding}
    />
  ),

  external_link_quiet: (props) => (
    <ButtonExternalLink
      {...defaultProps()}
      id={props.id}
      data={props.data}
      label={props.label}
      trackEvent={props.track_event}
      url={props.value}
      type="quiet"
      isRebranding={props.isRebranding}
    />
  ),
  button_close: (props) => <Button {...defaultProps()} name={props.name} text={props.label} onClick={props.onClick} />,
  link_copy: (props) => (
    <ButtonCopy
      {...defaultProps(props)}
      value={props.value}
      label={props.label}
      description={props.data.label}
      success={props.data.success}
      error={props.data.error}
      delay={props.data.delay}
      kind={props.kind || HIERARCHY.LINK}
      instructions={props.instructions}
      deviceType={props.deviceType}
      isRebranding={props.isRebranding}
    />
  ),
  button_form: (props) => (
    <ButtonForm
      {...defaultProps()}
      id={props.id}
      name={props.name}
      text={props.label}
      target={props.target}
      disabled={props.disabled}
      icon={props.icon}
    />
  ),
  logout_row_button: (props) => (
    <LogoutRowButton text={props.text} userName={props.user_name} imageURL={props.image_url} />
  ),
  button_close_modal: (props) => (
    <ButtonCloseModal {...defaultProps()} id={props.id} kind={props.kind} name={props.name} text={props.label} />
  ),
  button_close_modal_link: (props) => (
    <ButtonCloseModalLink
      {...defaultProps()}
      id={props.id}
      kind={HIERARCHY.TRANSPARENT}
      name={props.name}
      text={props.label}
    />
  ),
  toggle_button: (props) => <ToggleButton text={props.text} targets={props.targets} />,
  button_trigger_action: (props) => (
    <ButtonTriggerAction {...defaultProps()} id={props.id} kind={props.kind} name={props.name} text={props.label} />
  ),
  button_open_modal: (props) => (
    <ButtonOpenModal {...defaultProps()} id={props.id} kind={props.kind} label={props.label} blockLoading {...props} />
  ),
  button_move_step: (props) => (
    <ButtonMoveStep
      {...defaultProps()}
      id={props.id}
      type="button"
      kind={props.kind}
      name={props.name}
      text={props.label}
      disabled={props.disabled}
      history={props.history}
      value={props.value}
      blockLoading={props.block_loading}
    />
  ),
  button_accept_modal: (props) => (
    <Button {...defaultProps()} id={props.id} name={props.name} text={props.label} icon={props.icon} blockLoading />
  ),
  button_icon_copy: (props) => (
    <ButtonCopy value={props?.value} success={props?.data?.success} error={props?.data?.error} kind="icon" />
  ),
  button_andes_form: (props) => (
    <AndesButton id={props.id} form={props.form} type="submit">
      {props.text}
    </AndesButton>
  ),
};
