/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const { Thumbnail } = require('@andes/thumbnail');

const IconWrapper = require('../IconWrapper');

const IconsInstallments = ({ text, size }) => (
  <IconWrapper>
    <Thumbnail className={'icon-installments'} size={size}>
      <p>{text}</p>
    </Thumbnail>
  </IconWrapper>
);

IconsInstallments.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.string,
};

IconsInstallments.defaultProps = {
  className: '',
  text: '',
  size: 40,
};

module.exports = IconsInstallments;
