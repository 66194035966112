const React = require('react');

const IconWrapper = require('../IconWrapper');
const { LOGOS_API_ADMIN_BASE_URL } = require('../../../constants/logos');
const { Image } = require('nordic/image');

const account_money_url = `${LOGOS_API_ADMIN_BASE_URL}/5ec6cce0-0401-11ef-abe6-ef0735c1c9af-l.svg`;
const AccountMoneyYellow = require('@cow/core-components/components/Icons/AccountMoneyYellow');

const IconAccountMoney = ({ title, isRebranding }) => (
  <IconWrapper>
    {isRebranding ? (
      <AccountMoneyYellow className="icon-account-money-yellow" />
    ) : (
      <Image
        lazyload="off"
        width={'auto'}
        height={'16.36px'}
        src={account_money_url}
        alt={title}
        id="account-money-white-logo"
      />
    )}
  </IconWrapper>
);

module.exports = React.memo(IconAccountMoney);
