const { APP: { PAGE_ACTIONS }, COMMONS } = require('../../constants');
const { goToNextStep } = require('./nextStepAction');
const { INPUTS_ID } = require('../../constants/ui');
const { LOADER_TRIGGERED, LOADER_PAY_BUTTON } = require('../spa/actions/types');

export const pay = (
  {
    flow,
    stepActions,
    history,
    captcha,
    challengesInstructions,
    payer,
    loadingActions,
    setEmailErrorMessage,
    email,
    translations,
    emailErrorMessage,
    requestActions,
    requestId,
    captchaConfig,
    captchaToken,
  }) => {
  // KyC validation
  if (challengesInstructions?.kyc && Object.keys(challengesInstructions?.kyc).length) {
    return;
  }
  // Email validation
  if (payer?.is_guest) {
    if (!email || email?.length === 0) {
      setEmailErrorMessage(translations.COMPLETE_THIS_DATA);
      // Accessibility - Set focus to the email input
      document.getElementById(INPUTS_ID.USER_EMAIL)?.focus();
      return;
    }

    if (emailErrorMessage?.length > 0) {
      return;
    }
  }
  // Show Loader Wrapper animation
  loadingActions[LOADER_TRIGGERED](PAGE_ACTIONS.EMPTY, PAGE_ACTIONS.PAY);
  // Start Pay Button Loading Animation
  loadingActions[LOADER_PAY_BUTTON](true);

  // New Response
  const data = {
    id: PAGE_ACTIONS.PAY,
    values: {
      payer: {
        email,
      },
    },
  };
  goToNextStep({
    data,
    flow,
    history,
    stepActions,
    captcha,
    requestActions,
    requestId,
    captchaConfig,
    captchaToken,
  });
};
