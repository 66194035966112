const React = require('react');
const PropTypes = require('prop-types');

const { ProgressIndicatorCircular } = require('@andes/progress-indicator-circular');

const { DEVICE_TYPE } = require("../../../constants/commons");

const Processing = ({ modifier, deviceType }) => (
  <ProgressIndicatorCircular size={deviceType === DEVICE_TYPE.MOBILE ? "large" : "xlarge"} modifier={modifier} className='cow-loader' />
);

Processing.propTypes = {
  modifier: PropTypes.oneOf(['inline', 'block', 'fullscreen']),
  deviceType: PropTypes.string,
};

Processing.defaultProps = {
  modifier: 'fullscreen',
  deviceType: DEVICE_TYPE.DESKTOP,
};

module.exports = Processing;
