const React = require('react');

const { useI18n } = require('nordic/i18n');

const { Card, CardContent } = require('@andes/card');
const { List, ListItem } = require('@andes/list');
const translate = require('../../../translation');

const ShippingAddressSelectorMobile = ({ addresses, handleSelectAddress }) => {
  const { i18n } = useI18n();
  const translations = translate(i18n);

  return (
    <div className="address-selector-mobile">
      <Card paddingSize={12}>
        <CardContent>
          <List srLabel="Addresses" withDividers>
            {addresses.map((address, index) => (
              <ListItem
                key={index}
                value={address.id}
                title={address.title}
                description={address.description}
                onClick={() => handleSelectAddress(address.id)}
                chevron
              />
            ))}
            <ListItem title={translations.ADD_NEW_ADDRESS} onClick={() => handleSelectAddress('add_address')} chevron />
          </List>
        </CardContent>
      </Card>
    </div>
  );
};

module.exports = {
  ShippingAddressSelectorMobile,
};
