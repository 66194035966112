const React = require('react');
const { injectI18n } = require('nordic/i18n');
const { Title } = require('@andes/typography');
const Navbar = require('@cow/core-components/components/NavbarV2');
const ButtonBackURLs = require('../../../../components/ButtonBackURLs');
const CardListLoggedSection = require('../components/CardListLoggedSection');
const PaidWithCashSection = require('../components/PaidWithCashSection');
const PaymentOptionPropTypes = require('../templatePropTypes');
const Page = require('../../../../components/Page');
const Optimus = require('../../../../containers/Optimus');
const MessageAOPO = require('../../../../components/MessageAOPO');
const useHeightElement = require('../../../../hooks/useHeightElement');

const { CoachMarks } = require('@andes/coach-marks');
const translate = require('../../../../translation');
const { useNavbar } = require('../../../../hooks/useNavbar');

const {
  COMMONS: {
    DEVICE_TYPE: { MOBILE },
  },
  SELECTORS
} = require('../../../../../constants');
const { MESSAGE_LOCATIONS } = require('../constants/constant');
const Footer = require('@cow/core-components/components/Footer');
const classNames = require('classnames');

const PaymentOptionsMobileLogged = (props) => {
  const translations = translate(props.i18n);
  const paddingContent = useHeightElement();
  const { showNavbar } = useNavbar(props.flow, !props.userLogged, props.configs?.isWebview);
  const notNavbar = !showNavbar ? SELECTORS.NOT_NAVBAR : SELECTORS.LOGGED;

  const aboveMessage = props.frontendData.regulations?.find(regulation => regulation?.value?.location === MESSAGE_LOCATIONS.above);
  const belowMessage = props.frontendData.regulations?.find(regulation => regulation?.value?.location === MESSAGE_LOCATIONS.below);

  return (
    <Page
      title={props.frontendData.pageTitle}
      currentStep={props.currentStep}
      urls={props.urls}
      trackingPath={props.trackingPath}
      analytics={props.analytics}
      deviceType={props.deviceType}
    >
      <CoachMarks
        run={props.frontendData.showCoachMark}
        disableScrolling
        steps={props.frontendData.stepsCoachMark}
        locale={{
          last: translations.I_UNDERSTAND,
        }}
        onChange={props.frontendData.onChangeCoachMark}
        isMobile={props.deviceType === MOBILE}
      />

      {showNavbar && <Navbar {...props.frontendData.navbar} />}

      <section className={`payment-options-mobile-screen payment-options-mobile-screen${notNavbar}`}>
        <Optimus
          components={props.frontendData.summary}
          history={props.history}
          siteId={props.siteId}
          urls={props.urls}
          publicKey={props.publicKey}
          deviceType={props.deviceType}
          captcha={props.captcha}
          hiddenComponents={props.hidden_components}
        />

        <div className="payment-options-mobile-screen__content" style={{ paddingTop: paddingContent }}>
          <Title component="h1" size="s" className="payment-options-mobile-screen__content-title">
            {props.frontendData.pageTitle}
          </Title>

          {aboveMessage &&
            <MessageAOPO
              title={aboveMessage.value.title}
              message={aboveMessage.value.text}
              closable={aboveMessage.value.closable}
              location={aboveMessage.value.location}
              color={aboveMessage.value.message_type}
            />
          }

          <div
            className={classNames('payment-options-mobile-screen__content-card-list', {
              [SELECTORS.REBRANDING]: props.configs?.rebrandingMP,
            })}
          >
            {/* for now isSplitPayment  is always false  */}
            {!props.frontendData.showPayWithCashScreen && (
              <CardListLoggedSection
                i18n={props.i18n}
                hasCombination={props.frontendData.combination}
                paymentByAccountMoney={props.frontendData.paymentByAccountMoney}
                rows={props.frontendData.rows}
                moreThanTwoCards={props.frontendData.moreThanTwoCards}
                creditsAndOpenFinance={props.frontendData.creditsAndOpenFinance}
                otherPaymentMethods={props.frontendData.otherPaymentMethods}
                goToScreen={props.frontendData.goToScreen}
                isSplitPayment={false}
                goToCombinationScreen={props.frontendData.goToCombinationScreen}
                deviceType={props.deviceType}
                showPayWithCashSection={props.frontendData.showPayWithCash}
                isTheOptionToPayWithCash={props.frontendData.isTheOptionToPayWithCash}
              />
            )}

            {props.frontendData.showPayWithCashScreen && (
              <PaidWithCashSection
                {...props.frontendData.payWithCash[props.frontendData.showPayWithCashScreen]}
                deviceType={props.deviceType}
                onClickMethod={props.frontendData.goToStorePage}
                onNavigationBack={props.frontendData.hidePayWithCash}
              />
            )}

            {belowMessage &&
              <MessageAOPO
                title={belowMessage.value.title}
                message={belowMessage.value.text}
                closable={belowMessage.value.closable}
                location={belowMessage.value.location}
                color={belowMessage.value.message_type}
              />
            }

            {/* TODO USE BACK BUTTON FROM LIBRARY COMPONENT */}
            {props.frontendData.backButton && (
              <ButtonBackURLs label={props.frontendData.backButton?.title} url={props.frontendData.backButton?.url} />
            )}
          </div>
          {
            !props.frontendData.showPayWithCashScreen &&
            <div className="payment-options-mobile-screen__content-footer">
              <Footer
                requiredMessage={props.frontendData.footer.requiredMessage}
                requiredScreenReaderMessage={props.frontendData.footer.requiredScreenReaderMessage}
                deviceType={props.deviceType}
              />
            </div>
          }
        </div>
      </section>
    </Page>
  );
};

PaymentOptionsMobileLogged.propTypes = PaymentOptionPropTypes;

module.exports = injectI18n(PaymentOptionsMobileLogged);
