/* global MercadoPagoSDK */
const { secureFieldsInstance } = require('../../../../../utils/secure-fields');
const {
  APP: {
    SECURE_FIELDS: { EVENTS, FIELDS },
  },
  COMMONS: {
    PAYMENT_METHOD: { CREDIT_CARD, DEBIT_CARD, PREPAID_CARD },
    PLACEHOLDER: { CVV_THREE_DIGITS, CVV_FOUR_DIGITS },
  },
} = require('../../../../../../constants');

const mountSecureField = (paymentMethod, secureFieldActions) => {
  const mp = window.MercadoPagoSDK;

  const containerId = `${paymentMethod?.id}_${paymentMethod?.option_id}`;
  const container = document.getElementById(containerId);

  const shouldMount = paymentMethod.type === CREDIT_CARD || paymentMethod.type === DEBIT_CARD || paymentMethod.type === PREPAID_CARD;

  if (!shouldMount || !container || secureFieldsInstance.getSecurityCodeField()) {
    return;
  }

  let placeholderWording;
  if (typeof window !== 'undefined') {
    placeholderWording = window.__PRELOADED_STATE__?.translations['Ej.:']?.[1] ?? 'Ej.:';
  }

  const instance = mp.fields
    .create(FIELDS.SECURITY_CODE, {
      placeholder: `${placeholderWording} ${paymentMethod.security_code.length === 4 ? CVV_FOUR_DIGITS : CVV_THREE_DIGITS}`,
      style: {
        fontWeight: '300',
        fontStyle: 'normal',
        fontFamily: '"Proxima Nova", -apple-system, Roboto, Arial, sans-serif',
        placeholderColor: 'rgba(0, 0, 0, 0.55)',
        color: 'rgba(0, 0, 0, 0.9)',
      },
    })
    .mount(containerId);

  secureFieldsInstance.setSecurityCodeField(instance);

  instance.on(EVENTS.READY, () => {
    instance.update({
      settings: {
        mode: 'mandatory',
        length: paymentMethod.security_code.length,
      },
    });
  });

  instance.on(EVENTS.FOCUS, () => {
    secureFieldActions?.setSecureFieldFocus(true);
    secureFieldActions?.setSecureFieldError(false);
  });

  instance.on(EVENTS.BLUR, () => {
    secureFieldActions?.setSecureFieldFocus(false);
  });
};

const unmountSecureField = () => {
  const instance = secureFieldsInstance.getSecurityCodeField();
  if (instance) {
    instance.unmount();
    secureFieldsInstance.setSecurityCodeField(null);
  }
};

const remountSecureField = (paymentMethod, secureFieldActions) => {
  unmountSecureField();
  mountSecureField(paymentMethod, secureFieldActions);
  secureFieldActions?.resetSecureField();
};

const createCardToken = async () => {
  let error = null;
  let response = null;

  try {
    response = await window.MercadoPagoSDK.fields.createCardToken({});
  } catch (err) {
    error = err;
  }

  return { error, response };
};

const createCardTokenWithEsc = async (cardId, internalDeviceId) => {
  let error = null;
  let response = null;

  try {
    const payload = {
      requireEsc: true,
      fingerprint: {
        vendor_ids: [
          {
            name: 'browser',
            value: internalDeviceId,
          },
        ],
      },
    };

    response = await MercadoPagoSDK.fields.createCardToken({ cardId }, payload);
  } catch (err) {
    error = err;
  }

  return { error, response };
};

module.exports = {
  mountSecureField,
  unmountSecureField,
  remountSecureField,
  createCardTokenWithEsc,
  createCardToken,
};
