const { Card } = require('@andes/card');
const { List, ListItem } = require('@andes/list');
const { Thumbnail } = require('@andes/thumbnail');

const { generateIcon } = require('../../../utils/icons');
const { DEVICE_TYPE } = require('../../../../constants/commons');

const Address = ({ zipcode_text, onSelectOption, deviceType, isRebrand }) => {
  return (
    <Card className="address-card">
      <List withDividers srLabel="" className={deviceType === DEVICE_TYPE.MOBILE ? 'list-mobile' : 'list-desktop'}>
        <ListItem
          className="address-item"
          srLabel={zipcode_text}
          title={zipcode_text}
          leftContent={
            <Thumbnail className="shipping-icon-container" size="40" srLabel={zipcode_text}>
              {generateIcon({
                type: 'icon_pin',
                className: isRebrand ? 'shipping-rebrand-color' : 'shipping-icon-address',
              })}
            </Thumbnail>
          }
          chevron
          onClick={() => onSelectOption('modify_zipcode')}
        />
      </List>
    </Card>
  );
};

module.exports = {
  Address,
};
