const { useMemo, useCallback } = require('react');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const { Card } = require('@andes/card');
const { Typography } = require('@andes/typography');
const { List, ListItem } = require('@andes/list');
const { Thumbnail } = require('@andes/thumbnail');
const { Pill } = require('@andes/badge');
const { useI18n } = require('nordic/i18n');

const stepActions = require('../../../spa/actions/step');
const { getQueryParams } = require('../../../utils/Dom');
const { STEP_NEXT } = require('../../../spa/actions/types');
const { generateIcon } = require('../../../utils/icons');
const MercadoPagoYellow = require('@cow/core-components/components/Icons/MercadoPagoYellow');
const { DEVICE_TYPE } = require('../../../../constants/commons');
const translate = require('../../../translation');

const ShippingUserType = (props) => {
  const { i18n } = useI18n();
  const translations = translate(i18n);

  const { flow, data, history, stepAction, deviceType, configs } = props;
  const { isBPP } = data;
  const initSessionLabel = isBPP
    ? translations.LOG_IN_WITH_YOUR_ACCOUNT
    : translations.LOG_IN_WITH_YOUR_MERCADO_PAGO_ACCOUNT;

  const classRebranding = configs?.rebrandingMP ? 'shipping-rebrand-color' : 'shipping-icon-user';

  const mpIcon = configs?.rebrandingMP ? (
    <MercadoPagoYellow />
  ) : (
    <Thumbnail className="shipping-icon-container" size="40" srLabel="icon_mercado_pago">
      {generateIcon({ type: 'icon_mercado_pago', className: classRebranding })}
    </Thumbnail>
  );

  const items = useMemo(
    () => [
      {
        id: 'login_user_row_id',
        title: initSessionLabel,
        icon: mpIcon,
        description: isBPP ? translations.IF_YOUR_PACKAGE_DOES_NOT_ARRIVE_WE_WILL_REFUND_YOUR_MONEY : undefined,
        isBrandProtectionProgram: isBPP,
      },
      {
        id: 'guest_user_row_id',
        title: translations.CONTINUE_AS_A_GUEST,
        icon: (
          <Thumbnail className="shipping-icon-container" size="40" srLabel="icon_user">
            {generateIcon({ type: 'icon_user', className: classRebranding })}
          </Thumbnail>
        ),
        className: 'shipping-icon-user',
      },
    ],
    [],
  );

  const handleNextStep = useCallback(
    (id) => {
      const urlParams = getQueryParams();

      const defaultData = {
        urlParams,
        type: flow.type,
      };

      const payload = {
        isNewInterface: true,
        user_option_selected: id,
      };

      stepAction[STEP_NEXT](payload, flow.id, defaultData, flow.type, urlParams, history);
    },
    [flow, history],
  );

  return (
    <div className="shipping-user-type-container">
      <h1 className="c-title">{translations.CHOOSE_HOW_TO_PAY}</h1>

      <Card>
        <List withDividers srLabel="" className={deviceType === DEVICE_TYPE.MOBILE ? 'list-mobile' : 'list-desktop'}>
          {items.map(({ id, title, description, icon, isBrandProtectionProgram }) => (
            <ListItem
              srLabel={title}
              className={isBrandProtectionProgram ? 'is-bpp-option' : ''}
              id={id}
              key={id}
              chevron
              title={title}
              description={
                description && (
                  <Typography component="p" type="body" size="s" color="secondary">
                    {description}
                  </Typography>
                )
              }
              leftContent={icon && icon}
              rightContent={
                isBrandProtectionProgram
                  ? {
                      content: (
                        <Pill
                          className="is-bpp-tag"
                          color="green"
                          size="small"
                          hierarchy="quiet"
                          roundedCorners={['bottom-left', 'top-right']}
                        >
                          {translations.PROTECTED_PURCHASE}
                          {generateIcon({ type: 'icon_shield_bpp' })}
                        </Pill>
                      ),
                    }
                  : undefined
              }
              rightContentPosition="top"
              onClick={() => handleNextStep(id)}
            />
          ))}
        </List>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  flow: state.page.flow,
  configs: state.configurations,
});

const mapDispatchToProps = (dispatch) => ({
  stepAction: bindActionCreators(stepActions, dispatch),
});

module.exports = {
  ShippingUserType: connect(mapStateToProps, mapDispatchToProps)(ShippingUserType),
};
