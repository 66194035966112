const React = require('react');
const PropTypes = require('prop-types');
const { injectI18n } = require('nordic/i18n');
// Redux
const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
// Andes components
const { Text } = require('@andes/typography');
// Cow components
const SelectInstallments = require('@cow/core-components/components/SelectInstallments');

// Actions
const EXPRESS_ACTIONS = require('../../../../../spa/actions/express');
const { EXPRESS_UPDATE_INSTALLMENTS, EXPRESS_RESET_INSTALLMENTS } = require('../../../../../spa/actions/types');
// Utils
const translate = require('../../../../../translation');
const { formatAmount } = require('../../../../../utils/format');
const { localeFromSiteId } = require('../../../../../utils/locale');

const getInstallmentFromItems = (items, value) => items.find((item) => String(item.quantity) === value);

const customTriggerCallback =
  ({ translations, siteId, locale, currency, items }) =>
  () =>
  (_actualContent, currentValues) => {
    if (!currentValues || currentValues.length === 0) {
      return <span className="andes-form-control__placeholder">{translations.INSTALLMENTS_PLACEHOLDER}</span>;
    }

    const installment = getInstallmentFromItems(items, currentValues[0]);
    const isInterestFree = installment?.is_interest_free;

    const description = isInterestFree
      ? `${installment?.title} ${translations.NO_INTEREST()}`
      : `${installment?.title} (${formatAmount(siteId, locale, currency, installment?.total_finance_cost)})`;

    return <span>{description}</span>;
  };

const InstallmentsSelector = ({ items, paymentMethod, discount, i18n, siteId, express, expressActions }) => {
  const translations = translate(i18n);
  const { locale, currency } = localeFromSiteId(siteId);

  const [detail, setDetail] = React.useState('');
  const [installment, setInstallment] = React.useState({});

  const [renderCustomTrigger, setRenderCustomTrigger] = React.useState(
    customTriggerCallback({ translations, siteId, locale, currency, items }),
  );

  const handleDetail = (installment) => {
    if (installment?.detail) {
      setDetail(installment?.detail);
    }
  };

  const handleInstallmentChange = (value) => {
    const selectedInstallment = getInstallmentFromItems(items, value);
    expressActions[EXPRESS_UPDATE_INSTALLMENTS](paymentMethod, selectedInstallment, siteId);
    setInstallment(selectedInstallment);
    handleDetail(selectedInstallment);
  };
  
  const mapInstallmentsToItems = (items, translations, siteId, locale, currency) => 
    items.map((installment) => {
      const isInterestFree = installment?.is_interest_free;
  
      let description = '';
  
      if (installment?.quantity > 1) {
        description = isInterestFree
          ? translations.NO_INTEREST()
          : formatAmount(siteId, locale, currency, installment?.total_finance_cost);
      }
  
      return {
        description,
        title: installment?.title,
        value: String(installment?.quantity),
        highlighted: isInterestFree,
      };
    });

  React.useEffect(() => {
    handleDetail({});
    setInstallment({});
    expressActions?.[EXPRESS_RESET_INSTALLMENTS](paymentMethod);
  }, [discount]);

  React.useEffect(() => {
    setRenderCustomTrigger(customTriggerCallback({ translations, siteId, locale, currency, items }));
  }, [installment]);

  React.useEffect(() => {
    if (!express?.installments?.[paymentMethod]?.value) {
      const recommended = items.find((item) => item.recommended);
      const defaultInstallment = recommended || items[0];

      if (items.length !== 1 && !recommended) {
        return;
      }

      handleDetail(defaultInstallment);
      setInstallment(defaultInstallment);
      expressActions[EXPRESS_UPDATE_INSTALLMENTS](paymentMethod, defaultInstallment, siteId);
    }
  }, [express?.installments?.[paymentMethod]?.value]);

  return (
    <div className="installments-selector">
      <SelectInstallments
        menuAlignment="bottom"
        label={translations.INSTALLMENTS_LABEL()}
        placeholder={translations.INSTALLMENTS_PLACEHOLDER}
        value={express?.installments?.[paymentMethod]?.value || ''}
        modifier={express?.installments?.[paymentMethod]?.error && 'error'}
        helper={express?.installments?.[paymentMethod]?.error && translations.INSTALLMENTS_IS_REQUIRED}
        onChange={(_event, value) => handleInstallmentChange(value)}
        renderCustomTrigger={renderCustomTrigger}
        items={[
          {
            value: '0',
            disabled: true,
            title: translations.INSTALLMENTS_PLACEHOLDER,
          },
          ...mapInstallmentsToItems(items, translations, siteId, locale, currency),
        ]}
      />
      {express?.installments?.[paymentMethod]?.value && detail && (
        <div className="interest-info">
          <Text className="interest-info_text" size="s" color="secondary">
            {detail}
          </Text>
        </div>
      )}
    </div>
  );
};

InstallmentsSelector.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      detail: PropTypes.string,
      recommended: PropTypes.bool,
      quantity: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  express: PropTypes.shape({
    installments: PropTypes.shape({
      error: PropTypes.bool,
      value: PropTypes.string,
    }),
  }).isRequired,
  expressActions: PropTypes.shape({
    [EXPRESS_UPDATE_INSTALLMENTS]: PropTypes.func.isRequired,
  }).isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  discount: PropTypes.shape({
    detail: PropTypes.string.isRequired,
  }).isRequired,
  siteId: PropTypes.string.isRequired,
  paymentMethod: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  expressActions: bindActionCreators(EXPRESS_ACTIONS, dispatch),
});

const mapStateToProps = (state) => ({
  express: state.express,
  siteId: state.configurations.platform.siteId,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = injectI18n(InstallmentsSelector);
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(InstallmentsSelector));
}
