const { useMemo, useCallback } = require('react');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const { Card } = require('@andes/card');
const { List, ListItem } = require('@andes/list');
const { Thumbnail } = require('@andes/thumbnail');
const { useI18n } = require('nordic/i18n');

const stepActions = require('../../../spa/actions/step');
const { getQueryParams } = require('../../../utils/Dom');
const { STEP_NEXT } = require('../../../spa/actions/types');
const { generateIcon } = require('../../../utils/icons');
const { DEVICE_TYPE } = require('../../../../constants/commons');
const translate = require('../../../translation');

const ShippingType = (props) => {
  const { i18n } = useI18n();
  const translations = translate(i18n);

  const { flow, data, history, stepAction, deviceType, configs } = props;

  const items = useMemo(
    () =>
      [
        {
          id: 'shipping_row',
          title: translations.SHIPPING,
          icon: 'icon_shipping_type',
          className: configs?.rebrandingMP ? 'shipping-rebrand-color' : 'shipping-icon',
        },
        {
          id: 'local_pickup_row',
          title: translations.PICKUP_AT_SELLER_STORE,
          icon: 'icon_local_pick',
          className: configs?.rebrandingMP ? 'local-pickup-icon-rebrand' : 'shipping-icon',
          isRebrand: configs?.rebrandingMP,
        },
      ].slice(0, data.local_pickup ? undefined : -1),
    [],
  );

  const handleNextStep = useCallback(
    (id) => {
      const urlParams = getQueryParams();

      const defaultData = {
        urlParams,
        type: flow.type,
      };

      const payload = {
        isNewInterface: true,
        shipping_option_selected: id,
      };

      stepAction[STEP_NEXT](payload, flow.id, defaultData, flow.type, urlParams, history);
    },
    [flow, history],
  );

  return (
    <div className="shipping-type-container">
      <h1 className="c-title">{translations.CHOOSE_RECEIVE_YOUR_ORDER}</h1>

      <Card>
        <List withDividers srLabel="" className={deviceType === DEVICE_TYPE.MOBILE ? 'list-mobile' : 'list-desktop'}>
          {items.map(({ id, title, icon, className, isRebrand }) => (
            <ListItem
              srLabel={title}
              id={id}
              key={id}
              chevron
              title={title}
              leftContent={
                icon && (
                  <Thumbnail className="shipping-icon-container" size="40" srLabel={icon}>
                    {generateIcon({ type: icon, className, isRebrand })}
                  </Thumbnail>
                )
              }
              onClick={() => handleNextStep(id)}
            />
          ))}
        </List>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  flow: state.page.flow,
  configurations: state.configurations,
  configs: state.configurations,
});

const mapDispatchToProps = (dispatch) => ({
  stepAction: bindActionCreators(stepActions, dispatch),
});

module.exports = {
  ShippingType: connect(mapStateToProps, mapDispatchToProps)(ShippingType),
};
