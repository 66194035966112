const metricsStrategy = require('../../../../service/metrics');

function sendMetricBasedOnTransactionType({transactionType, device, action}) {
    const type = transactionType in metricsStrategy ? transactionType : 'default';
    
    if (!(type in metricsStrategy) || !(action in metricsStrategy[type])) {
        throw new Error(`No strategy found for transaction type: ${type} with action: ${action}`);
    }

    const strategy = metricsStrategy[type][action];

    strategy({device});
}

module.exports = {
    sendMetricBasedOnTransactionType,
};