const React = require("react");

const PaymentSummary = require('@cow/core-components/components/PaymentSummary');

const {handleSummaryItemProps, PAYMENT_SUMMARY_ALIGNMENT} = require("../../../utils/summary");
const {COMMONS, ANDES_STYLES_THEME, LINKS} = require('../../../../constants');
const PropTypes = require("prop-types");

const {CHECKOUT_CASE: {SUBSCRIPTION}, THEME} = COMMONS;

const Summary = (
  {
    summary,
    translations,
    tooltipContainer,
    setShowDiscountModal,
    transactionType,
    deviceType,
    subscription,
    theme,
    pcj,
    incentives,
  }) => {

  const SummaryTheme = React.useMemo(() => transactionType === SUBSCRIPTION || subscription !== null ? THEME.DARK : THEME.LIGHT, [transactionType, subscription]);

  const itemsForSummary = !pcj?.is_applicable ? summary?.items?.map((item) =>
    handleSummaryItemProps({
      item,
      summary,
      translations,
      deviceType,
      tooltipContainer,
      setShowDiscountModal,
      iconAlignment: PAYMENT_SUMMARY_ALIGNMENT.LEFT,
      isSubscription: transactionType === SUBSCRIPTION,
    }),
  ) : [];


  return (
    <div className="sidebar--default">
      <PaymentSummary
        title={summary?.title}
        items={itemsForSummary}
        theme={SummaryTheme}
        companyTheme={theme}
        showSubmitButton={false}
        securityPaymentInformationLabel={incentives?.is_secure_payment ? translations.SECURE_PAYMENT : null}
      />
    </div>
  );
}

Summary.propTypes = {
  summary: PropTypes.object.isRequired,
  translations: PropTypes.object,
  tooltipContainer: PropTypes.string,
  setShowDiscountModal: PropTypes.func,
  transactionType: PropTypes.string,
  deviceType: PropTypes.string,
  subscription: PropTypes.string,
  theme: PropTypes.string,
  pcj: PropTypes.object,
}

module.exports = Summary;
