/**
 * Method for get values the client browser
 * @param {Object} initialStore 'Get the value through Redux to extract the IP and accept_header'
 */

const getBrowserInfo = (initialStore) => {
  const ip = initialStore?.configurations?.country?.ip;
  const acceptHeader = initialStore?.configurations?.browserHeaders;

  return {
    accept_header: acceptHeader,
    color_depth: window?.screen.colorDepth,
    ip,
    java_enabled: window?.navigator.javaEnabled(),
    language: window?.navigator.language,
    screen_height: window?.screen.height,
    screen_width: window?.screen.width,
    timezone_offset: new Date().getTimezoneOffset(),
    user_agent: window?.navigator.userAgent
  };
}

module.exports = getBrowserInfo;
