/* eslint-disable react/sort-comp */
const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const { injectI18n } = require('nordic/i18n');

const { ValidatorProvider } = require('../../../utils/validator-provider');
const Form = require('../../Form');
const ZipcodeResult = require('../../ZipcodeFinderResult');
const Api = require('../../../service/api');
const buttonActions = require('../../../spa/actions/button');
const addressActions = require('../../../spa/actions/address');
const modalActions = require('../../../spa/actions/modal');
const textsActions = require('../../../spa/actions/texts');
const snackbarActions = require('../../../spa/actions/snackbar');
const {
  CLEAN_BUTTON_ACTIONS,
  SAVE_ADDITIONAL_ADDRESS_INFORMATION,
  CLEAR_ADDRESS_INFORMATION,
  TOGGLE_MODAL_VISIBILITY,
  UPDATE_MODAL_CUSTOM_TITLE,
  UPDATE_DINAMIC_TEXT_VALUE,
  SNACKBAR_TRIGGER,
} = require('../../../spa/actions/types');
const translate = require('../../../translation');

class GroupZipcodeFinder extends React.Component {
  constructor(props) {
    super(props);
    const { i18n } = props;
    this.state = {
      showResult: false,
      result: [],
    };

    this.resetTimeout = 3000;
    this.translations = translate(i18n);
    this.getZipcode = this.getZipcode.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onModify = this.onModify.bind(this);
  }

  componentDidMount() {
    this.updateModalTitle();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.buttonClicked === `${this.props.step}_button_trigger_action`) {
      this.getZipcode(this.props.address);
    }
  }

  onSubmit(event, zipcodeSelected) {
    event.preventDefault();
    const selectedAddress = this.state.result.find(opt => (opt.zipCode === zipcodeSelected));
    this.props.addressActions[SAVE_ADDITIONAL_ADDRESS_INFORMATION](
      {
        streetName: selectedAddress.street,
        streetNumber: this.props.streetNumber, // We want to use the streetNumber coming from the user's input
        zipcode: selectedAddress.zipCode,
        neighborhood: selectedAddress.neighborhood,
      },
    );
    // Modal view uses nestedPages instead of opening up a new modal, so we want to go back to the root page
    if (this.props.checkoutType === 'modal') {
      location.hash = `#/`; // eslint-disable-line
    }
    this.props.modalActions[TOGGLE_MODAL_VISIBILITY]();
  }

  onModify(event) {
    event.preventDefault();
    this.props.addressActions[CLEAR_ADDRESS_INFORMATION]();
    if (this.props.checkoutType === 'modal') {
      location.hash = `#/`; // eslint-disable-line
    }
    this.props.modalActions[TOGGLE_MODAL_VISIBILITY]();
  }

  getZipcode(address) {
    this.props.buttonActions[CLEAN_BUTTON_ACTIONS]();
    if (address.state.name && (address.city.name || (this.props.streetName && this.props.streetNumber))) {
      Api.getSuggestedZipcode(address.state.name, address.city.name, this.props.streetName, this.props.streetNumber)
        .then((res) => {
          this.setState({ result: res.suggestedZipCode, showResult: true });
          this.updateModalTitle();
        })
        .catch(() => {
          const snackbarData = {
            show: true,
            status: 'error',
            message: this.translations.NOT_IDENTIFY_ZIPCODE,
          };
          this.props.snackbarActions[SNACKBAR_TRIGGER](snackbarData);
          setTimeout(() => {
            snackbarData.show = false;
            this.props.snackbarActions[SNACKBAR_TRIGGER](snackbarData);
          }, this.resetTimeout);
        });
    }
  }

  updateModalTitle() {
    let newTitle = null;
    if (!this.state.showResult) {
      newTitle = this.translations.ADDRESS_DETAILS;
    } else if (this.state.result && this.state.result.length > 1) {
      newTitle = this.translations.CHOOSE_LOCATION;
    }

    if (this.props.modalActions) {
      this.props.modalActions[UPDATE_MODAL_CUSTOM_TITLE](newTitle);
    }
    if (this.props.textsActions) {
      this.props.textsActions[UPDATE_DINAMIC_TEXT_VALUE]('manual_zipcode_nested_title', newTitle);
    }
  }

  render() {
    return (
      <ValidatorProvider>
        <Form key={this.props.id} method="POST">
          <div className="zipcode-finder">
            {this.state.showResult
              ? (
                <ZipcodeResult
                  result={this.state.result}
                  onSubmit={this.onSubmit}
                  confirmButtonLabel={this.props.primaryLabel}
                  modifyButtonLabel={this.props.secondaryLabel}
                  onModify={this.onModify}
                  name={this.props.name}
                  deviceType={this.props.deviceType}
                />
              )
              : this.props.children}
          </div>
        </Form>
      </ValidatorProvider>
    );
  }
}

GroupZipcodeFinder.defaultProps = {
  children: null,
  primaryLabel: '',
  secondaryLabel: '',
  i18n: {
    gettext: t => t,
  },
  deviceType: 'desktop',
  buttonActions: {
    [CLEAN_BUTTON_ACTIONS]: () => { }
  },
  addressActions: {
    [SAVE_ADDITIONAL_ADDRESS_INFORMATION]: () => { },
    [CLEAR_ADDRESS_INFORMATION]: () => { },
  },
  textsActions: {
    [UPDATE_DINAMIC_TEXT_VALUE]: () => { },
  },
  snackbarActions: {
    [SNACKBAR_TRIGGER]: () => { },
  },
  modalActions: {
    [UPDATE_MODAL_CUSTOM_TITLE]: () => { },
    [TOGGLE_MODAL_VISIBILITY]: () => { }
  },
};

GroupZipcodeFinder.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  // Labels for buttons in ZipcodeResult view
  primaryLabel: PropTypes.string,
  secondaryLabel: PropTypes.string,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.string,
  buttonActions: PropTypes.shape({}),
  addressActions: PropTypes.shape({}),
  textsActions: PropTypes.shape({}),
  snackbarActions: PropTypes.shape({}),
  modalActions: PropTypes.shape({}),
};

const mapDispatchToProps = dispatch => ({
  buttonActions: bindActionCreators(buttonActions, dispatch),
  addressActions: bindActionCreators(addressActions, dispatch),
  modalActions: bindActionCreators(modalActions, dispatch),
  textsActions: bindActionCreators(textsActions, dispatch),
  snackbarActions: bindActionCreators(snackbarActions, dispatch),
});

const mapStateToProps = state => ({
  step: state.page.flow.step,
  buttonClicked: state.button.buttonClicked.id,
  checkoutType: state.configurations.checkoutType,
  address: state.address,
  streetName: state.inputValues.current[`${state.page.flow.step}_address_street_name`],
  streetNumber: state.inputValues.current[`${state.page.flow.step}_address_street_number`],
});

if (process.env.NODE_ENV === 'test') {
  module.exports = GroupZipcodeFinder;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(GroupZipcodeFinder)); // eslint-disable-line max-len
}
