const React = require('react');

const IconWrapper = require('../IconWrapper');
const AccountMoney = require('@cow/core-components/components/Icons/AccountMoneyYellow');

const AccountMoneyYellow = () => (
  <IconWrapper className="icon-account-money-yellow">
     <AccountMoney className="account-money-yellow__icon" />
  </IconWrapper>
);

module.exports = AccountMoneyYellow;
