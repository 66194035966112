module.exports = {
  SIZES: {
    S: 's',
    XS: 'xs',
    M: 'm',
    L: 'l',
  },
  WEIGHT: {
    REGULAR: 'regular',
    SEMI_BOLD: 'semibold',
    BOLD: 'bold',
  },
  COLORS: {
    PRIMARY: 'primary',
    POSITIVE: 'positive',
    SECONDARY: 'secondary',
    LINK: 'link',
    GREEN: 'green'
  },
};



