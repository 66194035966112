/* eslint-disable react/no-unused-state */
const React = require('react');
const ReactDOM = require('react-dom');
const PropTypes = require('prop-types');

const { getErrorAndValidate, getErrorProps } = require('../../containers/Optimus/helper');
const tracker = require('../../utils/Tracker');
const { SAVE_INPUT_VALUES } = require('../../spa/actions/types');
const {INPUT_ID, REGEX} = require("../../../constants/commons");

class BaseValidation extends React.Component {
  constructor(props) {
    super(props);

    // Bind Methods
    this.getComponentValue = this.getComponentValue.bind(this);
    this.validate = this.validate.bind(this);
    this.updateValidations = this.updateValidations.bind(this);
    this.onChange = this.onChange.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
  }

  /**
   * Check if the props change and compare them with the state
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateErrors(nextProps.globalErrors);
  }

  /**
   * @TODO - Move this logic and use the getComponentValue() method in the input to manipulate the value of this state
    */
  /**
   * Default onChange method
   * @param event
   */
  onChange(event) {
    let filteredValue = event.target?.value;
    const isPhoneInput = this.props.id === INPUT_ID.PHONE_NUMBER;
    const { onlyLetters } = this.props;

    if (isPhoneInput) {
      filteredValue = filteredValue.replace(REGEX.ONLY_NUMERIC, '');
    } else if (onlyLetters) {
      filteredValue = filteredValue.replace(REGEX.ONLY_LETTERS, '');
    }

    this.setState({
      value: filteredValue,
    });
  }


  /**
   * This method is going to return the value from the state
   * You can use it to rewrite the value of the component
   * In the case of the select we add a brakets to wrap the value
   * with these you can override this value and remove the brakets
   */
  getComponentValue() {
    return this.state.value;
  }

  /**
   * Update the state with the errors coming from event
   */
  updateErrors(globalErrors) {
    // Only updates if the id received is the one to update
    if (globalErrors) {
      const error = globalErrors[this.props.id];

      if (error) {
        const errorProps = getErrorProps({ errors: this.props.errors, validations: error });
        this.setState({
          error: errorProps.error,
          invalid: errorProps.invalid,
        });
      }
    }
  }

  /**
   * Validate field implementation
   *
   * VALIDATE: Only the render method returns a component
   *
   * @returns {boolean} true if it is valid and false if the element is invalid
   */
  validate() {
    // If the DOM element is not visible do not validate, always valid
    if (ReactDOM.findDOMNode(this) === null) { // eslint-disable-line
      return true;
    }

    // Only validate elements that are visible
    const componentValue = this.getComponentValue();
    let errorId = getErrorAndValidate(this.props.validations, componentValue);

    // If is all valid and have extra validations run them
    if (!errorId && this.extraValidations) {
      errorId = this.extraValidations(componentValue);
    }

    // If is all valid save input values in store
    if (!errorId && this.props.id && this.props.shouldSaveValue && this.props.step) {
      // If the component has a specific "valueToShow" that will be saved to the store and not the value
      this.props.inputValuesActions[SAVE_INPUT_VALUES](`${this.props.step}_${this.props.id}`, this.state.valueToShow ? this.state.valueToShow : this.state.value);
    }

    // If updateValidations is TRUE the element is valid
    const errorMessage = errorId ? (this.props.errors[errorId] || errorId) : null;
    return this.updateValidations({
      error: errorMessage ? [errorMessage] : [],
      invalid: !!errorId,
    });
  }

  /**
   * Update the state and execute the validateCallback if it is defined
   * @param error
   * @param invalid
   * @param value
   */
  updateValidations({ error = [], invalid = false, value = this.getComponentValue() }) {
    this.setState({
      error,
      invalid,
    });

    if (this.props.validateCallback) {
      this.props.validateCallback({
        // If this component is going to you show the errors, DO NOT SEND THEM TO THE GROUP
        error: (this.props.showErrorMessage) ? '' : error,
        invalid,
        value,
      });
    }

    return !invalid;
  }

  render() {
    return (null);
  }
}

BaseValidation.propTypes = {
  validations: PropTypes.arrayOf(PropTypes.shape({
    error: PropTypes.string,
    regex: PropTypes.string,
    show: PropTypes.bool,
  })),
  id: PropTypes.string,
  errors: PropTypes.object, // eslint-disable-line
  label: PropTypes.string,
  globalErrors: PropTypes.object, // eslint-disable-line
  validateCallback: PropTypes.func,
  showErrorMessage: PropTypes.bool,
  onlyLetters: PropTypes.bool,
  onChange: PropTypes.func,
};

BaseValidation.defaultProps = {
  validations: [],
  errors: {},
  label: '',
  globalErrors: {},
  id: '',
  validateCallback: null,
  showErrorMessage: false,
};

module.exports = BaseValidation;
