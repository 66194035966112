const React = require('react');
const PropTypes = require('prop-types');

// Bricks Components
const { CardFormBrick, useCardForm } = require('internal-checkout-bricks/lib/cardForm/client');
const { Theme } = require('internal-checkout-bricks/lib/cardForm/types/enums');
// Andes Components
const { Message } = require('@andes/message');

// Constants
const { COLORS_BY_STATUS, SITE_ID, OPERATION_TYPE } = require('../../../../../../constants/commons');
// Hooks
const { useActions } = require('../../hooks/useActions');
// Utils
const { trackInfo } = require('../../../../../service/api');
const { logErrorFromClient } = require('../../../../../utils/logTags');
const { newInterfaceGenericPropsTypes, newInterfaceGenericDefaultValues } = require('../../../../../utils/propTypes');
const { getErrorMessage } = require('../../../../../utils/card-form');
const { generateMultipleTokens } = require('../../utils/tokens');
const { handleBricksSubmitError } = require('../../utils/errors');

const CardFormWrapper = React.forwardRef((props, ref) => {
  const { publicKey, flow, stepActions, history, step_model, i18n, rebrandingMP, transactionType, siteId } = props;

  const isAccountFund = transactionType === OPERATION_TYPE.ACCOUNT_FUND;

  const shouldCheckDocument = isAccountFund && siteId === SITE_ID.MLA;

  const { generateToken } = useCardForm();
  const [formError, setFormError] = React.useState(null);

  const { sendCardData } = useActions({ flow, history, stepActions });

  // === Render Messages ===
  const renderNotificationMessage = React.useCallback(() => {
    const notifications = step_model?.notifications;

    if (!notifications) {
      return null;
    }

    return Object.keys(notifications).map((key) => {
      const notification = notifications[key];

      if (!notification) {
        return null;
      }

      const { message, status } = notification;

      return (
        <Message
          key={key}
          color={COLORS_BY_STATUS[status?.toUpperCase()]}
          hierarchy="quiet"
          closable={false}
          className="notification-message"
        >
          <div>{message}</div>
        </Message>
      );
    });
  }, [step_model?.notifications]);

  const renderErrorMessage = React.useCallback(() => {
    if (!formError) {
      return null;
    }

    const hasNotificationsData =
      step_model?.notifications &&
      Object.values(step_model.notifications).some((notification) => notification !== null);

    return (
      <div className={`${hasNotificationsData ? 'multiple-errors-message' : ''}`}>
        <Message color={COLORS_BY_STATUS.ERROR} hierarchy="quiet" closable={false} title={formError.reason}>
          {formError.message}
        </Message>
      </div>
    );
  }, [formError, step_model?.notifications]);

  // === Card Form Bricks Callbacks ===
  const handleCardBinChange = React.useCallback((bin) => {
    if (bin.length === 0) {
      setFormError(null);
    }
  }, []);

  const handleCardFormError = React.useCallback(
    (error) => {
      setFormError(getErrorMessage(i18n, error));
    },
    [i18n],
  );

  const handleGenerateToken = React.useCallback(
    () =>
      generateToken()
        .then((cardFormResponse) => {
          if (!cardFormResponse) {
            const error = {
              reason: 'cardFormResponse is null or undefined',
              detail: 'The generateToken from the useCardForm hook returned a null or undefined value.',
            };

            return Promise.reject(error);
          }

          sendCardData(cardFormResponse);
          return Promise.resolve();
        })
        .catch((error) => {
          if (handleBricksSubmitError(error)) {
            return Promise.resolve();
          }

          const logError = {
            name: error?.name || 'Error',
            message: error?.reason || error?.message,
          };

          logErrorFromClient(logError, error?.reason, '[CardFormWrapper][handleGenerateToken]');

          return Promise.reject(error);
        }),
    [generateToken, sendCardData],
  );

  const handleGenerateZeroDollarToken = React.useCallback(async () => {
    try {
      const zeroDollarQuantityToken = 2;
      const cardFormResponses = await generateMultipleTokens(zeroDollarQuantityToken, generateToken);
      const sendCardDataPayload = {
        ...cardFormResponses[0],
        ...(cardFormResponses.length === zeroDollarQuantityToken && {
          zeroDollarToken: cardFormResponses[1].cardToken,
        }),
      };
      sendCardData(sendCardDataPayload);
    } catch (error) {
      // Track info
      trackInfo({ message: 'CardFormWrapper handleGenerateZeroDollarToken' });
      const controlledError = error.rejected.some((r) => handleBricksSubmitError(r));
      if (!controlledError) {
        throw error;
      }
    }
  }, [generateToken, sendCardData]);

  React.useImperativeHandle(ref, () => ({
    handleGenerateToken,
    handleGenerateZeroDollarToken,
  }));

  const notificationMessage = renderNotificationMessage();
  const errorMessage = renderErrorMessage();

  const isNotificationMessageEmpty =
    Array.isArray(notificationMessage) && notificationMessage.every((item) => item === null);
  const isErrorMessageEmpty = errorMessage === null;

  return (
    <div>
      <CardFormBrick
        enableLuhnValidation
        publicKey={publicKey}
        onBinChange={handleCardBinChange}
        onError={handleCardFormError}
        customHeader={
          (!isNotificationMessageEmpty || !isErrorMessageEmpty) && (
            <>
              {notificationMessage}
              {errorMessage}
            </>
          )
        }
        theme={rebrandingMP ? Theme.MERCADO_LIBRE : Theme.MERCADO_PAGO}
        checkDocument={shouldCheckDocument}
      />
    </div>
  );
});

CardFormWrapper.displayName = 'CardFormWrapper';

CardFormWrapper.propTypes = {
  publicKey: PropTypes.string.isRequired,
  flow: PropTypes.object.isRequired,
  stepActions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  step_model: PropTypes.shape(newInterfaceGenericPropsTypes).isRequired,
  i18n: PropTypes.object.isRequired,
  rebrandingMP: PropTypes.bool,
  transactionType: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  context: PropTypes.shape({
    integration_id: PropTypes.string,
    site_id: PropTypes.string,
  }).isRequired,
};

CardFormWrapper.defaultProps = {
  publicKey: '',
  step_model: {
    step_model: {
      ...newInterfaceGenericDefaultValues,
    },
  },
  rebrandingMP: false,
};

module.exports = CardFormWrapper;
