import { Typography } from '@andes/typography';
import { VisuallyHidden } from '@andes/common';
import React from 'react';

import { DeviceType } from '../../constants/commons';
import { isDesktop } from '../../utils/deviceType';

type Link = {
  href: string;
  text: string;
};

type OptionalInformation = {
  message: string;
  links: Array<Link>;
};

export type FooterProps = {
  requiredMessage: string;
  requiredScreenReaderMessage: string;
  optionalInformation?: OptionalInformation;
  deviceType?: DeviceType.DESKTOP | DeviceType.MOBILE | DeviceType.TABLET;
};

const RenderLinks: React.FC<{ links: Array<Link> }> = ({ links }) =>
  links.map((link, i) => (
    <Typography
      component="a"
      color="link"
      href={link.href}
      target="_blank"
      size="xs"
      key={i}
    >
      {`${link.text}${i < links.length - 1 ? ' - ' : ''}`}
    </Typography>
  ));

const RenderPipe: React.FC<{ deviceType: DeviceType }> = ({ deviceType }) => {
  if (isDesktop(deviceType)) {
    return (
      <Typography
        color="secondary"
        size="xs"
        type="body"
        component="span"
        className="pipe"
        aria-hidden
      >
        |
      </Typography>
    );
  }

  return null;
};

const ConditionalWrapper: React.FC<{
  condition: boolean;
  wrapper: React.FC<{ children: React.ReactNode }>;
  children: React.ReactNode;
}> = ({ condition, wrapper, children }) =>
  condition ? wrapper({ children }) : <>{children}</>;

function Footer({
  requiredMessage = '',
  requiredScreenReaderMessage = '',
  optionalInformation,
  deviceType = DeviceType.DESKTOP,
}: FooterProps) {
  return (
    <div className="cow-footer">
      {isDesktop(deviceType) && <hr className="divider" aria-hidden />}
      <VisuallyHidden>
        {optionalInformation ? requiredScreenReaderMessage : requiredMessage}
      </VisuallyHidden>
      <Typography
        color="secondary"
        size="xs"
        type="body"
        component={isDesktop(deviceType) ? 'span' : 'div'}
        aria-hidden
      >
        {requiredMessage}
      </Typography>

      {optionalInformation && (
        <ConditionalWrapper
          condition={deviceType === DeviceType.MOBILE}
          wrapper={({ children }) => <div>{children}</div>}
        >
          <RenderPipe deviceType={deviceType} />
          <Typography
            color="secondary"
            size="xs"
            type="body"
            component="span"
            aria-hidden
          >
            {`${optionalInformation.message} - `}
          </Typography>
          <RenderLinks links={optionalInformation.links} />
        </ConditionalWrapper>
      )}
    </div>
  );
}

export default React.memo(Footer);
