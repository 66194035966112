const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { Modal } = require('@andes/modal');
const { Text } = require('@andes/typography');
const { Button } = require('@andes/button');

const duplicatedPaymentActions = require('../../spa/actions/duplicatedPayment');
const loadingActions = require('../../spa/actions/loading');
const animationsActions = require('../../spa/actions/animations');
const actionsTypes = require('../../spa/actions/types');
const connectToReduxAndInjectI18n = require('../../utils/connectToRedux');
const translation = require('../../translation');
const { useTracking } = require('../TrackingProvider');
const { MELIDATA_TRACKING_TYPE_VIEW } = require('../../../constants/app');

const METRIC_NAME = 'duplicated_payment_modal';
const VIEW_NAME = 'duplicated_payment';
const DuplicatedPaymentModal = (props) => {
  // tracking hook
  const { trackEvent } = useTracking();
  const translations = translation(props.i18n);

  const uncheckSelectedOption = React.useCallback(() => {
    const inputsChecked = document.querySelectorAll('input:checked.u-hide');

    if (!inputsChecked?.length) {
      return;
    }

    Array.from(inputsChecked).forEach((input) => {
      input.checked = false;
    });
  }, []);

  const closeModal = React.useCallback(() => {
    props.closeDuplicatedPaymentModal();
    uncheckSelectedOption();
  }, [props, uncheckSelectedOption]);

  // Function to add tracking properties to the instance
  const addPropsToTrackInstance =  React.useCallback((trackInstance) => {
      return trackInstance?.addProp('preference_id', props.tracks.preferenceId, false)
        .addProp('checkout_flow_id', props.tracks.checkoutFlowId, false)
        .addProp('router_request_id', props.tracks.routerRequestId, false)
        .addProp('checkout_session_id', props.tracks.checkoutSessionId, false)
  }, [props.tracks]);

  const payAgain = React.useCallback(() => {

    const trackInstance = trackEvent(`${METRIC_NAME}/pay_again`)?.addAllServices()
    addPropsToTrackInstance(trackInstance)?.exec()

    props.allowDuplicatedPayment();
    uncheckSelectedOption();

    props.disableLoading();
    props.loaderStopAnimationReset();
  }, [props, uncheckSelectedOption]);

  const onClose = React.useCallback(() => {
    const trackInstance = trackEvent(`${METRIC_NAME}/on_close`)?.addAllServices()
    addPropsToTrackInstance(trackInstance)?.exec()

    closeModal();
  }, [closeModal]);

  const understood = React.useCallback(() => {
    const trackInstance = trackEvent(`${METRIC_NAME}/understood`)?.addAllServices()
    addPropsToTrackInstance(trackInstance)?.exec()

    closeModal();
  }, [closeModal]);

  const goToActivity = React.useCallback(() => {
    const trackInstance = trackEvent(`${METRIC_NAME}/go_to_activity`)?.addAllServices()
    addPropsToTrackInstance(trackInstance)?.exec()

    const mpActivitiesUrl = `https://${props.mpBaseDomain}/activities`;
    window.location.replace(mpActivitiesUrl);

    closeModal();
  }, [closeModal, props.mpBaseDomain]);

  const onModalOpen = React.useCallback(() => {
    const trackInstance = trackEvent(VIEW_NAME, MELIDATA_TRACKING_TYPE_VIEW)?.addAllServices()
    addPropsToTrackInstance(trackInstance)?.exec()
  }, [props.show]);

  return (
    <Modal
      onOpen={onModalOpen}
      open={props.show}
      title={translations.YOU_HAVE_MADE_THIS_PAYMENT_RECENTLY}
      className="cow-duplicated-payment-modal"
      type="card"
      onClose={onClose}
      closable={props.paidWithALoggedUser}
      closeButtonSrLabel={translations.CLOSE}
      actions={{
        fixed: true,
        primary: props.paidWithALoggedUser ? (
          <Button onClick={goToActivity}>{translations.GO_TO_ACTIVITY}</Button>
        ) : (
          <Button onClick={understood}>{translations.I_UNDERSTAND}</Button>
        ),
        secondary: (
          <Button onClick={payAgain} hierarchy="transparent">
            {translations.PAY_AGAIN}
          </Button>
        ),
      }}
    >
      <Text size="m" className="cow-duplicated-payment-modal__message">
        {props.paidWithALoggedUser
          ? translations.IF_NEEDED_CONSULT_THE_DETAILS_IN_ACTIVITY
          : translations.CONSULT_THE_SELLER_IF_YOU_NEED_MORE_INFORMATION}
      </Text>
    </Modal>
  );
};

DuplicatedPaymentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  paidWithALoggedUser: PropTypes.bool.isRequired,
  closeDuplicatedPaymentModal: PropTypes.func.isRequired,
  allowDuplicatedPayment: PropTypes.func.isRequired,
  disableLoading: PropTypes.func.isRequired,
  loaderStopAnimationReset: PropTypes.func.isRequired,
  mpBaseDomain: PropTypes.string.isRequired,
  i18n: PropTypes.shape({ gettext: PropTypes.func }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  closeDuplicatedPaymentModal: bindActionCreators(duplicatedPaymentActions.closeDuplicatedPaymentModal, dispatch),
  allowDuplicatedPayment: bindActionCreators(duplicatedPaymentActions.allowDuplicatedPayment, dispatch),
  disableLoading: bindActionCreators(loadingActions.disableLoading, dispatch),
  loaderStopAnimationReset: bindActionCreators(animationsActions[actionsTypes.LOADER_STOP_ANIMATION_RESET], dispatch),
});

// Function to gather tracking data from the state
const getDataToTrackFromState = (state) => {
  const { flowId, checkout } = state.page?.data

  return {
    checkoutFlowId: flowId,
    checkoutSessionId: checkout?.checkout_session_id,
    preferenceId: checkout?.preference_id,
    routerRequestId: checkout?.router_request_id
  };
}

// Mapping state to props
const mapStateToProps = (state) => {
  return {
    show: state.duplicatedPayment.show,
    paidWithALoggedUser: state.duplicatedPayment.paidWithALoggedUser,
    mpBaseDomain: state.configurations.platform.domain,
    page: state.page,
    tracks: getDataToTrackFromState(state),
  };
};

module.exports = connectToReduxAndInjectI18n(DuplicatedPaymentModal, mapStateToProps, mapDispatchToProps);
