const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const CowBrand = require('@cow/core-components/components/Brand');

const { DEVICE_TYPE, ENVIROMENT } = require('../../../constants/commons');
const { useNavbar } = require('../../hooks/useNavbar');

const Brand = ({
  deviceType,
  brandName,
  brandLogo,
  maxBrandnameLength,
  maxInitialsLength,
  flow,
  userLogged,
  isWebview,
}) => {
  const { showNavbar } = useNavbar(flow, userLogged, isWebview);

  if (!brandName) {
    return null;
  }

  return (
    <CowBrand
      isMobile={deviceType === DEVICE_TYPE.MOBILE}
      name={brandName}
      image={brandLogo}
      maxNameLength={maxBrandnameLength}
      maxInitialsLength={maxInitialsLength}
      isUserLogged={userLogged}
      withNavbar={showNavbar}
    />
  );
};

Brand.propTypes = {
  brandName: PropTypes.string,
  brandLogo: PropTypes.string,
  maxBrandnameLength: PropTypes.number,
  maxInitialsLength: PropTypes.number,
  deviceType: PropTypes.string,
};

Brand.defaultProps = {
  brandName: '',
  brandLogo: '',
  maxBrandnameLength: 17,
  maxInitialsLength: 3,
};

const mapStateToProps = (state) => ({
  brandName: state.configurations.brandName,
  brandLogo: state.configurations.brandLogo,
  deviceType: state.configurations.deviceType,
  isWebview: state.configurations.isWebview,
  flow: state.page.flow,
  userLogged: !!state.page?.data?.payerId,
});

if (process.env.NODE_ENV === ENVIROMENT.TEST) {
  module.exports = Brand;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps)(Brand);
}
