/* global window document */
const { popUpPosition } = require('./Dom');
const { CALLBACK_URL_LOADED } = require('../../constants/app');

const RetryAndTimers = {
  RETRY: 30,
  RETRY_COOL_DOWN: 5000,
  INITIAL_INTERVAL: 10000,
};

class ChallengePopUp {
  static get POPUP_NAME() {
    return 'CheckoutOffChallengePopup';
  }

  constructor() {
    this.popupEl = null; // Popup itself
    this.interval = null; // Service check's loop interval object
    this.tryCount = 1; // Counter counts counting count
    this.lockServiceCall = true; // lock used to avoid parallel calls
  }

  /**
   * Get Popup Configuration
   * @param challengeUrl
   * @returns {{width: number, height: number, retryCoolDown: number, retries: number, name: string, challengeUrl: string}}
   */
  getConfig(challengeUrl) {
    return {
      width: 500,
      height: 600,
      initialInterval: RetryAndTimers.INITIAL_INTERVAL,
      retryCoolDown: RetryAndTimers.RETRY_COOL_DOWN,
      retries: RetryAndTimers.RETRY,
      name: ChallengePopUp.POPUP_NAME,
      challengeUrl,
      completeOnPostmessage: false,
    };
  }

  /**
   * Open Popup window on given challenge's url if it's necessary
   * @param challengeConfig {Object} - Object returned by `ChallengeConfigs`
   */
  open({ challengeUrl, pollingFunction, popupSetup = {}, submitFormId }) {
    const config = Object.assign(this.getConfig(challengeUrl), popupSetup);
    const submitButton = this.createSubmitButton(submitFormId);

    // Always trigger the popup no matter if the user is already logged (show the spinner)
    this.triggerPopup(config);

    if (this.popupEl === null) {
      // Popup was blocked by the browser
      return;
    }

    if (config.completeOnPostmessage) {
      this.listenPopupPostMessages(submitButton);
    } else {
      this.runIntervalCheck(config, submitButton, pollingFunction);
    }
  }

  listenPopupPostMessages(submitButton) {
    const validOrigin = window.location.origin;
    const expectedMessage = CALLBACK_URL_LOADED;

    window.onmessage = ({ origin, data }) => {
      if (origin !== validOrigin || data !== expectedMessage) {
        return null;
      }

      this.onComplete(submitButton);
    };
  }

  runIntervalCheck(config, submitButton, challengeStatusCall) {
    this.lockServiceCall = true;

    setTimeout(() => {
      if (this.interval) {
        clearInterval(this.interval);
      }

      this.interval = setInterval(() => {
        if (this.tryCount >= config.retries || !this.isOpen()) {
          this.stopInterval();
        } else {
          this.executeChallengeRetry(submitButton, challengeStatusCall);
        }

        this.tryCount += 1;
      }, config.retryCoolDown);
    }, config.initialInterval);
  }

  executeChallengeRetry(submitButton, challengeStatusCall) {
    if (this.lockServiceCall) {
      this.lockServiceCall = false; // lock loop

      challengeStatusCall(this.popupEl)
        .then((completed) => {
          this.lockServiceCall = true; // unlock loop

          if (completed) {
            this.onComplete(submitButton);
            this.stopInterval();
          }
        })
        .catch(() => {
          this.lockServiceCall = true; // unlock loop
        });
    }
  }

  onComplete(submitButton) {
    this.closePopup();
    this.submit(submitButton);
  }

  stopInterval() {
    clearInterval(this.interval);
  }

  /**
   * @private
   * Triggers popup (window.open)
   * Open it centered
   * @returns {Window | null}
   */
  triggerPopup({ challengeUrl, width, height, name }) {
    // Fixes dual-screen position - Most browsers - Firefox
    const { left, top } = popUpPosition(width, height);

    this.popupEl = window.open(challengeUrl, name, `scrollbars=yes, width=${width}, height=${height}, top=${top}, left=${left}`); //eslint-disable-line
  }

  /**
   * @private
   */
  createSubmitButton(submitFormId = '') {
    const el = document.createElement('button');
    const form = document.querySelector(`#${submitFormId}`);

    el.setAttribute('type', 'submit');
    el.style.display = 'none';

    form.appendChild(el);

    return el;
  }

  /**
   * @private
   */
  submit(submitButton) {
    submitButton.click();
  }

  /**
   * @private
   */
  isOpen() {
    return this.popupEl && !this.popupEl.closed;
  }

  /**
   * @private
   */
  closePopup() {
    this.popupEl.close();
  }
}

module.exports = new ChallengePopUp();

// Must be exported because is returning and instance of ChallengePopUp and cant be static get
module.exports.RETRY = RetryAndTimers.RETRY;
module.exports.RETRY_COOL_DOWN = RetryAndTimers.RETRY_COOL_DOWN;
module.exports.INITIAL_INTERVAL = RetryAndTimers.INITIAL_INTERVAL;
