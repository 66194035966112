const {
  SITE_ID: { MLC, MPE, MCO },
} = require('../../constants/commons');

const hasBankInterests = (siteId) => [MLC, MPE, MCO].includes(siteId);

const isInterestFree = (paymentMethod) => {
  const interestTotalAmount = Number(paymentMethod?.installment?.interest_total_amount);
  return typeof interestTotalAmount === 'number' && interestTotalAmount === 0;
};

module.exports = {
  hasBankInterests,
  isInterestFree,
};
