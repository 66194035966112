const { getCookie } = require('./CookieUtils');
const { COOKIES } = require('../../constants/app');

/**
 * Obtains the melisession-id by checking the specified priorities:
 * 1. From `props.meliSessionIdFromWebviewOrLogin`
 * 2. From `props.profile._id`
 * 3. From the cookie `MELI_SESSION_ID`
 *
 * @param {Object} configs
 * @returns {string|null} - Returns the melisession-id if found, or null if none exists.
 */
const getMelisessionId = (configs = {}) => {
  const { meliSessionIdFromWebviewOrLogin, profile: { _id: profileId } = {} } = configs;

  const meliSessionIdFromCookie = getCookie(COOKIES.MELI_SESSION_ID);

  return meliSessionIdFromWebviewOrLogin || profileId || meliSessionIdFromCookie;
};

module.exports = {
  getMelisessionId,
};
