const { getQueryParams } = require('./Dom');
const { STEP_NEXT } = require('../spa/actions/types');
const { CAPTCHA_VERSION } = require('../../constants/app');
const generateTokenCaptchaV3 = require('./generateTokenCaptchaV3');

const nextStepAction = ({ data, flow, history, stepActions }) => {
  const queryParams = getQueryParams();
  stepActions[STEP_NEXT](
    data,
    flow.id,
    {
      type: flow.type,
      urlParams: queryParams,
    },
    flow.type,
    queryParams,
    history,
  );
};

const goToNextStep = async (
  {
    data,
    flow,
    history,
    stepActions,
    captcha,
    requestActions,
    requestId,
    captchaConfig,
    captchaToken,
  }) => {
  if (captcha?.version === CAPTCHA_VERSION.V3) {
    if (window.captchaReady) {
      await generateTokenCaptchaV3({ requestActions, requestId, captchaConfig, captchaToken });
    } else if (!window.captchaError) {
      // In case Captcha from google is still loading we will save in window the callback function to execute when ready
      window.executeAction = async () => {
        await generateTokenCaptchaV3({ requestActions, requestId, captchaConfig, captchaToken });
        nextStepAction({ data, flow, history, stepActions });
      };
      // If Google's Api is still loading should save a callback method to continue with normal path if Google's Api fail to load
      window.executeErrorAction = () => {
        nextStepAction({ data, flow, history, stepActions });
      };
      return;
    }
  }

  nextStepAction({ data, flow, history, stepActions });
};

module.exports = { nextStepAction, goToNextStep };
