const React = require('react');

const IconWrapper = require('../IconWrapper');

const LocalPick = ({ className, isRebrand } = {}) => (
  <IconWrapper>
    <svg
      className={className ? className : 'with-custom-color'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.7549 9.005C15.7549 11.0788 14.0737 12.76 11.9999 12.76C9.92608 12.76 8.24491 11.0788 8.24491 9.005C8.24491 6.93117 9.92608 5.25 11.9999 5.25C14.0737 5.25 15.7549 6.93117 15.7549 9.005ZM9.74491 9.005C9.74491 10.2504 10.7545 11.26 11.9999 11.26C13.2453 11.26 14.2549 10.2504 14.2549 9.005C14.2549 7.7596 13.2453 6.75 11.9999 6.75C10.7545 6.75 9.74491 7.7596 9.74491 9.005Z"
        fill="currentColor"
        fill-opacity="0.9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.2519 8.999C20.2519 13.2398 17.6598 17.9095 12.5334 23.0386L12.0029 23.5693L11.4724 23.0386C6.34603 17.9095 3.75391 13.2398 3.75391 8.999C3.75391 4.4432 7.44711 0.75 12.0029 0.75C16.5587 0.75 20.2519 4.4432 20.2519 8.999ZM12.0029 2.25C8.27554 2.25 5.25391 5.27163 5.25391 8.999C5.25391 10.9487 5.89848 13.0507 7.19564 15.3007C7.68292 14.6621 8.452 14.25 9.31733 14.25H14.6699C15.5412 14.25 16.3154 14.6659 16.8049 15.31C18.1056 13.0564 18.7519 10.9514 18.7519 8.999C18.7519 5.27163 15.7303 2.25 12.0029 2.25ZM12.0029 21.4396C13.5515 19.8431 14.8316 18.3054 15.8452 16.8276C15.7935 16.2239 15.287 15.75 14.6699 15.75H9.31733C8.70597 15.75 8.20439 16.2201 8.15432 16.8185C9.16894 18.2991 10.4511 19.8398 12.0029 21.4396Z"
        fill="currentColor"
        fill-opacity="0.9"
      />
    </svg>
  </IconWrapper>
);

module.exports = React.memo(LocalPick);
