import React from 'react';

const AccountMoneyYellow = ({ className, id, ...props }: SVGProps) => (
  <svg
    {...props}
    className={className}
    id={id}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1262_80329)">
      <rect
        width="40"
        height="40"
        rx="20"
        fill="white"
        style={{ mixBlendMode: 'luminosity' }}
      />
      <rect
        width="110"
        height="110"
        transform="translate(-35 -35)"
        fill="#FFE600"
      />
      <g clip-path="url(#clip1_1262_80329)">
        <path
          d="M27.9167 30.0001H13.8611C10.1944 30.0001 8.97217 28.7779 8.97217 27.5557V17.1667C8.97217 12.8889 12.0277 12.8889 15.0833 12.8889C15.5836 12.8889 19.9722 9.83347 23.0278 8.00012C25.4723 8.61124 24.25 8.61124 26.0834 10.4444C27.3056 11.6666 27.3196 12.8889 27.9167 12.8889C29.8723 12.8889 30.7686 14.1111 30.9723 14.7222V17.7778L32.1945 19V23.889L30.9723 24.5001V27.5557C30.9723 28.1668 29.7501 30.0001 27.9167 30.0001Z"
          fill="white"
        />
        <path
          d="M27.9167 12.8317L25.0264 8.97791C24.6515 8.47804 24.0994 8.14072 23.4835 8.03522C22.8676 7.92973 22.2348 8.06408 21.7149 8.41068L14.9976 12.8889"
          stroke="black"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M32.1947 18.3889H27.9169C27.1065 18.3889 26.3293 18.7108 25.7563 19.2838C25.1833 19.8568 24.8613 20.634 24.8613 21.4444C24.8613 22.2548 25.1833 23.032 25.7563 23.605C26.3293 24.1781 27.1065 24.5 27.9169 24.5H32.1947V18.3889Z"
          stroke="black"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.9723 18.3888V15.9444C30.9723 15.134 30.6504 14.3568 30.0774 13.7838C29.5043 13.2107 28.7271 12.8888 27.9167 12.8888H12.0277C11.2174 12.8888 10.4402 13.2107 9.86713 13.7838C9.29409 14.3568 8.97217 15.134 8.97217 15.9444V26.9444C8.97217 27.7548 9.29409 28.532 9.86713 29.1051C10.4402 29.6781 11.2174 30 12.0277 30H27.9167C28.7271 30 29.5043 29.6781 30.0774 29.1051C30.6504 28.532 30.9723 27.7548 30.9723 26.9444V24.5"
          stroke="black"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="19.5"
      stroke="black"
      stroke-opacity="0.07"
    />
    <defs>
      <clipPath id="clip0_1262_80329">
        <rect width="40" height="40" rx="20" fill="white" />
      </clipPath>
      <clipPath id="clip1_1262_80329">
        <rect width="28" height="28" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);

export default React.memo(AccountMoneyYellow);
