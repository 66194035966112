const React = require('react');
const PropTypes = require('prop-types');

const NavbarV2 = require('@cow/core-components/components/NavbarV2');

const { isMobile } = require('../../utils/webview');
const { getBrandNameCompany } = require('../../pages/review/templates/utils/configurations');
const { COMMONS } = require('../../../constants');
const { useLogOut } = require('../../hooks/useLogOut');

const { SITE_ID } = COMMONS;

const NavbarCOWComponent = (
  {
    browserName,
    deviceType,
    flow,
    history,
    payer,
    siteId,
    snackbarActions,
    stepActions,
    theme,
    translations,
    onOpen,
    platform,
    configurations,
  }) => {


  const NavbarMenuOptions = {
    title: translations.YOU_ARE_LOGGED_AS,
    changeUserLabel: translations.CHANGE_ACCOUNT,
    logOutUserLabel: translations.LOG_OUT,
  };

  const { logout } = useLogOut({
    flow,
    siteId,
    browserName,
    history,
    stepActions,
    snackbarActions,
    translations,
    platform,
    configurations,
  });

  return (
    <NavbarV2
      user={{
        fullName: payer.name,
        firstname: payer.first_name,
        lastname: payer.last_name,
        email: payer.email.value,
        avatar: payer.avatar,
      }}
      menuOptions={NavbarMenuOptions}
      theme={theme}
      isMLB={siteId === SITE_ID.MLB}
      isMobile={isMobile(deviceType)}
      logOut={() => logout({ changeUser: false })}
      changeUser={() => logout({ changeUser: true })}
      altLogo={getBrandNameCompany(theme, translations)}
      altUser={payer.name}
      altClosedMenu={translations.YOU_ARE_USING_YOUR_ACCOUNT(payer.first_name)}
      altOpenedMenu={translations.CLOSE_ACCOUNT_OPTION}
      onOpen={onOpen}
    />
  );
};

NavbarCOWComponent.propTypes = {
  flow: PropTypes.object,
  payer: PropTypes.object,
  translations: PropTypes.object,
  theme: PropTypes.string,
  siteId: PropTypes.string,
  deviceType: PropTypes.string,
  browserName: PropTypes.string,
  history: PropTypes.object,
  stepActions: PropTypes.array,
  snackbarActions: PropTypes.array,
  onOpen: PropTypes.func,
  platform: PropTypes.string,
  configurations: PropTypes.object,
};

module.exports = NavbarCOWComponent;
