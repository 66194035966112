const UI = require('./ui');
const APP = require('./app');
const LINKS = require('./links');
const COLORS = require('./colors');
const COMMONS = require('./commons');
const METRICS = require('./metrics');
const SERVICES = require('./services');
const SELECTORS = require('./selectors');
const TRANSLATIONS = require('../app/translation');
const QUALITY_CHECK = require('./qualityCheck');
const ANDES_STYLES_THEME = require('./andesStylesTheme');

module.exports = {
  UI,
  APP,
  LINKS,
  COLORS,
  COMMONS,
  METRICS,
  SERVICES,
  SELECTORS,
  TRANSLATIONS,
  QUALITY_CHECK,
  ANDES_STYLES_THEME
};
