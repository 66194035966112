const React = require('react');

const {bindActionCreators} = require('redux');
const PropTypes = require('prop-types');

const translate = require('../../../../translation');
const { APP, COMMONS} = require('../../../../../constants');
const {getCompanyTheme} = require('../../../review/templates/utils/configurations');
const PaymentMethodInfo = require('../../components/PaymentMethodInfo');
const SummaryDesktop = require('../../components/SummaryDesktop');
const {propsPaymentMethodInfo} = require('../../utils/formatProps');
const useActions = require('../../hooks/useActions');
const {PAGE_ACTIONS} = require('../../../../../constants/app');
const SNACKBAR_ACTIONS = require('../../../../spa/actions/snackbar');
const STEP_ACTIONS = require('../../../../spa/actions/step');
const ANIMATION_ACTIONS = require('../../../../spa/actions/animations');
const CAPTCHA_TOKEN_ACTIONS = require('../../../../spa/actions/captchaToken');
const REQUEST_ACTIONS = require('../../../../spa/actions/request');
const LOADING_ACTIONS = require('../../../../spa/actions/loading');
const Brand = require('@cow/core-components/components/Brand');
const LegalsFooter = require('../../../../components/LegalsFooter');
const DynamicContent = require('../../../../components/DynamicContent');
const Layout = require('../../../../components/LayoutContainer');
const ShippingCard = require('../../../../components/ShippingCard');
const BackLink = require("@cow/core-components/components/BackLink");
const {sanitizeUrl} = require("../../../../utils/UrlUtils");
const connectToReduxAndInjectI18n = require("../../../../utils/connectToRedux");
const ButtonBackURLs = require('../../../../components/ButtonBackURLs');
const Footer = require("@cow/core-components/components/Footer");
const {hasCaptcha} = require("../../../../utils/terms-and-conditions");

const {SUMMARY_ITEMS_TYPES} = APP;
const {useState, createRef} = React;

const CombinedPaymentReviewDesktopTemplate = (props) => {

  const translations = translate(props.i18n);

  const tooltipContainer = createRef();

  const [showDiscountModal, setShowDiscountModal] = useState(false);


  const {
    flow,
    siteId,
    blockedByCaptcha,
    requestLoading,
    deviceType,
    loadingPayButton,
    loadingStopAnimation,
    stepActions,
    history,
    requestActions,
    loadingActions,
    setEmailErrorMessage,
    emailErrorMessage,
    email,
    rebrandingMP,
    emailComponent,
    analytics,
    currentStep,
    trackingPath,
    urls,
    captchaToken,
    captchaConfig,
    step_model: {
      brand,
      pcj,
      terms_and_conditions: termsAndConditions,
      main_action: mainAction,
      challenge_instructions,
      transaction_type,
      payment_methods,
      subscription,
      step_title,
      payer,
      captcha,
      summary,
      incentives,
      split_payment,
      navigation,
      shipping,
    },
  } = props;

  const {changePaymentMethod, pay, changeShippingOption, changeShippingAddress} = useActions({
    flow,
    history,
    stepActions,
    captcha,
    captchaToken,
    captchaConfig,
    requestActions,
    challengesInstructions: challenge_instructions,
    payer,
    loadingActions,
    setEmailErrorMessage,
    email,
    translations,
    emailErrorMessage,
  });

  const [isCouponVisible, setIsCouponVisible] = React.useState(false);

  const theme = getCompanyTheme(props.configs?.internalConfigurations);

  const progressDone = loadingStopAnimation && requestLoading;

  const hasDiscountCodeApplied = summary?.items.find((item) => item.type === SUMMARY_ITEMS_TYPES.COUPON_DISCOUNT);

  const [firstPaymentMethod, secondPaymentMethod] = propsPaymentMethodInfo({
    siteId,
    translations,
    rebrandingMP,
    paymentMethods: payment_methods,
    isSplitCard: split_payment
  });

  const templateCase = props.templateCase || 'default';

  const mainContent = (
    <>
      <PaymentMethodInfo
        {...firstPaymentMethod}
        actionName={PAGE_ACTIONS.CHANGE_PAYMENT_OPTION_FIRST}
        changePaymentMethod={changePaymentMethod}
      />
      <PaymentMethodInfo
        {...secondPaymentMethod}
        actionName={PAGE_ACTIONS.CHANGE_PAYMENT_OPTION_SECOND}
        changePaymentMethod={changePaymentMethod}
      />
      <ShippingCard
        shipping={shipping}
        translations={translations}
        changeShippingOption={changeShippingOption}
        changeShippingAddress={changeShippingAddress}
      />
      <DynamicContent
        defaultContent={emailComponent}
      />
      {/* Back link */}
      {navigation?.back_urls?.failure?.length > 0 && (
        <ButtonBackURLs label={translations.RETURN_TO_SITE} url={navigation.back_urls.failure} />
      )}
    </>
  );

  const sidebarContent = (
    <>
      {brand && <Brand image={brand.avatar} name={brand.name}/>}
      {summary?.items?.length &&
        <SummaryDesktop
          {...{
            pcj,
            summary,
            translations,
            tooltipContainer,
            setShowDiscountModal,
            transaction_type,
            deviceType,
            mainAction,
            subscription,
            captcha,
            termsAndConditions,
            blockedByCaptcha,
            isCouponVisible,
            hasDiscountCodeApplied,
            theme,
            loadingPayButton,
            progressDone,
            siteId,
            incentives,
            payer,
            onPayClick: pay,
          }}
        />
      }
    </>
  );

  const footerContent = props.checkoutType !== COMMONS.CHECKOUT_TYPE.MODAL && (
    <section className="layout__col-footer">
      <Footer
        requiredMessage={translations.PROCESSED_BY_MERCADO_PAGO}
        requiredScreenReaderMessage={translations.SCREEN_READER_FOOTER_MESSAGE}
        deviceType={props.deviceType}
        optionalInformation={hasCaptcha(termsAndConditions?.has_captcha, translations)}
      />
    </section>
  );

  const stepTitle = {
    text: shipping ? translations.CHECK_YOUR_PURCHASE : translations.CHECK_YOUR_PAYMENT
  }

  return (
    <Layout
      brand={brand}
      deviceType={deviceType}
      history={history}
      mainContent={mainContent}
      footerContent={footerContent}
      payer={payer}
      sidebarContent={sidebarContent}
      siteId={siteId}
      stepTitle={stepTitle}
      templateCase={templateCase}
      theme={theme}
      translations={translations}
      currentStep={currentStep}
      trackingPath={trackingPath}
      analytics={analytics}
      urls={urls}
      navigation={navigation}
    />
  );

};

const mapStateToProps = (state) => ({
  flow: state.page.flow,
  configs: state.configurations,
  firstRender: state.page.firstRender,
  basePath: state.configurations.basePath,
  captchaConfig:
  state.configurations.captcha,
  browserName:
  state.configurations.browserName,
  blockedByCaptcha: state.captchaToken.blockedByCaptcha,
  loadingPayButton: state.request.loadingPayButton,
  requestLoading: state.request.loading,
  loadingStopAnimation: state.request.loadingStopAnimation,
});

const mapDispatchToProps = (dispatch) => ({
  snackbarActions: bindActionCreators(SNACKBAR_ACTIONS, dispatch),
  stepActions: bindActionCreators(STEP_ACTIONS, dispatch),
  animationActions: bindActionCreators(ANIMATION_ACTIONS, dispatch),
  captchaToken: bindActionCreators(CAPTCHA_TOKEN_ACTIONS, dispatch),
  requestActions: bindActionCreators(REQUEST_ACTIONS, dispatch),
  loadingActions: bindActionCreators(LOADING_ACTIONS, dispatch),
});

CombinedPaymentReviewDesktopTemplate.propTypes = {
  flow: PropTypes.object,
  siteId: PropTypes.string,
  blockedByCaptcha: PropTypes.bool,
  requestLoading: PropTypes.bool,
  deviceType: PropTypes.string,
  loadingPayButton: PropTypes.bool,
  loadingStopAnimation: PropTypes.bool,
  stepActions: PropTypes.object,
  history: PropTypes.object,
  requestActions: PropTypes.object,
  loadingActions: PropTypes.object,
  setEmailErrorMessage: PropTypes.func,
  emailErrorMessage: PropTypes.string,
  email: PropTypes.string,
  step_model: PropTypes.object,
  rebrandingMP: PropTypes.bool,
  analytics: PropTypes.element,
  currentStep: PropTypes.string,
  trackingPath: PropTypes.string,
  urls: PropTypes.array,
  captchaToken: PropTypes.object,
  captchaConfig: PropTypes.object,
};


module.exports = connectToReduxAndInjectI18n(
  CombinedPaymentReviewDesktopTemplate,
  mapStateToProps,
  mapDispatchToProps
);
