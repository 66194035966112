import React from 'react';

const DebitCard = ({ ...props }: SVGProps) => (
  <svg
    {...props}
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.20107 9.6H7.99787V8H3.20107V9.6Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
    <path
      d="M7.99787 6.4H3.20107V4.8H7.99787V6.4Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
    <path
      d="M9.6016 9.6H14.3984V8H9.6016V9.6Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
    <path
      d="M20.7989 9.6H16.0021V8H20.7989V9.6Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.6 0H2.4C1.07452 0 0 1.07452 0 2.4V15.2C0 16.5255 1.07452 17.6 2.4 17.6H21.6C22.9255 17.6 24 16.5255 24 15.2V2.4C24 1.07452 22.9255 0 21.6 0ZM1.6 2.4C1.6 1.95817 1.95817 1.6 2.4 1.6H21.6C22.0418 1.6 22.4 1.95817 22.4 2.4V15.2C22.4 15.6418 22.0418 16 21.6 16H2.4C1.95817 16 1.6 15.6418 1.6 15.2V2.4Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
  </svg>
);

export default React.memo(DebitCard);
