/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';

// Andes Components
import { ProgressButton } from '@andes/button';
import { Typography } from '@andes/typography';

// Icons
import Lock from '../Icons/Lock';

// Constants
import { Theme } from '../../constants/commons';

export type StickyPayButtonProps = {
  withBackgroundOnHandleScroll?: boolean;
  label?: string;
  securityPaymentInformationLabel?: string;
  payButtonHelperText?: string | React.ReactNode;
  onPayClick?: () => void;
  loadingButton?: boolean;
  companyTheme?: Theme;
  timeout?: number;
  disablePayButton?: boolean;
};

export default function StickyPayButton({
  withBackgroundOnHandleScroll = false,
  label = 'Pagar',
  securityPaymentInformationLabel,
  loadingButton = false,
  companyTheme = Theme.DEFAULT,
  timeout = 5000,
  payButtonHelperText,
  onPayClick,
  disablePayButton = false,
}: StickyPayButtonProps) {
  const [isBottom, setIsBottom] = useState(false);

  const handleScroll = () => {
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const { body } = document;
    const html = document.documentElement;

    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const windowBottom = windowHeight + window.scrollY;

    setIsBottom(windowBottom >= docHeight);
  };

  useEffect(() => {
    if (withBackgroundOnHandleScroll) {
      handleScroll();
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <div
      className={`cow-sticky__pay cow-sticky__pay--${companyTheme} ${
        isBottom ? 'cow-sticky__pay--bottom' : ''
      }`}
    >
      <ProgressButton
        className="cow-sticky__pay__button"
        onClick={onPayClick}
        loading={loadingButton}
        timeout={timeout}
        disabled={disablePayButton}
      >
        {label}
      </ProgressButton>

      {securityPaymentInformationLabel && (
        <div className="cow-sticky__pay__secure">
          <Lock className="cow-sticky__pay__secure--icon" />

          <Typography
            className="cow-sticky__pay__secure--text"
            color="secondary"
            type="body"
            size="xs"
            weight="semibold"
          >
            {securityPaymentInformationLabel}
          </Typography>
        </div>
      )}

      {!!payButtonHelperText && typeof payButtonHelperText === 'string' ? (
        <div className="cow-sticky__pay__payment-instructions">
          <Typography
            type="body"
            size="xs"
            color="secondary"
            weight="semibold"
            className="cow-sticky__pay__payment-instructions--text"
          >
            {payButtonHelperText}
          </Typography>
        </div>
      ) : (
        payButtonHelperText
      )}
    </div>
  );
}
