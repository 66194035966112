const React = require('react');

const IconWrapper = require('../IconWrapper');

const Pin = ({ className } = {}) => (
  <IconWrapper>
    <svg className={className ? className : "with-custom-color"} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M10 1a6.347 6.347 0 016.347 6.347c0 3.043-1.975 6.838-5.89
        11.432l-.457.536-.457-.536c-3.915-4.594-5.89-8.389-5.89-11.432A6.347
        6.347 0 0110 1zm0 1.2a5.147 5.147 0 00-5.147 5.147c0 2.578 1.706 5.963
        5.147 10.11 3.441-4.147 5.147-7.532 5.147-10.11A5.147 5.147 0 0010 2.2zm0
        2.985a2.41 2.41 0 110 4.82 2.41 2.41 0 010-4.82zm0 1.2a1.21 1.21 0 100
        2.42 1.21 1.21 0 000-2.42z"
      />
    </svg>
  </IconWrapper>
);

module.exports = React.memo(Pin);
