/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');
const { injectI18n } = require('nordic/i18n');

const translate = require('../../translation');
const ApiService = require('../../service/api');
const SecureFieldInput = require('../SecureFieldInput');
const PaymentMethodUtils = require('../../utils/PaymentMethodUtils');
const CreditCardActions = require('../../spa/actions/creditCard');
const MercadoPagoSdkActions = require('../../spa/actions/mercadopagoSdk');
const { getQueryParams } = require('../../utils/Dom');
const { logErrorFromClient } = require('../../utils/logTags');
const { navigateToException } = require('../../utils/navigateTo');
const { injectSecureFieldCardFormValidations } = require('../../utils/validator-provider');
const { secureFieldsInstance } = require('../../utils/secure-fields');

const {
  COMMONS: { ENVIROMENT },
  APP: {
    ERROR_SPA: { SECURE_FIELD_WRAPPER_ERROR },
    SECURE_FIELDS: {
      FIELDS: { CARD_NUMBER, EXPIRATION_DATE, SECURITY_CODE },
      EVENTS,
      ERROR_CAUSES,
    },
  },
} = require('../../../constants');

const {
  CREDIT_CARD_CLEAN,
  CREDIT_CARD_ISSUER,
  CREDIT_CARD_GUESSING,
  CREDIT_CARD_PC_NUMBER,
  MERCADOPAGO_SDK_SECURE_FIELD_MOUNT,
  MERCADOPAGO_SDK_SECURE_FIELD_UNMOUNT,
  MERCADOPAGO_SDK_TOKENIZE_ERRORS_CLEAN,
  MERCADOPAGO_SDK_SECURE_FIELD_EXCEPTION,
} = require('../../spa/actions/types');

const { useState, useEffect, useCallback, useMemo } = React;

const SecureFieldWrapper = (props) => {
  const {
    id,
    label,
    hint,
    field,
    paymentMethods,
    deviceType,
    creditCardActions,
    mercadoPagoSdkActions,
    flow,
    history,
    sdkLoaded,
    sdkErrors,
    exceptionThrown,
    i18n,
    showIcon,
    cvvSettings,
    secureFieldController,
    modeCvvWithEsc,
    placeholder,
    maxInstallments,
  } = props;

  let lastPaymentMethodIdSelected = null;

  const translations = translate(i18n);

  const [isFocused, setFocus] = useState(false);
  const [error, setError] = useState(null);
  const [settings, setSettings] = useState({ ...cvvSettings, mode: modeCvvWithEsc });
  const [show, setShow] = useState(true);
  const [paymentMethodIcon, setPaymentMethodIcon] = useState('');
  const [selectedPaymentMethodId, setSelectedPaymentMethod] = useState(null);

  const errorMap = useMemo(
    () => ({
      cardNumber: {
        [ERROR_CAUSES.INVALID_LENGTH]: translations.INCOMPLETE_CARD_NUMBER,
        [ERROR_CAUSES.INVALID_TYPE]: translations.REQUIRED_FIELD,
        [ERROR_CAUSES.INVALID_VALUE]: translations.INVALID_CARD_NUMBER,
        [ERROR_CAUSES.EMPTY_VALUE]: translations.REQUIRED_FIELD,
        [ERROR_CAUSES.INVALID_PAYMENT_METHOD]: translations.INVALID_PAYMENT_METHOD,
      },
      securityCode: {
        [ERROR_CAUSES.INVALID_LENGTH]: translations.INVALID_CVV,
        [ERROR_CAUSES.INVALID_TYPE]: translations.REQUIRED_FIELD,
        [ERROR_CAUSES.INVALID_VALUE]: translations.INVALID_CVV,
        [ERROR_CAUSES.EMPTY_VALUE]: translations.INCOMPLETE_CVV,
      },
      expirationDate: {
        [ERROR_CAUSES.INVALID_LENGTH]: translations.INCOMPLETE_EXP_DATE,
        [ERROR_CAUSES.INVALID_TYPE]: translations.REQUIRED_FIELD,
        [ERROR_CAUSES.INVALID_VALUE]: translations.INVALID_EXP_DATE,
        [ERROR_CAUSES.EMPTY_VALUE]: translations.REQUIRED_FIELD,
      },
      default: translations.INVALID_FIELD,
    }),
    [i18n],
  );

  const cvvPlaceholder = `${translations.PLACEHOLDER_CVV} ${'1234'.substring(0, cvvSettings?.length)}`;
  const baseSecureFieldConfig = useMemo(
    () => ({
      /**
       * in case we need to change the fonts of the inputs we need to change this line
       */
      customFonts: [
        {
          src: 'https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/300-400.css',
        },
      ],
      /**
       * Custom style to match andes textfield
       */
      style: {
        fontFamily: 'Proxima Nova',
        fontStyle: 'normal',
        fontWeight: '300',
        color: '#000000',
        placeholderColor: 'rgba(0, 0, 0, 0.55)',
      },
      placeholder: id === 'cvv' ? cvvPlaceholder : placeholder,
    }),
    [],
  );

  const createSecureField = (MercadoPagoSDK, options, fieldKey, secureFieldId) => {
    const availableFields = [CARD_NUMBER, EXPIRATION_DATE, SECURITY_CODE];

    if (availableFields.includes(fieldKey)) {
      let secureElement = secureFieldsInstance.getField(fieldKey);

      if (secureElement) {
        secureElement.unmount();
        secureFieldsInstance.setField(fieldKey, null);
      }

      secureElement = MercadoPagoSDK.fields.create(fieldKey, options).mount(secureFieldId);
      secureFieldsInstance.setField(fieldKey, secureElement);

      return secureElement;
    }

    return null;
  };

  const unmountSecureField = (fieldKey) => {
    const secureElement = secureFieldsInstance.getField(fieldKey);

    if (!secureElement) {
      return;
    }

    secureElement.unmount();
    secureFieldsInstance.setField(fieldKey, null);
  };

  /**
   * remove card icon
   * reset payment card
   */
  const creditCardClean = () => {
    setPaymentMethodIcon(null);
    creditCardActions[CREDIT_CARD_CLEAN]();
  };

  /**
   * Reset secure fields instances from SDK and global scope on wrapper unmount
   */
  const unmmountSecureFields = useCallback(() => {
    mercadoPagoSdkActions[MERCADOPAGO_SDK_TOKENIZE_ERRORS_CLEAN]();
    Object.keys(secureFieldsInstance.getFields()).forEach((fieldKey) => {
      unmountSecureField(fieldKey);
    });
  }, [mercadoPagoSdkActions]);

  const binChangeHandler = useCallback(
    async (guessing, MercadoPagoSDK) => {
      const { bin } = guessing;

      if (!bin) {
        // if there is no bin on bin change means the number was deleted and the store needs to be cleared
        creditCardClean();
        return;
      }

      creditCardActions[CREDIT_CARD_PC_NUMBER](bin);

      /**
       * Processing payment methods - guessing
       * this should be done by the SDK but currently we have a discrepancy on
       * how we perform the request to PM to retrieve the available payment methods
       */
      const pmUtil = new PaymentMethodUtils(paymentMethods, { max_installments: maxInstallments });
      const paymentMethod = pmUtil.getPaymentMethod(bin);
      const { id: paymentMethodId, settings: paymentMethodSettings } = paymentMethod;

      /**
       * Store the selected payment method id to get the card logo
       * on the component state change
       */
      if (paymentMethodId !== lastPaymentMethodIdSelected) {
        // update the lastPaymentMethodIdSelected
        lastPaymentMethodIdSelected = paymentMethodId;
        setSelectedPaymentMethod(lastPaymentMethodIdSelected);
      }

      // get pci data settings from guessing
      const { card_number, security_code } = paymentMethodSettings[0];

      // update pci fields
      const fields = secureFieldsInstance.getFields();

      if (fields.cardNumber?.update) {
        fields.cardNumber.update({ settings: card_number });
      }

      if (security_code.mode === 'optional') {
        mercadoPagoSdkActions[MERCADOPAGO_SDK_SECURE_FIELD_UNMOUNT]({
          field: SECURITY_CODE,
        });
      } else if (fields.securityCode?.update) {
        fields.securityCode.update({
          placeholder: cvvPlaceholder,
          settings: security_code,
        });
      } else {
        mercadoPagoSdkActions[MERCADOPAGO_SDK_SECURE_FIELD_MOUNT]({
          field: SECURITY_CODE,
          settings: security_code,
        });
      }

      // set the guessing data in the store
      creditCardActions[CREDIT_CARD_GUESSING]({ bin, paymentMethod });
      const issuers = await MercadoPagoSDK.getIssuers({ paymentMethodId, bin });

      // set the issuer data in the store
      creditCardActions[CREDIT_CARD_ISSUER](issuers[0]);
    },
    [creditCardActions, mercadoPagoSdkActions, paymentMethods],
  );

  const resolveErrorCause = useCallback(
    (errors) => {
      let resolvedCause = ERROR_CAUSES.INVALID_VALUE;

      const hasInvalidValue = errors.some((it) => it?.cause === ERROR_CAUSES.INVALID_VALUE);
      const hasCurrentLength = errors.some((it) => it?.details?.currentLength > 0);
      const isEmpty = !!errors.some((it) => it?.details?.reason === ERROR_CAUSES.EMPTY_VALUE);
      const hasInvalidMonth = !!errors.some((it) => it?.details?.currentMonth === 0);
      const hasInvalidLength = !!errors.some((it) => it?.cause === ERROR_CAUSES.INVALID_LENGTH);
      const hasInvalidPaymentMethod = !!errors.some((it) => it?.cause === ERROR_CAUSES.INVALID_PAYMENT_METHOD);

      if (hasInvalidValue && !isEmpty) {
        resolvedCause = ERROR_CAUSES.INVALID_VALUE;
      } else if (isEmpty) {
        if (hasCurrentLength || hasInvalidMonth) {
          resolvedCause = ERROR_CAUSES.INVALID_LENGTH;
        } else {
          resolvedCause = ERROR_CAUSES.EMPTY_VALUE;
        }
      } else if (hasInvalidLength) {
        resolvedCause = ERROR_CAUSES.INVALID_LENGTH;
      } else if (hasInvalidPaymentMethod) {
        resolvedCause = ERROR_CAUSES.INVALID_PAYMENT_METHOD;
      }

      return [resolvedCause];
    },
    [field],
  );

  const fieldValidation = useCallback(
    (err, currentField) => {
      if (Array.isArray(err) && err.length > 0 && errorMap[currentField]) {
        let customMessage = errorMap.default;

        if (err.length > 0) {
          const [resolvedCause] = resolveErrorCause(err);
          customMessage = errorMap[currentField][resolvedCause] || customMessage;
        }

        setError(customMessage);
      }
    },
    [resolveErrorCause, errorMap],
  );

  const getFieldConfig = (fieldType, baseConfig) => {
    const config = { ...baseConfig };

    if (fieldType === CARD_NUMBER) {
      config.enableLuhnValidation = true;
    }

    return config;
  };

  const mountSecureField = useCallback(
    (fieldKey, MercadoPagoSDK) => {
      if (!MercadoPagoSDK) {
        return;
      }

      const secureFieldConfig = getFieldConfig(fieldKey, baseSecureFieldConfig);
      const secureElement = createSecureField(MercadoPagoSDK, secureFieldConfig, fieldKey, id);

      if (secureElement) {
        // set the exception falg to false when mounting the Secure Fields
        mercadoPagoSdkActions[MERCADOPAGO_SDK_SECURE_FIELD_EXCEPTION](false);

        // update cvv with settings
        if (fieldKey === SECURITY_CODE && cvvSettings) {
          secureElement.on(EVENTS.READY, () => secureElement.update({ settings }));
        }

        // if the secure field is cardNumber subscribe to the binChange event
        if (fieldKey === CARD_NUMBER) {
          secureElement.on(EVENTS.BIN_CHANGE, async (guessing) => binChangeHandler(guessing, MercadoPagoSDK));
        }

        // control the input state / color on different events: focus
        secureElement.on(EVENTS.FOCUS, () => setFocus(true));

        // control the input state / color on different events: blur
        secureElement.on(EVENTS.BLUR, () => setFocus(false));

        // this is a general error event. ie: failed to create an element or invalid element.
        secureElement.on(EVENTS.ERROR, (err) => {
          if (!exceptionThrown && err) {
            const originError = '[SecureFieldWrapper][mountSecureField]';
            const { data_error } = logErrorFromClient(err, SECURE_FIELD_WRAPPER_ERROR, originError);
            mercadoPagoSdkActions[MERCADOPAGO_SDK_SECURE_FIELD_EXCEPTION](true);
            navigateToException(history, flow.type, getQueryParams(), data_error, SECURE_FIELD_WRAPPER_ERROR);
          }
        });
      }
    },
    [
      id,
      baseSecureFieldConfig,
      cvvSettings,
      exceptionThrown,
      mercadoPagoSdkActions,
      flow.type,
      history,
      settings,
      binChangeHandler,
    ],
  );

  /**
   * Toggle (on/off) a secure field if necessary
   * ie: optional cvv +  new card
   * Triggered by show state
   */
  useEffect(() => {
    if (!show) {
      unmountSecureField(field);
    } else {
      const { MercadoPagoSDK } = window;
      mountSecureField(field, MercadoPagoSDK);
    }
  }, [show]);

  /**
   * Mount and initialize secure fields.
   * Unmounts all securefields on exit.
   * Triggered by changes on SDK loaded prop
   */
  useEffect(() => {
    if (sdkLoaded) {
      const { MercadoPagoSDK } = window;
      mountSecureField(field, MercadoPagoSDK);
      return unmmountSecureFields;
    }
  }, [sdkLoaded, field]);

  /**
   * update errors from the store when tokenization failed
   */
  useEffect(() => {
    if (sdkErrors[field]) {
      const err = sdkErrors[field];
      fieldValidation(err, field);
    } else {
      // cleanup errors from state
      setError(null);
    }
  }, [sdkErrors, field, fieldValidation]);

  useEffect(() => {
    if (typeof secureFieldController !== 'undefined') {
      setShow(secureFieldController.show);
      if (secureFieldController.show) {
        setSettings(secureFieldController.settings);
      }
    }
  }, [secureFieldController]);

  /**
   * Get Payment method logo
   */
  const getPaymentMethodLogo = async (paymentMethodId) => {
    if (paymentMethodId) {
      try {
        const logo = await ApiService.getLogoUrlById(paymentMethodId);
        setPaymentMethodIcon(logo);
      } catch (err) {
        setPaymentMethodIcon(null);
      }
    }
  };
  /**
   * Decouple de payment method icon away from the issuer guessing
   * to avoid blocking essentials requests
   */
  useEffect(() => {
    getPaymentMethodLogo(selectedPaymentMethodId);
  }, [selectedPaymentMethodId]);

  const showIconCvv = field === SECURITY_CODE && showIcon && deviceType;

  return (
    show && (
      <SecureFieldInput
        label={label}
        hint={hint}
        id={id}
        field={field}
        errorMessage={error || props.error}
        showCvvIcon={showIconCvv}
        paymentMethodIcon={paymentMethodIcon}
        isFocused={isFocused}
      />
    )
  );
};

SecureFieldWrapper.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  field: PropTypes.string,
  cvvSettings: PropTypes.shape({
    mode: PropTypes.string,
    length: PropTypes.number,
  }),
  creditCardActions: PropTypes.shape({}),
  mercadoPagoSdkActions: PropTypes.shape({}),
  exceptionThrown: PropTypes.bool,
  deviceType: PropTypes.string,
  showIcon: PropTypes.bool,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      payment_method_id: PropTypes.string,
      name: PropTypes.string,
      settings: PropTypes.arrayOf(
        PropTypes.shape({
          bin: PropTypes.object,
          card_number: PropTypes.object,
          security_code: PropTypes.object,
        }),
      ),
    }),
  ),
  flow: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  sdkLoaded: PropTypes.bool,
  sdkErrors: PropTypes.shape({}),
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  secureFieldController: PropTypes.shape({
    show: PropTypes.bool,
    settings: PropTypes.shape({}),
  }),
  modeCvvWithEsc: PropTypes.string,
  placeholder: PropTypes.string,
  maxInstallments: PropTypes.number.isRequired,
};

SecureFieldWrapper.defaultProps = {
  id: '',
  label: '',
  hint: '',
  field: '',
  cvvSettings: {
    mode: 'mandatory',
    length: 3,
  },
  deviceType: '',
  creditCardActions: {},
  mercadoPagoSdkActions: {},
  exceptionThrown: false,
  showIcon: false,
  paymentMethods: [],
  flow: {
    id: '',
    type: '',
  },
  history: {},
  sdkLoaded: false,
  sdkErrors: {},
  secureFieldController: undefined,
  modeCvvWithEsc: 'mandatory',
  placeholder: '',
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
/* istanbul ignore next: cant test it with tests */
const mapDispatchToProps = (dispatch) => ({
  creditCardActions: bindActionCreators(CreditCardActions, dispatch),
  mercadoPagoSdkActions: bindActionCreators(MercadoPagoSdkActions, dispatch),
});
/* istanbul ignore next: cant test it with tests */
const mapStateToProps = (state, props) => ({
  deviceType: state?.configurations?.deviceType,
  sdkLoaded: state.mercadopagoSdk.loaded,
  sdkErrors: state.mercadopagoSdk.errors,
  exceptionThrown: state.mercadopagoSdk.exceptionThrown,
  flow: state.page.flow,
  secureFieldController: state.mercadopagoSdk.secureFields[props.field],
  modeCvvWithEsc: state.esc?.cardTokenId ? 'optional' : 'mandatory',
  maxInstallments: state.installment.maxInstallments,
  validateNewCardSDK: state.page.flow.step === 'card_form',
});

if (process.env.NODE_ENV === ENVIROMENT.TEST) {
  module.exports = SecureFieldWrapper;
} else {
  /**
   * @TODO
   * This HOC injectSecureFieldCardFormValidations needs to be deleted after migrating the card-form to bricks
   * and we also need TODO it work for express, congrats, and paid combination
   */
  const WrapperWithHoc = injectSecureFieldCardFormValidations(SecureFieldWrapper);
  /* istanbul ignore next: cant test it with tests */
  module.exports = injectI18n(connect(mapStateToProps, mapDispatchToProps)(WrapperWithHoc));
}
