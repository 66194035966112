const React = require('react');

const { useState, useEffect, useCallback } = React;
const PropTypes = require('prop-types');

const { Typography } = require('@andes/typography');

const { getPaymentMethodId } = require('../utils/buildPayment');

const ModifyPaymentMethodText = ({ callback, modifyText }) => (
    <Typography 
      id='review-saved-card__change-payment-method'
      onClick={callback}
      component="a"
      color="link"
      size="s"
      weight="semibold">
      {modifyText}
    </Typography>
  )

ModifyPaymentMethodText.propTypes = {
  modifyText: PropTypes.string.isRequired,
};

function transformPaymentMethodsToCards({ paymentMethods, callback, rebrandingMP, modifyText }) {
  if (!Array.isArray(paymentMethods)) {
    return [];
  }

  return paymentMethods.map((paymentMethod) => {
    const paymentMethodId = getPaymentMethodId(paymentMethod, rebrandingMP); 

    return {
      id: paymentMethod?.option_id,
      className: "review-saved-card-item",
      title: paymentMethod?.title,
      description: paymentMethod?.detail,
      method: paymentMethodId,
      promotion: paymentMethod?.additional_info,
      withVisuallyHidden: false,
      paymentMethodId,
      onClick: callback,
      rightContent: <ModifyPaymentMethodText callback={callback} modifyText={modifyText} />,
    };
  });
}

const usePaymentCard = ({
  paymentMethods,
  rebrandingMP,
  handleChangePaymentMethod,
  modifyText,
}) => {
  const transformMethods = useCallback(() => 
    transformPaymentMethodsToCards({
      paymentMethods,
      rebrandingMP,
      callback: handleChangePaymentMethod,
      modifyText
    }), 
    [paymentMethods, rebrandingMP, handleChangePaymentMethod, modifyText]
  );

  const [paymentCard, setPaymentCardList] = useState(transformMethods);

  useEffect(() => {
    setPaymentCardList(transformMethods());
  }, [transformMethods]);

  return {
    paymentCard,
  };
}

usePaymentCard.propTypes = {
  paymentMethods: PropTypes.array.isRequired,
  rebrandingMP: PropTypes.bool,
  handleChangePaymentMethod: PropTypes.func.isRequired,
};

module.exports = {
  usePaymentCard,
  transformPaymentMethodsToCards
}