import type { TypographyProps } from '@andes/typography';
import type { MoneyAmountProps, MoneyAmountSize } from '@andes/money-amount';

import { Typography } from '@andes/typography';
import React from 'react';
import { MoneyAmount } from '@andes/money-amount';
import { VisuallyHidden } from '@andes/common';

import { DeviceType } from '../../../constants/commons';
import {
  AndesTypographyColors,
  AndesTypographySizes,
} from '../../../constants/styles';

type MoneyAmountValues = {
  cents: string;
  fraction: string;
  centsType: MoneyAmountProps['centsType'];
  symbol: MoneyAmountProps['symbol'];
  srLabel: string;
};

type AmountDetailProps = {
  totalAmount?: MoneyAmountValues;
  isPromotion?: boolean;
  totalAmountSuffix?: string;
  deviceType?: DeviceType;
  content?: string;
  ariaHiddenMoneyAmount?: boolean;
  isRebrandingMP?: boolean;
};

const AmountDetail = ({
  totalAmount,
  isPromotion = false,
  totalAmountSuffix = '',
  deviceType = DeviceType.DESKTOP,
  content,
  ariaHiddenMoneyAmount = false,
  isRebrandingMP = false,
}: AmountDetailProps) => {
  const typographyProps: Partial<TypographyProps> = {
    type: 'body',
  };

  const suffixTypographyProps: Partial<TypographyProps> = {
    ...typographyProps,
    color: AndesTypographyColors.SECONDARY,
    size:
      DeviceType.DESKTOP === deviceType
        ? AndesTypographySizes.M
        : AndesTypographySizes.XS,
  };

  let size: AndesTypographySizes;

  if (isRebrandingMP) {
    size = AndesTypographySizes.S;
  } else {
    size =
      DeviceType.DESKTOP === deviceType
        ? AndesTypographySizes.L
        : AndesTypographySizes.XS;
  }

  const contentTypographyProps: Partial<TypographyProps> = {
    ...typographyProps,
    color: isPromotion
      ? AndesTypographyColors.POSITIVE
      : AndesTypographyColors.SECONDARY,
    size,
  };

  let moneyAmountSize: MoneyAmountSize;

  if (isRebrandingMP) {
    moneyAmountSize = deviceType === DeviceType.DESKTOP ? 16 : 14;
  } else {
    moneyAmountSize = deviceType === DeviceType.DESKTOP ? 16 : 12;
  }

  return (
    <div className="installments-right-content">
      {totalAmount ? (
        <>
          <VisuallyHidden>{`${totalAmount.srLabel} `}</VisuallyHidden>
          {ariaHiddenMoneyAmount ? (
            <div aria-hidden={ariaHiddenMoneyAmount}>
              <MoneyAmount
                centsType={totalAmount.centsType}
                size={moneyAmountSize}
                symbol={totalAmount.symbol}
                value={{
                  cents: totalAmount.cents,
                  fraction: totalAmount.fraction,
                }}
                className="total-amount"
              />
            </div>
          ) : (
            <MoneyAmount
              centsType={totalAmount.centsType}
              size={moneyAmountSize}
              symbol={totalAmount.symbol}
              value={{
                cents: totalAmount.cents,
                fraction: totalAmount.fraction,
              }}
              className="total-amount"
            />
          )}
          {totalAmountSuffix && (
            <Typography
              {...suffixTypographyProps}
              component={'span'}
              className="total-amount-suffix"
              aria-hidden
            >
              {totalAmountSuffix}
            </Typography>
          )}
        </>
      ) : (
        <Typography
          {...contentTypographyProps}
          aria-hidden
          className={'is-promotion'}
        >
          {content}
        </Typography>
      )}
    </div>
  );
};

export default AmountDetail;
