const logger = require('nordic/logger');
const { pushDatadogMetric } = require('../../../app/service/api');
const { trackMelidataEvent } = require('../../utils/Tracker');
const { MELIDATA_TRACKING_TYPE_EVENT } = require('../../../constants/app');

const constants = {
  MELIDATA: 'melidata', DATADOG: 'datadog', LOGGER: 'logger',
};

/**
 * Represents a property of an event.
 */
class EventProperty {
  constructor(key, value, isFinite) {
    this.key = key;
    this.value = value;
    this.isFinite = isFinite;
  }
}

/**
 * Builder class to construct and execute event tracking.
 */
export class TrackBuilder {
  constructor(eventName, melidataBasePath, melidataTrackType = MELIDATA_TRACKING_TYPE_EVENT) {
    this.eventName = eventName;
    this.services = [];
    this.eventProperties = [];
    this.melidataBasePath = melidataBasePath
    this.logger = logger('TrackBuilder')
    this.melidataTrackType = melidataTrackType
  }

  /**
   * Adds all available services to the tracking list.
   */
  addAllServices() {
    this.addMelidata();
    this.addDatadog();
    this.addLogger();
    return this;
  }

  /**
   * Adds the Melidata service to the tracking list.
   * @returns {TrackBuilder} The current TrackBuilder instance.
   */
  addMelidata() {
    this.services.push(constants.MELIDATA);
    return this;
  }

  /**
   * Adds the Datadog service to the tracking list.
   * @returns {TrackBuilder} The current TrackBuilder instance.
   */
  addDatadog() {
    this.services.push(constants.DATADOG);
    return this;
  }

  /**
   * Adds the Logger service to the tracking list.
   * @returns {TrackBuilder} The current TrackBuilder instance.
   */
  addLogger() {
    this.services.push(constants.LOGGER);
    return this;
  }

  /**
   * Adds a property to the event.
   * @param {string} key - The key of the property.
   * @param {*} value - The value of the property.
   * @param {boolean} [isFinite=false] - Whether the property has a finite value.
   */
  addProp(key, value, isFinite = false) {
    this.eventProperties.push(new EventProperty(key, value, isFinite));
    return this;
  }

  /**
   * Retrieves properties marked as finite.
   * @returns {Object} The finite properties.
   */
  getPropsFinite() {
    const props = {};
    this.eventProperties.filter(prop => prop.isFinite).forEach(prop => {
      props[prop.key] = prop.value;
    });
    return props;
  }

  /**
   * Retrieves all properties of the event.
   * @returns {Object} All properties.
   */
  getAllProps() {
    const props = {};
    this.eventProperties.forEach(prop => {
      props[prop.key] = prop.value;
    });
    return props;
  }

  #sendTrackToMelidata() {
    const path = this.melidataBasePath
      ? `${this.melidataBasePath}/${this.eventName}`
      : `/${this.eventName}`;

    this.logger.debug(
      `Tracking to ${constants.MELIDATA} | path:${path}  data:${JSON.stringify(
        this.getAllProps()
      )}`
    );

    trackMelidataEvent({ path: path, data: this.getAllProps(), clean: true, trackType: this.melidataTrackType });
  }

  #sendToDatadog() {
    this.logger.debug(
      `Tracking to ${constants.DATADOG} | ${this.eventName}  tags: ${JSON.stringify(
        this.getPropsFinite()
      )}`
    );

    pushDatadogMetric(this.eventName, this.getPropsFinite());
  }

  #sendToLogger() {
    this.logger.info(`${this.eventName} props: ${JSON.stringify(this.getAllProps())}`);
  }

  /**
   * Executes the tracking by sending data to all added services.
   */
  exec() {
    const services = {
      [constants.MELIDATA]: this.#sendTrackToMelidata.bind(this),
      [constants.DATADOG]: this.#sendToDatadog.bind(this),
      [constants.LOGGER]: this.#sendToLogger.bind(this),
    };

    for (const service of this.services) {
      try {
        services[service]();
      } catch (error) {
        this.logger.error(`Error tracking with service ${service}:`, error);
      }
    }

    return this
  }
}
