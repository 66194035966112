const React = require("react");
const CardList = require("@cow/core-components/components/CardListV2");
const {Switch} = require('@andes/switch');
const PropTypes = require("prop-types");

const SwitchCombinations = ({unableCombination, translations, stepper}) => (
  <>
    {stepper === 1 &&
      <section className="switch-combination__content">
        <CardList
          {
            ...{
              items: [
                {
                  id: 'switch-combination',
                  title: <span aria-hidden>{translations.COMBINE_PAYMENT_METHODS}</span>,
                  rightContent: (
                    <Switch
                      checked
                      srLabel={translations.COMBINE_PAYMENT_METHODS}
                      onChange={(event) => {
                        unableCombination();
                      }}
                    />
                  ),
                }
              ]
            }
          }
        />
      </section>
    }
  </>
);

SwitchCombinations.propTypes = {
  unableCombination: PropTypes.func,
  translations: PropTypes.object,
  stepper: PropTypes.number,
};

module.exports = SwitchCombinations;
