const { useState } = require('react');

const { useI18n } = require('nordic/i18n');
const { Button } = require('@andes/button');
const { Card } = require('@andes/card');
const { RadioList, RadioListItem } = require('@andes/radio-list');
const translate = require('../../../translation');

const ShippingAddressSelectorDesktop = ({ addresses, handleSelectAddress }) => {
  const { i18n } = useI18n();
  const translations = translate(i18n);
  const [selectedAddress, setSelectedAddress] = useState(() => (addresses.length ? addresses[0].id : undefined));

  return (
    <div className="address-selector-desktop">
      <Card>
        <RadioList
          srLabel="addresses"
          defaultValue={selectedAddress}
          name="addresses"
          withDividers
          onChange={(_, itemValue) => setSelectedAddress(itemValue)}
        >
          {addresses.map((address, index) => (
            <RadioListItem
              className="shipping-option-radio"
              size="medium"
              key={index}
              value={address.id}
              title={address.title}
              description={address.description}
            />
          ))}

          <RadioListItem
            className="shipping-option-radio"
            value="add_address"
            title={translations.ADD_NEW_ADDRESS}
            description=" "
          />
        </RadioList>
      </Card>

      <div className="shipping-footer">
        <Button hierarchy="loud" size="large" onClick={() => handleSelectAddress(selectedAddress)}>
          {translations.CONTINUE}
        </Button>
      </div>
    </div>
  );
};

module.exports = {
  ShippingAddressSelectorDesktop,
};
