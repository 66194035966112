const {
  APP: {
    SECURE_FIELDS: {
      FIELDS: { CARD_NUMBER, EXPIRATION_DATE, SECURITY_CODE },
    },
  },
} = require('../../../constants');

class SecureFields {
  static #instance = null;

  #fields = {
    cardNumber: null,
    securityCode: null,
    expirationDate: null,
  };

  constructor() {}

  static getInstance() {
    if (!this.#instance) {
      this.#instance = new SecureFields();
    }

    return this.#instance;
  }

  setField(name, field) {
    this.#fields[name] = field;
  }

  getField(name) {
    return this.#fields[name];
  }

  setSecurityCodeField(field = null) {
    this.setField(SECURITY_CODE, field);
  }

  getSecurityCodeField() {
    return this.getField(SECURITY_CODE);
  }

  setCardNumberField(field = null) {
    this.setField(CARD_NUMBER, field);
  }

  getCardNumberField() {
    return this.getField(CARD_NUMBER);
  }

  setExpirationDateField(field = null) {
    this.setField(EXPIRATION_DATE, field);
  }

  getExpirationDateField() {
    return this.getField(EXPIRATION_DATE);
  }

  getFields() {
    return this.#fields;
  }
}

/**
 * @type {SecureFields}
 */
const secureFieldsInstance = SecureFields.getInstance();

module.exports = { secureFieldsInstance };
