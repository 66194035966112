import { useState } from 'react';

const { CHECKOUT_TYPE } = require('../../../constants/commons');

export function useNavbar(flow, isGuest, isWebview) {
  if (flow?.type === CHECKOUT_TYPE.EMBED || isGuest || isWebview) {
    return { showNavbar: false };
  }

  return { showNavbar: true };
}

export function useControlSummary() {
  const [isOpenedNavbar, setIsOpenedNavbar] = useState(false);
  const [isOpenedSummary, setIsOpenedSummary] = useState(false);

  const handleOpenNavbar = (opened) => {
    setIsOpenedNavbar(opened)
    if(opened) {
      setIsOpenedSummary(!opened)
    }
  }

  const handleCollapsedSummary = (opened) => {
    if (opened && !isOpenedNavbar) {
      setIsOpenedSummary(opened)
    }
  }

  return {
    isOpenedNavbar,
    handleOpenNavbar,
    handleCollapsedSummary,
    isOpenedSummary
  }
}
