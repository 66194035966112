const ACTION_REVIEW_METRICS = {
  PAY: 'pay',
  LOADED: 'loaded',
  CHANGED: 'changed',
}

const newInterfaceExpressMetricPayload = (NAME) => ({
  NAME,
  ACTION: ACTION_REVIEW_METRICS,
  AVAILABLE_TAGS: {
    ACTION: ['pay', 'loaded', 'changed'],
    DEVICE: ['desktop', 'mobile'],
    INTERFACE_EXPRESS: ['saved_card', 'credits', 'express'],
  },
})

module.exports = {
  ACTION_REVIEW_METRICS,
  REVIEW_EXPRESS: newInterfaceExpressMetricPayload('reviewexpress'),
  REVIEW_SAVED_CARD: newInterfaceExpressMetricPayload('new_interface_express'),
  REVIEW_CREDITS: newInterfaceExpressMetricPayload('new_interface_express'),
  SMART_TOKENIZATION: {
    NAME: 'smartTokenization',
    ACTION: {
      TOKENIZATION_METHODS: 'tokenization_methods',
      HANDLE_OPERATION: 'handle_operation',
      RESET_CAP: 'reset_cap',
    },
  },
  REBRANDING: {
    NAME: 'rebranding.rollout',
    THEME: {
      CYAN: 'cyan-light',
      YELLOWBLUE: 'yellowblue-light',
    },
  },
};
