// Utils
const { logErrorFromClient } = require('../../../../../utils/logTags');

const generateMultipleTokens = async (quantity, generateToken) => {
  try {
    const promises = Array.from({ length: quantity }, () => generateToken());

    const results = await Promise.allSettled(promises);

    const fulfilled = results.filter((result) => result.status === 'fulfilled').map((result) => result.value);
    const rejected = results.filter((result) => result.status === 'rejected').map((result) => result.reason);

    if (fulfilled.length === 0) {
      const error = {
        reason: 'cardFormResponse is null or undefined for all tries to generate a token',
        message: `It was not possible to generate any of the requested tokens. Reasons for rejection: ${rejected
          .map((r) => r.reason)
          .join(', ')}`,
        rejected,
      };

      throw error;
    }

    if (rejected.length > 0) {
      const error = {
        reason: 'Not all tokens were generated successfully',
        detail: `${quantity - fulfilled.length} tokens were not generated successfully. Reasons: ${rejected
          .map((r) => r.reason)
          .join(', ')}`,
      };

      logErrorFromClient(new Error(error.detail), error.reason, '[CardFormWrapper][generateMultipleTokens]');
    }

    return fulfilled;
  } catch (error) {
    const logError = {
      name: error?.name || error?.reason || 'Error',
      message: error?.message,
    };

    logErrorFromClient(logError, error?.reason, '[CardFormWrapper][generateMultipleTokens][catch]');

    throw error;
  }
};

module.exports = {
  generateMultipleTokens,
};
