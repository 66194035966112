/* eslint-disable import/order */
/* eslint-disable react/jsx-no-bind */
const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

// Redux
const { bindActionCreators } = require('redux');

// i18n
const translate = require('../../../../../translation');

// Andes components
const { Message } = require('@andes/message');
const { CardFooter } = require('@andes/card');

// Cow components
const PaymentMethodCardList = require('@cow/core-components/components/PaymentMethodCardList');

// Custom Components
const ReviewBase = require('../index');
const ConsumerCredits = require('../../components/ConsumerCredits');

// Custom hooks
const { useActions } = require('../../hooks/useActions');
const { usePaymentCardList } = require('../../hooks/usePaymentCardList');

// Actions
const STEP_ACTIONS = require('../../../../../spa/actions/step');
const REQUEST_ACTIONS = require('../../../../../spa/actions/request');
const LOADING_ACTIONS = require('../../../../../spa/actions/loading');
const EXPRESS_ACTIONS = require('../../../../../spa/actions/express');
const captchaToken = require('../../../../../spa/actions/captchaToken');

// Action Types
const {
  LOADER_PAY_BUTTON,
  EXPRESS_RESET_CARD,
  EXPRESS_CARD_TOKEN,
  EXPRESS_RESET_INSTALLMENTS,
  EXPRESS_SET_INSTALLMENTS_ERROR,
  EXPRESS_SET_SECURE_FIELDS_ERROR,
  EXPRESS_SET_SECURE_FIELDS_FOCUS,
  EXPRESS_RESET_SECURE_FIELDS,
} = require('../../../../../spa/actions/types');

// Utils
const PCJ = require('../../../../../utils/pcj');
const { sleep } = require('../../../../../utils/sleep');
const { setCookieWithJSON } = require('../../../../../utils/CookieUtils');
const { formatAmountWithInstallments } = require('../../../../../utils/format');
const { hasBankInterests } = require('../../../../../utils/interests');
const { localeFromSiteId } = require('../../../../../utils/locale');
const {
  createCardToken,
  mountSecureField,
  unmountSecureField,
  remountSecureField,
} = require('../../utils/secure-fields');
const {
  genericPropsTypes,
  genericDefaultValues,
  newInterfaceGenericPropsTypes,
  newInterfaceGenericDefaultValues,
} = require('../../../../../utils/propTypes');
const connectToReduxAndInjectI18n = require('../../../../../utils/connectToRedux');
const getBrowserInfo = require('../../../../../utils/BrowserInfo');
const { logErrorFromClient } = require('../../../../../utils/logTags');
const withSmartTokenizationProvider = require('../../../../../components/HOCs/withSmartTokenization');
const { sendMetricBasedOnTransactionType } = require('../../utils/metricsHandler');

// Constants
const {
  APP: {
    COOKIES,
    SUMMARY_ITEMS_TYPES: { COUPON_DISCOUNT },
    SECURE_FIELDS: {
      ERROR_CAUSES: { INVALID_VALUE },
    },
    ERROR_SPA: { BROWSER_INFO_ERROR },
  },
  COMMONS: {
    DEVICE_TYPE: { DESKTOP },
    CHECKOUT_CASE: { EXPRESS },
    PAYMENT_METHOD_ID: { CONSUMER_CREDITS },
    PAYMENT_METHOD: { CREDIT_CARD, DEBIT_CARD, PREPAID_CARD },
    ICONS_METHODS
  },
  SELECTORS: { ANDES_BUTTON_PAY_DESKTOP },
  METRICS: { ACTION_REVIEW_METRICS },
} = require('../../../../../../constants');

const { useSmartTokenizationContext } = require('../../../../../components/SmartTokenizationProvider');
const handleCardTokenization = require('../../utils/card-tokenization');

const ReviewExpress = (originalProps) => {
  const translations = translate(originalProps.i18n);

  const {
    sdk,
    flow,
    siteId,
    history,
    express,
    stepActions,
    captchaToken,
    captchaConfig,
    expressActions,
    requestActions,
    loadingActions,
    internalDeviceId,
    rebrandingMP,
    step_model,
  } = originalProps;
  const { locale, currency } = localeFromSiteId(siteId);

  const [browserInfo, setBrowserInfo] = React.useState({});
  const [cardToken, setCardToken] = React.useState('');
  const [nextDueDate, setNextDueDate] = React.useState(null);
  const [showKycModal, setShowKycModal] = React.useState(false);
  const [paymentMethods, setPaymentMethods] = React.useState(originalProps.step_model.payment_methods);
  const [paymentMethod, setPaymentMethod] = React.useState(originalProps.step_model.payment_methods[0]);
  const [showConsumerCreditsInfos, setShowConsumerCreditsInfos] = React.useState(false);

  const props = {
    ...PCJ.ifCandidateInjectPropsIntoSummary({
      translations,
      props: originalProps,
      paymentMethod,
    }),
  };

  const {
    payer,
    captcha,
    challenge_instructions: challengesInstructions,
    transaction_type: transactionType } = props.step_model;

  const hasCCAPTokenizationFlagEnabled = originalProps?.ccapTokenizationFlagEnabled;
  const { paymentCardList, shouldRenderInstallments, shouldRenderSecurityCode } = usePaymentCardList({
    express,
    translations,
    paymentMethods,
    rebrandingMP,
    hasCCAPTokenizationFlagEnabled,
    selectedMethod: paymentMethod,
    discount: paymentMethod?.discount,
  });

  const { changePaymentMethod, reviewExpressPay, reviewExpressKyC } = useActions({
    flow,
    payer,
    captcha,
    history,
    stepActions,
    captchaToken,
    captchaConfig,
    requestActions,
    loadingActions,
    challengesInstructions,
  });
  const smartTokenizationContext = useSmartTokenizationContext();

  const secureFieldActions = {
    resetSecureField: expressActions[EXPRESS_RESET_SECURE_FIELDS],
    setSecureFieldError: expressActions[EXPRESS_SET_SECURE_FIELDS_ERROR],
    setSecureFieldFocus: expressActions[EXPRESS_SET_SECURE_FIELDS_FOCUS],
  };

  const getBottomContentListClass = () => {
    const lastPaymentMethod = paymentCardList[paymentCardList.length - 1];
    return classNames('review-express__change-payment-method', {
      'review-express__change-payment-method--single': !lastPaymentMethod.collapsedContent,
    });
  };

  const showBankInterests = () => hasBankInterests(siteId) && paymentMethod?.type === CREDIT_CARD;

  const getCustomSummaryItems = () => {
    const customSummaryItems = props.step_model.summary?.items?.filter((item) => item.type !== COUPON_DISCOUNT);

    const installmentIndex = express?.installments?.[paymentMethod.option_id]?.value;
    const isAccountMoneyBlack = paymentMethod?.id === ICONS_METHODS.ICON_ACCOUNT_MONEY_BLACK;

    step_model.summary.total.detail = '';

    const isValidInstallment = installmentIndex && installmentIndex !== '' && !isAccountMoneyBlack;
    const installment = isValidInstallment ? paymentMethod.installments?.[installmentIndex - 1] : null;

    if (isValidInstallment && installment?.is_interest_free) {
      step_model.summary.total.detail = translations.NO_INTEREST();
    }

    return [...customSummaryItems, paymentMethod?.discount];
  };

  const handleConsumerCreditsChange = (paymentMethod) => {
    if (paymentMethod.id === CONSUMER_CREDITS) {
      setNextDueDate(paymentMethod.next_due_date);
      setShowConsumerCreditsInfos(true);
      return;
    }

    setNextDueDate(null);
    setShowConsumerCreditsInfos(false);
  };

  const handleSecureFieldChange = (paymentMethod) => {
    remountSecureField(paymentMethod, secureFieldActions);
  };

  const handleInstallmentsChange = () => {
    expressActions[EXPRESS_RESET_INSTALLMENTS](paymentMethod.option_id);
  };

  const handlePaymentMethodChange = (paymentMethodId) => {
    const findPaymentMethod = paymentMethods?.find((paymentMethod) => paymentMethod.option_id === paymentMethodId);

    if (paymentMethodId === paymentMethod.option_id) {
      return;
    }

    handleInstallmentsChange();

    handleSecureFieldChange(findPaymentMethod);
    handleConsumerCreditsChange(findPaymentMethod);
    setPaymentMethod(findPaymentMethod);

    setShowKycModal(false);
  };

  const handleFieldsValidation = async (securityCodeCardToken) => {
    let isSecurityCodeValid = true;
    let isInstallmentsValid = true;
    let err = null;

    const renderInstallments = shouldRenderInstallments(paymentMethod);
    const renderSecurityCode = shouldRenderSecurityCode(paymentMethod);

    if (!renderSecurityCode && !renderInstallments) {
      return true;
    }

    if (renderSecurityCode && !securityCodeCardToken) {
      if (hasCCAPTokenizationFlagEnabled) {
        const { error } = await smartTokenizationContext.handleTokenizationWithCCAP(paymentMethod);
        if (error) isSecurityCodeValid = false;
      } else {
        const { error } = await createCardToken();
        err = error;
      }

      if (err) {
        isSecurityCodeValid = false;
        secureFieldActions.setSecureFieldError(
          Array.isArray(err) && err.find((err) => err.cause === INVALID_VALUE)
            ? translations.CVV_IS_REQUIRED
            : translations.CVV_INCOMPLETE,
        );
      }
    }

    if (renderInstallments) {
      isInstallmentsValid = express?.installments?.[paymentMethod.option_id]?.value;
      expressActions[EXPRESS_SET_INSTALLMENTS_ERROR](paymentMethod.option_id, !isInstallmentsValid);
    }

    return isInstallmentsValid && isSecurityCodeValid;
  };

  const handleChangePaymentMethod = () => {
    sendMetricBasedOnTransactionType({transactionType, device : DESKTOP, action: ACTION_REVIEW_METRICS.CHANGED});
    unmountSecureField();
    changePaymentMethod();
  };

  const handleSubmit = ({ action, cardTokenId, installments, tokenizationType}) => {
    const isWebview = originalProps.isWebview;
    const payload = {
      card_token: cardTokenId,
      installments: String(installments),
      payer: {
        email: payer?.email?.value,
      },
      payment_method: {
        type: paymentMethod?.type,
        method: paymentMethod?.id,
        id: paymentMethod?.option_id,
      },
      token_generated_with_esc: tokenizationType
    };

    if (isWebview) {
      payload.browser_info = {
        browser_accept_header: browserInfo.accept_header,
        browser_color_depth: browserInfo.color_depth,
        browser_ip: browserInfo.ip,
        browser_java_enabled: browserInfo.java_enabled,
        browser_language: browserInfo.language,
        browser_screen_height: browserInfo.screen_height,
        browser_screen_width: browserInfo.screen_width,
        browser_timezone: browserInfo.timezone_offset,
        browser_user_agent: browserInfo.user_agent,
      };
    }

    unmountSecureField();
    action(payload);
  };

  const handleKyc = () => {
    const cardId = paymentMethod?.option_id;
    const cardTokenId = expressActions[EXPRESS_CARD_TOKEN](cardId) || cardToken;
    const installments = express?.installments?.[cardId]?.value || 1;
    handleSubmit({ action: reviewExpressKyC, cardTokenId, installments });
  };

  const handlePayClick = async () => {
    const cardId = paymentMethod?.option_id;
    const installments = express?.installments?.[cardId]?.value || 1;
    let cardTokenId = expressActions[EXPRESS_CARD_TOKEN](cardId) || cardToken;
    let tokenization = null

    if (!(await handleFieldsValidation(cardTokenId))) {
      setShowKycModal(false);
      return;
    }

    if (
      !cardTokenId &&
      (paymentMethod.type === CREDIT_CARD || paymentMethod.type === DEBIT_CARD || paymentMethod.type === PREPAID_CARD)
    ) {
      loadingActions[LOADER_PAY_BUTTON](true);

      const { cardTokenId: newCardTokenId, error, tokenizationType } = await handleCardTokenization({
        hasCCAPTokenizationFlagEnabled,
        handleTokenizationWithCCAP: smartTokenizationContext?.handleTokenizationWithCCAP,
        paymentMethod,
        cardId,
        internalDeviceId,
      });

      if (error) {
        loadingActions[LOADER_PAY_BUTTON](false);
        secureFieldActions.setSecureFieldError(translations.INVALID_CVV);
        return;
      }

      tokenization = tokenizationType
      cardTokenId = newCardTokenId;
      setCardToken(cardTokenId);
      expressActions[EXPRESS_RESET_CARD](cardId);
    }

    setShowKycModal(true);

    if (challengesInstructions?.kyc) {
      if (!showKycModal) {
        sleep().then(() => {
          document.querySelector(ANDES_BUTTON_PAY_DESKTOP)?.click();
        });
      }

      loadingActions[LOADER_PAY_BUTTON](false);
      return;
    }

    sendMetricBasedOnTransactionType({transactionType, device: DESKTOP, action: ACTION_REVIEW_METRICS.PAY});

    handleSubmit({ action: reviewExpressPay, cardTokenId, tokenizationType: tokenization, installments });
  };

  React.useEffect(() => {
    try {
      setBrowserInfo(getBrowserInfo(originalProps.initialStore));
    } catch (error) {
      logErrorFromClient(error, BROWSER_INFO_ERROR, '[express][3ds-browserinfo]');
    }
  }, []);

  React.useEffect(() => {
    sendMetricBasedOnTransactionType({transactionType, device: DESKTOP, action: ACTION_REVIEW_METRICS.LOADED});
    if (window && window?.hj) {
      window.hj('event', 'review_express_redesigned');
    }
  }, []);

  React.useEffect(() => {
    const pms = props.step_model.payment_methods;
    const pm = pms?.find((pm) => pm.option_id === paymentMethod.option_id);
    setPaymentMethods(pms);
    setPaymentMethod(pm);
  }, [props.step_model.payment_methods]);

  React.useEffect(() => {
    if (sdk.loaded) {
      mountSecureField(paymentMethod, secureFieldActions);
    }
  }, [sdk.loaded]);

  return (
    <ReviewBase
      deviceType={DESKTOP}
      templateCase={EXPRESS}
      showIncentives={false}
      showMlaTaxes={false}
      showKycModal={showKycModal}
      showTaxes={paymentMethod?.taxes?.length !== 0}
      customHandleKyc={handleKyc}
      customSummaryItems={getCustomSummaryItems()}
      customHandlePayClick={handlePayClick}
      customPrice={
        express?.installments?.[paymentMethod.option_id]?.price ||
        formatAmountWithInstallments(siteId, locale, currency, paymentMethod?.total_amount)
      }
      customFooterMessage={
        showBankInterests() && (
          <Message hierarchy="quiet" color="accent" className="review-express__bank_interests">
            {translations.BANK_INTERESTS}
          </Message>
        )
      }
      consumerCreditsFooter={
        showConsumerCreditsInfos && (
          <ConsumerCredits className="review-express__consumer-credits__footer">
            <ConsumerCredits.Legals siteId={siteId} />
          </ConsumerCredits>
        )
      }
      consumerCreditsSummary={
        showConsumerCreditsInfos && (
          <ConsumerCredits className="review-express__consumer-credits__summary">
            <ConsumerCredits.NextDueDate date={nextDueDate} siteId={siteId} />
            <ConsumerCredits.TermsAndConditions
              siteId={siteId}
              deviceType={DESKTOP}
              contractUrl={express?.installments?.[paymentMethod.option_id]?.contract}
            />
          </ConsumerCredits>
        )
      }
      customCardList={
        <PaymentMethodCardList
          withDividers
          deviceType={DESKTOP}
          items={paymentCardList}
          currentSelected={paymentMethod?.option_id}
          onChange={(value) => handlePaymentMethodChange(value)}
          bottomContentList={
            <CardFooter
              className={getBottomContentListClass()}
              link={{
                icon: true,
                text: translations.CHANGE_PAYMENT_METHOD,
                onClick: handleChangePaymentMethod,
              }}
            />
          }
        />
      }
      {...props}
    />
  );
};

ReviewExpress.defaultProps = {
  ...genericDefaultValues,
  step_model: {
    ...newInterfaceGenericDefaultValues,
  },
  rebrandingMP: false,
  isWebview: false,
};

ReviewExpress.propTypes = {
  ...genericPropsTypes,
  templateCase: PropTypes.string,
  step_model: PropTypes.shape({
    ...newInterfaceGenericPropsTypes,
  }).isRequired,
  rebrandingMP: PropTypes.bool,
  isWebview: PropTypes.bool,
};

// Map all the actions with the dispatchers on the props
const mapDispatchToProps = (dispatch) => ({
  stepActions: bindActionCreators(STEP_ACTIONS, dispatch),
  loadingActions: bindActionCreators(LOADING_ACTIONS, dispatch),
  requestActions: bindActionCreators(REQUEST_ACTIONS, dispatch),
  expressActions: bindActionCreators(EXPRESS_ACTIONS, dispatch),
  captchaToken: bindActionCreators(captchaToken, dispatch),
});

// Generate the state (store) using the reducers
const mapStateToProps = (state) => ({
  flow: state.page.flow,
  sdk: state.mercadopagoSdk,
  firstRender: state.page.firstRender,
  basePath: state.configurations.basePath,
  siteId: state.configurations.platform.siteId,
  captchaConfig: state.configurations.captcha,
  express: state.express,
  currency: state.configurations.currency,
  rebrandingMP: state.configurations.rebrandingMP,
  isWebview: state.configurations.isWebview,
  siteSecurity: state.configurations.siteSecurity,
});

module.exports = connectToReduxAndInjectI18n(
  withSmartTokenizationProvider(ReviewExpress),
  mapStateToProps,
  mapDispatchToProps,
);
