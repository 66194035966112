// Installments Detail components
const AmountDetail = require('@cow/core-components/components/CardListV2/InstallmentRowDetail/AmountDetail');
const QuantityDetail = require('@cow/core-components/components/CardListV2/InstallmentRowDetail/QuantityDetail');

// Utils
const { getDecimalSeparator } = require('../DecimalSeparator');
// Constants
const { SITE_ID } = require('../../../../../../constants/commons');

const getComponentId = (quantity) => `${quantity}x-row`;

const getQuantityText = (quantity, site) => {
  if (SITE_ID.MLM === site) {
    return `${quantity} de`;
  }
  return `${quantity}x`;
};

const getPluralOrSingularWording = ({ quantity, translations, types }) => {
  const { is_promotion } = types;
  if (quantity === 1) {
    return translations.INSTALLMENT_OF;
  }

  return is_promotion ? translations.INSTALLMENTS_WITHOUT_INTEREST_OF(quantity) : translations.INSTALLMENTS_OF(quantity);
};

const mapQuantityDetail = ({ quantity, price, translations, site, deviceType, types }) => {
  const { cents, amount, currency_symbol, decimal_separator } = price;

  return (
    <QuantityDetail
      srLabel={getPluralOrSingularWording({ quantity, translations, types })}
      quantity={getQuantityText(quantity, site)}
      deviceType={deviceType}
      amountInfo={{
        cents,
        fraction: amount,
        centsType: getDecimalSeparator(decimal_separator),
        symbol: currency_symbol,
      }}
    />
  );
};

const getAmountSrLabel = (isThirdParty, translations) =>
  isThirdParty ? translations.THIRD_PARTY_AMOUNT_LABEL : translations.INSTALLMENT_TOTAL;

const shouldHideTextValue = (site, quantity) => site === SITE_ID.MPE && quantity === 1;

const mapAmountDetail = ({ detail, types, translations, deviceType, site, quantity, isRebrandingMP }) => {
  const { text_value, amount, cents, currency_symbol, decimal_separator } = detail;
  const { is_promotion, is_third_party } = types;
  if (text_value && !shouldHideTextValue(site, quantity)) {
    return (
      <AmountDetail
        content={text_value}
        isPromotion={is_promotion}
        deviceType={deviceType}
        isRebrandingMP={isRebrandingMP}
      />
    );
  }
  if (!amount || amount === '0') {
    return <AmountDetail deviceType={deviceType} isRebrandingMP={isRebrandingMP} />;
  }
  return (
    <AmountDetail
      deviceType={deviceType}
      isRebrandingMP={isRebrandingMP}
      ariaHiddenMoneyAmount={is_third_party}
      totalAmount={{
        cents,
        fraction: amount,
        centsType: getDecimalSeparator(decimal_separator),
        symbol: currency_symbol,
        srLabel: getAmountSrLabel(is_third_party, translations),
      }}
      totalAmountSuffix={is_third_party ? '*' : ''}
    />
  );
};

const mapInstallment = ({ installment, selectInstallmentAction, translations, site, deviceType, isRebrandingMP }) => {
  const { quantity, price, rate, detail, types } = installment;
  return {
    id: getComponentId(quantity),
    withChevron: true,
    title: mapQuantityDetail({ quantity, price, translations, site, deviceType, types }),
    rightContent: mapAmountDetail({ detail, types, translations, deviceType, site, quantity, isRebrandingMP }),
    onClick: () => selectInstallmentAction(quantity, rate),
    className: 'installment-row',
  };
};

const generateItems = ({ installments, selectInstallment: selectInstallmentAction, translations, siteId: site, deviceType, isRebrandingMP }) => {
  if (!Array.isArray(installments) || installments.length === 0) {
    return [];
  }

  return installments.map((installment) =>
    mapInstallment({ installment, selectInstallmentAction, translations, site, deviceType, isRebrandingMP }),
  );
};

const hasThirdPartyInstallments = (installments) => {
  if (!Array.isArray(installments) || installments.length === 0) {
    return false;
  }

  return installments.some((installment) => installment.types.is_third_party);
};

export { generateItems, hasThirdPartyInstallments, shouldHideTextValue };
