const { popUpPosition } = require('./Dom');

const RetryAndTimers = {
  RETRY: 150,
  RETRY_COOL_DOWN: 1500,
  INITIAL_INTERVAL: 10000,
};

class ChallengeOneClick {
  constructor() {
    this.popupEl = null; // Popup itself
    this.oneClickBackUrl = null;
    this.flowId = null;
    this.flowType = null;
    this.interval = null; // Service check's loop interval object
    this.tryCount = 1; // Counter counts counting count
    this.lockServiceCall = true; // lock used to avoid parallel calls
    this.pollingFunction = this.pollingFunction.bind(this);
    this.submitFunction = this.submitFunction.bind(this);
  }

  /**
   * Get Popup Configuration
   * @param submitForm
   * @param target
   * @returns {{width: number, height: number, retryCoolDown: number, retries: number, name: string, loginUrl: string}}
   */
  getConfig(submitForm, target) {
    return {
      width: 520,
      height: 600,
      initialInterval: RetryAndTimers.INITIAL_INTERVAL,
      retryCoolDown: RetryAndTimers.RETRY_COOL_DOWN,
      retries: RetryAndTimers.RETRY,
      submitForm,
      target,
    };
  }

  async pollingFunction(popupEl) {
    try {
      // If the user came back to Checkout in the Popup we need to finish the polling
      const validCheckoutRegex = /^(?:http(s)?:\/\/)(www|dev|beta|alpha|delta)\.mercadopago\.(cl|com\.(ar|br|mx|pe|co|uy))(:8443)?\/.*$/g; //eslint-disable-line

      if (popupEl.location.href && popupEl.location.href.match(validCheckoutRegex)) {
        this.oneClickBackUrl = popupEl.location.href;

        // Special case for integrations with Redirect inside iframes, popup url will be embed, we need to update it.
        if (this.oneClickBackUrl.includes('/embed')) {
          this.oneClickBackUrl = this.oneClickBackUrl.replace('/embed', '/redirect');
        }

        return await Promise.resolve(true);
      }

      return await Promise.resolve(false);
    } catch (error) {
      return Promise.resolve(false);
    }
  }

  submitFunction(failedProcessing = false) {
    if (this.oneClickBackUrl && !failedProcessing) {
      // When the popup returned to MP we need to update the current window(Modal) to execute the Middle for Oneclick
      window.location.replace(`${this.oneClickBackUrl}&finish_loading=true&failed_processing=${failedProcessing}`);
    } else {
      // When finish the polling function or the user closed the Popup, we need to tell flows this case
      const paths = window.location.pathname.split(`/${this.flowType}`);
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const oneClickUrl = `${baseUrl}${paths[0] || ''}/${this.flowType}/webpay-one-click/${this.flowId}`;

      window.location.replace(`${oneClickUrl}?finish_loading=true&failed_processing=${failedProcessing}`);
    }
  }

  finishProcessing() {
    this.closePopup();
    this.submitFunction(true);
  }

  /**
   * Open Popup window on given challenge's url if it's neccesary
   * @param challengeConfig {Object} - Object returned by `ChallengeConfigs`
   */
  open(submitForm, target, flowId, flowType, blockedPopups) {
    this.flowId = flowId;
    this.flowType = flowType;

    const config = this.getConfig(submitForm, target);

    // Always trigger the popup no matter if the user is already logged (show the spinner)
    this.triggerPopup(config);

    if (this.popupEl === null) {
      // Popup was blocked by the browser
      if (blockedPopups) {
        blockedPopups();
      }

      return;
    }

    this.runIntervalCheck(config, this.submitFunction, this.pollingFunction);
  }

  runIntervalCheck(config, submitFunction, challengeStatusCall) {
    this.lockServiceCall = true;

    setTimeout(() => {
      if (this.interval) {
        clearInterval(this.interval);
      }

      this.interval = setInterval(() => {
        if (this.tryCount >= config.retries || !this.isOpen()) {
          this.stopInterval();
          this.finishProcessing();
        } else {
          this.executeChallengeRetry(submitFunction, challengeStatusCall);
        }

        this.tryCount += 1;
      }, config.retryCoolDown);
    }, config.initialInterval);
  }

  executeChallengeRetry(submitFunction, challengeStatusCall) {
    if (this.lockServiceCall) {
      this.lockServiceCall = false; // lock loop

      challengeStatusCall(this.popupEl)
        .then((completed) => {
          this.lockServiceCall = true; // unlock loop

          if (completed) {
            this.closePopup();
            submitFunction();
            this.stopInterval();
          }
        })
        .catch(() => {
          this.lockServiceCall = true; // unlock loop
        });
    }
  }

  stopInterval() {
    clearInterval(this.interval);
  }

  /**
   * @private
   * Triggers popup (window.open)
   * Open it centered
   * @returns {Window | null}
   */
  triggerPopup({ submitForm, target, width, height }) {
    // Fixes dual-screen position - Most browsers - Firefox
    const { left, top } = popUpPosition(width, height);

    const isBlank = target === '_blank';

    submitForm.target = target;
    this.popupEl = window.open(
      isBlank ? submitForm.action : '',
      target,
      `scrollbars=yes, width=${width}, height=${height}, top=${top}, left=${left}`,
    );

    if (this.popupEl && !isBlank) {
      submitForm.submit();
    }
  }

  /**
   * @private
   */
  isOpen() {
    return this.popupEl && !this.popupEl.closed;
  }

  /**
   * @private
   */
  closePopup() {
    this.popupEl.close();
  }
}

module.exports = new ChallengeOneClick();

// Must be exported because is returning and instance of ChallengeOneClick and cant be static get
module.exports.RETRY = RetryAndTimers.RETRY;
module.exports.RETRY_COOL_DOWN = RetryAndTimers.RETRY_COOL_DOWN;
module.exports.INITIAL_INTERVAL = RetryAndTimers.INITIAL_INTERVAL;
