const { isExternalDeepLinkFromBackUrl } = require('./deeplinks');
/**
 * Method that insert a new param or update an existing one in the param url
 * @param {String} key
 * @param {String} value
 * @param {String} url
 */
const insertParam = (key, value, url) => {
  const params = url.substr(1).split('&');

  let i = params.length - 1;
  let keyAndValue;

  while (i) {
    keyAndValue = params[i].split('=');

    if (keyAndValue[0] === key) {
      keyAndValue[1] = value;
      params[i] = keyAndValue.join('=');

      break;
    }

    i -= 1;
  }

  if (i === 0) {
    params[params.length] = [key, value].join('=');
  }

  return `?${params.join('&')}`;
};

const containsHtmlEntities = (query) => {
  const htmlEntitiesRegex = /&(#[0-9]+|[a-z]+);/;

  return !!htmlEntitiesRegex.test(query);
};

const buildCheckoutURL = (req, replaceString, replacement) => {
    const { originalUrl } = req;
    const host = req.get('Host');
    if (replaceString?.length > 0 && replacement?.length > 0) {
      return `https://${host}${originalUrl.replace(replaceString, replacement)}`;
    }
    return `https://${host}${originalUrl}`;
}

const sanitizeUrl = (url) => {
  if (isExternalDeepLinkFromBackUrl(url)) {
    return url.replace(/^https?:\/\//, '')
  }
  return url;
}

const hasDangerousExtension = (url) => {
  if(!url) {
    return false;
  }
  const dangerousExtensionsPattern = /\.(exe|bat|cmd|msi|deb|js|vbs|ps1|sh|zip|rar|tar|gz|ini|xml|dll|dmg|apk)$/i;
  const resourcePath = url.split('?')[0];

  return dangerousExtensionsPattern.test(resourcePath);
};

module.exports = {
  insertParam,
  containsHtmlEntities,
  buildCheckoutURL,
  sanitizeUrl,
  hasDangerousExtension
};
