const React = require('react');

const { useEffect, useState } = React;

const useHeightElement = () => {
  const [height, setHeight] = useState(0);

  const calculateTotal = (...args) => args.reduce((acc, val) => acc + (typeof val !== 'undefined' && val), 0);

  useEffect(() => {
    const navBarHeight = document.getElementsByClassName('cow-navbar-v2')[0]?.offsetHeight;
    const brandNameHeight = document.getElementsByClassName('brand')[0]?.offsetHeight;
    const summaryOptimus = document.getElementsByClassName('sticky-summary')[0]?.offsetHeight;
    const summaryCow = document.getElementsByClassName('cow-payment_summary_mobile')[0]?.offsetHeight;

    setHeight(calculateTotal(navBarHeight, brandNameHeight, summaryOptimus, summaryCow));
  }, []);

  return height;
};

module.exports = useHeightElement;
