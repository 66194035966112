const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');

const Button = require('../../containers/Button/ButtonWithStore');
const IconPin = require('../icons/Pin');
const GroupList = require('../Groups/GroupList');
const List = require('../List');
const ListItem = require('../ListItem');
const { DEVICE_TYPE } = require('../../../constants/commons');


class ZipcodeFinderResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: this.props.result && this.props.result.length > 0 ? this.props.result[0].zipCode : '',
    };

    this.createAddressItem = this.createAddressItem.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
    this.renderResult = this.renderResult.bind(this);
    this.setOption = this.setOption.bind(this);
    this.doSubmit = this.doSubmit.bind(this);
    this.handleChoosingOption = this.handleChoosingOption.bind(this);
  }

  splitSelectedOption() {
    return this.state.selectedOption.split('-')[0];
  }

  setOption(event, value) {
    this.setState({ selectedOption: value });
  }

  setOptionMobile(item) {
    return item?.split('-')[0];
  }

  handleChoosingOption(event, value) {
    const selectedOption = this.props.deviceType === DEVICE_TYPE.DESKTOP ? this.splitSelectedOption() : this.setOptionMobile(value);

    this.setOption(event, selectedOption);
    this.doSubmit(event, selectedOption);
  }

  doSubmit(event, selectedValue) {
    if (this.props.onSubmit) {
      this.props.onSubmit(event, this.props.deviceType === DEVICE_TYPE.DESKTOP ? this.splitSelectedOption() : selectedValue);
    }
  }

  createAddressItem(item) {
    let firstRow = '';
    let secondRow = '';
    let id = '';
    let value = '';

    if (item.neighborhood) {
      firstRow = `${item.neighborhood}, CP ${item.zipCode}`;
      id = `${item.zipCode}-${item.neighborhood.replace(/\s+/g, '-').toLowerCase()}`;
      value = `${item.zipCode}-${item.neighborhood.replace(/\s+/g, '-').toLowerCase()}`;
    } else if (item.department) {
      firstRow = `${item.department}, CP ${item.zipCode}`;
      id = `${item.zipCode}-${item.department.replace(/\s+/g, '-').toLowerCase()}`;
      value = `${item.zipCode}-${item.department.replace(/\s+/g, '-').toLowerCase()}`;
    } else {
      firstRow = `CP ${item.zipCode}`;
      id = item.zipCode;
      value = item.zipCode;
    }
    if (item.street) {
      secondRow = item.range ? `${item.street}, ${item.range}` : `${item.street}`;
    }
    const thirdRow = item.department ? `${item.department}, ${item.state}` : item.state;

    return {
      primary: firstRow,
      secondary: item.index !== undefined ? (<div className="finder-result--secondary"><span>{secondRow}</span><span>{thirdRow}</span></div>) : secondRow,
      tertiary: item.index !== undefined ? '' : thirdRow,
      value,
      id,
      nextPage: '/',
      type: 'list_item',
    };
  }

  renderButtons() {
    return (
      <div className="buttons-container">
        <Button
          text={this.props.confirmButtonLabel}
          onClick={this.handleChoosingOption}
          kind="loud"
        />
        <Button
          text={this.props.modifyButtonLabel}
          onClick={this.props.onModify}
          kind="quiet"
        />
      </div>
    );
  }

  renderDesktopResult() {
    const items = [{
      type: 'radio_list',
      name: 'shipping_options',
      id: 'group_list',
      value: this.props.result.map((item, index) => this.createAddressItem({ ...item, index })),
    }];
    return (
      <>
        <GroupList value={items} name={this.props.name} deviceType="desktop" onClick={this.setOption} />
        {this.renderButtons()}
      </>
    );
  }

  renderMobileResult() {
    return (
      <List>{this.props.result.map((item, index) => this.createAddressItem({ ...item, index })).map((item) => (
        <React.Fragment key={item.nextPath}>
          <ListItem {...item} onClick={(e) => this.handleChoosingOption(e, item.value)} />
        </React.Fragment>
      ))}</List>
    );
  }

  renderResult() {
    if (this.props.result.length > 1) {
      return this.props.deviceType === 'desktop' ? this.renderDesktopResult() : this.renderMobileResult();
    }
    const address = this.createAddressItem(this.props.result[0]);
    return (
      <div className="single-result">
        <IconPin />
        <h2>{address.primary}</h2>
        {address.secondary ? <h3>{address.secondary}</h3> : null}
        <p className="text gray">{address.tertiary}</p>
        {this.renderButtons()}
      </div>
    );
  }

  render() {
    return (
      <div className="zipcode-result">
        {this.props.result && this.props.result.length !== 0 ? this.renderResult() : null}
      </div>
    );
  }
}

ZipcodeFinderResult.defaultProps = {
  selectedOption: null,
};

ZipcodeFinderResult.propTypes = {
  result: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node).isRequired,
    PropTypes.array.isRequired
  ]),
  // Labels for buttons
  confirmButtonLabel: PropTypes.string.isRequired,
  modifyButtonLabel: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  deviceType: state.configurations.deviceType,
});

module.exports = ZipcodeFinderResult;
if (process.env.NODE_ENV === 'test') {
  module.exports = ZipcodeFinderResult;
} else {
  /* istanbul ignore next: cant test it with tests */module.exports = connect(mapStateToProps)(ZipcodeFinderResult);
}
