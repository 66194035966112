const React = require('react');
const PropTypes = require('prop-types');

// Do not export translate method, if you do that, you cant stub dictionary
const dictionary = require('./dictionary');
const { isProcessV2 } = require('../../utils/HiddenComponents');
/**
 * Get the component from the dictionary
 * @param uiComponent
 */
const translate = uiComponent => (dictionary.get([uiComponent.type]) ? (dictionary.get([uiComponent.type])(uiComponent)) : '');

/**
 * Using the dictionary, this method is going to return the appropiate components to be render on the page
 * If you have a group, the translation of the elements are going to be sended to the group as children
 * @param components
 * @param onStepSubmit
 * @param siteId
 * @param history
 * @param deviceType
 * @param publicKey
 * @param shouldUseProcessValue
 * @param parentGroups
 */
const generate = (components, onStepSubmit, siteId, urls, history, deviceType, publicKey, onCheckoutClose, shouldUseProcessValue, parentGroups = '', isRebranding) => (
  components?.map((c) => {
    // If it is a group send the value element as children. Also, send a string with the parent hierarchy, so later
    // we can generate the request to the middle-end
    if (c.type && c.type.split('_')[0] === 'group') {
      c.children = generate(c.value || [], onStepSubmit, siteId, urls, history, deviceType, publicKey, onCheckoutClose, shouldUseProcessValue, `${parentGroups}[${c.id}]`, isRebranding);
    }

    // If it is a form, pass the onSubmit method as prop (Assuming always 1 form per step, or multiple forms with one submit method)
    if (c.type && c.type.split('_')[1] === 'form') {
      // If have onStepSubmit function add it their props
      if (onStepSubmit) {
        c.onSubmit = onStepSubmit;
      }
    }

    // This is the button that must trigger the close of modal on Checkout Payment last step
    if (c.type === 'button_close' || c.type === 'button_close_link') {
      if (onCheckoutClose) {
        c.onClick = onCheckoutClose;
      }
    }
    if (shouldUseProcessValue) {
      // in case shouldUseProcessValue is set use [value][id] as component name
      c.name = `[values][${c.id}]`;
    } else {
      // Set parents as the string that will tell the component who are their parents
      c.name = `${parentGroups}[${c.id}]`;
    }
    // Inject history on every component
    c.history = history;
    // Inject siteID on every component
    c.siteId = siteId;
    // Inject deviceType on every component
    c.deviceType = deviceType;
    // Inject public key on every component
    c.publicKey = publicKey;
    // Inject urls on every component
    c.urls = urls;

    c.isRebranding = isRebranding;

    return translate(c);
  }).filter(c => !!c)
);

/**
 * This is the entrypoint, and is only going to call the generate method
 * the parentGroups is always going to be ui_components, because the components that Optimus generate are always UI
 *
 * onStepSubmit: This is going to be map on the form from the step. You can pass a custom method to execute on form submit
 *
 * MUST BE A CLASS, if not, forceUpdate() doesn't work!
 * @param components
 * @param onStepSubmit
 */
class Optimus extends React.Component { // eslint-disable-line react/prefer-stateless-function
  /**
   * Base parent group
   * @returns {string}
   * @constructor
   */
  static get BASE_PARENT_GROUP() {
    return '[ui_components]';
  }

  /**
   * Only update component if the component object change (Is the only thing that can trigger a re render - only needs to happen on a page change)
   * @param nextProps
   * @param nextState
   * @returns {boolean}
   */
  shouldComponentUpdate(nextProps) {
    return (this.props.components !== nextProps.components);
  }

  render() {
    // Do not modify the components props, create a new variable
    const components = (!this.props?.components) ? null : [].concat(this.props.components);
    const shouldUseProcessValue = isProcessV2(this.props.hiddenComponents);
    const {
      onStepSubmit,
      onCheckoutClose,
      siteId,
      history,
      deviceType,
      publicKey,
      urls,
      isRebranding,
    } = this.props;

    return (
      <div className="optimus">
        {generate(
          components,
          onStepSubmit,
          siteId,
          urls,
          history,
          deviceType,
          publicKey,
          onCheckoutClose,
          shouldUseProcessValue,
          Optimus.BASE_PARENT_GROUP, 
          isRebranding)
        }
      </div>
    );
  }
}

Optimus.defaultProps = {
  components: [],
  onStepSubmit: null,
  onCheckoutClose: null,
  siteId: '',
  deviceType: '',
  publicKey: '',
  hiddenComponents: {},
  history: null,
  urls: {
    tyc: {
      link: '',
    },
  },
  isRebranding: false,
};

Optimus.propTypes = {
  components: PropTypes.arrayOf(PropTypes.object).isRequired,
  onStepSubmit: PropTypes.func,
  onCheckoutClose: PropTypes.func,
  siteId: PropTypes.string,
  deviceType: PropTypes.string,
  publicKey: PropTypes.string,
  hiddenComponents: PropTypes.object, // eslint-disable-line
  history: PropTypes.object, // eslint-disable-line
  urls: PropTypes.shape({
    tyc: PropTypes.shape({
      link: PropTypes.string,
    }),
  }),
  isRebranding: PropTypes.bool,
};

module.exports = Optimus;

if (process.env.NODE_ENV === 'test') {
  exports = module.exports;
  exports.generate = generate;
}
