const { getESCCookie } = require('../../../../../utils/CookieUtils');
const {
    APP: {
      COOKIES
    },
  } = require('../../../../../../constants');

function escValidateCard(optionId, requests, express, escAvailable) {
    const escCookie = getESCCookie(`${COOKIES.ESC}${optionId}`);
    const escNotConsulted = !requests?.requests.find((el) => el === optionId) && !express?.[optionId]?.cardIdsValidated;
  
    if (escCookie && escNotConsulted && escAvailable) return escCookie;

    return null
}

module.exports = escValidateCard;