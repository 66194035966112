import type { TextProps } from '@andes/typography';
import type { ThumbnailProps } from '@andes/thumbnail';

import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Image } from 'nordic/image';
import { Thumbnail } from '@andes/thumbnail';
import { Text } from '@andes/typography';

import { getInitials } from '../../utils/string';

export type BrandProps = {
  name: string;
  image?: string;
  imageTitle?: string;
  imageAltText?: string;
  maxNameLength?: number;
  maxInitialsLength?: number;
  shape?: ThumbnailProps['modifier'];
  isMobile?: boolean;
  isUserLogged?: boolean;
  withNavbar?: boolean;
};

export default function Brand({
  name,
  image,
  imageTitle = 'Brand logo',
  imageAltText = 'Brand logo',
  maxNameLength = 17,
  maxInitialsLength = 2,
  shape = 'circle',
  isMobile = false,
  isUserLogged = false,
  withNavbar = false,
}: BrandProps) {
  const nameSize = useMemo<TextProps['size']>(
    () => (isMobile ? 'm' : 'l'),
    [isMobile, name, maxNameLength],
  );

  return (
    <div
      className={classNames('brand', {
        'brand--mobile': isMobile,
        'user-logged': isUserLogged,
        'user-guest': !isUserLogged,
        'not-navbar': !withNavbar,
      })}
    >
      <Thumbnail
        className="brand__thumbnail"
        modifier={shape}
        size={isMobile ? '24' : '40'}
      >
        {image ? (
          <Image
            className="brand__thumbnail--image"
            alt={imageAltText}
            src={image}
            title={imageTitle}
            lazyload="off"
          />
        ) : (
          <Text component="span" className="brand__thumbnail--text" size="l">
            {getInitials(name, maxInitialsLength)}
          </Text>
        )}
      </Thumbnail>

      <Text
        size={nameSize}
        component="span"
        className="brand__name"
        weight="semibold"
      >
        {name}
      </Text>
    </div>
  );
}
