import type { TypographyProps } from '@andes/typography';
import type {
  PaymentSummaryItem,
  PaymentSummarySplitPayment,
  PaymentSummaryTotal,
} from './types';

import React from 'react';
// Andes Components
import { Card, CardContent } from '@andes/card';
import { Typography } from '@andes/typography';
import { ProgressButton } from '@andes/button';

// Icons
import Lock from '../Icons/Lock';
// Custom Components
import RowItem from '../RowItem';
// Constants
import { PaymentSummaryTheme, Theme } from '../../constants/commons';
import TotalSection from './TotalSection';
import RowCollapsible from '../RowCollapsible';

export type PaymentSummaryProps = {
  title: string;
  items: Array<PaymentSummaryItem>;
  splitPayment?: PaymentSummarySplitPayment;
  total?: PaymentSummaryTotal & {
    nameObservation?: string;
    nameObservationColor?: TypographyProps['color'];
    nameObservationSize?: Exclude<TypographyProps['size'], 'xl'>;
    priceObservation?: string;
    priceObservationColor?: TypographyProps['color'];
    priceObservationSize?: Exclude<TypographyProps['size'], 'xl'>;
    observationDirection?: 'column' | 'row';
    helperText?: string;
    textSize?: Exclude<TypographyProps['size'], 'xl'>;
  };
  securityPaymentInformationLabel?: string;
  // Dynamic External Content
  bottomContent?: React.ReactNode;
  theme?: PaymentSummaryTheme;
  companyTheme?: Theme;
  showSubmitButton?: boolean;
  buttonText?: string;
  disablePayButton?: boolean;
  payButtonHelperText?: React.ReactNode;
  onPayClick?: () => void;
  loadingButton?: boolean;
  timeout?: number;
  isFullAndes?: boolean;
};

function PaymentSummary({
  title,
  items,
  splitPayment,
  total,
  securityPaymentInformationLabel,
  // Dynamic External Content
  bottomContent,
  theme = PaymentSummaryTheme.LIGHT,
  companyTheme = Theme.DEFAULT,
  showSubmitButton = true,
  buttonText,
  disablePayButton = false,
  onPayClick,
  payButtonHelperText,
  loadingButton = false,
  timeout = 5000,
  isFullAndes = false,
}: PaymentSummaryProps) {
  return (
    <Card
      className={`cow-payment_summary ${
        isFullAndes ? '' : 'cow-payment_summary--desktop'
      } cow-payment_summary--${theme} cow-payment_summary--${companyTheme}`}
      paddingSize={32}
    >
      <CardContent>
        <Typography
          className="cow-payment_summary__title"
          component={isFullAndes ? 'span' : 'h2'}
          type="body"
          size="m"
          weight="semibold"
        >
          {title}
        </Typography>

        {items.map((row) => {
          const isRowCollapsible =
            Array.isArray(row.subItems) && row.subItems.length;

          return isRowCollapsible ? (
            <RowCollapsible
              key={`${row.name}-${row.price}`}
              name={row.name}
              price={row.price}
              subItems={row.subItems}
            />
          ) : (
            <RowItem
              key={`${row.name}-${row.price}`}
              label={row.name}
              labelColor={row.color || row.nameColor}
              value={row.price}
              valueWeight={row.priceWeight}
              valueSize={row.priceSize}
              valueCents={row.priceCents}
              valueCentsType={row.priceCentsType}
              valueSymbol={row.priceSymbol}
              valueColor={row.color || row.priceColor}
              labelWeight={row.nameWeight}
              className={`cow-payment_summary__row cow-payment_summary__row--${
                row.type || 'product'
              }`}
              icon={row.icon}
              labelComplement={row.nameComplement}
              labelComplementColor={row.nameComplementColor}
              labelObservation={row.nameObservation}
              valueObservation={row.priceObservation}
            />
          );
        })}

        {total && <hr className="cow-payment_summary__line" />}
        <TotalSection total={total} splitPayment={splitPayment} />

        {/* Split / Combination Payment */}
        {!!splitPayment && (
          <React.Fragment>
            <Typography type="body" size="m" color="primary" weight="semibold">
              {splitPayment.label}
            </Typography>

            {splitPayment.methods.map((method, index, methods) => {
              const isLastItem = index === methods.length - 1;

              return (
                <React.Fragment key={method.name}>
                  <RowItem
                    id={`payment-method-${index + 1}`}
                    label={method.name}
                    value={method.price}
                    valueObservation={method.priceObservation}
                    valueObservationColor="positive"
                    textSize="l"
                    className="cow-payment_summary__row cow-payment_summary__row--payment-method"
                  />

                  {isLastItem && <hr className="cow-payment_summary__line" />}
                </React.Fragment>
              );
            })}
          </React.Fragment>
        )}

        {total?.name && total.price && !!splitPayment && (
          <RowItem
            label={total.name}
            labelWeight={total.nameWeight || 'semibold'}
            value={total.price}
            valueWeight={total.priceWeight || 'semibold'}
            valueObservation={total.priceObservation}
            valueObservationColor={total.priceObservationColor || 'positive'}
            textSize="l"
            className="cow-payment_summary__row cow-payment_summary__row--total"
          />
        )}

        {/* External Content */}
        {bottomContent}

        {showSubmitButton && (
          <ProgressButton
            className="cow-payment_summary__button"
            onClick={onPayClick}
            disabled={disablePayButton}
            loading={loadingButton}
            timeout={timeout}
          >
            {buttonText}
          </ProgressButton>
        )}

        {securityPaymentInformationLabel && (
          <div className="cow-payment_summary__security-payment">
            <Lock />

            <Typography type="body" size="xs" weight="semibold">
              {securityPaymentInformationLabel}
            </Typography>
          </div>
        )}

        {!!payButtonHelperText && typeof payButtonHelperText === 'string' ? (
          <div className="cow-payment_summary__security-payment">
            <Typography type="body" size="xs" weight="semibold">
              {payButtonHelperText}
            </Typography>
          </div>
        ) : (
          payButtonHelperText
        )}
      </CardContent>
    </Card>
  );
}

export default PaymentSummary;
