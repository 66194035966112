const { useState } = require('react');
const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const { Button } = require('@andes/button');
const { useI18n } = require('nordic/i18n');

const stepActions = require('../../../spa/actions/step');
const { getQueryParams } = require('../../../utils/Dom');
const { STEP_NEXT } = require('../../../spa/actions/types');

const { Address } = require('./Address');
const { ShippingLocalPickup } = require('./ShippingLocalPickup');
const { ShippingCost } = require('./ShippingCost');
const { DEVICE_TYPE } = require('../../../../constants/commons');
const translate = require('../../../translation');

const ShippingReview = (props) => {
  const { flow, data, history, stepAction, configurations, configs } = props;
  const { street_text, zipcode_text, contact_text, local_pickup, shipping_option } = data ?? {};
  const { options } = shipping_option ?? {};
  const { currency, deviceType } = configurations ?? {};
  const isDesktop = deviceType === DEVICE_TYPE.DESKTOP;

  const { i18n } = useI18n();
  const translations = translate(i18n);
  const [selectedShippingOptionId, setSelectedShippingOptionId] = useState(() =>
    options.length ? options[0].id : undefined,
  );

  const handleNextStep = (id) => {
    const urlParams = getQueryParams();

    const defaultData = {
      urlParams,
      type: flow.type,
    };

    const payload = {
      isNewInterface: true,
      selected_row: String(id),
    };

    stepAction[STEP_NEXT](payload, flow.id, defaultData, flow.type, urlParams, history);
  };

  const onSelectOption = (id) => {
    (isDesktop ? setSelectedShippingOptionId : handleNextStep)(id);
  };

  const titlePage = local_pickup
    ? translations.HOW_DO_YOU_WANT_TO_RECEIVE_OR_PICK_UP_YOUR_PURCHASE
    : translations.CHOOSE_WHEN_TO_RECEIVE_THE_ORDER;

  return (
    <div className="shipping-review-container">
      <h1 className="c-title">{titlePage}</h1>
      <div className="shipping-content">
        <Address
          street_text={street_text}
          zipcode_text={zipcode_text}
          contact_text={contact_text}
          onSelectOption={handleNextStep}
          isDesktop={isDesktop}
          isRebrand={configs?.rebrandingMP}
        />

        <ShippingCost
          options={options}
          currency={currency}
          onSelectOption={onSelectOption}
          isDesktop={isDesktop}
          selectedShippingOptionId={selectedShippingOptionId}
        />

        {local_pickup ? (
          <ShippingLocalPickup
            onSelectOption={onSelectOption}
            isDesktop={isDesktop}
            selectedShippingOptionId={selectedShippingOptionId}
          />
        ) : undefined}
      </div>
      {isDesktop ? (
        <div className="shipping-review-footer">
          <Button onClick={() => handleNextStep(selectedShippingOptionId)}>{translations.CONTINUE}</Button>
        </div>
      ) : undefined}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    flow: state.page.flow,
    configurations: state.configurations,
    configs: state.configurations,
  };
};

const mapDispatchToProps = (dispatch) => ({
  stepAction: bindActionCreators(stepActions, dispatch),
});

module.exports = {
  ShippingReview: connect(mapStateToProps, mapDispatchToProps)(ShippingReview),
};
