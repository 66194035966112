const React = require('react');
const { useState, useEffect, useCallback } = React;
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const ZipCodeModal = require('@cow/core-components/components/ZipCodeModal');
const { Typography } = require('@andes/typography');

const connectToReduxAndInjectI18n = require('../../utils/connectToRedux');
const translate = require('../../translation');
const ApiService = require('../../service/api');
const { formatZipcode } = require('../../utils/format');
const { logErrorFromClient } = require('../../utils/logTags');
const { ERROR_SPA, HTTP_STATUS } = require('../../../constants/app');
const SNACKBAR_ACTIONS = require('../../spa/actions/snackbar');
const { SNACKBAR_TRIGGER } = require('../../spa/actions/types');
const { COLORS_BY_STATUS } = require('../../../constants/commons');

const ModalZipcode = ({ deviceType, i18n, open, setOpen, populateFields, callback, countryId, snackbarActions }) => {
  const translations = translate(i18n);

  const [zipcode, setZipcode] = useState('');
  const [city, setCity] = useState({ name: '', disabled: true });
  const [state, setState] = useState({ id: '', name: '', value: '' });
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [zipCodesInformation, setZipCodesInformation] = useState([]);
  const [title, setTitle] = useState(translations.ZIPCODE_MODAL_TITLE_ADDRESS);


  const showSnackBar = () => {
    const snackBarActionData = {
      show: true,
      status: COLORS_BY_STATUS.ERROR,
      message: <Typography component="p" size='m' color='inverted' >{translations.ZIP_CODE_NOT_FOUND}</Typography>
    }
    snackbarActions[SNACKBAR_TRIGGER](snackBarActionData);
    setTimeout(() => {
      snackBarActionData.show = false;
      snackbarActions[SNACKBAR_TRIGGER](snackBarActionData);
    }, 10_000);

  };

  const handleStates = useCallback(async () => {
    try {
      const response = await ApiService.getStates(countryId);
      setStates(response || []);
    } catch (error) {
      logErrorFromClient(error, ERROR_SPA.STATES_FETCH_ERROR, '[ModalZipcode][handleStates]', { countryId });
    }
  }, []);

  const handleCities = useCallback(async (stateId) => {
    try {
      const response = await ApiService.getCities(stateId);
      if (response) {
        setCity({ name: response[0].name, disabled: false });
      }
      setCities(response || []);
    } catch (error) {
      logErrorFromClient(error, ERROR_SPA.CITIES_FETCH_ERROR, '[ModalZipcode][handleCities]', { stateId });
    }
  }, []);

  const handleSuggestedZipCodes = useCallback(async () => {
    try {
      const response = await ApiService.getSuggestedZipcode(state.name, city.name);
      if (response.status === HTTP_STATUS.NOT_FOUND) {
        showSnackBar();
      }
      if (response.status === HTTP_STATUS.OK) {
        setZipCodesInformation(response.suggestedZipCode);
        setTitle(translations.ZIPCODE_MODAL_TITLE_LOCATION);
      }
    } catch (error) {
      logErrorFromClient(error, ERROR_SPA.SUGGESTED_LOCATIONS_FETCH_ERROR, '[ModalZipcode][handleSuggestedZipCodes]', {
        state: state.name,
        city: city.name,
      });
    }
  }, [state.name, city.name, translations.ZIPCODE_MODAL_TITLE_LOCATION]);

  const handleStateChange = (e, value) => {
    const [id, name] = value.split('|');
    setState({ id, name, value });
    setCity({ name: '', disabled: false });
    handleCities(id);
  };

  const handleCityChange = (e, values) => {
    setCity({ name: values.name, disabled: false });
  };

  const handleAddressChange = () => {
    setCities([]);
    setZipCodesInformation([]);
    setTitle(translations.ZIPCODE_MODAL_TITLE_ADDRESS);
    setZipcode('');
    setCity({ name: '', disabled: true });
    setState({ id: '', name: '' });
  };

  const handleZipCodeChange = (e, value) => {
    const index = value || 0;
    setZipcode(zipCodesInformation[index]?.zipCode);
  };

  const handleSubmit = () => {
    const formatedZipcode = formatZipcode(zipcode || zipCodesInformation[0]?.zipCode);
    populateFields((prevState) => ({
      ...prevState,
      city: city.name,
      state: state.id,
      zipcode: formatedZipcode,
    }));

    if (callback) {
      callback(formatedZipcode);
    }

    setOpen(false);
    handleAddressChange();
  };

  const handleCloseModal = () => {
    setOpen(false);
    handleAddressChange();
  };

  useEffect(() => {
    if (open) {
      handleStates();
    }
  }, [open, countryId, handleStates]);

  return (
    <ZipCodeModal
      title={title}
      statePlaceholder={translations.SELECT_STATE}
      stateLabel={translations.LABEL_STATE}
      statesList={states}
      citiesList={cities}
      handleStateChange={handleStateChange}
      handleCityChange={handleCityChange}
      handleSuggestedZipCodes={handleSuggestedZipCodes}
      handleAddressChange={handleAddressChange}
      handleZipCodeChange={handleZipCodeChange}
      handleSubmit={handleSubmit}
      selectedState={state}
      selectedCity={city}
      cityPlaceholder={translations.PLACEHOLDER_CITY_ZIP_CODE_MODAL_V2}
      cityLabel={translations.LABEL_CITY}
      findZipCodeButtonLabel={translations.FIND_ZIPCODE}
      zipCodesInformation={zipCodesInformation}
      backButtonText={translations.TO_GO_BACK}
      continueButtonText={translations.CONTINUE}
      cityErrorModifier={translations.INVALID_CITY}
      zipCodeWording={translations.ZIP_CODE_ACRONYM}
      isOpen={open}
      onClose={handleCloseModal}
      deviceType={deviceType}
    />
  );
};

ModalZipcode.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  deviceType: PropTypes.string.isRequired,
  populateFields: PropTypes.func.isRequired,
  callback: PropTypes.func,
  countryId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  snackbarActions: PropTypes.shape({}),
};

const mapDispatchToProps = (dispatch) => ({
  snackbarActions: bindActionCreators(SNACKBAR_ACTIONS, dispatch),
});

module.exports = connectToReduxAndInjectI18n(ModalZipcode, null, mapDispatchToProps);
