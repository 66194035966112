const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const { Text } = require('@andes/typography');

const Button = require('../../containers/Button/ButtonWithStore');
const { SNACKBAR_TRIGGER } = require('../../spa/actions/types');
const snackbarActions = require('../../spa/actions/snackbar');
const tracker = require('../../utils/Tracker');
const InstructionModal = require('../InstructionModal');
const IconCopy = require('../icons/Copy');
const { defaultProps } = require('../../containers/Optimus/helper');
const { BUTTON_HIERARCHY } = require('../../../constants/commons');

const { useRef, useState } = React;

const ButtonCopy = ({
  delay,
  description,
  error,
  instructions,
  label,
  success,
  kind,
  snackbarActions,
  value,
  deviceType,
  isRebranding,
}) => {
  let copied = false;
  const textArea = useRef(null);
  const [visibleInstructions, setVisibleInstructions] = useState(false);

  const onCloseModalHandler = () => {
    setVisibleInstructions(false);
    copied = false;
  };
  const copyToClipboard = (e) => {
    e.preventDefault();

    if (!document.queryCommandSupported('copy') || copied) {
      return;
    }

    copied = true;
    textArea.current.focus();
    textArea.current.select();
    const copySuccess = document.execCommand('copy');

    const snackbarType = kind === 'icon' ? 'green' : 'default';

    // set action data for success message
    const snackbarActionData = {
      show: true,
      status: copySuccess ? snackbarType : 'red',
      message: copySuccess ? success : error,
    };

    if (instructions && instructions?.steps) {
      setVisibleInstructions(true);
      return;
    }

    snackbarActions[SNACKBAR_TRIGGER](snackbarActionData);

    setTimeout(() => {
      snackbarActionData.show = false;
      snackbarActions[SNACKBAR_TRIGGER](snackbarActionData);
      copied = false;
    }, delay);
  };

  return (
    <>
      {kind === 'icon' ? (
        <button className="button_icon_copy" onClick={copyToClipboard} type="button">
          <IconCopy isRebranding={isRebranding} />
          <textarea key={Math.random()} ref={textArea} value={value} readOnly className="button_copy_textarea" />
        </button>
      ) : (
        <>
          {isRebranding && kind !== BUTTON_HIERARCHY.LOUD ? (
            <Text
              className={'cow-link'}
              size="s"
              color="link"
              component="a"
              onClick={copyToClipboard}
              weight="semibold"
            >
              {label}
            </Text>
          ) : (
            <Button type="button" text={label} kind={kind} blockLoading onClick={copyToClipboard} />
          )}
          <textarea className="button_copy_textarea" key={Math.random()} ref={textArea} value={value} readOnly />
          {visibleInstructions && instructions && (
            <InstructionModal
              {...defaultProps()}
              visible={visibleInstructions}
              className="instruction-modal"
              title={instructions?.title}
              steps={instructions?.steps}
              ctaLabel={instructions?.cta_label}
              onCloseAction={onCloseModalHandler}
              deviceType={deviceType}
            />
          )}
        </>
      )}
    </>
  );
};
/**
 * Prop Types
 */
ButtonCopy.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  kind: PropTypes.oneOf(['link', 'loud', 'quiet', 'transparent', 'icon']),
  description: PropTypes.string,
  error: PropTypes.string,
  success: PropTypes.string.isRequired,
  delay: PropTypes.number,
  instructions: PropTypes.shape({
    title: PropTypes.string,
    steps: PropTypes.arrayOf(PropTypes.string),
    cta_label: PropTypes.string,
  }),
  isRebranding: PropTypes.bool,
};

/**
 * Default Props
 */
ButtonCopy.defaultProps = {
  value: '',
  label: '',
  description: '',
  success: '',
  error: '',
  kind: 'loud',
  delay: 3000,
  id: '1',
  instructions: {},
  instructions: false,
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  snackbarActions: bindActionCreators(snackbarActions, dispatch),
});

/**
 * Expose OptionList
 */
if (process.env.NODE_ENV === 'test') {
  module.exports = ButtonCopy;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(null, mapDispatchToProps)(ButtonCopy);
}
