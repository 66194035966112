const React = require('react');

const { useState, useCallback, useMemo } = React;
const PropTypes = require('prop-types');
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');
const injectI18n = require('nordic/i18n/injectI18n');
const CreditsActivation = require('@cow/core-components/components/CreditsActivation');
const { ENVIROMENT } = require('../../../../../constants/commons');
const Page = require('../../../../components/Page');
const Skeleton = require("../../../../components/Skeleton");
const SkeletonFull = require("../../../../components/SkeletonFull");
const { COMMONS: { DEVICE_TYPE } } = require('../../../../../constants');
const STEP_ACTIONS = require('../../../../spa/actions/step');
const { useActions } = require("../../hooks/useActions");
const translation = require("../../../../translation");
const { newInterfaceGenericPropsTypes } = require('../../../../utils/propTypes');
const Markdown = require('../../../../components/Markdown');



const OpenSeaCreditsFirstUse = ({
  i18n,
  deviceType,
  flow,
  history,
  stepActions,
  urls,
  currentStep,
  trackingPath,
  analytics,
  templateCase,
  step_model: {
    step_title,
    description,
  }
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const translations = useMemo(() => translation(i18n), [i18n]);
  const { acceptConsumerCredits, dismissConsumerCredits } = useActions({ flow, history, stepActions });

  const handleAcceptCredits = useCallback(() => {
    setIsOpen(false);
    acceptConsumerCredits();
  }, [acceptConsumerCredits]);

  const handleDenyCredits = useCallback(() => {
    setIsOpen(false);
    dismissConsumerCredits();
  }, [dismissConsumerCredits]);

  return (
    <Page
      title={step_title}
      currentStep={`${currentStep}_template_${templateCase}`}
      urls={urls}
      trackingPath={trackingPath}
      analytics={analytics}
      deviceType={deviceType}
    >
      {deviceType === DEVICE_TYPE.DESKTOP ? <SkeletonFull /> : <Skeleton />}
      <CreditsActivation
        title={step_title}
        description={<Markdown text={description} />}
        acceptButton={translations.I_WANT_THE_CREDIT_LINE}
        denyButton={translations.NOT_NOW}
        open={isOpen}
        deviceType={deviceType}
        acceptCredits={handleAcceptCredits}
        denyCredits={handleDenyCredits}
      />
    </Page>
  );
};

OpenSeaCreditsFirstUse.propTypes = {
  templateCase: PropTypes.string,
  urls: PropTypes.object.isRequired,
  currentStep: PropTypes.string.isRequired,
  trackingPath: PropTypes.string.isRequired,
  analytics: PropTypes.object.isRequired,
  flow: PropTypes.object.isRequired,
  i18n: PropTypes.object,
  deviceType: PropTypes.string.isRequired,
  stepActions: PropTypes.object,
  history: PropTypes.object,
  step_model: PropTypes.shape({
    ...newInterfaceGenericPropsTypes,
    step_title: PropTypes.string,
    description: PropTypes.string,
  }),
};

OpenSeaCreditsFirstUse.defaultProps = {
  templateCase: 'default',
  flow: {},
  i18n: {},
  deviceType: DEVICE_TYPE.DESKTOP,
  stepActions: {},
  history: {},
  step_model: {
    step_title: "",
    description: "",
  },
};

const mapDispatchToProps = (dispatch) => ({
  stepActions: bindActionCreators(STEP_ACTIONS, dispatch),
});

const mapStateToProps = (state) => ({
  flow: state.page.flow,
});

if (process.env.NODE_ENV === ENVIROMENT.TEST) {
  module.exports = OpenSeaCreditsFirstUse;
} else {
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(OpenSeaCreditsFirstUse));
}
