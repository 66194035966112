/* eslint-disable react/no-unused-state */
const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');
const { injectI18n } = require('nordic/i18n');

const { injectValidations } = require('../../utils/validator-provider');
const PaymentMethodUtils = require('../../utils/PaymentMethodUtils');
const PaymentCardIcon = require('../PaymentCardIcon');
const Select = require('../Select');
const currencyUtil = require('../../utils/currency');
const creditCardActions = require('../../spa/actions/creditCard');
const { CREDIT_CARD_ISSUER } = require('../../spa/actions/types');
const translate = require('../../translation');


class CardNumberWithIssuer extends React.Component {
  constructor(props) {
    super(props);
    const { i18n } = props;
    // Default State
    this.state = {
      paymentMethod: {},
      paymentMethods: [],
      issuer: null,
      amount: currencyUtil.getFloatValue(props.amount, this.props.currencySettings),
    };
    this.translations = translate(i18n);
    this.issuerChanged = this.issuerChanged.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.pmUtils = new PaymentMethodUtils(nextProps.paymentMethods, {
      ...nextProps.currencySettings,
      max_installments: this.props.maxInstallments,
    });

    if (this.props.paymentMethods.length === 0 || !this.pmUtils) {
      this.updatePaymentMethod(nextProps);
    }


    const binChanged = this.props.guessing.bin !== nextProps.guessing.bin;
    const amountChanged = this.props.amount !== nextProps.amount;

    if (binChanged || amountChanged) {
      this.updatePaymentMethod(nextProps);
    }
  }

  /**
   * Updates current payment method with incoming props.
   * @param {Object} props - incoming props
   */
  updatePaymentMethod(props) {
    if (this.pmUtils) {
      const amount = currencyUtil.getFloatValue(props.amount, this.props.currencySettings);
      const { bin } = props.guessing;

      const paymentMethod = this.pmUtils.getPaymentMethod(bin, undefined, amount) || {};

      this.setState({
        amount,
        paymentMethod,
      });

      this.updateIssuer(paymentMethod);
    }
  }

  /**
   * Updates state's issuer taking in consideration current selected issuer.
   * If it's already selected and it matches with coming issuers, we keep it.
   * @param {Object} pm - The issuer's payment method to update with
   */
  updateIssuer(pm) {
    const issuers = pm.issuers || [];

    if (issuers.length === 1) { // Unique issuer: Set directly
      this.setState({ issuer: issuers[0] });
    } else if (this.state.issuer) { // Multi issuers: check if already selected of reset it
      const match = issuers.filter(issuer => issuer.id === this.state.issuer.id);
      if (!match.length) { this.setState({ issuer: null }); }
    } else {
      this.setState({ issuer: null });
    }

    this.notifyIssuer(issuers[0]);
  }

  /**
   * Issuers Select onChange callback
   * @param {*} e - event
   * @param {*} value - value changed
   */
  issuerChanged(e, value) {
    const issuerId = value;
    const issuer = this.state.paymentMethod.issuers.filter(i => i.id === issuerId)[0];

    this.setState({ issuer });
    this.notifyIssuer(issuer);
  }

  /**
   * Send action with issuer updates
   * @param {*} issuer - Issuer to notify on action
   */
  notifyIssuer(issuer = null) {
    this.props.creditCardActions[CREDIT_CARD_ISSUER](issuer);
  }

  /**
   * Render issuers dropdown if there are more than one issuer option
   * @param {Array} issuers - list of issuers
   */
  renderIssuerSelect(issuers = []) {
    const shouldShowSelect = issuers.length > 1;
    const options = shouldShowSelect ? issuers.map(issuer => ({
      key: issuer.id,
      value: issuer.id,
      label: issuer.name,
      selected: false,
    })) : [];

    const selectProps = {
      id: 'issuer_select',
      showPlaceholder: true,
      options,
      label: this.translations.BANK,
      onChange: this.issuerChanged,
      errors: this.props.errors,
      validations: options.length ? this.props.validations : {},
    };

    return (
      <div className={`card-number-with-issuer__group ${!options.length ? 'hidden' : ''}`}>
        <Select {...selectProps} />
      </div>
    );
  }

  render() {
    const {
      children,
      siteId,
      paymentMethods
    } = this.props;

    const issuers = this.state.paymentMethod.issuers || [];
    const { issuer } = this.state;

    let cardNumberInput = React.Children.toArray(children)[0];

    if (cardNumberInput && paymentMethods && this.pmUtils?.getAll) {
      const data = this.pmUtils.getAll();
      cardNumberInput = React.cloneElement(cardNumberInput, { data, shouldSaveValue: false });
    }

    return (
      <div className="card-number-with-issuer__wrapper">
        <div className={`card-number-with-issuer__group ${issuers.length > 1 ? 'multiple-issuers' : ''}`}>
          {cardNumberInput}
        </div>
        {this.renderIssuerSelect(issuers)}
      </div>
    );
  }
}

/**
 * Prop Types
 */
CardNumberWithIssuer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hidden_components: PropTypes.arrayOf(PropTypes.any),
  currencySettings: PropTypes.object, // eslint-disable-line
  siteId: PropTypes.string,
  amount: PropTypes.string,
  guessing: PropTypes.object, // eslint-disable-line
  paymentMethods: PropTypes.arrayOf(PropTypes.node),
  maxInstallments: PropTypes.number.isRequired,
};

/**
 * Default Props
 */
CardNumberWithIssuer.defaultProps = {
  children: null,
  hidden_components: [],
  currencySettings: {},
  siteId: '',
  amount: '',
  guessing: {},
  i18n: {
    gettext: (t, i) => t.replace('{0}', i),
  },
  paymentMethods: [],
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  creditCardActions: bindActionCreators(creditCardActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state, props) => ({
  hidden_components: state.page.data.hidden_components,
  currencySettings: state.configurations.currency,
  siteId: state.configurations.platform.siteId,
  amount: props.amount || state.inputValues.current[`${state.page.flow.step}_split_payment_amount`],
  guessing: state.creditCard.guessing,
  paymentMethods: state.paymentMethods,
  maxInstallments: state.installment.maxInstallments,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = CardNumberWithIssuer;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(injectValidations(CardNumberWithIssuer)));
}
