// DESKTOP
const ReviewDesktopProvider = require('./desktop');
const { useReviewDesktopContext } = require('./desktop/context');
// MOBILE
const ReviewMobileProvider = require('./mobile');
const { useReviewMobileContext } = require('./mobile/context');

module.exports = {
    desktop: {
        PROVIDER: ReviewDesktopProvider,
        CONTEXT: useReviewDesktopContext
    },
    mobile: {
        PROVIDER: ReviewMobileProvider,
        CONTEXT: useReviewMobileContext
    }
}