import React, { useState } from 'react';
import { Button } from '@andes/button';

import ChevronDown from '@andes/icons/ChevronDown20';
import ChevronUp from '@andes/icons/ChevronUp20';

export type ToggleButtonProps = {
  labelToggle: string;
  id: string;
  className?: string;
  onClick?: () => void;
};

export default function ToggleButton({
  labelToggle,
  id,
  className = '',
  onClick,
}: ToggleButtonProps) {
  const [iconRotation, setRotateIcon] = useState(false);

  function toggleIcon(): void {
    onClick?.();
    setRotateIcon(!iconRotation);
  }

  return (
    <Button
      className={`coupon-button ${className}`}
      hierarchy="transparent"
      size="large"
      onClick={toggleIcon}
      id={id}
    >
      {labelToggle}

      {iconRotation ? (
        <div className="cow-chevron-up">
          <ChevronUp />
        </div>
      ) : (
        <div className="cow-chevron-down">
          <ChevronDown />
        </div>
      )}
    </Button>
  );
}
