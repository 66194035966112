const React = require('react');

const IconWrapper = require('../IconWrapper');

const Copy = ({ isRebranding }) => (
  <IconWrapper className="icon-copy">
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8017 0H3.79993V2.4472H4.99993V1.2H14.6017V13.2288H13.4177V14.4288H15.8017V0ZM12.2011 3.59772H0.199219V18H12.2011V3.59772ZM1.39922 16.8V4.79772H11.0011V16.8H1.39922Z"
        fill={isRebranding ? "var(--andes-color-blue-500)" : "#009EE3"}
      />
    </svg>
  </IconWrapper>
);

module.exports = React.memo(Copy);
