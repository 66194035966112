const React = require('react');

const {Button} = require('@andes/button');
const {Snackbar} = require("@andes/snackbar");
const Footer = require("@cow/core-components/components/Footer");
const Brand = require("@cow/core-components/components/Brand");

const {bindActionCreators} = require('redux');
const PropTypes = require('prop-types');

const translate = require('../../../../translation');
const {ANDES_STYLES_THEME, COMMONS} = require('../../../../../constants');
const {getCompanyTheme} = require('../../../review/templates/utils/configurations');

const Layout = require('../../../../components/LayoutContainer');
const connectToReduxAndInjectI18n = require("../../../../utils/connectToRedux");
const useActions = require("../../hooks/useActions");
const SNACKBAR_ACTIONS = require("../../../../spa/actions/snackbar");
const STEP_ACTIONS = require("../../../../spa/actions/step");


const SwitchCombinations = require('../../components/SwitchCombinations');
const OptionListCombination = require('../../components/OptionListCombination');
const SummaryDesktop = require("../../components/Summary");

const {useState} = React;

const PaymentOptionCombinationsDesktop = (props) => {

  const translations = translate(props.i18n);

  const [validations, setValidations] = useState({});

  const {
    flow,
    siteId,
    blockedByCaptcha,
    deviceType,
    loadingPayButton,
    stepActions,
    history,
    requestActions,
    rebrandingMP,
    analytics,
    currentStep,
    trackingPath,
    urls,
    captchaToken,
    captchaConfig,
    checkoutType,
    currency,
    step_model: {
      brand,
      pcj,
      terms_and_conditions: termsAndConditions,
      main_action: mainAction,
      transaction_type,
      payment_options: paymentOptions,
      subscription,
      step_title,
      payer,
      captcha,
      summary,
      incentives,
      navigation,
      stepper,
      totalAmountPay
    },
  } = props;

  const theme = getCompanyTheme(props.configs?.internalConfigurations);

  const [selected, setSelected] = useState(paymentOptions?.length === 1 ? {
    id: paymentOptions[0].option_id,
    method: paymentOptions[0].id,
    type: paymentOptions[0].type,
  } : null);

  const {submit, snackbarMessage, showSnackbar, unableCombinations} = useActions({
    flow,
    history,
    stepActions,
    captcha,
    requestActions,
    captchaConfig,
    captchaToken,
    validations,
    selected,
    stepper,
    translations
  });

  const templateCase = props.templateCase || 'default';

  const mainContent = (
    <>
      <SwitchCombinations
        translations={translations}
        unableCombination={() => unableCombinations()}
        stepper={stepper}
      />
      <OptionListCombination
        paymentOptions={paymentOptions}
        translations={translations}
        stepper={stepper}
        selected={selected}
        handleSelect={setSelected}
        deviceType={deviceType}
        siteId={siteId}
        rebrandingMP={rebrandingMP}
        totalAmountPay={totalAmountPay}
        currency={currency}
      />
      <div className="actions-payment-option-combination">
        {stepper === 2 && <Button
          hierarchy="quiet"
          type="button"
          onClick={(event) => {
            event.preventDefault();
            window.history.back();
          }}
        >
          {translations.TO_GO_BACK}
        </Button>
        }
        <Button type="button" onClick={() => submit()}>{translations.CONTINUE}</Button>
      </div>
      <Snackbar show={showSnackbar} message={snackbarMessage}/>
    </>
  );

  const sidebarContent = (
    <>
      {brand && <Brand image={brand.avatar} name={brand.name}/>}
      {summary?.items?.length &&
        <SummaryDesktop
          {...{
            pcj,
            summary,
            translations,
            transaction_type,
            deviceType,
            mainAction,
            subscription,
            captcha,
            termsAndConditions,
            blockedByCaptcha,
            theme,
            loadingPayButton,
            siteId,
            incentives,
            payer,
          }}
        />
      }
    </>
  );

  const footerContent = (
    <>
      {
        checkoutType !== COMMONS.CHECKOUT_TYPE.MODAL &&
        <section className="layout__col-footer">
          { stepper === 1 && <Footer
            requiredMessage={translations.PROCESSED_BY_MERCADO_PAGO}
            requiredScreenReaderMessage={translations.SCREEN_READER_FOOTER_MESSAGE}
            deviceType={props.deviceType}
          />}
        </section>
      }
    </>
  );

  const stepTitle = {
    text: step_title,
    size: ANDES_STYLES_THEME.SIZES.S,
  }

  return (
    <Layout
      brand={brand}
      deviceType={deviceType}
      history={history}
      mainContent={mainContent}
      footerContent={footerContent}
      payer={payer}
      sidebarContent={sidebarContent}
      siteId={siteId}
      stepTitle={stepTitle}
      templateCase={templateCase}
      theme={theme}
      translations={translations}
      currentStep={currentStep}
      trackingPath={trackingPath}
      analytics={analytics}
      urls={urls}
      navigation={navigation}
    />
  );

};

const mapStateToProps = (state) => ({
  flow: state.page.flow,
  configs: state.configurations,
  firstRender: state.page.firstRender,
  basePath: state.configurations.basePath,
  currency: state.configurations.currency,
  captchaConfig:
  state.configurations.captcha,
  browserName:
  state.configurations.browserName,
  blockedByCaptcha: state.captchaToken.blockedByCaptcha,
  loadingPayButton: state.request.loadingPayButton,
  requestLoading: state.request.loading,
  loadingStopAnimation: state.request.loadingStopAnimation,
});

const mapDispatchToProps = (dispatch) => ({
  snackbarActions: bindActionCreators(SNACKBAR_ACTIONS, dispatch),
  stepActions: bindActionCreators(STEP_ACTIONS, dispatch),
});

PaymentOptionCombinationsDesktop.propTypes = {
  flow: PropTypes.object,
  siteId: PropTypes.string,
  blockedByCaptcha: PropTypes.bool,
  requestLoading: PropTypes.bool,
  deviceType: PropTypes.string,
  loadingPayButton: PropTypes.bool,
  loadingStopAnimation: PropTypes.bool,
  stepActions: PropTypes.object,
  history: PropTypes.object,
  requestActions: PropTypes.object,
  loadingActions: PropTypes.object,
  setEmailErrorMessage: PropTypes.func,
  emailErrorMessage: PropTypes.string,
  email: PropTypes.string,
  step_model: PropTypes.object,
  rebrandingMP: PropTypes.bool,
  analytics: PropTypes.element,
  currentStep: PropTypes.string,
  trackingPath: PropTypes.string,
  urls: PropTypes.array,
  captchaToken: PropTypes.object,
  captchaConfig: PropTypes.object,
};


module.exports = connectToReduxAndInjectI18n(
  PaymentOptionCombinationsDesktop,
  mapStateToProps,
  mapDispatchToProps
);
